import React from 'react';
import styles from './PopularTimes.module.css';

const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const DayList: React.FC = () => {
  return (
    <nav className={styles.dayList}>
      {days.map((day, index) => (
        <div key={day} className={index === 3 ? styles.activeDay : styles.day}>
          {day}
        </div>
      ))}
    </nav>
  );
};

export default DayList;
