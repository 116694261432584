import clsx from 'clsx';
import * as React from 'react';
import { CircularProgress, Box } from '@mui/material';

import { useUrl } from 'hooks/useUrl';

import styles from './TdsCheckoutModal.module.css';
import { CheckoutFormValues } from './formValues';

const jsonToQueryString = (json: { [key: string]: string }) => {
  const params = new URLSearchParams(json);
  return params.toString();
};

interface Props {
  values: CheckoutFormValues | null;
  digitalMapId: string;
  open: boolean;
  onClose?: () => void;
  onSuccessfulPayment: () => void;
}

export const TdsCheckoutModal = ({
  values,
  digitalMapId,
  open,
  onClose,
  onSuccessfulPayment,
}: Props) => {
  const [loading, setloading] = React.useState(true);

  const checkoutPath = `/maps/${digitalMapId}/checkout`;

  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  const query = {
    productInstanceId: values?.productInstance?.id || '',
    guestCounts: JSON.stringify(values?.guestCounts) || '',
    productId: values?.productInstance?.product_id || '',
  };

  const queryParam = jsonToQueryString(query);

  const url = useUrl(`${checkoutPath}?${queryParam}`);

  React.useEffect(() => {
    const iframe = iframeRef.current;

    if (!iframe) {
      return;
    }

    iframe.onload = () => {
      setloading(false);
    };

    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== window.origin) return;

      if (event.data === 'closeButtonClicked') {
        onSuccessfulPayment();
        onClose?.();
      }

      if (event.data === 'closeButtonClickedWithError') {
        onClose?.();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      iframe.onload = null;
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div className={clsx(styles['nutmeg-modal-overlay'], open ? styles['open'] : null)}>
      <div className={clsx(styles['nutmeg-modal'])}>
        <a
          className={clsx(styles['closebtn'])}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClose?.();
          }}
        >
          &times;
        </a>
        {loading && (
          <Box
            width="100%"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
        <iframe
          className={styles['nutmeg-modal-iframe']}
          width="100%"
          height="100%"
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
          ref={iframeRef}
        />
      </div>
    </div>
  );
};
