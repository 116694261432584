import * as React from 'react';

import { GoogleMap } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'ducks';

import config from 'config';
import { useGoogleMapsApi } from 'hooks/useGoogleMapsApi';
import './Map-global.css';
import styles from './Map.module.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { SchedulePane } from './SchedulePane/SchedulePane';
import { SearchContext } from './SearchContext';
import { IllustrationOverlay } from './IlustrationOverlay/IllustrationOverlay';
import { CurrentPositionMarker } from './CurrentPositionMarker';
import { CurrentLocationButton } from './CurrentLocationButton';
import { Filters, MapDisplayContext, Page, getDefaultFilters } from './MapDisplayContext';
import { SearchResults } from './SearchResults/SearchResults';
import { PinDetails } from './PinDetails/PinDetails';
import { FilterPane } from 'components/Map/FilterPane/FilterPane';
import { SearchInput } from './SearchInput/SearchInput';
import { MyAccount } from './MyAccount/MyAccount';
import { Tickets } from './Tickets/Tickets';
import { ProductSummary } from 'models/product';
import { Checkout } from './Checkout/Checkout';
import { StampModal } from './StampModal';
import { StampRallyModal } from 'components/StampRallyModal/StampRallyModal';
import { GuidancePageCoupon } from 'models/guidancePage';
import { CouponModal } from './CouponModal/CouponModal';
import { Pins } from './Pins';
import { MarketingAutomationCampaignFetcher } from './MarketingAutomationCampaignFetcher';
import { Header } from './Header/Header';
import { Notifications } from './Notifications/Notifications';
import { CheckoutFormValues } from './Checkout/formValues';
import { SavedDisplayStateLoader } from './SavedDisplayStateLoader';
import { DigitalMapPin } from 'models/digitalMap';
import { CurrentPositionProvider } from 'components/CurrentPositionProvider/CurrentPositionProvider';
import { LocationLogger } from './LocationLogger';
import { RestaurantDetails } from './RestaurantDetails/RestaurantDetails';
import { useExistingReservation } from './useExistingReservation';
import { useCustomerToken } from 'hooks/useCustomerToken';
import { lookupReservationsByAccessToken } from 'ducks/client/reservation';
import { useTranslation } from 'react-i18next';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { getCommonRedirectUri } from 'lib/util/customer';
import moment from 'moment';
import { setMarketingAutomationCampaignReservation } from 'ducks/client/marketingAutomationCampaigns';

import { getSolidBackgroundMapType } from './getSolidBackgroundMapType';
import { getTiledMapType } from './getTiledMapType';
import { SingleCategorySelect } from './SingleCategorySelect/SingleCategorySelect';
import { ImagePreloader } from './ImagePreloader';
import { useMediaQuery } from '@mui/material';
import { DesktopSearchResults } from './DesktopSearchResults/DesktopSearchResults';
import { DirectionsFooterBar } from './DirectionsFooterBar/DirectionsFooterBar';
import { CouponBottomSheet } from './CouponBottomSheet/CouponBottomSheet';
import { refreshDigitalMap } from 'ducks/universal/digitalMap';
import { usePinDisplayState } from './usePinDisplayState';
import { useRouter } from 'next/router';
import { WaitTimeUpdateNotifier } from './WaitTimeUpdateNotifier';
import OrdersPage from './Orders/OrdersPage/OrdersPage';
import { fetchAllRestaurantOrdersByAccessToken } from 'ducks/client/restaurantOrders';
import { removeNotifications } from 'ducks/client/notification';
import { StampRallyFetcher } from './StampRallyFetcher';
import { MapHead } from './MapHead';
import { FloorSelector } from './FloorSelector/FloorSelector';
import { getFloorOverlay } from './util';
import { OpenTableReservation } from './OpenTableReservation/OpenTableReservation';

export interface DirectionsRoute {
  outlinePolyline: google.maps.Polyline | null;
  pathPolyline: google.maps.Polyline | null;
  distanceInMeters: number;
  timeInSeconds: number;
  destinationPinName: string;
}

export const Map = () => {
  const directionsService = React.useRef<google.maps.DirectionsService | null>(null);
  const directionsRenderer = React.useRef<google.maps.DirectionsRenderer | null>(null);
  const [directionsRoute, setDirectionsRoute] = React.useState<DirectionsRoute | null>(null);

  const { isLoaded } = useGoogleMapsApi();
  const { i18n } = useTranslation();
  const router = useRouter();
  const reservationId = router.query.r as string;

  const [selectedFloor, setSelectedFloor] = React.useState(1);
  const [shouldFetchCustomerReservations, setShouldFetchCustomerReservations] = React.useState(
    true
  );
  const [activePage, setActivePage] = React.useState<Page>('MAP');
  const [displayMode, setDisplayMode] = React.useState<
    'BASE' | 'SEARCH_RESULTS' | 'SEARCH_INPUT' | 'DESKTOP_SEARCH_RESULTS' | 'SHOW_DIRECTIONS_ROUTE'
  >('BASE');
  const [searchText, setSearchText] = React.useState('');
  const [sortBy, setSortBy] = React.useState<'RELEVANCE' | 'DISTANCE'>('RELEVANCE');
  const [recentSearchQueries, setRecentSearchQueries] = React.useState<string[]>([]);
  const [googleMap, setGoogleMap] = React.useState<google.maps.Map | null>(null);
  const [showFilterPane, setShowFilterPane] = React.useState(false);
  const [searchResultsListPosition, setSearchResultsListPosition] = React.useState<
    'MINIMIZED' | 'MIDWAY' | 'MAXIMIZED'
  >('MINIMIZED');
  const [productInCart, setProductInCart] = React.useState<ProductSummary | null>(null);
  const [savedCartParams, setSavedCartParams] = React.useState<CheckoutFormValues | null>(null);
  const [activeStampPinIdx, setActiveStampPinIdx] = React.useState<number | null>(null);
  const [openStampRallyModal, setOpenStampRallyModal] = React.useState(false);
  const [activeCouponViewContext, setActiveCouponViewContext] = React.useState<{
    coupon: GuidancePageCoupon;
    pin: DigitalMapPin;
  } | null>(null);
  const [menuExpanded, setMenuExpanded] = React.useState<boolean>(false);
  const [activeRestaurantId, setActiveRestaurantId] = React.useState<string | null>(null);
  const [activeOpenTableRestaurantId, setActiveOpenTableRestaurantId] = React.useState<
    string | null
  >(null);
  const [orderStatusRestaurantId, setOrderStatusRestaurantId] = React.useState<string | null>(null);
  const [orderStatusRestaurantOrderId, setOrderStatusRestaurantOrderId] = React.useState<
    string | null
  >(null);
  const [shouldFetchRestaurantOrders, setShouldFetchRestaurantOrders] = React.useState(true);

  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);
  const stampRally = useSelector(
    (state: ReduxState) => state.universal.digitalGuidanceStampRally.stampRally
  );

  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  const initialSelectedCategories = digitalMap?.pin_categories
    .filter((category) => category.initially_selected)
    .map((category) => category.label);

  const [filters, setFilters] = React.useState<Filters>({
    ...getDefaultFilters(),
    ...((initialSelectedCategories || []).length > 0 && {
      categories: initialSelectedCategories,
    }),
  });

  const { activePinKey, pinInfoDisplayStatus, setPinInfoDisplayState } = usePinDisplayState();

  const { accessToken, idProvider } = useCustomerToken();
  const dispatch = useDispatch();
  const { apiKey } = React.useContext(ApiKeyContext);

  const restaurantOrderNotifications = useSelector(
    (state: ReduxState) => state.notification.restaurantOrderNotifications
  );

  React.useEffect(() => {
    if (restaurantOrderNotifications.length > 0) {
      setShouldFetchRestaurantOrders(true);
      dispatch(
        removeNotifications(restaurantOrderNotifications.map((notification) => notification.id))
      );
    }
  }, [restaurantOrderNotifications, dispatch]);

  React.useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Refresh digital map data when page becomes visible; for example, when the
        // device is unlocked after being locked.
        if (digitalMap?.id) {
          dispatch(refreshDigitalMap(apiKey, digitalMap.id, i18n.language, reservationId));
        }
      }
    };

    // Add event listener
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [apiKey, digitalMap?.id, dispatch, i18n.language, reservationId]);

  React.useEffect(() => {
    if (accessToken && shouldFetchCustomerReservations) {
      dispatch(
        lookupReservationsByAccessToken(
          apiKey,
          i18n.language,
          accessToken,
          '',
          idProvider ?? '',
          getCommonRedirectUri(),
          ['CONFIRMED'],
          moment().add(-7, 'days').format('YYYY-MM-DD'),
          undefined
        )
      );

      setShouldFetchCustomerReservations(false);
    }
  }, [accessToken, i18n.language, apiKey, dispatch, idProvider, shouldFetchCustomerReservations]);

  React.useEffect(() => {
    if (accessToken && shouldFetchRestaurantOrders) {
      dispatch(
        fetchAllRestaurantOrdersByAccessToken(
          apiKey,
          i18n.language,
          accessToken,
          '',
          idProvider ?? '',
          getCommonRedirectUri()
        )
      );

      setShouldFetchRestaurantOrders(false);
    }

    // Set up visibility change listener to refresh restaurant orders when the page becomes visible
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (accessToken) {
          dispatch(
            fetchAllRestaurantOrdersByAccessToken(
              apiKey,
              i18n.language,
              accessToken,
              '',
              idProvider ?? '',
              getCommonRedirectUri()
            )
          );
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [accessToken, i18n.language, apiKey, dispatch, idProvider, shouldFetchRestaurantOrders]);

  const background = digitalMap?.background;
  const existingReservation = useExistingReservation();
  const shouldShowOverlay = background?.image_url;

  React.useEffect(() => {
    if (existingReservation) {
      dispatch(setMarketingAutomationCampaignReservation(existingReservation));
    }
  }, [existingReservation, dispatch]);

  const onLoad = React.useCallback(
    (map: google.maps.Map) => {
      setGoogleMap(map);
      if (digitalMap) {
        map.mapTypes.set('tiled', getTiledMapType(digitalMap, selectedFloor));
        map.mapTypes.set('solid', getSolidBackgroundMapType(digitalMap));
      }

      directionsService.current = new google.maps.DirectionsService();
      directionsRenderer.current = new google.maps.DirectionsRenderer({
        map,
        suppressMarkers: true,
        polylineOptions: {
          strokeOpacity: 0,
          strokeWeight: 0,
          zIndex: 0,
        },
      });

      (map as any).setHeadingInteractionEnabled(false);

      map.setCenter({
        lat: digitalMap?.default_map_center.latitude ?? 0,
        lng: digitalMap?.default_map_center.longitude ?? 0,
      });
    },
    [digitalMap, selectedFloor]
  );

  React.useEffect(() => {
    if (googleMap && digitalMap) {
      googleMap.mapTypes.set('tiled', getTiledMapType(digitalMap, selectedFloor));
    }
  }, [googleMap, digitalMap, selectedFloor]);

  const onGetDirections = React.useCallback(
    async (origin: google.maps.LatLng, destinationPin: DigitalMapPin) => {
      const destination = new google.maps.LatLng(
        destinationPin.location.latitude,
        destinationPin.location.longitude
      );

      try {
        const resp = await directionsService.current?.route({
          origin,
          destination,
          travelMode: google.maps.TravelMode.WALKING,
        });

        if (resp) {
          // Note: directionsRenderer.setDirections() is used for centering directions. Drawing of the
          // directions is handled by the polyline below.
          directionsRenderer.current?.setDirections(resp);
        }

        const path = [
          new google.maps.LatLng(origin.lat(), origin.lng()),
          ...google.maps.geometry.encoding.decodePath(resp?.routes?.[0]?.overview_polyline ?? ''),
          new google.maps.LatLng(destination.lat(), destination.lng()),
        ];

        const distanceInMeters = resp?.routes?.[0]?.legs?.[0]?.distance?.value ?? 0;
        const timeInSeconds = resp?.routes?.[0]?.legs?.[0]?.duration?.value ?? 0;

        setDirectionsRoute({
          destinationPinName: destinationPin.title,
          distanceInMeters,
          timeInSeconds,
          outlinePolyline: new google.maps.Polyline({
            path,
            map: googleMap,
            strokeColor: '#4284F0',
            strokeOpacity: 1.0,
            strokeWeight: 12,
            zIndex: 1,
          }),
          pathPolyline: new google.maps.Polyline({
            path,
            map: googleMap,
            strokeColor: '#3AA0FF',
            strokeOpacity: 1.0,
            strokeWeight: 10,
            zIndex: 2,
          }),
        });

        if (resp?.routes?.[0]?.bounds && googleMap) {
          googleMap.fitBounds(resp.routes[0].bounds);
        }

        setPinInfoDisplayState({ pinKey: destinationPin.key, displayState: 'HIDDEN' });
        setDisplayMode('SHOW_DIRECTIONS_ROUTE');

        (googleMap as any).setHeadingInteractionEnabled(true);
      } catch (e) {
        console.log('directions failed: ', e);
      }
    },
    [googleMap]
  );

  const onUnmount = React.useCallback(() => {
    setGoogleMap(null);
  }, []);

  React.useEffect(() => {
    if (googleMap && digitalMap) {
      if (digitalMap.map_rotation) {
        googleMap?.setHeading(digitalMap.map_rotation);
      }

      const mapCapabilities = googleMap.getMapCapabilities();

      if (!mapCapabilities.isAdvancedMarkersAvailable) {
        console.warn('Advanced markers are not available on this map.');
      }
    }
  }, [googleMap, digitalMap]);

  const onClickPin = React.useCallback(
    (pinKey: string) => {
      if (displayMode === 'SHOW_DIRECTIONS_ROUTE') {
        setPinInfoDisplayState({ pinKey: null, displayState: 'HIDDEN' });
        return;
      }

      if (displayMode === 'SEARCH_RESULTS' || !isSmallScreen) {
        setPinInfoDisplayState({ pinKey, displayState: 'DETAILS' });
      } else {
        setPinInfoDisplayState({ pinKey, displayState: 'SUMMARY' });
      }
    },
    [displayMode, isSmallScreen, setPinInfoDisplayState]
  );

  // Auto-expand pin details on large screens
  React.useEffect(() => {
    if (!isSmallScreen && activePinKey && pinInfoDisplayStatus === 'SUMMARY') {
      setPinInfoDisplayState({ pinKey: activePinKey, displayState: 'DETAILS' });
    }
  }, [activePinKey, pinInfoDisplayStatus, setPinInfoDisplayState, isSmallScreen]);

  const onClickMap = React.useCallback(() => {
    if (directionsRoute) {
      return;
    }
    setPinInfoDisplayState({
      pinKey: null,
      displayState: 'HIDDEN',
    });
    if (displayMode === 'SEARCH_RESULTS') {
      setSearchResultsListPosition('MINIMIZED');
    }
  }, [directionsRoute, displayMode, setPinInfoDisplayState]);

  const mapDefaultOptions = React.useMemo(
    () => ({
      mapTypeControl: false,
      fullscreenControl: false,
      zoomControl: false,
      streetViewControl: false,
      heading: digitalMap?.map_rotation ?? 0,
      mapId: digitalMap?.background?.do_not_use_overlay_image
        ? '998d8780ce687665'
        : 'ae93e5105a43cc74',
      mapTypeId: digitalMap?.background?.do_not_use_overlay_image
        ? 'roadmap'
        : digitalMap?.background?.use_overlay_tiling
        ? 'tiled'
        : 'solid',
      zoom: digitalMap?.default_map_zoom ?? 0,
      minZoom: (digitalMap?.default_map_zoom ?? 0) - 2,
    }),
    [digitalMap?.default_map_zoom, digitalMap?.map_rotation, digitalMap?.background]
  );

  const activePin = digitalMap?.pins.find((pin) => pin.key === activePinKey) ?? null;
  const activeStampPin = digitalMap?.pins.find((pin) => pin.index === activeStampPinIdx) ?? null;

  const overlay = getFloorOverlay(digitalMap, selectedFloor);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <CurrentPositionProvider>
        <MapDisplayContext.Provider
          value={{
            activePage,
            setActivePage,
            displayMode,
            setDisplayMode,
            searchText,
            setSearchText,
            sortBy,
            setSortBy,
            filters,
            setFilters,
            showFilterPane,
            setShowFilterPane,
            searchResultsListPosition,
            setSearchResultsListPosition,
            productInCart,
            setProductInCart,
            savedCartParams,
            setSavedCartParams,
            activeStampPinIdx,
            setActiveStampPinIdx,
            showStampRallyModal: openStampRallyModal,
            setShowStampRallyModal: setOpenStampRallyModal,
            activeCouponViewContext,
            setActiveCouponViewContext,
            menuExpanded,
            setMenuExpanded,
            googleMap,
            activeRestaurantId: activeRestaurantId,
            setActiveRestaurantId: setActiveRestaurantId,
            activeOpenTableRestaurantId,
            setActiveOpenTableRestaurantId,
            orderStatusRestaurantId,
            setOrderStatusRestaurantId,
            orderStatusRestaurantOrderId,
            setOrderStatusRestaurantOrderId,
            shouldRefreshCustomerReservations: shouldFetchCustomerReservations,
            setShouldRefreshCustomerReservations: setShouldFetchCustomerReservations,
            onGetDirections,
            selectedFloor,
            setSelectedFloor,
          }}
        >
          <MapHead />
          <SavedDisplayStateLoader />
          <SearchContext.Provider
            value={{ recentQueries: recentSearchQueries, setRecentQueries: setRecentSearchQueries }}
          >
            <style jsx global>
              {`
                html,
                body {
                  overscroll-behavior: none;
                }
              `}
            </style>
            {isLoaded ? (
              <GoogleMap
                id="rich-map"
                onClick={onClickMap}
                options={mapDefaultOptions}
                mapContainerClassName={styles['map-container']}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                {shouldShowOverlay && !digitalMap?.background?.use_overlay_tiling && overlay && (
                  <>
                    <IllustrationOverlay
                      zIndex={102}
                      map={googleMap}
                      url={overlay.image_url}
                      aspectRatio={overlay.image_aspect_ratio}
                      topLeft={overlay.top_left}
                      widthInMeters={overlay.image_projection_width_in_meters}
                      heading={digitalMap?.map_rotation ?? 0}
                    />
                  </>
                )}
                {googleMap && <Pins map={googleMap} onClickPin={onClickPin} />}

                {googleMap && <CurrentPositionMarker map={googleMap} />}
              </GoogleMap>
            ) : null}
            {/* displayMode === 'BASE' && <Search /> */}
            {/* <GoogleLogo /> */}
            {googleMap && (
              <CurrentLocationButton
                map={googleMap}
                style={
                  directionsRoute
                    ? {
                        bottom: '100px',
                      }
                    : undefined
                }
              />
            )}

            <FloorSelector />

            <StampRallyFetcher />
            {stampRally && (
              <StampModal
                pin={activeStampPin}
                onClose={() => {
                  setActiveStampPinIdx(null);
                }}
                openStampRallyModal={() => setOpenStampRallyModal(true)}
                pageId={digitalMap?.id ?? ''}
                guidanceStampRally={stampRally}
                itemKey={`${activeStampPin?.index}` ?? ''}
                stampRallyLabel={activeStampPin?.stamp_rally_label ?? ''}
                stampImageUrl={activeStampPin?.stamp_image_url ?? ''}
              />
            )}
            {stampRally && (config.enableNpsSurvey || existingReservation) && (
              <StampRallyModal
                guidanceStampRally={stampRally}
                open={openStampRallyModal}
                reservation={existingReservation}
                onClose={() => setOpenStampRallyModal(false)}
              />
            )}
            {activeCouponViewContext &&
              (config.enableIAAPADemo ? (
                <CouponBottomSheet
                  coupon={activeCouponViewContext.coupon}
                  pin={activeCouponViewContext.pin}
                  onClose={() => setActiveCouponViewContext(null)}
                  pageId={digitalMap?.id ?? ''}
                />
              ) : (
                <CouponModal
                  coupon={activeCouponViewContext.coupon}
                  pin={activeCouponViewContext.pin}
                  open={true}
                  onClose={() => setActiveCouponViewContext(null)}
                  pageId={digitalMap?.id ?? ''}
                />
              ))}
            {activePin && pinInfoDisplayStatus !== 'HIDDEN' && (
              <PinDetails
                key={activePin.index}
                pin={activePin}
                onClose={() => {
                  setPinInfoDisplayState({ pinKey: null, displayState: 'HIDDEN' });
                }}
              />
            )}

            <LocationLogger reservation={existingReservation} />

            {showFilterPane && <FilterPane onClose={() => setShowFilterPane(false)} />}

            {productInCart && <Checkout />}
            {activeRestaurantId && <RestaurantDetails />}
            {orderStatusRestaurantId && <RestaurantDetails initialPage="STATUS" />}
            {activeOpenTableRestaurantId && <OpenTableReservation />}
            {displayMode === 'SEARCH_INPUT' && <SearchInput />}
            {activePage === 'SCHEDULE' && <SchedulePane />}
            {activePage === 'ACCOUNT' && <MyAccount />}
            {activePage === 'TICKETS' && <Tickets />}
            {activePage === 'NOTIFICATIONS' && <Notifications />}
            {activePage === 'ORDERS' && <OrdersPage />}
            {(displayMode === 'BASE' || displayMode === 'DESKTOP_SEARCH_RESULTS') && <Header />}
            {activePage === 'MAP' &&
              displayMode !== 'SEARCH_INPUT' &&
              displayMode !== 'SHOW_DIRECTIONS_ROUTE' &&
              !menuExpanded && <SingleCategorySelect />}

            {displayMode === 'SEARCH_RESULTS' && <SearchResults />}
            {displayMode === 'DESKTOP_SEARCH_RESULTS' && <DesktopSearchResults />}
            {displayMode === 'SHOW_DIRECTIONS_ROUTE' && directionsRoute && (
              <DirectionsFooterBar
                directionsRoute={directionsRoute}
                onClickClose={() => {
                  directionsRoute?.outlinePolyline?.setMap(null);
                  directionsRoute?.pathPolyline?.setMap(null);
                  setDirectionsRoute(null);
                  setPinInfoDisplayState({ pinKey: null, displayState: 'HIDDEN' });
                  setDisplayMode('BASE');
                  (googleMap as any).setHeadingInteractionEnabled(false);
                  (googleMap as any).setHeading(digitalMap?.map_rotation ?? 0);
                }}
              />
            )}

            {config.enableMapImagePreloading && <ImagePreloader />}

            {/*

            Marketing Automation
            ====================

            Several pieces work together to display marketing automation campaigns on the map:

            1. MarketingAutomationCampaignFetcher is the entry point that fetches instant campaigns for Map-specific events. It does not display anything.
            2. MarketingAutomationDistributor renders the appropriate MA components based on state. It's responsible for displaying popups originating from
               instant campaign fetches, websocket notifications, and web push notifications.
            3. The setMarketingAutomationCampaignReservation() action is dispatched in this file and this reservation is used by MarketingAutomationDistributor
               when displaying campaigns.
            4. setActiveMarketingAutomationCampaign() controls which campaign is currently being displayed. It is dispatched in MarketingAutomationDistributor
               when a new campaign is to be displayed and it is also dispatched from the notifications page when a previously displayed campaign is clicked.
            
            */}

            <MarketingAutomationCampaignFetcher />

            <WaitTimeUpdateNotifier />
          </SearchContext.Provider>
        </MapDisplayContext.Provider>
      </CurrentPositionProvider>
    </LocalizationProvider>
  );
};
