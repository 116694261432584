import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import styles from './TimePicker.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string;
  onChange: (newTime: string) => void;
}

export const TimePicker = ({ value, onChange }: Props) => {
  const { t } = useTranslation();

  const m = moment(value, 'HH:mm');
  const [hours, setHours] = React.useState<string>(`${m.hours()}`);
  const [minutes, setMinutes] = React.useState<string>(m.minutes().toString().padStart(2, '0'));
  const [error, setError] = React.useState<string>('');
  // const [amPm, setAmPm] = React.useState<'AM' | 'PM' | ''>('AM');

  return (
    <>
      <div>
        <div className={clsx(styles['filter__body__input__time'])}>
          <input
            type="number"
            value={hours}
            onChange={(e) => {
              const newHours = parseInt(e.target.value);
              setHours(e.target.value);

              if (!isNaN(newHours) && 0 <= newHours && newHours <= 23) {
                setError('');
                onChange(`${e.target.value}:${minutes}`);
              } else {
                setError(t('Invalid Time'));
              }
            }}
          />
          <span>:</span>
          <input
            type="number"
            value={minutes}
            onChange={(e) => {
              const newMinutes = parseInt(e.target.value);
              setMinutes(e.target.value);

              if (!isNaN(newMinutes) && 0 <= newMinutes && newMinutes <= 59) {
                setError('');
                onChange(`${hours}:${newMinutes.toString().padStart(2, '0')}`);
              } else {
                setError(t('Invalid Time'));
              }
            }}
          />
        </div>
        {error && <div className={styles['error']}>{t('Invalid Time')}</div>}
      </div>
    </>
  );
};
