import styles from './TextField.module.css';

interface Props {
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
  label?: string;
  type?: 'text' | 'email' | 'password';
  canHideInput?: boolean;
}

export const TextField = ({ value, onChange, placeholder, label, type = 'text' }: Props) => {
  return (
    <div>
      <div className={styles['label']}>{label}</div>
      <div className={styles['input-container']}>
        <input
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};
