import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../Page';
import styles from './PaymentSettings.module.css';
import { EditPaymentMethod } from './EditPaymentMethod';
import { CustomerContext } from 'contexts/CustomerContext';

interface Props {
  onBackClick: () => void;
}

export const PaymentSettings = ({ onBackClick }: Props) => {
  const [showEditPaymentMethod, setShowEditPaymentMethod] = React.useState(false);
  const { t } = useTranslation();

  const { customer } = React.useContext(CustomerContext);

  return showEditPaymentMethod ? (
    <EditPaymentMethod onBackClick={() => setShowEditPaymentMethod(false)} />
  ) : (
    <Page title={t('Payment Settings')} onBackClick={onBackClick}>
      <div className={styles['content']}>
        {customer?.payment_profile_card_info && (
          <div className={styles['card-item']} onClick={() => setShowEditPaymentMethod(true)}>
            <div className={styles['card-details']}>
              <div className={styles['card-info']}>
                <div className={styles['card-brand']}>
                  {customer?.payment_profile_card_info?.card_brand}
                </div>
                <div className={styles['card-number']}>
                  ****{customer?.payment_profile_card_info?.last_four_digits}
                </div>
              </div>
            </div>
            <img src="/static/images/ic_arrow_right.svg" className={styles['arrow-icon']} />
          </div>
        )}
        {!customer?.payment_profile_card_info && (
          <button className={styles['btn']} onClick={() => setShowEditPaymentMethod(true)}>
            {t('Add Payment method')}
          </button>
        )}
      </div>
    </Page>
  );
};
