import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { useTranslation } from 'react-i18next';
import styles from './PrivacyPolicy.module.css';

export const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const settings = useSelector((state: ReduxState) => state.server.settings.all);

  return settings.customer_ledger_settings?.privacy_policy ? (
    <div className={styles['register__item']}>
      <p className={clsx(styles['ttl'], styles['ttl--large'])}>{t('Privacy Policy')}</p>
      <div className={clsx(styles['form__item'])}>
        <div className={clsx(styles['register__item__box__pp'])}>
          {settings.customer_ledger_settings?.privacy_policy}
        </div>
      </div>
    </div>
  ) : null;
};
