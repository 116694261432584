export const mergeFormFieldResponses = (
  existingResponses: { key: string; value: string }[] = [],
  newResponses: { key: string; value: string }[]
) => {
  const newResponsesMap = newResponses.reduce((acc, response) => {
    acc[response.key] = response;
    return acc;
  }, {} as Record<string, { key: string; value: string }>);

  return existingResponses
    .filter((response) => !newResponsesMap[response.key])
    .concat(newResponses);
};
