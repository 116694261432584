import * as React from 'react';
import Image from 'next/image';
import moment from 'moment-timezone';
import clsx from 'clsx';

import { DigitalMapPin } from 'models/digitalMap';

import styles from './Result.module.css';
import { findActivePinSchedule, formattedTimeOfDay } from '../util';
import { useTranslation } from 'react-i18next';
import { AnimatedClickableDiv } from '../AnimatedClickableDiv';

interface Props {
  pin: DigitalMapPin;
  onClick: () => void;
}

export const Result = ({ pin, onClick }: Props) => {
  const { t } = useTranslation();
  const thumbnail = pin.media_items?.length > 0 ? pin.media_items[0].url : pin.icon_url;
  const now = moment();
  const openHours = findActivePinSchedule(pin.open_hour_schedules, now);
  const showtimes = findActivePinSchedule(pin.showtime_schedules, now)?.showtimes ?? [];

  const openNow =
    openHours &&
    moment(openHours?.start_time_local, 'HH:mm').isSameOrBefore(now) &&
    moment(openHours?.end_time_local, 'HH:mm').isSameOrAfter(now);

  return (
    <AnimatedClickableDiv
      className={styles['container']}
      clickedClassName={styles['clicked']}
      onClick={onClick}
    >
      <Image
        priority
        alt="thumbnail"
        width={34}
        height={34}
        className={styles['thumbnail']}
        src={thumbnail}
      />
      <div className={styles['info']}>
        <div className={styles['title']}>{pin.title}</div>
        <div className={styles['open-hours']}>
          {openHours?.start_time_local &&
            (openNow ? (
              <div className={clsx(styles['open-status'], styles['open'])}>{t('Open')}</div>
            ) : (
              <div className={clsx(styles['open-status'], styles['closed'])}>{t('Closed')}</div>
            ))}
          {openHours?.start_time_local && (
            <div className={styles['hours']}>
              {formattedTimeOfDay(openHours?.start_time_local)} -{' '}
              {formattedTimeOfDay(openHours?.end_time_local)}
            </div>
          )}
          {showtimes?.length > 0 && (
            <div className={styles['hours']}>
              {showtimes?.map((showtime) => formattedTimeOfDay(showtime)).join(', ')}
            </div>
          )}
        </div>
      </div>
    </AnimatedClickableDiv>
  );
};
