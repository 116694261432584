import * as React from 'react';

import { CustomerContext } from 'contexts/CustomerContext';
import { WebSocketConnectionReceiver } from './WebSocketConnectionReceiver';

export const CustomerWebSocketConnectionReceiver = () => {
  const { customer } = React.useContext(CustomerContext);

  if (!customer?.id) {
    return null;
  }

  return (
    <WebSocketConnectionReceiver
      name="CustomerWebSocketConnectionReceiver"
      targetId={customer.id}
    />
  );
};
