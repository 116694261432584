import clsx from 'clsx';

import styles from './HamburgerIcon.module.css';

interface Props {
  isCross: boolean;
  onClick: () => void;
}

export const HamburgerIcon = ({ onClick, isCross }: Props) => {
  return (
    <div className={clsx(styles['hamburger'], isCross && styles['toggled'])} onClick={onClick}>
      <div className={clsx(styles['line'], styles['line1'])} />
      <div className={clsx(styles['line'], styles['line2'])} />
      <div className={clsx(styles['line'], styles['line3'])} />
    </div>
  );
};
