import { Attribution, ReviewRating } from 'models/review';

export function convertReviewRatingToNumber(reviewRating: ReviewRating): number {
  return parseInt(reviewRating.replace('REVIEW_RATING_', ''));
}

export interface SearchFormValues {
  ratings: ReviewRating[];
  attributions: Attribution[];
  monthStart: number;
  monthEnd: number;
  language: string | undefined;
}
