import clsx from 'clsx';
import * as React from 'react';
import { CircularProgress } from '@mui/material';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import styles from './DatePicker.module.css';
import { getWeeksForMonth } from 'lib/util/datePickerHelpers';
import { getMonthYearFormat } from 'lib/util/i18nHelpers';

export interface DateMask {
  date: string;
  disabled: boolean;
}

interface Props {
  onClose: () => void;
  onDateChange: (d: string) => void;
  selectedDate: string;
  onMonthChange?: (m: string) => void;
  loading?: boolean;
  dateMasks?: DateMask[];
}

export const DatePicker = ({
  onClose,
  onDateChange,
  selectedDate,
  onMonthChange,
  loading,
  dateMasks,
}: Props) => {
  const [year, setYear] = React.useState<number>(moment(selectedDate).year());
  const [month, setMonth] = React.useState<string>(moment(selectedDate).format('YYYY-MM'));
  const [viewType, setViewType] = React.useState<'MONTH_SELECT' | 'DATE_SELECT'>('DATE_SELECT');
  const { i18n } = useTranslation();

  const { t } = useTranslation();
  const weekDays = [t('Sun'), t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat')];

  const weeks = getWeeksForMonth(month);

  const monthsInYear = Array.from({ length: 12 }, (_, i) =>
    moment().year(year).month(i).format('YYYY-MM')
  );

  const handleMonthChange = (m: string) => {
    setMonth(m);
    onMonthChange?.(m);
  };

  if (viewType === 'MONTH_SELECT') {
    return (
      <div className={styles['calendar-overlay']} onClick={onClose}>
        <div className={styles['calendar']} onClick={(e) => e.stopPropagation()}>
          <header className={styles['calendar-header']}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={() => setYear(year - 1)}
            >
              <path
                d="M8.5002 12.8008L14.2002 18.4008C14.6002 18.8008 15.2002 18.8008 15.6002 18.4008C16.0002 18.0008 16.0002 17.4008 15.6002 17.0008L10.7002 12.0008L15.6002 7.00078C16.0002 6.60078 16.0002 6.00078 15.6002 5.60078C15.4002 5.40078 15.2002 5.30078 14.9002 5.30078C14.6002 5.30078 14.4002 5.40078 14.2002 5.60078L8.5002 11.2008C8.1002 11.7008 8.1002 12.3008 8.5002 12.8008C8.5002 12.7008 8.5002 12.7008 8.5002 12.8008Z"
                fill="#3AA0FF"
              />
            </svg>
            <div className={styles['calendar-header-title']}>
              <h2 className={styles['calendar-header-year']}>{year}</h2>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={() => setYear(year + 1)}
            >
              <path
                d="M15.5402 11.2895L9.88023 5.63955C9.78726 5.54582 9.67666 5.47143 9.5548 5.42066C9.43294 5.36989 9.30224 5.34375 9.17023 5.34375C9.03821 5.34375 8.90751 5.36989 8.78565 5.42066C8.66379 5.47143 8.55319 5.54582 8.46023 5.63955C8.27398 5.82691 8.16943 6.08036 8.16943 6.34455C8.16943 6.60873 8.27398 6.86219 8.46023 7.04955L13.4102 12.0495L8.46023 16.9995C8.27398 17.1869 8.16943 17.4404 8.16943 17.7045C8.16943 17.9687 8.27398 18.2222 8.46023 18.4095C8.55284 18.504 8.66329 18.5792 8.78516 18.6307C8.90704 18.6822 9.03792 18.709 9.17023 18.7095C9.30253 18.709 9.43342 18.6822 9.55529 18.6307C9.67717 18.5792 9.78761 18.504 9.88023 18.4095L15.5402 12.7595C15.6417 12.6659 15.7227 12.5523 15.7781 12.4258C15.8336 12.2993 15.8622 12.1627 15.8622 12.0245C15.8622 11.8864 15.8336 11.7498 15.7781 11.6233C15.7227 11.4968 15.6417 11.3832 15.5402 11.2895Z"
                fill="#3AA0FF"
              />
            </svg>
          </header>
          <main className={styles['calendar-main']}>
            {[
              monthsInYear.slice(0, 3),
              monthsInYear.slice(3, 6),
              monthsInYear.slice(6, 9),
              monthsInYear.slice(9, 12),
            ].map((row, idx) => (
              <div className={styles['calendar-row']} key={idx}>
                {row.map((m) => (
                  <div
                    key={idx}
                    className={styles['calendar-month']}
                    onClick={() => {
                      setViewType('DATE_SELECT');
                      handleMonthChange(m);
                    }}
                  >
                    {moment(m).locale(i18n.language).format('MMM')}
                  </div>
                ))}
              </div>
            ))}
          </main>
        </div>
      </div>
    );
  }

  return (
    <div className={styles['calendar-overlay']} onClick={onClose}>
      <div className={styles['calendar']} onClick={(e) => e.stopPropagation()}>
        <header className={styles['calendar-header']}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            onClick={() =>
              handleMonthChange(moment(month, 'YYYY-MM').subtract(1, 'month').format('YYYY-MM'))
            }
          >
            <path
              d="M8.5002 12.8008L14.2002 18.4008C14.6002 18.8008 15.2002 18.8008 15.6002 18.4008C16.0002 18.0008 16.0002 17.4008 15.6002 17.0008L10.7002 12.0008L15.6002 7.00078C16.0002 6.60078 16.0002 6.00078 15.6002 5.60078C15.4002 5.40078 15.2002 5.30078 14.9002 5.30078C14.6002 5.30078 14.4002 5.40078 14.2002 5.60078L8.5002 11.2008C8.1002 11.7008 8.1002 12.3008 8.5002 12.8008C8.5002 12.7008 8.5002 12.7008 8.5002 12.8008Z"
              fill="#3AA0FF"
            />
          </svg>
          <div
            className={styles['calendar-header-title']}
            onClick={() => setViewType('MONTH_SELECT')}
          >
            <h2 className={styles['calendar-header-month']}>
              {moment(month, 'YYYY-MM')
                .locale(i18n.language)
                .format(getMonthYearFormat(i18n.language))}
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M9.74978 3.87825C9.60926 3.73856 9.41917 3.66016 9.22103 3.66016C9.02289 3.66016 8.8328 3.73856 8.69228 3.87825L5.99978 6.53325L3.34478 3.87825C3.20426 3.73856 3.01417 3.66016 2.81603 3.66016C2.61789 3.66016 2.4278 3.73856 2.28728 3.87825C2.21698 3.94797 2.16119 4.03092 2.12311 4.12232C2.08503 4.21371 2.06543 4.31174 2.06543 4.41075C2.06543 4.50976 2.08503 4.60779 2.12311 4.69918C2.16119 4.79058 2.21698 4.87353 2.28728 4.94325L5.46728 8.12325C5.537 8.19355 5.61995 8.24934 5.71135 8.28742C5.80274 8.3255 5.90077 8.3451 5.99978 8.3451C6.09879 8.3451 6.19682 8.3255 6.28821 8.28742C6.37961 8.24934 6.46256 8.19355 6.53228 8.12325L9.74978 4.94325C9.82008 4.87353 9.87587 4.79058 9.91395 4.69918C9.95202 4.60779 9.97163 4.50976 9.97163 4.41075C9.97163 4.31174 9.95202 4.21371 9.91395 4.12232C9.87587 4.03092 9.82008 3.94797 9.74978 3.87825Z"
                fill="#393D3F"
              />
            </svg>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            onClick={() =>
              handleMonthChange(moment(month, 'YYYY-MM').add(1, 'month').format('YYYY-MM'))
            }
          >
            <path
              d="M15.5402 11.2895L9.88023 5.63955C9.78726 5.54582 9.67666 5.47143 9.5548 5.42066C9.43294 5.36989 9.30224 5.34375 9.17023 5.34375C9.03821 5.34375 8.90751 5.36989 8.78565 5.42066C8.66379 5.47143 8.55319 5.54582 8.46023 5.63955C8.27398 5.82691 8.16943 6.08036 8.16943 6.34455C8.16943 6.60873 8.27398 6.86219 8.46023 7.04955L13.4102 12.0495L8.46023 16.9995C8.27398 17.1869 8.16943 17.4404 8.16943 17.7045C8.16943 17.9687 8.27398 18.2222 8.46023 18.4095C8.55284 18.504 8.66329 18.5792 8.78516 18.6307C8.90704 18.6822 9.03792 18.709 9.17023 18.7095C9.30253 18.709 9.43342 18.6822 9.55529 18.6307C9.67717 18.5792 9.78761 18.504 9.88023 18.4095L15.5402 12.7595C15.6417 12.6659 15.7227 12.5523 15.7781 12.4258C15.8336 12.2993 15.8622 12.1627 15.8622 12.0245C15.8622 11.8864 15.8336 11.7498 15.7781 11.6233C15.7227 11.4968 15.6417 11.3832 15.5402 11.2895Z"
              fill="#3AA0FF"
            />
          </svg>
        </header>
        <nav className={styles['calendar-nav']}>
          {weekDays.map((day) => (
            <div key={day} className={styles['calendar-nav-day']}>
              {day}
            </div>
          ))}
        </nav>
        <main className={styles['calendar-main']}>
          {loading ? (
            <div className={styles['loading-container']}>
              <CircularProgress />
            </div>
          ) : (
            weeks.map((week, index) => (
              <div className={styles['calendar-week']} key={index}>
                {week.days.map((day) => (
                  <div
                    key={day}
                    className={clsx(
                      styles['calendar-day'],
                      dateMasks?.find((d) => d.date === day)?.disabled && styles['disabled']
                    )}
                    onClick={() => {
                      onDateChange(day);
                      onClose();
                    }}
                  >
                    {moment(day).format('D')}
                  </div>
                ))}
              </div>
            ))
          )}
        </main>
      </div>
    </div>
  );
};
