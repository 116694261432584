import * as React from 'react';
import styles from './PopularTimes.module.css';
import DayList from './DayList';
import Chart from './Chart';
import LiveInfo from './LiveInfo';
import { DigitalMapPin } from 'models/digitalMap';
import { useTranslation } from 'react-i18next';
import { Toggle } from 'components/Map/Toggle';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectwaitTimeNotificationPinKeys,
  toggleWaitTimeNotificationPinKey,
} from 'ducks/client/mapUserSettings';

const PopularTimes: React.FC<{ pin: DigitalMapPin }> = ({ pin }) => {
  const waitTimeNotificationPinKeys = useSelector(selectwaitTimeNotificationPinKeys);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h2 className={styles.title}>Popular Times</h2>
        <p className={styles.subtitle}>
          People usually spend <span className={styles.bold}>1-1.5 hours</span> here.
        </p>
      </header>
      <div className={styles.timeChart}>
        <DayList />
        <Chart />
        <LiveInfo pin={pin} />
      </div>
      <div className={styles.toggle}>
        <Toggle
          label={t('Notify me when wait is less than 30 minutes')}
          onChange={() => dispatch(toggleWaitTimeNotificationPinKey(pin.key))}
          checked={waitTimeNotificationPinKeys.includes(pin.key)}
        />
      </div>
    </div>
  );
};

export default PopularTimes;
