import { throttle } from 'lodash';

import {
  EventType,
  logCustomerEventByVisitorIdentifierWithNoSideEffects,
} from 'ducks/client/customerEvents';
import * as React from 'react';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { Reservation } from 'models/reservation';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useVisitorIdentifier, VisitorIdentifier } from 'hooks/useVisitorIdentifier';

interface Props {
  reservation?: Reservation | null;
}

export const LocationLogger = ({ reservation }: Props) => {
  const { apiKey } = React.useContext(ApiKeyContext);
  const { i18n } = useTranslation();

  const visitorToken = useSelector((state: ReduxState) => state.tracking.visitorToken);
  const router = useRouter();
  const path = router.asPath;

  const visitorIdentifier = useVisitorIdentifier();
  const stringifiedVisitorIdentifier = JSON.stringify(visitorIdentifier);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLogCustomerEvent = React.useCallback(
    throttle(
      ({
        apiKey,
        eventType,
        visitorToken,
        visitorIdentifier,
        metadata,
      }: {
        apiKey: string;
        eventType: EventType;
        visitorToken: string;
        visitorIdentifier: VisitorIdentifier;
        metadata?: string;
      }) =>
        logCustomerEventByVisitorIdentifierWithNoSideEffects({
          apiKey,
          eventType,
          visitorToken,
          visitorIdentifier,
          metadata,
        }),
      5000,
      {
        leading: true,
      }
    ),
    []
  );

  React.useEffect(() => {
    const parsedVisitorIdentifier = JSON.parse(stringifiedVisitorIdentifier) as VisitorIdentifier;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        debouncedLogCustomerEvent({
          apiKey,
          eventType: 'location',
          visitorToken,
          visitorIdentifier: parsedVisitorIdentifier,
          metadata: JSON.stringify({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            page: path,
            page_title: document.title,
            lang: i18n.language,
            site: 'onsite',
          }),
        });
      },
      (error) => {
        console.info('#### location Error getting current position:', error);
      },
      { enableHighAccuracy: true }
    );
    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, [
    apiKey,
    debouncedLogCustomerEvent,
    reservation,
    visitorToken,
    i18n.language,
    path,
    stringifiedVisitorIdentifier,
  ]);

  return null;
};
