import axios from 'axios';
import { Button, Box } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import config from 'config';
import { Modal } from 'components/Modal/Modal';
import { GuidancePageCoupon } from 'models/guidancePage';
import { redeemCoupon } from 'ducks/client/coupons';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { ReduxState } from 'ducks';

import styles from './CouponModal.module.css';
import { triggerSalesOffer } from 'ducks/client/salesOffers';
import { logCustomerEvent } from 'ducks/client/customerEvents';
import { fetchMarketingAutomationCampaigns } from 'ducks/client/marketingAutomationCampaigns';
import { DigitalMapPin } from 'models/digitalMap';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';
import { outOfRange } from '../util';
import { useExistingReservation } from '../useExistingReservation';

interface Props {
  onClose: () => void;
  open: boolean;
  coupon: GuidancePageCoupon;
  pin: DigitalMapPin;
  pageId: string;
  salesOfferId?: string;
  insertRoot?: boolean;
}

export const CouponModal = ({
  coupon,
  pin,
  onClose,
  open,
  pageId,
  salesOfferId,
  insertRoot,
}: Props) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { apiKey } = React.useContext(ApiKeyContext);

  const [submittedTime, setSubmittedTime] = React.useState('');

  const submitting = useSelector((state: ReduxState) => state.coupons.loading);
  const visitorToken = useSelector((state: ReduxState) => state.tracking.visitorToken);

  const { currentPosition } = React.useContext(CurrentPositionContext);

  const outOfCouponRange = pin.activation_range
    ? outOfRange(currentPosition, pin.location, pin.activation_range)
    : false;

  const existingReservation = useExistingReservation();

  const { t } = useTranslation();

  return (
    <Modal
      onClose={() => {
        if (submittedTime) {
          dispatch(
            triggerSalesOffer({
              triggerType: 'ONLINE_GUIDE_AFTER_COUPON_REDEMPTION',
              couponId: coupon.id,
            })
          );

          if (existingReservation) {
            dispatch(
              fetchMarketingAutomationCampaigns(
                apiKey,
                existingReservation.id,
                'online-guide-after-coupon-redemption',
                i18n.language,
                JSON.stringify({
                  couponId: coupon.id,
                })
              )
            );

            dispatch(
              logCustomerEvent({
                apiKey,
                eventType: 'online-guide-after-coupon-redemption',
                visitorToken,
                reservationId: existingReservation.id,
                metadata: JSON.stringify({
                  couponId: coupon.id,
                }),
              })
            );
          }
        }
        onClose?.();
      }}
      open={open}
      insertRoot={insertRoot}
    >
      <Box className={styles['title']} display="flex" justifyContent="center">
        <h1>{coupon.title}</h1>
      </Box>
      {submittedTime ? (
        <Box mt={1}>
          <Box mt={2} display="flex" justifyContent="center">
            <div>
              <img height={200} width={200} src="/static/images/ic_thankyou.png" />
            </div>
          </Box>
          <Box mt={1} display="flex" justifyContent="center">
            {t('Time of use: {{time}}', { time: submittedTime })}
          </Box>
        </Box>
      ) : (
        <>
          <Box mt={1}>
            <p className="newline">{coupon.description}</p>
          </Box>
          {outOfCouponRange && (
            <Box mt={2} className={styles['out-of-range-note']}>
              {t(
                '* To redeem the coupon, you must be within {{distance}} meters of the designated location.',
                {
                  distance: pin.activation_range,
                }
              )}
            </Box>
          )}
          {!existingReservation && (
            <Box mt={2} className={styles['out-of-range-note']}>
              {t('* To redeem the coupon, you must book at least one ticket.')}
            </Box>
          )}
          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              disabled={!existingReservation || submitting || outOfCouponRange}
              onClick={async () => {
                await dispatch(
                  redeemCoupon(apiKey, {
                    reservation_id: existingReservation?.id ?? '',
                    coupon_id: coupon.id,
                    page_id: pageId,
                  })
                );
                if (salesOfferId) {
                  axios.post(
                    `${config.apiUrl}/salesofferevents`,
                    {
                      sales_offer_id: salesOfferId,
                      reservation_id: existingReservation?.id ?? '',
                      event_type: 'CONVERSION',
                    },
                    {
                      headers: { 'x-api-key': apiKey },
                    }
                  );
                }
                const now = moment().format('YYYY/MM/DD HH:mm');
                setSubmittedTime(now);
              }}
              variant="contained"
              color="primary"
            >
              {t('Use Coupon')}
            </Button>
          </Box>
        </>
      )}
    </Modal>
  );
};
