import * as React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { MapDisplayContext } from '../MapDisplayContext';

import styles from './FilterPane.module.css';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { DigitalMap } from 'models/digitalMap';
import { SingleOptionSelect } from './SingleOptionSelect';
import { TimePicker } from '../TimePicker';
import { AnimatedButton } from '../AnimatedButton';

const getStructuredInfoValues = (label: string, digitalMap: DigitalMap): string[] => {
  return _.uniq(
    _.flatten(
      digitalMap.pins.map(
        (pin) =>
          pin.structured_info_items.find((item) => item.info_category_key === label)?.values ?? []
      )
    )
  );
};

interface Props {
  onClose: () => void;
}

export const FilterPane = ({ onClose }: Props) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const { t } = useTranslation();
  const { filters, setFilters } = React.useContext(MapDisplayContext);
  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);
  const innerContainerRef = React.useRef<HTMLDivElement>(null);
  const allTags = _.uniq(digitalMap?.pins.map((pin) => pin.tags).flat() ?? []);

  const structuredInfoItems =
    digitalMap?.structured_info_categories?.map((category) => ({
      label: category.label,
      values: getStructuredInfoValues(category.label, digitalMap),
    })) ?? [];

  React.useEffect(() => {
    setIsExpanded(true);
  }, []);

  const timeSelectedOption = filters.openNow ? 'OPEN_NOW' : filters.openAt ? 'CUSTOM' : 'ANY';
  const showtimesSelectedOption = filters.showTimeFrom || filters.showTimeTo ? 'CUSTOM' : 'ANY';

  return (
    <div className={clsx(styles['filter'], isExpanded && styles['is-active'])}>
      <div ref={innerContainerRef} className={styles['inner-container']}>
        <div className={styles['header']}>
          <AnimatedButton
            className={styles['filter-btn']}
            clickedClassName={styles['clicked']}
            onClick={() => {
              onClose();
            }}
          >
            <img width="20px" height="20px" src="/static/images/map/filter_white.svg" />
          </AnimatedButton>
          <h2 className={styles['header-text']}>{t('Filters')}</h2>
        </div>

        <h3 className={styles['tags']}>{t('Tags')}</h3>
        <div className={styles['tag-container']}>
          {allTags.map((tag) => (
            <div
              key={tag}
              className={clsx(styles['tag'], filters.tags.includes(tag) && styles['active'])}
              onClick={() => {
                setFilters({
                  ...filters,
                  tags: filters.tags.includes(tag)
                    ? filters.tags.filter((t) => t !== tag)
                    : [...filters.tags, tag],
                });
              }}
            >
              {tag}
            </div>
          ))}
        </div>
        {structuredInfoItems.map((item) => (
          <>
            <h3 className={styles['subheader']}>{item.label}</h3>

            <div className={styles['tag-container']}>
              {item.values.map((val) => (
                <div
                  key={val}
                  className={clsx(
                    styles['tag'],
                    (
                      filters.structuredInfoItems.find((i) => i.info_category_key === item.label)
                        ?.values ?? []
                    ).includes(val) && styles['active']
                  )}
                  onClick={() => {
                    const existingOptions = filters.structuredInfoItems.find(
                      (i) => i.info_category_key === item.label
                    )?.values;

                    const newOptions = existingOptions?.includes(val)
                      ? existingOptions?.filter((v) => v !== val)
                      : [...(existingOptions ?? []), val];

                    setFilters({
                      ...filters,
                      structuredInfoItems: existingOptions
                        ? filters.structuredInfoItems.map((i) =>
                            i.info_category_key === item.label
                              ? { info_category_key: item.label, values: newOptions }
                              : i
                          )
                        : [
                            ...filters.structuredInfoItems,
                            {
                              info_category_key: item.label,
                              values: newOptions,
                            },
                          ],
                    });
                  }}
                >
                  {val}
                </div>
              ))}
            </div>
          </>
        ))}
        <h3 className={styles['subheader']}>{t('Time')}</h3>
        <SingleOptionSelect
          options={[
            { value: 'ANY', label: t('Any') },
            { value: 'OPEN_NOW', label: t('Open Now') },
            { value: 'CUSTOM', label: t('Custom') },
          ]}
          selectedOption={timeSelectedOption}
          onChange={(newOption) => {
            if (newOption === 'ANY') {
              setFilters({ ...filters, openNow: false, openAt: '' });
            } else if (newOption === 'OPEN_NOW') {
              setFilters({ ...filters, openNow: true, openAt: '' });
            } else {
              setFilters({ ...filters, openNow: false, openAt: moment().format('HH:mm') });
            }
          }}
        />
        {filters.openAt && (
          <div className={styles['time-picker-container']}>
            <TimePicker
              value={filters.openAt}
              onChange={(newTime) => {
                setFilters({
                  ...filters,
                  openAt: newTime,
                });
              }}
            />
          </div>
        )}
        <h3 className={styles['subheader']}>{t('Show Times')}</h3>
        <SingleOptionSelect
          options={[
            { value: 'ANY', label: t('Any') },
            { value: 'CUSTOM', label: t('Custom') },
          ]}
          selectedOption={showtimesSelectedOption}
          onChange={(newOption) => {
            if (newOption === 'ANY') {
              setFilters({ ...filters, showTimeFrom: '', showTimeTo: '' });
            } else {
              setFilters({
                ...filters,
                showTimeFrom: moment().format('HH:mm'),
                showTimeTo: moment().add(1, 'hours').format('HH:mm'),
              });
              setTimeout(() => {
                innerContainerRef.current?.scrollBy({ top: 83, left: 0, behavior: 'smooth' });
              }, 100);
            }
          }}
        />
        {filters.showTimeFrom && filters.showTimeTo && (
          <div className={styles['time-picker-container']}>
            <div>
              <p>{t('From')}</p>
              <TimePicker
                value={filters.showTimeFrom}
                onChange={(newTime) => {
                  setFilters({
                    ...filters,
                    showTimeFrom: newTime,
                  });
                }}
              />
            </div>
            <div>
              <p>{t('To')}</p>
              <TimePicker
                value={filters.showTimeTo}
                onChange={(newTime) => {
                  setFilters({
                    ...filters,
                    showTimeTo: newTime,
                  });
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles['actions']}>
        <AnimatedButton
          className={styles['reset-btn']}
          clickedClassName={styles['clicked']}
          onClick={() => {
            setFilters({
              ...filters,
              tags: [],
              structuredInfoItems: [],
              openNow: false,
              openAt: '',
              showTimeFrom: '',
              showTimeTo: '',
              noWait: false,
            });
          }}
        >
          {t('Reset All')}
        </AnimatedButton>
        <AnimatedButton
          className={styles['apply-btn']}
          clickedClassName={styles['clicked']}
          onClick={onClose}
        >
          {t('Apply')}
        </AnimatedButton>
      </div>
    </div>
  );
};
