import * as React from 'react';
import moment from 'moment-timezone';

import styles from './Reviews.module.css';
import Stars from '../Stars/Stars';
import { Review as ReviewType } from 'models/review';
import { useTranslation } from 'react-i18next';
import { ReduxState } from 'ducks';
import { useDispatch, useSelector } from 'react-redux';
import { DigitalMapPin } from 'models/digitalMap';
import { ProductSummary } from 'models/product';
import { fetchReviews } from 'ducks/universal/reviews';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { convertReviewRatingToNumber } from 'components/ProductReviewsPane/review.utils';

interface ReviewProps {
  review: ReviewType;
  productName: string;
}

const Review: React.FC<ReviewProps> = ({ review, productName }) => {
  const { i18n } = useTranslation();

  return (
    <article className={styles['review']}>
      <header>
        <p className={styles['review-avatar']}>{review.guest_nickname.substring(0, 1)}</p>
        <div className={styles['review-guest-name']}>{review.guest_nickname}</div>
      </header>
      <Stars rating={convertReviewRatingToNumber(review.rating)} />
      <h3 className={styles['review-comment']}>{review.title}</h3>
      <p className={styles['review-date']}>
        {productName}
        {`  `}
        <time>
          {i18n.language === 'en-US'
            ? moment(review.written_date_time_utc).locale(i18n.language).fromNow()
            : moment(review.written_date_time_utc).format('DD/MM/YYYY')}
        </time>
      </p>
      <p className={styles['review-text']}>{review.body}</p>
    </article>
  );
};

interface Props {
  pin: DigitalMapPin;
}

export const Reviews = ({ pin }: Props) => {
  const { t, i18n } = useTranslation();
  const productSummaries = useSelector((state: ReduxState) => state.server.productSummaries.all);
  const bookableProductsWithReviews: ProductSummary[] = [];
  const dispatch = useDispatch();
  const { apiKey } = React.useContext(ApiKeyContext);

  pin.associated_product_ids?.forEach((id) => {
    const productSummary = productSummaries.find((product) => product.id === id);

    if (productSummary) {
      if (productSummary.review_count) {
        bookableProductsWithReviews.push(productSummary);
      }
    }
  });

  const [selectedProductId, setSelectedProductId] = React.useState<string | null>(
    bookableProductsWithReviews?.length ? bookableProductsWithReviews[0].id : null
  );

  React.useEffect(() => {
    if (selectedProductId) {
      dispatch(
        fetchReviews(apiKey, i18n.language, { productIds: [selectedProductId], pageSize: 20 })
      );
    }
  }, [selectedProductId, apiKey, i18n.language, dispatch]);

  const productOptions = bookableProductsWithReviews.map((product) => ({
    value: product.id,
    label: product.product_name,
  }));
  const selectedProduct = bookableProductsWithReviews.find(
    (product) => product.id === selectedProductId
  );
  let reviewRating = 0;
  let reviewCount = 0;
  if (selectedProduct?.review_count) {
    reviewRating = (selectedProduct?.review_rating ?? 0) / selectedProduct.review_count;
    reviewCount = selectedProduct.review_count;
  }

  const reviews = useSelector((state: ReduxState) => state.universal.reviews.all);

  return (
    <section className={styles['reviews-container']} id="review">
      <header>
        <h2>{t('Customer Reviews')}</h2>
        <div className={styles['product-type']}>
          <select
            value={selectedProductId ?? ''}
            onChange={(e) => setSelectedProductId(e.target.value)}
          >
            {productOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6992e0d80476aa79941c9e7c768258e0f5895e6d2db48a924192d30243da2b2d?apiKey=3d59b5c84d524ccda3d6150a37390818&"
            alt="Waves Icon"
            className={styles['wave-icon']}
          />
        </div>
        <div className={styles['product-rating']}>
          <Stars rating={reviewRating} />
          <p className={styles['reviews-count']}>
            {t('{{reviewCount}} reviews', {
              reviewCount,
            })}
          </p>
        </div>
      </header>
      {/*
      <section className={styles["photos-section"]}>
        <h2>Photos</h2>
        <div className={styles["photos-carousel"]}>
          <Image
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ce427d2dd2eb959c8e3bbd9ee43a4356049598e60f09f23117e2dda1698a2f5a?apiKey=3d59b5c84d524ccda3d6150a37390818&"
            alt="Photo 1"
            className={styles["photo-large"
          />
          <div className={styles["additional-photos"]}>
            <Image
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b28292b951e5cd3ea4e6799e59689831bef7077d38ed3d4c74b531a3f49b3c9d?apiKey=3d59b5c84d524ccda3d6150a37390818&"
              alt="Photo 2"
              className={styles["photo-small"
            />
            <Image
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6bf64b406614c6eb15b03fd42965d65a7c8ef2fce0957f7a5f10c6e0f3dd583?apiKey=3d59b5c84d524ccda3d6150a37390818&"
              alt="Photo 3"
              className={styles["photo-small"
            />
          </div>
        </div>
      </section>
        */}
      <section className={styles['reviews-section']}>
        <div className={styles['reviews-section-header']}>{t('Reviews')}</div>
        {reviews?.map((review, idx) => (
          <Review key={idx} review={review} productName={selectedProduct?.product_name ?? ''} />
        ))}
      </section>
    </section>
  );
};
