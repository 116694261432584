import { useRouter } from 'next/router';
import { useUrl } from './useUrl';

// useMapUrl is used to get the URL for the current map.
// Map page types:
// - /
// - /:apiKey/maps/:id
// - /maps/:id
export const useMapUrl = (): string => {
  const router = useRouter();
  const mapId = router.query.id;
  const mapUrl = useUrl(`/maps/${mapId}`);

  return mapId ? mapUrl : '';
};
