import moment from 'moment-timezone';

import { BookingDeadline, ProductInstance } from 'models/productInstance';

const getBookingDeadline = (
  productInstance: ProductInstance,
  confirmationType: 'INSTANT' | 'REQUEST'
): BookingDeadline | null => {
  return (
    ((productInstance && productInstance.booking_deadlines) || []).find(
      (deadline) => deadline.confirmation_type === confirmationType
    ) || null
  );
};

const getBookingDeadlineMoment = (
  bookingDeadline: BookingDeadline | null,
  timezone: string
): moment.Moment | null => {
  return bookingDeadline && moment.tz(bookingDeadline.date_time_utc, timezone);
};

const bookingDeadlineIsExpired = (
  bookingDeadline: BookingDeadline | null,
  timezone: string
): boolean => {
  const bookingDeadlineMoment = getBookingDeadlineMoment(bookingDeadline, timezone);
  return !bookingDeadlineMoment || moment().isAfter(bookingDeadlineMoment);
};

export const productInstanceIsDisabled = (
  productInstance: ProductInstance,
  timezone: string
): boolean => {
  if (
    allBookingDeadlinesExpired(productInstance, timezone) ||
    productInstance.is_closed ||
    productInstance.is_fully_booked ||
    (bookingDeadlineIsExpired(getBookingDeadline(productInstance, 'REQUEST'), timezone) &&
      getAvailableSlots(productInstance) <= 0)
  ) {
    return true;
  }

  return false;
};

export const productInstanceIsInstantConfirmation = (
  productInstance: ProductInstance,
  timezone: string
): boolean => {
  return (
    !productInstanceIsDisabled(productInstance, timezone) &&
    !productInstanceIsRequestOnly(productInstance, timezone)
  );
};

export const productInstanceIsRequestOnly = (
  productInstance: ProductInstance,
  timezone: string
): boolean => {
  if (productInstanceIsDisabled(productInstance, timezone)) {
    return false;
  }

  const instantDeadlineExpired = bookingDeadlineIsExpired(
    getBookingDeadline(productInstance, 'INSTANT'),
    timezone
  );
  const requestDeadlineExpired = bookingDeadlineIsExpired(
    getBookingDeadline(productInstance, 'REQUEST'),
    timezone
  );
  const availableSlots = getAvailableSlots(productInstance);
  return (availableSlots <= 0 || instantDeadlineExpired) && !requestDeadlineExpired;
};

const getAvailableSlots = (productInstance: ProductInstance): number => {
  return productInstance.available_slots || 0;
};

export const allBookingDeadlinesExpired = (p: ProductInstance, timezone: string): boolean => {
  const now = moment();
  for (const bookingDeadline of p.booking_deadlines) {
    if (now.isBefore(moment.tz(bookingDeadline.date_time_utc, timezone))) {
      return false;
    }
  }

  return true;
};
