import React from 'react';
import styles from './MobileOrderNotificationCard.module.css';
import { useTranslation } from 'react-i18next';
import { MarketingAutomationCampaign } from 'models/marketingAutomationCampaign';
import {
  getContentDescription,
  getContentName,
  getHeaderTitle,
} from 'components/MarketingAutomationV2/MarketingAutomationPopup/utils';
import { useDispatch } from 'react-redux';
import { setActiveMarketingAutomationCampaign } from 'ducks/client/marketingAutomationCampaigns';
import { useMapDisplayContext } from '../MapDisplayContext';

interface Props {
  campaign: MarketingAutomationCampaign;
}

export const MarketingAutomationNotificationCard: React.FC<Props> = ({ campaign }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setActivePage } = useMapDisplayContext();

  const webPopupContent = campaign.content.website_popup_content;

  const headerTitle = getHeaderTitle(webPopupContent.content_type, t);
  const title = getContentName(webPopupContent);
  const description = getContentDescription(webPopupContent, t);

  return (
    <section
      className={styles.container}
      onClick={() => {
        dispatch(setActiveMarketingAutomationCampaign(campaign));
        setActivePage('MAP');
      }}
    >
      <p className={styles.categoryHeader}>{headerTitle}</p>
      <div className={styles.contentWrapper}>
        <img src="/static/images/map/notification_bell_2.svg" />
        <div className={styles.textContent}>
          <h2 className={styles.title}>{title}</h2>
          {description && <p className={styles.description}>{description}</p>}
        </div>
      </div>
    </section>
  );
};
