import { Moment } from 'moment-timezone';

export const formatDateTime = (dateTime: Moment, language: string) => {
  return formatDate(dateTime, language) + formatTime(dateTime, language);
};

export const formatDate = (dateTime: Moment, language: string) => {
  return language === 'ja-JP' ? dateTime.format('YYYY年MM月DD日') : dateTime.format('YYYY/MM/DD');
};

export const formatTime = (dateTime: Moment, language: string) => {
  return language === 'ja-JP'
    ? dateTime.format('HH:mm')
    : dateTime.format('hh:mm') + (dateTime.hours() >= 12 ? 'PM' : 'AM');
};
