import { useTranslation } from 'react-i18next';

import { MenuItem } from 'models/digitalMap';
import { CategoryItemCard } from './CategoryItemCard';
import styles from './FilterResultsSection.module.css';

interface Props {
  items: MenuItem[];
  onClickItem: (item: MenuItem) => void;
}

export const FilterResultsSection = ({ items, onClickItem }: Props) => {
  const { t } = useTranslation();

  return (
    <section className={styles.section}>
      <div className={styles.itemsContainer}>
        {!items.length && (
          <div className={styles['no-results-container']}>{t('Sorry, no results found')}</div>
        )}

        {items.map((item) => (
          <a key={item.name} onClick={() => onClickItem(item)}>
            <CategoryItemCard item={item} />
          </a>
        ))}
      </div>
    </section>
  );
};
