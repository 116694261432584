import { BackButton } from '../Components/BackButton';
import styles from './Page.module.css';

interface Props {
  children: React.ReactNode;
  title: string;
  onBackClick?: () => void;
}

export const Page = ({ children, title, onBackClick }: Props) => {
  return (
    <div className={styles['page-container']}>
      <div className={styles['inner']}>
        {onBackClick && (
          <BackButton
            onClick={onBackClick}
            headerContent={<div className={styles['title']}>{title}</div>}
          />
        )}

        {children}
      </div>
    </div>
  );
};
