import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import styles from './CouponBottomSheet.module.css';
import { GuidancePageCoupon } from 'models/guidancePage';
import { DigitalMapPin } from 'models/digitalMap';
import { useDispatch } from 'react-redux';
import { useExistingReservation } from 'components/Map/useExistingReservation';
import { outOfRange } from 'components/Map/util';
import { useSelector } from 'react-redux';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';
import { ReduxState } from 'ducks';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { triggerSalesOffer } from 'ducks/client/salesOffers';
import { fetchMarketingAutomationCampaigns } from 'ducks/client/marketingAutomationCampaigns';
import { logCustomerEvent } from 'ducks/client/customerEvents';
import { redeemCoupon } from 'ducks/client/coupons';

interface CouponBottomSheetProps {
  coupon: GuidancePageCoupon;
  pin: DigitalMapPin;
  onClose: () => void;
  pageId: string;
}

export const CouponBottomSheet: React.FC<CouponBottomSheetProps> = ({
  coupon,
  pin,
  onClose,
  pageId,
}) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { apiKey } = React.useContext(ApiKeyContext);

  const [submittedTime, setSubmittedTime] = React.useState('');

  const submitting = useSelector((state: ReduxState) => state.coupons.loading);
  const visitorToken = useSelector((state: ReduxState) => state.tracking.visitorToken);

  const { currentPosition } = React.useContext(CurrentPositionContext);

  const outOfCouponRange = pin.activation_range
    ? outOfRange(currentPosition, pin.location, pin.activation_range)
    : false;

  const existingReservation = useExistingReservation();

  const { t } = useTranslation();

  return (
    <section className={styles.offerContainer}>
      <div className={styles.mapAttractions}>
        <div className={styles.overlay}>
          <div className={styles.contentWrapper}>
            <button
              className={styles.closeButton}
              onClick={() => {
                if (submittedTime) {
                  dispatch(
                    triggerSalesOffer({
                      triggerType: 'ONLINE_GUIDE_AFTER_COUPON_REDEMPTION',
                      couponId: coupon.id,
                    })
                  );

                  if (existingReservation) {
                    dispatch(
                      fetchMarketingAutomationCampaigns(
                        apiKey,
                        existingReservation.id,
                        'online-guide-after-coupon-redemption',
                        i18n.language,
                        JSON.stringify({
                          couponId: coupon.id,
                        })
                      )
                    );

                    dispatch(
                      logCustomerEvent({
                        apiKey,
                        eventType: 'online-guide-after-coupon-redemption',
                        visitorToken,
                        reservationId: existingReservation.id,
                        metadata: JSON.stringify({
                          couponId: coupon.id,
                        }),
                      })
                    );
                  }
                }
                onClose?.();
              }}
            >
              <img
                src="/static/images/map/close_white.svg"
                alt="Close"
                className={styles.closeIcon}
              />
            </button>
            <article className={styles.offerCard}>
              {coupon.thumbnail_url && (
                <div className={styles.imageWrapper}>
                  <img
                    src={coupon.thumbnail_url}
                    alt={coupon.title}
                    className={styles.offerImage}
                  />
                </div>
              )}
              <div className={styles.offerDetails}>
                <div className={styles.offerContent}>
                  <div className={styles.offerText}>
                    <header className={styles.offerHeader}>
                      <p className={styles.limitedTimeOffer}>{t('LIMITED TIME OFFER')}</p>
                      <h2 className={styles.offerTitle}>{coupon.title}</h2>
                    </header>

                    {submittedTime ? (
                      <p className={styles.offerDescription}>
                        {t('Time of use: {{time}}', { time: submittedTime })}
                      </p>
                    ) : (
                      <>
                        <p className={styles.offerDescription}>{coupon.description}</p>

                        {outOfCouponRange && (
                          <p className={styles.outOfRangeNote}>
                            {t(
                              '* To redeem the coupon, you must be within {{distance}} meters of the designated location.',
                              { distance: pin.activation_range }
                            )}
                          </p>
                        )}

                        {!existingReservation && (
                          <p className={styles.outOfRangeNote}>
                            {t('* To redeem the coupon, you must book at least one ticket.')}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.buttonGroup}>
                  {!submittedTime && (
                    <button
                      disabled={!existingReservation || submitting || outOfCouponRange}
                      className={styles.redeemButton}
                      onClick={async () => {
                        await dispatch(
                          redeemCoupon(apiKey, {
                            reservation_id: existingReservation?.id ?? '',
                            coupon_id: coupon.id,
                            page_id: pageId,
                          })
                        );
                        const now = moment().format('YYYY/MM/DD HH:mm');
                        setSubmittedTime(now);
                      }}
                    >
                      {t('Redeem Now')}
                    </button>
                  )}
                  <button className={styles.closeOfferButton} onClick={onClose}>
                    {t('Close')}
                  </button>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
