import * as React from 'react';
import clsx from 'clsx';
import { Box, FormControl, FormLabel, TextField, Grid, FormHelperText } from '@mui/material';
import { useTranslation, WithTranslation } from 'react-i18next';

import { GeneralCreditCardContext } from 'contexts/GeneralCreditCardContext';

import styles from './GeneralCreditCardInput.module.css';

const isCardInfoComplete = (cardNumber: string, cardExpiry: string, cardCvc: string): boolean => {
  return (
    (cardNumber.length === 16 || cardNumber.length === 15 || cardNumber.length === 14) &&
    cardExpiry.length === 6 &&
    cardCvc.length > 0
  );
};

const validateCardExpiry = (expiry: string, t: WithTranslation['t']): string => {
  const regex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
  return regex.test(expiry) ? '' : t('Expiration date must be in MM/YY format');
};

const validateCardCvc = (cvc: string, t: WithTranslation['t']): string => {
  const regex = /^[0-9]{3,4}$/;
  return regex.test(cvc) ? '' : t('CVC must be 3 or 4 digits');
};

const isPastDate = (expiry: string): boolean => {
  const [month, year] = expiry.split('/');
  const expiryDate = new Date(Number(`20${year}`), Number(month) - 1);
  const now = new Date();
  return expiryDate < now;
};

interface Props {
  onChange: ({ error, complete }: { error: string; complete: boolean }) => void;
}

export const GeneralCreditCardInput = ({ onChange }: Props) => {
  const [focusedElement, setFocusedElement] = React.useState<'NUMBER' | 'EXPIRY' | 'CVC' | ''>('');
  const { t, i18n } = useTranslation();

  const {
    cardNumber,
    setCardNumber,
    cardExpiry,
    setCardExpiry,
    cardCvc,
    setCardCvc,
  } = React.useContext(GeneralCreditCardContext);

  const [errors, setErrors] = React.useState({
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  });

  React.useEffect(() => {
    const complete = isCardInfoComplete(cardNumber, cardExpiry, cardCvc);
    onChange({ error: '', complete });
  }, [cardNumber, cardExpiry, cardCvc, onChange]);

  const handleChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^0-9/]/g, ''); // Remove non-digit characters except '/'
    let error = '';

    switch (field) {
      case 'cardNumber':
        setCardNumber(value);
        break;
      case 'cardExpiry':
        if (value.length === 3 && value[2] !== '/') {
          value = `${value.slice(0, 2)}/${value.slice(2)}`;
        }
        if (value.length > 5) {
          value = value.slice(0, 5);
        }
        if (value.length === 5) {
          const [month, year] = value.split('/');
          if (parseInt(month, 10) > 12) {
            value = ''; // Ignore invalid month
          } else {
            setCardExpiry(`20${year}${month}`);
          }
        } else {
          setCardExpiry(value);
        }
        error = validateCardExpiry(value, t);
        break;
      case 'cardCvc':
        setCardCvc(value);
        error = validateCardCvc(value, t);
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));

    if (!error) {
      const complete = isCardInfoComplete(cardNumber, cardExpiry, cardCvc);
      onChange({ error: '', complete });
    } else {
      onChange({ error, complete: false });
    }
  };

  const handleBlur = (field: string) => () => {
    let error = '';

    switch (field) {
      case 'cardNumber':
        error =
          cardNumber.length === 16 || cardNumber.length === 15 || cardNumber.length === 14
            ? ''
            : t('Card number must be 14 ~ 16 digits');
        break;
      case 'cardExpiry':
        const formattedExpiry = cardExpiry.slice(4, 6) + '/' + cardExpiry.slice(2, 4);
        error = validateCardExpiry(formattedExpiry, t);
        if (!error && isPastDate(formattedExpiry)) {
          error = t('Expiration date cannot be in the past');
        }
        break;
      case 'cardCvc':
        error = validateCardCvc(cardCvc, t);
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));
    if (error) {
      onChange({ error, complete: false });
    }
  };

  return (
    <Box>
      <FormControl
        variant="standard"
        className={styles['card-number']}
        fullWidth
        error={Boolean(errors.cardNumber)}
      >
        <FormLabel>{t('Card Number')}</FormLabel>
        <TextField
          value={cardNumber}
          onChange={handleChange('cardNumber')}
          onFocus={() => setFocusedElement('NUMBER')}
          onBlur={handleBlur('cardNumber')}
          className={clsx(styles['card-field'], focusedElement === 'NUMBER' && styles['focused'])}
          variant="outlined"
          placeholder="1234 1234 1234 1234"
          fullWidth
        />
        <FormHelperText>{errors.cardNumber}</FormHelperText>
      </FormControl>
      <Grid>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={6}>
            <FormControl
              variant="standard"
              className={styles['card-expiry']}
              error={Boolean(errors.cardExpiry)}
              fullWidth
            >
              <FormLabel>{t('Expiration')}</FormLabel>
              <TextField
                value={
                  cardExpiry.length === 6
                    ? `${cardExpiry.slice(4, 6)}/${cardExpiry.slice(2, 4)}`
                    : cardExpiry
                }
                onChange={handleChange('cardExpiry')}
                onFocus={() => setFocusedElement('EXPIRY')}
                onBlur={handleBlur('cardExpiry')}
                className={clsx(
                  styles['card-field'],
                  focusedElement === 'EXPIRY' && styles['focused']
                )}
                variant="outlined"
                placeholder="MM/YY"
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              variant="standard"
              className={styles['card-cvc']}
              error={Boolean(errors.cardCvc)}
              fullWidth
            >
              <FormLabel>{t('CVC')}</FormLabel>
              <TextField
                value={cardCvc}
                onChange={handleChange('cardCvc')}
                onFocus={() => setFocusedElement('CVC')}
                onBlur={handleBlur('cardCvc')}
                className={clsx(
                  styles['card-field'],
                  focusedElement === 'CVC' && styles['focused']
                )}
                variant="outlined"
                placeholder="CVC"
                fullWidth
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid>
          <FormHelperText>{errors.cardCvc}</FormHelperText>
          <FormHelperText>{errors.cardExpiry}</FormHelperText>
        </Grid>
      </Grid>
      <div className={styles['cvc-help-link']}>
        <a
          href={
            i18n.language === 'ja-JP'
              ? 'https://squareup.com/help/jp/ja/article/6287-jp-only-how-to-check-cvv'
              : 'https://www.codemag.com/Other/Cvc'
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('What is CVC?')}
        </a>
      </div>
    </Box>
  );
};
