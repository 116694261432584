import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { Field, useForm } from 'react-final-form';
import styles from './Params.module.css';
import { useMapDisplayContext } from '../MapDisplayContext';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductInstances } from 'ducks/client/productInstances';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { DateMask, DatePicker } from '../DatePicker';
import { ReduxState } from 'ducks';
import { GuestPicker } from './GuestPicker';
import { GuestType } from 'models/product';
import { ProductInstance } from 'models/productInstance';
import { useFieldState } from './useFieldState';
import { CheckoutFormValues } from './formValues';
import Image from 'next/image';
import { getImageUrl } from 'lib/util/imageUrl';
import { required } from 'lib/forms/validate';
import { productInstanceIsInstantConfirmation } from 'lib/util/productInstanceHelpers';
import { getGuestTypesFromProductInstance, getGuestTypesUsedInProductSummary } from './util';
import { AnimatedButton } from '../AnimatedButton';
import { PageContainer } from '../Components/PageContainer';
import { logCustomerEventWithNoSideEffects } from 'ducks/client/customerEvents';
import { useRouter } from 'next/router';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';

const formattedGuests = (guests: { [key: string]: number }, guestTypes: GuestType[]) => {
  const guestCounts = Object.entries(guests).map(([key, value]) => {
    for (const guestType of guestTypes) {
      if (guestType.key === key) {
        return `${value} ${guestType.title}`;
      }
    }

    return '';
  });

  return guestCounts.join(' | ');
};

export const Params = () => {
  const guestInputRef = React.useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const { productInCart, clearCart } = useMapDisplayContext();
  const [showDatePicker, setShowDatePicker] = React.useState<boolean>(false);
  const [showGuestPicker, setShowGuestPicker] = React.useState<boolean>(false);
  const [guestPickerPosition, setGuestPickerPosition] = React.useState<{
    top: string;
    left: string;
  }>({
    top: '0',
    left: '0',
  });
  const [selectedProductInstance, setSelectedProductInstance] = useFieldState<
    CheckoutFormValues['productInstance']
  >('productInstance');
  const [guests, setGuests] = useFieldState<CheckoutFormValues['guestCounts']>('guestCounts');
  const [selectedDate, setSelectedDate] = React.useState<string>(
    selectedProductInstance?.start_date_time_local?.slice(0, 10) || moment().format('YYYY-MM-DD')
  );
  const [instancesForSelectedDate, setInstancesForSelectedDate] = React.useState<ProductInstance[]>(
    selectedProductInstance ? [selectedProductInstance] : []
  );
  const [month, setMonth] = React.useState<string>(selectedDate.slice(0, 7));
  const dispatch = useDispatch();
  const { apiKey } = React.useContext(ApiKeyContext);

  const loading = useSelector((state: ReduxState) => state.productInstances.loading);

  const rawProductInstances = useSelector((state: ReduxState) => state.productInstances.all);

  const productInstances = React.useMemo(
    () =>
      rawProductInstances.filter((p) =>
        productInstanceIsInstantConfirmation(p, productInCart?.start_timezone ?? '')
      ),
    [rawProductInstances, productInCart]
  );

  const form = useForm<CheckoutFormValues>();

  React.useEffect(() => {
    // This effect handles the following case:
    // - We click back from the payment page to the params page
    // - Initially, we don't have the times for the selected date but once the current month is loaded
    //   we have the times for the selected date

    const instances = productInstances.filter(
      (p) => p.start_date_time_local.includes(selectedDate) && p.product_id === productInCart?.id
    );

    if (instances.length > instancesForSelectedDate.length) {
      setInstancesForSelectedDate(instances);
    }
  }, [selectedDate, productInstances, instancesForSelectedDate, productInCart]);

  React.useEffect(() => {
    // Set selected instance to the next chronological instance from the current time if:
    // - Product instances have loaded
    // - Selected date is today
    // - Selected instance is unset
    if (
      instancesForSelectedDate.length > 0 &&
      moment(selectedDate).isSame(moment(), 'day') &&
      selectedProductInstance == null
    ) {
      for (const instance of instancesForSelectedDate) {
        if (moment(instance.start_date_time_local).isAfter(moment())) {
          setSelectedProductInstance(instance);
          break;
        }
      }
    }
  }, [instancesForSelectedDate, selectedDate, setSelectedProductInstance, selectedProductInstance]);

  const timeOptions = instancesForSelectedDate.map((instance) => ({
    label: `${moment(instance.start_time_local, 'H:mm').format('H:mm')}${
      instance.start_time_description ? ` ${instance.start_time_description}` : ''
    }`,
    value: instance.id,
  }));
  let firstDay = moment(month).date(1);
  while (firstDay.day() !== 0) {
    // Move firstDay back to the previous Sunday
    firstDay = firstDay.subtract(1, 'day');
  }
  let lastDay = moment(month).endOf('month').add(1, 'day');
  while (lastDay.day() !== 0) {
    // Move lastDay to the next Sunday
    lastDay = lastDay.add(1, 'day');
  }

  const handleSelectedDateChange = (date: string) => {
    const instances = productInstances.filter((p) => p.start_date_time_local.includes(date));
    setInstancesForSelectedDate(instances);
    if (instances?.length) {
      setSelectedProductInstance(instances[0]);
    }
    setSelectedDate(date);
  };

  const firstDayYyyyMmDd = firstDay.format('YYYY-MM-DD');
  const lastDayYyyyMmDd = lastDay.format('YYYY-MM-DD');

  React.useEffect(() => {
    if (firstDayYyyyMmDd && lastDayYyyyMmDd && firstDayYyyyMmDd < lastDayYyyyMmDd) {
      dispatch(
        fetchProductInstances(
          apiKey,
          productInCart?.id || '',
          firstDayYyyyMmDd,
          lastDayYyyyMmDd,
          i18n.language
        )
      );
    }
  }, [apiKey, i18n.language, firstDayYyyyMmDd, lastDayYyyyMmDd, productInCart?.id, dispatch]);

  const dateMasks: DateMask[] = [];
  for (const date = moment(firstDay); date.isBefore(lastDay); date.add(1, 'day')) {
    dateMasks.push({
      date: date.format('YYYY-MM-DD'),
      disabled: !productInstances.some((instance) =>
        instance.start_date_time_local.includes(date.format('YYYY-MM-DD'))
      ),
    });
  }

  const router = useRouter();
  const pathname = router.asPath;
  const { currentPositionRef } = React.useContext(CurrentPositionContext);

  const stringifiedGuestCount = JSON.stringify(guests);

  const visitorToken = useSelector((state: ReduxState) => state.tracking.visitorToken);
  const customerId = useSelector((state: ReduxState) => state.customer.customer?.id);

  React.useEffect(() => {
    const guestCount = JSON.parse(stringifiedGuestCount);
    if (
      selectedProductInstance?.product_id &&
      selectedProductInstance?.start_date_time_local &&
      Object.values(guestCount).some((count: any) => count > 0)
    ) {
      Object.keys(guestCount).forEach((key) => {
        guestCount[key] = `${guestCount[key]}`;
      });

      const metadata = {
        page: pathname,
        product_id: selectedProductInstance.product_id,
        date_time: selectedProductInstance.start_date_time_local,
        guest_count: guestCount,
        lat: currentPositionRef.current?.lat(),
        lng: currentPositionRef.current?.lng(),
        site: 'onsite',
      };

      (async () => {
        try {
          await logCustomerEventWithNoSideEffects({
            apiKey,
            eventType: 'check-availability',
            visitorToken,
            metadata: JSON.stringify(metadata),
            customerId: customerId ?? '',
          });
        } catch (err) {
          console.error(err);
        }
      })();
    }
  }, [
    currentPositionRef,
    pathname,
    stringifiedGuestCount,
    customerId,
    selectedProductInstance?.product_id,
    selectedProductInstance?.start_date_time_local,
    apiKey,
    visitorToken,
  ]);

  const atLeastOneGuestRequired = React.useCallback(
    (guestCounts: Record<string, number>) => {
      if (Object.values(guestCounts).reduce((acc, count) => acc + count, 0) === 0) {
        return t('At least one guest required');
      }

      return undefined;
    },
    [t]
  );

  const productGuestTypes = productInCart
    ? getGuestTypesUsedInProductSummary(productInCart, t)
    : [];

  return (
    <PageContainer
      onBackClick={() => clearCart()}
      headerContent={<h1 className={styles['title']}>{t('Book Tickets')}</h1>}
    >
      <div className={styles['main-section']}>
        <Image
          priority
          alt=""
          height={178}
          width={328}
          className={styles['banner']}
          key={productInCart?.media.find((m) => m.type === 'IMAGE')?.url}
          src={productInCart?.media.find((m) => m.type === 'IMAGE')?.url ?? ''}
          loader={({ width, src }) => getImageUrl(src, width * 1.5)}
        />
        <h3 className={styles['event-title']}>{productInCart?.product_name}</h3>
        <p className={styles['description']}>
          {t('Please select your date, time, and participants.')}
        </p>
        <div className={styles['form']}>
          <Field name="productInstance" validate={required}>
            {({ meta: { touched, error } }) => (
              <>
                <div className={styles['date-time-row']}>
                  <div
                    className={clsx(styles['form-group'], styles['date'])}
                    onClick={() => {
                      setShowDatePicker(true);
                    }}
                  >
                    <label className={styles['form-label']}>{t('Date')}</label>
                    <div className={styles['form-control']}>
                      <input
                        type="text"
                        id="date"
                        className={styles['form-input']}
                        value={moment(selectedDate).locale(i18n.language).format('LL')}
                        readOnly
                      />
                      <svg
                        className={styles['icon-small']}
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                      >
                        <path
                          d="M1 0.5L4 3.5L7 0.5"
                          stroke="#393D3F"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                  {showDatePicker && (
                    <DatePicker
                      onClose={() => setShowDatePicker(false)}
                      onDateChange={(date) => handleSelectedDateChange(date)}
                      selectedDate={selectedDate}
                      onMonthChange={(date) => setMonth(date)}
                      loading={loading}
                      dateMasks={dateMasks}
                    />
                  )}
                  <label htmlFor="time" className={styles['form-group']}>
                    <div className={styles['form-label']}>{t('Time')}</div>
                    <div className={styles['form-control']}>
                      <select
                        id="time"
                        className={styles['form-input']}
                        value={selectedProductInstance?.id || ''}
                        onChange={(e) => {
                          const instance =
                            productInstances.find((p) => p.id === e.target.value) || null;
                          setSelectedProductInstance(instance);

                          if (Object.keys(guests).length === 0) {
                            const newGuests: { [key: string]: number } =
                              instance?.units.reduce(
                                (acc, unit) => ({
                                  ...acc,
                                  [unit.guest_type.key]: 0,
                                }),
                                {}
                              ) || {};
                            setGuests(newGuests);
                          }
                        }}
                      >
                        <option value="" disabled>
                          {loading
                            ? t('-')
                            : timeOptions.length > 0
                            ? t('Select a time')
                            : t('No Times Available')}
                        </option>

                        {timeOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      <svg
                        className={styles['icon-small']}
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="4"
                        viewBox="0 0 8 4"
                        fill="none"
                      >
                        <path
                          d="M1 0.5L4 3.5L7 0.5"
                          stroke="#393D3F"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </label>
                </div>

                {touched && error && (
                  <span className={styles['error']}>{t('Please select a date and time')}</span>
                )}
              </>
            )}
          </Field>
          <Field name="guestCounts" validate={atLeastOneGuestRequired}>
            {({ meta: { touched, error } }) => (
              <>
                <div
                  className={styles['participants-section']}
                  ref={guestInputRef}
                  onClick={() => {
                    if (guestInputRef.current) {
                      const rect = guestInputRef.current.getBoundingClientRect();
                      setGuestPickerPosition({
                        top: `${rect.bottom + 4}px`, // Adjusting for scroll
                        left: `${rect.left}px`,
                      });
                      setShowGuestPicker(true);
                    }
                  }}
                >
                  <h3 className={styles['participants-title']}>{t('Participants')}</h3>
                  <div className={styles['form-control']}>
                    <input
                      type="text"
                      id="participants"
                      className={styles['participants-input']}
                      value={formattedGuests(guests, productGuestTypes)}
                      readOnly
                    />
                    <svg
                      className={styles['icon-small']}
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="4"
                      viewBox="0 0 8 4"
                      fill="none"
                    >
                      <path
                        d="M1 0.5L4 3.5L7 0.5"
                        stroke="#393D3F"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                {touched && error && <span className={styles['error']}>{error}</span>}
              </>
            )}
          </Field>
          {showGuestPicker && (
            <GuestPicker
              onClose={() => setShowGuestPicker(false)}
              position={guestPickerPosition}
              onGuestsChange={(newGuests) => setGuests(newGuests)}
              guestCounts={guests}
              guestTypes={
                selectedProductInstance
                  ? getGuestTypesFromProductInstance(selectedProductInstance, t)
                  : productGuestTypes
              }
            />
          )}
          <AnimatedButton
            className={styles['next-button']}
            clickedClassName={styles['clicked']}
            onClick={() => {
              form.submit();
            }}
            type="button"
          >
            {t('Next')}
          </AnimatedButton>
          <AnimatedButton
            className={styles['button-back']}
            clickedClassName={styles['clicked']}
            onClick={() => clearCart()}
            type="button"
          >
            {t('Back')}
          </AnimatedButton>
        </div>
      </div>
    </PageContainer>
  );
};
