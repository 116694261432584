import { useTranslation } from 'react-i18next';

import { currency } from 'lib/currency';
import styles from './CartButton.module.css';
import { useActiveRestaurant } from './useActiveRestaurant';
import { useForm } from 'react-final-form';
import { CheckoutFormValues } from './checkoutFormValues';
import { useCurrencyFormatter } from '../useCurrencyFormatter';
import { calculateTotalPrice } from './calculateTotalPrice';
import { AnimatedButton } from '../AnimatedButton';

interface Props {
  onClick: () => void;
}

export const CartButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  const currencyFormatter = useCurrencyFormatter();

  const restaurant = useActiveRestaurant();
  const menu = restaurant?.menu;

  const values = useForm<CheckoutFormValues>().getState().values;

  const total = values.items
    .map((item) => {
      const menuItem = menu?.menu_items.find((menuItem) => menuItem.key === item.menuItemKey);

      if (!menuItem) {
        throw new Error('Menu item not found');
      }

      return calculateTotalPrice(menuItem, item, 1);
    })
    .reduce((total, price) => {
      return currency(total).add(currency(price)).format();
    });

  return (
    <div className={styles['container']}>
      <AnimatedButton
        className={styles.cartButton}
        onClick={onClick}
        type="button"
        clickedClassName={styles.clicked}
      >
        <div className={styles['cart-icon']}>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5ef3d94bdf82dceca4332cf1a58c642d1cf7ea0dff731307766f0f549fca2af?apiKey=3d59b5c84d524ccda3d6150a37390818&"
            alt="Cart icon"
          />
        </div>
        <div className={styles.cartDetails}>
          <span className={styles.cartLabel}>{t('View Cart')}</span>
          <span className={styles.cartItem}>{restaurant?.name}</span>
        </div>
        <div className={styles.cartTotal}>{currencyFormatter.format(total)}</div>
      </AnimatedButton>
    </div>
  );
};
