import * as React from 'react';
import { useDispatch } from 'react-redux';

import config from 'config';
import { addNotification } from 'ducks/client/notification';
import { ApiKeyContext } from 'contexts/ApiKeyContext';

interface Props {
  targetId?: string;
  name: string;
}

export const WebSocketConnectionReceiver = ({ targetId, name }: Props) => {
  const dispatch = useDispatch();
  const { apiKey } = React.useContext(ApiKeyContext);
  const wsRef = React.useRef<WebSocket | null>(null);
  const reconnectTimeoutRef = React.useRef<NodeJS.Timeout>();
  const reconnectDelay = 3000; // 3 seconds

  const connect = React.useCallback(() => {
    if (!apiKey || !targetId) return;

    try {
      const webSocketEndpointUrl = config.webSocketEndpointUrl;
      if (!webSocketEndpointUrl) {
        throw new Error('web socket endpoint URL not present in configs');
      }

      wsRef.current = new WebSocket(
        `${webSocketEndpointUrl}?apiKey=${apiKey}&targetId=${targetId}`
      );

      wsRef.current.onopen = () => {
        console.log(`${name}: opened`);
      };

      wsRef.current.onclose = (event) => {
        console.log(`${name}: closed`, event.code);
        // Only attempt to reconnect on abnormal closure
        if (event.code !== 1000) {
          if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
          }

          reconnectTimeoutRef.current = setTimeout(() => {
            if (wsRef.current) {
              wsRef.current.close();
            }
            connect();
          }, reconnectDelay);
        }
      };

      wsRef.current.onmessage = (event) => {
        if (typeof event.data === 'string') {
          const notification: any = JSON.parse(event.data);
          dispatch(addNotification(notification));
        }
      };
    } catch (err) {
      console.log('err', err);
    }
  }, [apiKey, targetId, dispatch, name]);

  const reconnect = React.useCallback(() => {
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
    }

    reconnectTimeoutRef.current = setTimeout(() => {
      if (wsRef.current) {
        wsRef.current.close();
      }
      connect();
    }, reconnectDelay);
  }, [connect]);

  // Handle visibility change (app going to background/foreground)
  React.useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Check if the connection is actually dead
        if (
          wsRef.current?.readyState === WebSocket.CLOSED ||
          wsRef.current?.readyState === WebSocket.CLOSING
        ) {
          reconnect();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [reconnect]);

  // Initial connection
  React.useEffect(() => {
    connect();

    return () => {
      if (wsRef.current) {
        wsRef.current.close(1000); // Normal closure
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [connect]);

  return null;
};
