import dynamic from 'next/dynamic';
import styles from './TicketScanner.module.css';

const ReactQrReader = dynamic<any>(() => import('react-qr-reader') as any, {
  ssr: false,
});

interface Props {
  onClose: () => void;
}

export const TicketScanner = ({ onClose }: Props) => {
  return (
    <section className={styles['ticket-container']}>
      <div className={styles['ticket-header']}>
        <svg
          onClick={onClose}
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
        >
          <path
            d="M14 1L1 14"
            stroke="#393D3F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 1L14 14"
            stroke="#393D3F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <ReactQrReader
        style={{ width: '100%' }}
        onScan={() => {
          /*empty*/
        }}
      />
    </section>
  );
};
