import React from 'react';

interface Props {
  checked: boolean;
  onChange?: () => void;
  label: string;
}

import styles from './Toggle.module.css';

export const Toggle = ({ checked, onChange, label }: Props) => {
  return (
    <div className={styles['toggle-container']}>
      <div
        className={styles['toggle-button']}
        onClick={() => {
          onChange?.();
        }}
      >
        <input type="checkbox" className={styles['toggle-input']} checked={checked} />
        <label htmlFor="toggle" className={styles['toggle-label']}></label>
      </div>
      <label htmlFor="toggle" className={styles['toggle-text']}>
        {label}
      </label>
    </div>
  );
};
