import useSWR from 'swr';
import axios from 'axios';

import config from 'config';

const fetcher = (ids: string[], apiKey: string, contentLanguage: string) => {
  const f = (id: string) =>
    axios
      .get(`${config.apiUrl}/products/${id}`, {
        headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      })
      .then((response) => response.data);
  return Promise.all(ids.map((id) => f(id)));
};

export const useProductDetails = (ids: string[], apiKey: string, contentLanguage: string) => {
  const { data, error } = useSWR([ids, apiKey, contentLanguage], fetcher);

  return {
    productDetails: data,
    isLoading: !error && !data,
    isError: error,
  };
};
