import { getGuestAddOns, showTransportation, productHasTransportations } from 'lib/util/util';
import { CheckoutFormValues } from 'components/Checkout/CheckoutFormValues';
import { CreateReservationRequest, Guest } from 'models/reservation';
import { Product } from 'models/product';
import { GroupBookingTemplate } from 'models/groupBookingTemplate';

type FieldResponse = {
  key: string;
  response: string;
};

const getGuestFieldResponses = (
  guestFieldResponses: { [key: string]: Array<{ [key: string]: any }> },
  guestTypeKey: string,
  guestNumber: number
): FieldResponse[] => {
  const fieldResponseMap =
    guestFieldResponses &&
    guestFieldResponses[guestTypeKey] &&
    guestFieldResponses[guestTypeKey][guestNumber];

  if (!fieldResponseMap) {
    return [];
  }

  const fieldResponses: FieldResponse[] = [];
  for (const key of Object.keys(fieldResponseMap)) {
    fieldResponses.push({ key, response: fieldResponseMap[key] });
  }

  return fieldResponses;
};

export const generateRandomString = (
  length: number,
  charset = 'ABCDEFGHIJKLMNOPQRSTUVWZYZabcdefghijklmnopqrstuvwzyz0123456789'
): string => {
  let text = '';
  for (let i = 0; i < length; i++) {
    text += charset.charAt(Math.floor(Math.random() * charset.length));
  }

  return text;
};

// Plagiarized from nutmeg which was plagiarized from
// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
export const generateAgentReference = () => {
  const uniqueChars = 8;

  return `${generateRandomString(uniqueChars, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789')}`;
};

export const getCreateReservationRequest = (
  reservationFormParams: CheckoutFormValues,
  language: string,
  agentReference: string,
  product: Product | null,
  accessToken: string | null,
  idProvider: string | null,
  redirectUri: string | null,
  checkoutFormQueryParams: { [key: string]: string | string[] },
  group?: GroupBookingTemplate,
  landingInformation?: { referrer: string; sid: string }
): CreateReservationRequest => {
  const {
    guestCount,
    firstName,
    lastName,
    kanaFirstName,
    kanaLastName,
    email,
    phone,
    address1,
    address2,
    city,
    state,
    zip,
    country,
    hotel,
    hotelTBD,
    customFields,
    specialRequests,
    productInstance,
    guestSelectedAddOns,
    guestFieldResponses,
    transportation,
    bookingAddOns,
    reservationPaymentType,
    paymentMethod,
    paymentCurrencyType,
    promoCode,
    rebookedFromReservationId,
    internationalPhone,
    homeAddress,
    consentForm,
    bookingSource,
    bookingPaymentMethod,
    preexistingCustomerReservationId,
    shouldUseCustomerPayment,
    shouldRegisterCustomerCard,
    shouldUsePreexistingReservationPayment,
    equipmentAssignments,
    shouldAutoRenewAnnualPass,
    gmoMpTokens,
    paymentGateway,
    gmoAccessId,
    finalizeTdsAuth,
    requestedPickupLocationPlaceId,
    dynamicProductInstanceId,
  } = reservationFormParams;

  const guests: Guest[] = [];
  Object.keys(guestCount).forEach((guestTypeKey) => {
    for (let i = 0; i < guestCount[guestTypeKey]; i++) {
      const guestAddOns = getGuestAddOns(guestSelectedAddOns, guestTypeKey, i);
      guests.push({
        guest_type_key: guestTypeKey,
        field_responses: getGuestFieldResponses(guestFieldResponses, guestTypeKey, i),
        ...(guestAddOns &&
          guestAddOns.length !== 0 && {
            add_ons: guestAddOns,
          }),
      });
    }
  });

  const address = address1 && address1 + (address2 ? `, ${address2}` : '');

  const fieldResponses = [
    {
      key: 'given_name',
      response: firstName,
    },
    {
      key: 'family_name',
      response: lastName,
    },
    {
      key: 'kana_given_name',
      response: kanaFirstName,
    },
    {
      key: 'kana_family_name',
      response: kanaLastName,
    },
    {
      key: 'email',
      response: email,
    },
    {
      key: 'phone',
      response: phone,
    },
    {
      key: 'address',
      response: address,
    },
    {
      key: 'city',
      response: city,
    },
    {
      key: 'state',
      response: state,
    },
    {
      key: 'country',
      response: country,
    },
    {
      key: 'zip',
      response: zip,
    },
    {
      key: 'preferred_language_iso2',
      response: language,
    },
    {
      key: 'international_phone',
      response: internationalPhone,
    },
    {
      key: 'home_address',
      response: homeAddress,
    },
    {
      key: 'consent_form',
      response: consentForm ? 'Yes' : '',
    },
  ];
  if (customFields) {
    for (const key of Object.keys(customFields)) {
      fieldResponses.push({
        key,
        response: customFields[key],
      });
    }
  }

  const hotelLocationName = (hotel && hotel.locationName) || '';
  const hotelGooglePlaceId = (hotel && hotel.placeId) || '';
  let isCheckinCheckoutOnly = true;
  let defaultTransportation = '';
  if (showTransportation(product) || !product) {
    // Show the transportation selector when there is a paid transportation option.
    isCheckinCheckoutOnly = !transportation || transportation === 'Checkin/Checkout Only';
  } else {
    // Not Show the transportation selector when
    //   - No transportation option
    //   - No Checkin/Checkout and One free transportation option
    if (productHasTransportations(product)) {
      isCheckinCheckoutOnly = false;
      defaultTransportation = product.transportations[0].key;
    } else {
      isCheckinCheckoutOnly = true;
    }
  }

  let hotelLocation;
  if (hotelTBD) {
    hotelLocation = {
      location_name: 'TBD',
    };
  } else if (hotel) {
    hotelLocation = {
      location_name: hotelLocationName,
      google_place_id: hotelGooglePlaceId,
    };
  }

  let guestHotel;
  if (hotelLocation) {
    guestHotel = hotelLocation;
  }

  const pickupLocations = (product?.pickup || []).map((pickup) => ({
    location_name: pickup.location_name,
    google_place_id: pickup.google_place_id,
  }));

  let finalRequestedPickupLocation:
    | { location_name: string; google_place_id?: string }
    | undefined = undefined;

  if (
    !isCheckinCheckoutOnly &&
    requestedPickupLocationPlaceId && // it is possible not to be undefined when change reservation
    requestedPickupLocationPlaceId !== 'TBD'
  ) {
    finalRequestedPickupLocation = pickupLocations.find(
      (location) => location.google_place_id === requestedPickupLocationPlaceId
    );
  }
  // Only copy hotel to requested pickup location if a transportation option is selected.
  if (!finalRequestedPickupLocation && !isCheckinCheckoutOnly && hotelLocation) {
    finalRequestedPickupLocation = hotelLocation;
  }

  const equipment_assignments = equipmentAssignments?.map((assignment) => ({
    equipment_id: assignment.equipmentId,
    start_time_key: assignment.startTimeKey,
    equipment_block_instance_key: assignment.equipmentBlockInstanceKey,
    equipment_instance_id: assignment.equipmentInstanceId,
    occupation: {
      guest_type_counts: assignment.guestTypeCounts.map((guestTypeCount) => ({
        guest_type_key: guestTypeCount.guestTypeKey,
        count: guestTypeCount.count,
      })),
    },
    equipment_block_reference: assignment.equipmentBlockReference,
    original_equipment_instance_id: assignment.originalEquipmentInstanceId,
  }));

  return {
    agent_reference: agentReference,
    product_instance_id:
      product?.is_dynamic_package_product?.value && dynamicProductInstanceId
        ? dynamicProductInstanceId
        : productInstance?.id,
    guest_hotel: guestHotel,
    requested_pickup_location: finalRequestedPickupLocation,
    requested_dropoff_location: finalRequestedPickupLocation,
    guests,
    field_responses: fieldResponses.filter((f) => Boolean(f.response)),
    // Default to 'PAID_IN_FULL' for fare calculation before guest has selected payment type.
    payment_type: reservationPaymentType || 'PAID_IN_FULL',
    ...(!isCheckinCheckoutOnly && {
      transportation: defaultTransportation || transportation,
    }),
    ...(bookingAddOns &&
      bookingAddOns.length !== 0 && {
        add_ons: bookingAddOns,
      }),
    ...((paymentMethod?.id || gmoAccessId || gmoMpTokens?.[0]) && {
      payment_profile_gateway_reference: paymentMethod?.id || gmoAccessId || gmoMpTokens?.[0] || '',
    }),
    agent_notes: specialRequests,
    ...(paymentCurrencyType && {
      settlement_currency: paymentCurrencyType,
    }),
    promo_code: promoCode,
    rebooked_from_reservation_id: rebookedFromReservationId,
    booking_source: group
      ? {
          source_type: 'GROUP',
          group_id: group.id,
          group_name: group.name,
        }
      : bookingSource
      ? {
          source_type: bookingSource,
        }
      : {
          source_type: 'DIRECT_WEB',
        },
    payment_method: bookingPaymentMethod,
    preexisting_customer_reservation_id: preexistingCustomerReservationId,
    should_use_preexisting_customer_reservation_payment_info: shouldUsePreexistingReservationPayment,
    should_use_customer_payment_info: shouldUseCustomerPayment || shouldRegisterCustomerCard,
    checkout_form_query_params: Object.entries(checkoutFormQueryParams)
      .filter(([key]) => !['productInstanceId', 'guestCount', 'id', 'groupId'].includes(key))
      .map(([key, value]) => ({
        key,
        value: typeof value === 'string' ? [value] : value,
      })),
    ...(accessToken && {
      access_token: accessToken,
    }),
    ...(idProvider && {
      id_provider: idProvider,
    }),
    ...(redirectUri && {
      redirect_uri: redirectUri,
    }),
    should_auto_renew_annual_pass: {
      value: shouldAutoRenewAnnualPass,
    },
    ...(equipment_assignments && {
      equipment_assignments,
    }),
    landing_referrer: landingInformation?.referrer,
    landing_sid: landingInformation?.sid,
    payment_gateway: paymentGateway,
    is_partnership_reservation: product?.booking_widget_api_key ? true : false, // When product has booking widget api key, it is a partnership reservation.
    finalize_tds_auth: finalizeTdsAuth || false,
  };
};
