import React from 'react';
import styles from './OrderItem.module.css';
import { useForm } from 'react-final-form';
import { currency } from 'lib/currency';
import { CheckoutFormValues } from '../checkoutFormValues';
import { OrderSummaryItem } from './util';
import { useCurrencyFormatter } from 'components/Map/useCurrencyFormatter';
import { optionGroupListsEqual } from '../util';

type OrderItemProps = OrderSummaryItem & { menuItemKey: string; onClick: () => void };

const OrderItem: React.FC<OrderItemProps> = ({
  name,
  unitPrice,
  image,
  quantity,
  menuItemKey,
  optionGroups,
  onClick,
}) => {
  const form = useForm<CheckoutFormValues>();
  const currencyFormatter = useCurrencyFormatter();

  return (
    <div className={styles.card} onClick={onClick}>
      <img src={image} alt={name} className={styles.itemImage} />
      <div className={styles.quantityBadge}>
        <span>{quantity}</span>
        <span className={styles.quantityX}>x</span>
      </div>
      <div className={styles.itemDetails}>
        <div className={styles.itemName}>{name}</div>
        {optionGroups.map((group) =>
          group.options.map((option) => (
            <div key={option.key} className={styles.option}>
              {option.name}
            </div>
          ))
        )}
        <div className={styles.itemPrice}>
          {currencyFormatter.format(currency(unitPrice).multiply(quantity).format())}
        </div>
      </div>
      <img
        onClick={(e) => {
          e.stopPropagation();
          const newItems = form.getState().values.items.filter((item) => {
            return (
              item.menuItemKey !== menuItemKey ||
              !optionGroupListsEqual(
                item.optionGroups,
                optionGroups.map((group) => ({
                  key: group.key,
                  optionKeys: group.options.map((option) => option.key),
                }))
              )
            );
          });

          form.change('items', newItems);
        }}
        src="/static/images/map/trash.svg"
        alt="Remove item"
        className={styles.removeIcon}
      />
    </div>
  );
};

export default OrderItem;
