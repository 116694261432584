import React from 'react';
import { currency } from 'lib/currency';
import styles from './OrderSummary.module.css';
import OrderItem from './OrderItem';
import { useForm } from 'react-final-form';
import { CheckoutFormValues, OrderItemOptionGroup } from '../checkoutFormValues';
import { useTranslation } from 'react-i18next';
import { CustomerContext } from 'contexts/CustomerContext';
import { useCurrencyFormatter } from 'components/Map/useCurrencyFormatter';
import { useActiveRestaurant } from '../useActiveRestaurant';
import { calculateTotalPrice } from '../calculateTotalPrice';
import { AnimatedButton } from 'components/Map/AnimatedButton';
import { AnimatedClickableDiv } from 'components/Map/AnimatedClickableDiv';
import { ButtonLoader } from 'components/Map/MyAccount/ButtonLoader';
import { ReduxState } from 'ducks';
import { useSelector } from 'react-redux';
import { OrderSummaryItem, orderSummaryItemsEqual } from './util';

interface Props {
  onBackClick: () => void;
  onItemClick: (itemKey: string, optionGroups: OrderItemOptionGroup[], quantity: number) => void;
  onClickEditPaymentMethod: () => void;
}

export const OrderSummary: React.FC<Props> = ({
  onClickEditPaymentMethod,
  onBackClick,
  onItemClick,
}) => {
  const restaurant = useActiveRestaurant();
  const settings = useSelector((state: ReduxState) => state.server.settings.all);

  const form = useForm<CheckoutFormValues>();
  const submitting = form.getState().submitting;
  const values = form.getState().values;
  const currencyFormatter = useCurrencyFormatter();

  const defaultCurrency = settings?.default_currency || 'USD';

  const orderItems = values.items
    .map(
      (item): OrderSummaryItem => {
        const menuItem = restaurant?.menu?.menu_items.find(
          (menuItem) => menuItem.key === item.menuItemKey
        );
        if (!menuItem) {
          throw new Error('MenuItem not found');
        }

        const unitPrice = calculateTotalPrice(menuItem, item, 1);
        return {
          name: menuItem?.name ?? '',
          unitPrice,
          image: menuItem?.media_items.find((mediaItem) => mediaItem.type === 'IMAGE')?.url ?? '',
          quantity: 1,
          key: menuItem?.key ?? '',
          optionGroups: item.optionGroups.map((group) => {
            const menuItemOptionGroup = menuItem.option_groups.find(
              (optionGroup) => optionGroup.key === group.key
            );

            return {
              key: group.key,
              options: group.optionKeys.map((optionKey) => {
                const menuItemOption = menuItemOptionGroup?.options.find(
                  (option) => option.key === optionKey
                );
                return {
                  key: optionKey,
                  name: menuItemOption?.name ?? '',
                  price: menuItemOption?.price ?? '',
                };
              }),
            };
          }),
        };
      }
    )
    .reduce<OrderSummaryItem[]>((acc, item) => {
      // Find an existing item with the same key, add-ons, and option
      const existingItem = acc.find((accItem) => orderSummaryItemsEqual(accItem, item));

      if (existingItem) {
        existingItem.quantity++;
      } else {
        acc.push(item);
      }

      return acc;
    }, []);

  const subtotal = orderItems
    .map((item) => {
      return currency(item.unitPrice).multiply(item.quantity).format();
    })
    .reduce((total, price) => {
      return currency(total).add(currency(price)).format();
    }, `${defaultCurrency}0`);

  const { customer } = React.useContext(CustomerContext);

  const submitError = form.getState().submitError;

  const { t } = useTranslation();

  if (orderItems.length === 0) {
    return (
      <div className={styles.container}>
        <main className={styles.mainContent}>
          <h2 className={styles.orderTitle}>{t('Your order is empty')}</h2>

          <div className={styles.addMoreButton} onClick={() => onBackClick()}>
            <div className={styles.addMoreText}>{t('Add more items')}</div>
            <div className={styles.addMoreIcon}>+</div>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <main className={styles.mainContent}>
        <h2 className={styles.orderTitle}>{t('Your Order')}</h2>
        {orderItems.map((item) => (
          <OrderItem
            {...item}
            menuItemKey={item.key}
            key={item.key}
            onClick={() =>
              onItemClick(
                item.key,
                item.optionGroups.map((optionGroup) => ({
                  key: optionGroup.key,
                  optionKeys: optionGroup.options.map((option) => option.key),
                })),
                item.quantity
              )
            }
          />
        ))}
        <div className={styles.addMoreButton} onClick={() => onBackClick()}>
          <div className={styles.addMoreText}>{t('Add More Items')}</div>
          <div className={styles.addMoreIcon}>+</div>
        </div>
      </main>
      <div className={styles.divider} />
      <section className={styles.summarySection}>
        <div className={styles.summaryContent}>
          <h2 className={styles.summaryTitle}>{t('Summary')}</h2>
          <div className={styles.summaryRow}>
            <div className={styles.summaryLabel}>{t('Subtotal')}</div>
            <div className={styles.summaryValue}>{currencyFormatter.format(subtotal)}</div>
          </div>
          <div className={styles.summaryDivider} />
          <div className={styles.totalRow}>
            <div className={styles.summaryLabel}>{t('Total')}</div>
            <div className={styles.summaryValue}>{currencyFormatter.format(subtotal)}</div>
          </div>
        </div>
        <section className={styles['payment-method']}>
          <AnimatedClickableDiv
            className={styles['payment-method-info']}
            clickedClassName={styles['clicked']}
            onClick={onClickEditPaymentMethod}
          >
            <div className={styles['card-info']}>
              {customer?.payment_profile_card_info?.last_four_digits ? (
                <div>
                  <div className={styles['card-type']}>
                    {customer?.payment_profile_card_info?.card_brand}
                  </div>
                  <div className={styles['card-number']}>
                    ****{customer?.payment_profile_card_info?.last_four_digits}
                  </div>
                </div>
              ) : (
                <div className={styles['add-card']}>{t('Add Payment Method')}</div>
              )}
            </div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path d="M15.5402 11.2895L9.88023 5.63955C9.78726 5.54582 9.67666 5.47143 9.5548 5.42066C9.43294 5.36989 9.30224 5.34375 9.17023 5.34375C9.03821 5.34375 8.90751 5.36989 8.78565 5.42066C8.66379 5.47143 8.55319 5.54582 8.46023 5.63955C8.27398 5.82691 8.16943 6.08036 8.16943 6.34455C8.16943 6.60873 8.27398 6.86219 8.46023 7.04955L13.4102 12.0495L8.46023 16.9995C8.27398 17.1869 8.16943 17.4404 8.16943 17.7045C8.16943 17.9687 8.27398 18.2222 8.46023 18.4095C8.55284 18.504 8.66329 18.5792 8.78516 18.6307C8.90704 18.6822 9.03792 18.709 9.17023 18.7095C9.30253 18.709 9.43342 18.6822 9.55529 18.6307C9.67717 18.5792 9.78761 18.504 9.88023 18.4095L15.5402 12.7595C15.6417 12.6659 15.7227 12.5523 15.7781 12.4258C15.8336 12.2993 15.8622 12.1627 15.8622 12.0245C15.8622 11.8864 15.8336 11.7498 15.7781 11.6233C15.7227 11.4968 15.6417 11.3832 15.5402 11.2895Z" />
            </svg>
          </AnimatedClickableDiv>
        </section>

        {submitError && <div className={styles['error']}>{submitError}</div>}
      </section>

      <div className={styles['continue-button-container']}>
        <AnimatedButton
          type="button"
          onClick={() => form.submit()}
          className={styles.continueButton}
          clickedClassName={styles.clicked}
        >
          {submitting ? <ButtonLoader /> : t('Pay Now')}
        </AnimatedButton>
      </div>
    </div>
  );
};
