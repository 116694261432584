import * as React from 'react';

export interface GeneralCreditCardContextType {
  cardNumber: string;
  setCardNumber: (cardNumber: string) => void;
  cardExpiry: string;
  setCardExpiry: (cardExpiry: string) => void;
  cardCvc: string;
  setCardCvc: (cardCvc: string) => void;
}

export const GeneralCreditCardContext = React.createContext<GeneralCreditCardContextType>({
  cardNumber: '',
  setCardNumber: () => {},
  cardExpiry: '',
  setCardExpiry: () => {},
  cardCvc: '',
  setCardCvc: () => {},
});
