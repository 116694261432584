import * as React from 'react';
import DownloadIcon from '@mui/icons-material/GetApp';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './ImageDownloadCard.module.css';

interface Props {
  imageUrl: string;
  mediaType: 'IMAGE' | 'VIDEO';
}

export const ImageDownloadCard = ({ imageUrl, mediaType }: Props) => {
  const { t } = useTranslation();

  const isImage = mediaType !== 'VIDEO';

  return (
    <div className={styles['c-card-option']}>
      <div className={styles['c-card-option__pic']}>
        <div className={styles['img-container']}>
          {isImage ? (
            <img src={imageUrl} alt="test" />
          ) : (
            <video width="300" height="200" controls>
              <source type="video/mp4" src={imageUrl} />
            </video>
          )}
        </div>
      </div>
      <Box mt={2} display="flex" justifyContent="center">
        <a href={imageUrl} download>
          <Button size="small" color="primary" variant="contained" startIcon={<DownloadIcon />}>
            {t('Download')}
          </Button>
        </a>
      </Box>
    </div>
  );
};
