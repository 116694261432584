import * as React from 'react';

interface SearchContextType {
  recentQueries: string[];
  setRecentQueries: (queries: string[]) => void;
}

export const SearchContext = React.createContext<SearchContextType>({
  recentQueries: [],
  setRecentQueries: () => {},
});
