import styles from './FixedQuery.module.css';

interface Props {
  query: string;
  onClick: () => void;
}

export const FixedQuery = ({ query, onClick }: Props) => {
  return (
    <div className={styles['container']} onClick={onClick}>
      <div className={styles['search-icon']}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
        >
          <path
            d="M5.96554 11.4313C8.70793 11.4313 10.9311 9.20808 10.9311 6.46563C10.9311 3.72319 8.70793 1.5 5.96554 1.5C3.22315 1.5 1 3.72319 1 6.46563C1 9.20808 3.22315 11.4313 5.96554 11.4313Z"
            stroke="#393D3F"
            strokeWidth="1.5"
          />
          <path
            d="M9.68945 10.1895L12.9998 13.4999"
            stroke="#393D3F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className={styles['search-text']}>{query}</div>
    </div>
  );
};
