import * as React from 'react';
import { ReduxState } from 'ducks';
import { useSelector } from 'react-redux';
import Image from 'next/image';

import styles from './ImagePreloader.module.css';
import { getResizedUrl } from 'lib/util/imageUrl';

const PIN_DETAILS_FIRST_IMAGES_DELAY = 1000;
const PIN_DETAILS_SECONDARY_IMAGES_DELAY = 5000;
const PIN_DETAILS_EDITOR_JS_IMAGES_DELAY = 6000;

const getEditorJsImageSrcSet = (url: string) => {
  return [640, 750, 828, 1080]
    .map((width) => {
      const resizedUrl = getResizedUrl(url, {
        resize: {
          width,
          fit: 'cover',
        },
      });

      return `${resizedUrl} ${width}w`;
    })
    .join(', ');
};

export const ImagePreloader = () => {
  const [preloadPinDetailsFirstImages, setPreloadPinDetailsFirstImages] = React.useState(false);
  const [preloadPinDetailsSecondaryImages, setPreloadPinDetailsSecondaryImages] = React.useState(
    false
  );
  const [preloadPinDetailsEditorJsImages, setPreloadPinDetailsEditorJsImages] = React.useState(
    false
  );

  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);

  const editorJsImageUrls = digitalMap?.pins
    .flatMap((pin) =>
      pin.free_form_content_items.flatMap((item) => {
        const parsedData = JSON.parse(item.data);
        return parsedData?.blocks
          ?.filter((block: any) => block.type === 'image')
          .map((block: any) => block.data.file.url);
      })
    )
    .flat()
    .filter((url): url is string => typeof url === 'string');

  React.useEffect(() => {
    setTimeout(() => {
      setPreloadPinDetailsFirstImages(true);
    }, PIN_DETAILS_FIRST_IMAGES_DELAY);
    setTimeout(() => {
      setPreloadPinDetailsSecondaryImages(true);
    }, PIN_DETAILS_SECONDARY_IMAGES_DELAY);
    setTimeout(() => {
      setPreloadPinDetailsEditorJsImages(true);
    }, PIN_DETAILS_EDITOR_JS_IMAGES_DELAY);
  }, []);

  const pinThumbnails = digitalMap?.pins
    ?.filter((pin) => pin.use_thumbnail_image_pin_marker)
    .map((pin) => {
      let thumbnailUrl = pin.thumbnail_url;
      if (pin.media_items?.length) {
        const thumbnailImgUrl = pin.media_items?.find((media) => media.type === 'IMAGE')?.url;
        if (thumbnailImgUrl) {
          thumbnailUrl = thumbnailImgUrl;
        }
      }

      return thumbnailUrl;
    });

  const pinDetailsFirstImages = digitalMap?.pins
    .map((pin) => {
      return pin.media_items
        ?.filter((media) => media.type === 'IMAGE')
        ?.slice(0, 1)
        ?.map((media) => media.url);
    })
    .flat();

  const pinDetailsSecondaryImages = digitalMap?.pins
    .map((pin) => {
      return pin.media_items
        ?.filter((media) => media.type === 'IMAGE')
        ?.slice(1)
        ?.map((media) => media.url);
    })
    .flat();

  const categoryIcons = digitalMap?.pin_categories?.map((category) => category.icon_url);

  const menuIcons = [
    '/static/images/map/all_icon.svg',
    '/static/images/map/back_arrow.svg',
    '/static/images/map/brush.svg',
    '/static/images/map/close.svg',
    '/static/images/map/down_chevron.svg',
    '/static/images/map/filter.svg',
    '/static/images/map/filter_white.svg',
    '/static/images/map/fork_spoon_white.svg',
    '/static/images/map/gift.svg',
    '/static/images/map/map_pin.svg',
    '/static/images/map/map_pin_bold.svg',
    '/static/images/map/notification_bell.svg',
    '/static/images/map/schedule.svg',
    '/static/images/map/search.svg',
    '/static/images/map/stamp_rally.svg',
    '/static/images/map/ticket.svg',
    '/static/images/map/ticket_white.svg',
    '/static/images/map/trash.svg',
    '/static/images/map/up_arrow.svg',
    '/static/images/map/user_circle.svg',
  ];

  return (
    <div className={styles['invisible-container']}>
      {pinThumbnails?.map((url) => (
        <Image key={url} priority src={url} width={94} height={94} alt="" />
      ))}
      {preloadPinDetailsFirstImages &&
        pinDetailsFirstImages?.map((url) => (
          <Image key={url} priority src={url} width={328} height={250} alt="" />
        ))}
      {preloadPinDetailsSecondaryImages &&
        pinDetailsSecondaryImages?.map((url) => (
          <Image key={url} priority src={url} width={328} height={250} alt="" />
        ))}
      {preloadPinDetailsEditorJsImages &&
        editorJsImageUrls?.map((url) => (
          <img key={url} src={url} srcSet={getEditorJsImageSrcSet(url)} sizes="100vw" alt="" />
        ))}
      {categoryIcons?.map((url) => (
        <Image key={url} priority src={url} width={12} height={12} alt="" />
      ))}
      {menuIcons?.map((url) => (
        <Image unoptimized key={url} priority src={url} width={16} height={24} alt="" />
      ))}
    </div>
  );
};
