import * as React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './PinHeaderActions.module.css';
import { DigitalMapPin } from 'models/digitalMap';

import { CircularButton } from './CircularButton';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { MapDisplayContext } from '../MapDisplayContext';
import { CustomerContext } from 'contexts/CustomerContext';
import { useExistingReservation } from '../useExistingReservation';
import { AnimatedButton } from '../AnimatedButton';
import config from 'config';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';
import { usePinDisplayState } from '../usePinDisplayState';

interface Props {
  pin: DigitalMapPin;
}

export const PinHeaderActions = ({ pin }: Props) => {
  const { t } = useTranslation();
  const { customer } = React.useContext(CustomerContext);

  const {
    setActiveRestaurantId,
    setActiveCouponViewContext,
    setActiveStampPinIdx,
    setDisplayMode,
    googleMap,
    onGetDirections,
  } = React.useContext(MapDisplayContext);
  const { setPinInfoDisplayState } = usePinDisplayState();

  const stampRally = useSelector(
    (state: ReduxState) => state.universal.digitalGuidanceStampRally.stampRally
  );
  const stampRallyRedeemed = stampRally?.stamp_rally_redemptions?.some(
    (redemption) => redemption.item_key === `${pin.index}`
  );
  const existingReservation = useExistingReservation();
  const hasStamp =
    pin.should_use_stamp_rally &&
    stampRally &&
    (config.enableNpsSurvey || existingReservation || customer);
  const hasRedeemableStamp = hasStamp && !stampRallyRedeemed;

  const redeemedCouponIds = useSelector((state: ReduxState) => state.coupons.redeemedCouponIds);
  const coupon = pin?.coupons?.length ? pin?.coupons[0] : null;

  const hasCoupon = Boolean(coupon && (existingReservation || customer));
  const couponRedeemed = coupon?.redeemed || redeemedCouponIds.includes(coupon?.id ?? '');
  const hasBookableProducts = pin.associated_product_ids?.length > 0;
  const hasMobileOrder = Boolean(pin.online_order_restaurant_id);

  const { currentPosition } = React.useContext(CurrentPositionContext);

  return (
    <div className={styles['action-container']}>
      <div className={styles['info-actions']}>
        {config.enableMapGetDirections ? (
          currentPosition && (
            <AnimatedButton
              className={styles['action-button']}
              clickedClassName={styles['clicked']}
              onClick={(e) => {
                e.stopPropagation();
                setPinInfoDisplayState({ pinKey: null, displayState: 'HIDDEN' });
                onGetDirections(currentPosition, pin);
              }}
            >
              <div className={styles['action-button-content']}>
                <img
                  src="/static/images/map/map_pin_bold.svg"
                  alt=""
                  className={styles['action-icon']}
                />
                <span>{t('Get Directions')}</span>
              </div>
            </AnimatedButton>
          )
        ) : (
          <AnimatedButton
            className={styles['action-button']}
            clickedClassName={styles['clicked']}
            onClick={(e) => {
              e.stopPropagation();
              setPinInfoDisplayState({ pinKey: null, displayState: 'HIDDEN' });
              setDisplayMode('BASE');
              if (googleMap) {
                googleMap.panTo({
                  lat: pin.location?.latitude ?? 0,
                  lng: pin.location?.longitude ?? 0,
                });
              }
            }}
          >
            <div className={styles['action-button-content']}>
              <img
                src="/static/images/map/map_pin_bold.svg"
                alt=""
                className={styles['action-icon']}
              />
              <span>{t('Show On Map')}</span>
            </div>
          </AnimatedButton>
        )}
        {hasBookableProducts && (
          <CircularButton
            icon="/static/images/map/ticket_white.svg"
            color="green"
            onClick={() => {
              setPinInfoDisplayState({ pinKey: pin.key, displayState: 'DETAILS' });

              setTimeout(() => {
                document.querySelector('#bookable-products')?.scrollIntoView({
                  behavior: 'smooth',
                });
              }, 100);
            }}
          />
        )}
        {hasMobileOrder && (
          <CircularButton
            icon="/static/images/map/fork_spoon_white.svg"
            color="orange"
            onClick={() => {
              setPinInfoDisplayState({ pinKey: pin.key, displayState: 'DETAILS' });
              setActiveRestaurantId(pin.online_order_restaurant_id);
            }}
          />
        )}
        {hasCoupon && (
          <CircularButton
            icon="/static/images/map/gift.svg"
            color={!couponRedeemed ? 'orange' : 'gray'}
            onClick={() => {
              if (!couponRedeemed && coupon) {
                setActiveCouponViewContext({ coupon, pin });
              }
            }}
          />
        )}
        {hasStamp && (
          <CircularButton
            icon="/static/images/map/brush.svg"
            color={hasRedeemableStamp ? 'green' : 'gray'}
            onClick={() => {
              if (hasRedeemableStamp) {
                setActiveStampPinIdx(pin.index);
              }
            }}
          />
        )}
        {pin.show_wait_time &&
          (pin.is_closed ? (
            <span className={styles['wait-time']}>{t('Closed')}</span>
          ) : (
            <span className={styles['wait-time']}>
              {t('{{minutes}} Min Wait', {
                minutes: pin.wait_time || 0,
              })}
            </span>
          ))}
        {pin?.tags?.map((tag) => (
          <div key={tag} className={styles['tag']}>
            {tag}
          </div>
        ))}
      </div>
    </div>
  );
};
