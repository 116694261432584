import clsx from 'clsx';
import * as React from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';

import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { DigitalMapPin } from 'models/digitalMap';
import { MapDisplayContext } from '../MapDisplayContext';

import styles from './SchedulePane.module.css';
import { ScheduleItem } from './ScheduleItem';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '../DatePicker';
import { findActivePinSchedule } from '../util';
import { getMonthYearFormat } from 'lib/util/i18nHelpers';
import { usePinDisplayState } from '../usePinDisplayState';

type PinWithShowtime = {
  pin: DigitalMapPin;
  showtime: string;
};

export const Schedule = () => {
  const daysRef = React.useRef<HTMLDivElement>(null);
  const { i18n } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedDate, setSelectedDate] = React.useState(moment().format('YYYY-MM-DD'));
  const [showDatePicker, setShowDatePicker] = React.useState(false);

  const { setActivePage } = React.useContext(MapDisplayContext);
  const { setPinInfoDisplayState } = usePinDisplayState();
  const month = selectedDate.substring(0, 7);

  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);

  const onResultClick = (result: { key: string }) => {
    setPinInfoDisplayState({ pinKey: result.key, displayState: 'DETAILS' });
    setActivePage('MAP');
  };

  React.useEffect(() => {
    // When selected date changes, scroll to the selected date
    const selectedDay = daysRef.current?.querySelector(`.${styles['selected']}`);
    if (selectedDay) {
      selectedDay.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [selectedDate]);

  const m = moment(selectedDate);

  const pinsWithTimes: PinWithShowtime[] = _.orderBy(
    digitalMap?.pins
      ?.map((pin) => {
        const showtimes = findActivePinSchedule(pin.showtime_schedules, m)?.showtimes ?? [];

        return showtimes.map((showtime) => ({
          pin,
          showtime,
        }));
      })
      .flat() ?? [],
    (pinWithShowtime) => moment(pinWithShowtime.showtime, 'HH:mm')
  );

  const datesInMonth: string[] = [];
  const firstDayOfMonth = moment(month, 'YYYY-MM').startOf('month');
  const daysInMonth = firstDayOfMonth.daysInMonth();

  for (let i = 0; i < daysInMonth; i++) {
    const date = firstDayOfMonth.clone().add(i, 'days');
    datesInMonth.push(date.format('YYYY-MM-DD'));
  }

  return (
    <>
      <div className={styles['month-selector']}>
        <span className={styles['month']}>
          {moment(month, 'YYYY-MM').locale(i18n.language).format(getMonthYearFormat(i18n.language))}
        </span>
        <img
          className={styles['calendar']}
          src="/static/images/ic_blank_calendar.svg"
          onClick={() => {
            setShowDatePicker(true);
          }}
        />
        {showDatePicker && (
          <DatePicker
            onClose={() => setShowDatePicker(false)}
            onDateChange={(date) => setSelectedDate(date)}
            selectedDate={selectedDate}
          />
        )}
      </div>
      <div className={styles['days']} ref={daysRef}>
        <div className={styles['days-inner']}>
          {datesInMonth.map((date) => (
            <div
              key={date}
              className={clsx(styles['day'], date === selectedDate && styles['selected'])}
              onClick={() => setSelectedDate(date)}
            >
              <time className={styles['day-name']}>
                {moment(date).locale(i18n.language).format('ddd')}
              </time>
              <time className={styles['day-date']}>
                {moment(date).locale(i18n.language).format('DD')}
              </time>
            </div>
          ))}
        </div>
      </div>
      <div className={styles['items']}>
        {pinsWithTimes.map(({ pin, showtime }) => {
          return (
            <div key={`${pin.index}-${showtime}`} className={styles['item']}>
              <ScheduleItem
                pin={pin}
                date={selectedDate}
                showtime={showtime}
                onClick={() => onResultClick(pin)}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
