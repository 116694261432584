import config from 'config';
import { Customer, UpdateCustomerRequest } from 'models/customer';

export interface CustomerCardValues {
  cardHolderName: string;
  paymentCurrencyType: 'JPY' | 'USD';
  paymentMethod: stripe.PaymentMethodResponse['paymentMethod'] | null;
  gmoMpTokens: string[];
  paymentGateway: 'STRIPE' | 'GMO';
}

export const getInitialCardValues = (customer: Customer | null): CustomerCardValues => ({
  cardHolderName: customer?.payment_profile_card_info?.card_holder_name || '',
  paymentCurrencyType: 'JPY',
  paymentMethod: null,
  gmoMpTokens: [],
  paymentGateway: customer?.payment_gateway || 'STRIPE',
});

export const convertToUpdateCustomerCardRequest = (
  values: CustomerCardValues
): UpdateCustomerRequest => {
  if (config.enableGmoPaymentGateway) {
    if (values.paymentGateway === 'GMO') {
      if (values.gmoMpTokens.length > 0) {
        return {
          card_settlement_currency: values.paymentCurrencyType,
          payment_profile_gateway_reference: values.gmoMpTokens[0],
          payment_gateway: values.paymentGateway,
        };
      }
    } else {
      if (values.paymentMethod) {
        return {
          payment_profile_gateway_reference: values.paymentMethod.id,
          card_settlement_currency: values.paymentCurrencyType,
        };
      }
    }
    return {};
  }
  if (values.paymentMethod) {
    return {
      payment_profile_card_info: {
        payment_profile_gateway_reference: values.paymentMethod.id,
        card_holder_name: values.cardHolderName,
        card_brand: values.paymentMethod.card?.brand as string,
        last_four_digits: values.paymentMethod.card?.last4 as string,
        exp_month: values.paymentMethod.card?.exp_month || 0,
        exp_year: values.paymentMethod.card?.exp_year || 0,
      },
      card_settlement_currency: values.paymentCurrencyType,
    };
  }
  return {};
};
