import moment from 'moment-timezone';
import { WithTranslation } from 'react-i18next';
import { flatten, uniqBy } from 'lodash';

import { GuestType, ProductSummary } from 'models/product';
import { ProductInstance } from 'models/productInstance';
import { Customer } from 'models/customer';
import { CheckoutFormValues } from './formValues';
import { CreateReservationRequest } from 'models/reservation';

// getGuestTypesUsedInProduct returns the guest types from product data.
export const getGuestTypesUsedInProductSummary = (
  product: ProductSummary,
  t: WithTranslation['t']
): GuestType[] => {
  const activeAndFuturePriceSchedules = (product.pricing ?? []).filter(
    (priceSchedule) =>
      !priceSchedule.end_date_local ||
      priceSchedule.end_date_local >=
        moment()
          .tz(product.start_timezone ?? '')
          .format('YYYY-MM-DD')
  );

  if (activeAndFuturePriceSchedules.length === 0) {
    return [];
  }

  {
    const guestTypes = flatten(
      activeAndFuturePriceSchedules.map((sched) =>
        sched.units.filter((unit) => unit.method === 'PER_PARTICIPANT').map((u) => u.guest_type)
      )
    );
    if (guestTypes.length > 0) {
      return uniqBy(guestTypes, (g) => g.key);
    }
  }

  {
    const perBookingGuestTypes = flatten(
      activeAndFuturePriceSchedules.map(
        (sched) =>
          sched.units.find((unit) => unit.method === 'PER_BOOKING' && unit.per_booking_guest_types)
            ?.per_booking_guest_types ?? []
      )
    );

    if ((perBookingGuestTypes ?? []).length > 0) {
      return uniqBy(perBookingGuestTypes, (g) => g.key);
    }
  }

  return [
    {
      key: 'guest',
      title: t('Guest'),
    },
  ];
};

export const getGuestTypesFromProductInstance = (
  productInstance: ProductInstance,
  t: WithTranslation['t']
) => {
  const guestTypes = (productInstance.units ?? [])
    .filter((unit) => unit.method === 'PER_PARTICIPANT')
    .map((u) => u.guest_type);
  if (guestTypes.length > 0) {
    return uniqBy(guestTypes, (g) => g.key);
  }

  const perBookingGuestTypes = productInstance?.units.find(
    (u) => u.method === 'PER_BOOKING' && (u.per_booking_guest_types ?? []).length > 0
  )?.per_booking_guest_types;
  if ((perBookingGuestTypes ?? []).length > 0) {
    return uniqBy(perBookingGuestTypes as any, (g: GuestType) => g.key);
  }

  return [
    {
      key: 'guest',
      title: t('Guest'),
    },
  ];
};

export const getCreateReservationParam = ({
  customer,
  values,
  agentReference,
  language,
  redirectUri,
  accessToken,
  idProvider,
  gmoAccessId,
  finalizeTdsAuth,
}: {
  customer: Customer | null;
  values: CheckoutFormValues;
  agentReference: string;
  language: string;
  redirectUri: string;
  accessToken: string | null;
  idProvider: string | null;
  gmoAccessId?: string | null;
  finalizeTdsAuth?: boolean;
}): CreateReservationRequest => {
  return {
    settlement_currency: customer?.card_settlement_currency ?? '',
    product_instance_id: values.productInstance?.id ?? '',
    guests:
      Object.entries(values.guestCounts)
        .map(([key, value]) =>
          Array.from({ length: value as number }, () => ({
            guest_type_key: key,
            field_responses: [],
          }))
        )
        .flat() ?? [],
    agent_reference: agentReference,
    field_responses: [
      {
        key: 'email',
        response: customer?.email ?? '',
      },
      {
        key: 'preferred_language_iso2',
        response: language,
      },
      ...(customer?.kana_given_name && customer?.kana_family_name
        ? [
            {
              key: 'kana_given_name',
              response: customer?.kana_given_name,
            },
            {
              key: 'kana_family_name',
              response: customer?.kana_family_name,
            },
          ]
        : []),
      ...(customer?.given_name && customer?.family_name
        ? [
            {
              key: 'given_name',
              response: customer?.given_name ?? '',
            },
            {
              key: 'family_name',
              response: customer?.family_name ?? '',
            },
          ]
        : []),
    ],
    payment_type: 'PAID_IN_FULL',
    booking_source: {
      source_type: 'DIRECT_WEB',
    },
    ...(gmoAccessId && {
      payment_profile_gateway_reference: gmoAccessId,
    }),
    ...(accessToken && {
      access_token: accessToken,
    }),
    id_provider: idProvider ?? '',
    redirect_uri: redirectUri,
    should_use_customer_payment_info: true,
    landing_sid: 'maps',
    finalize_tds_auth: finalizeTdsAuth || false,
  };
};

export const getCreateReservationErrorMessage = (error: string, t: WithTranslation['t']) => {
  if (/Failed to finalize 3D Secure 2.0 charge/.test(error)) {
    return t(
      'Please check your credit card number, and that the card supports 3D Secure 2.0. If credit card information is correct but still fails, please contact your card issuer or try with different credit card.'
    );
  }
  return error;
};
