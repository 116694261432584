import * as React from 'react';
import clsx from 'clsx';
import config from 'config';
import Image from 'next/image';
import { ReduxState } from 'ducks';
import { DigitalMap, DigitalMapPin } from 'models/digitalMap';
import { useSelector } from 'react-redux';
import { AdvancedMarker } from './AdvancedMarker';
import { useTranslation } from 'react-i18next';

import styles from './PinMarker.module.css';
import { smoothMoveCamera } from './smoothMoveCamera';
import { useMapDisplayContext } from './MapDisplayContext';

const getCategoryIconUrl = (digitalMap: DigitalMap | null, category: string): string => {
  if (!category || !digitalMap) {
    return '';
  }

  const categorySettings = digitalMap.pin_categories?.find((c) => c.label === category);

  if (!categorySettings) {
    return '';
  }

  return categorySettings.icon_url;
};

interface Props {
  map: google.maps.Map;
  pin: DigitalMapPin;
  isActive: boolean;
  onClick?: (pinKey: string) => void;
}

export const PinMarker = React.forwardRef<google.maps.marker.AdvancedMarkerElement, Props>(
  ({ map, pin, isActive, onClick }, ref) => {
    const { filters } = useMapDisplayContext();

    const { t } = useTranslation();

    const clickHandler = (e: Event) => {
      e.stopPropagation();
      onClick?.(pin.key);
      if (map) {
        smoothMoveCamera(map, {
          center: {
            lat: pin.location?.latitude ?? 0,
            lng: pin.location?.longitude ?? 0,
          },
        });
      }
    };

    const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);

    const iconUrl = pin.icon_url || getCategoryIconUrl(digitalMap, pin.category);

    if (
      pin.show_wait_time &&
      (!config.enableWaitTimeCategory ||
        filters.categories?.find((category) => category === 'WAIT_TIME'))
    ) {
      const waitTime = pin.wait_time || 0;

      return (
        <AdvancedMarker
          ref={ref}
          key={pin.index}
          map={
            null /* Marker clusterer controls which markers are attached to the map based on filters */
          }
          position={{
            lat: pin.location?.latitude ?? 0,
            lng: pin.location?.longitude ?? 0,
          }}
          onClick={clickHandler}
          zIndex={isActive ? 2 : 1}
        >
          <div className={styles['map__pin']}>
            <div
              className={clsx(
                styles['map__pin__circle'],
                styles['large'],
                styles['wait__time'],
                isActive && styles['active']
              )}
            >
              {pin.is_closed ? (
                <div className={styles['closed']}>{t('Closed')}</div>
              ) : (
                <>
                  <div className={styles['min']}>{waitTime}</div>
                  <div className={styles['label']}>{t('Min Wait')}</div>
                </>
              )}
            </div>
            <div
              className={clsx(
                styles['map__pin__arrow'],
                styles['large'],
                isActive && styles['active']
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="17"
                viewBox="0 0 22 17"
                fill="none"
              >
                <path d="M4.38862 2.49414L9.78302 11.8998C10.1511 12.5415 11.0643 12.5753 11.4788 11.9626L17.8839 2.49414" />
              </svg>
            </div>
          </div>
        </AdvancedMarker>
      );
    }

    if (pin.use_thumbnail_image_pin_marker) {
      let thumbnailUrl = pin.thumbnail_url;
      if (pin.media_items?.length) {
        const thumbnailImgUrl = pin.media_items?.find((media) => media.type === 'IMAGE')?.url;
        if (thumbnailImgUrl) {
          thumbnailUrl = thumbnailImgUrl;
        }
      }

      return (
        <AdvancedMarker
          ref={ref}
          key={`${pin.index}`}
          map={
            null /* Marker clusterer controls which markers are attached to the map based on filters */
          }
          position={{
            lat: pin.location?.latitude ?? 0,
            lng: pin.location?.longitude ?? 0,
          }}
          onClick={clickHandler}
          zIndex={isActive ? 2 : 1}
        >
          <div className={styles['map__pin']}>
            <Image
              priority
              alt="pin"
              className={clsx(
                styles['map__pin__circle'],
                styles['large'],
                isActive && styles['active']
              )}
              src={thumbnailUrl}
              width={94}
              height={94}
            />
            <div
              className={clsx(
                styles['map__pin__arrow'],
                styles['large'],
                isActive && styles['active']
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="17"
                viewBox="0 0 22 17"
                fill="none"
              >
                <path d="M4.38862 2.49414L9.78302 11.8998C10.1511 12.5415 11.0643 12.5753 11.4788 11.9626L17.8839 2.49414" />
              </svg>
            </div>
          </div>
        </AdvancedMarker>
      );
    }

    return (
      <AdvancedMarker
        ref={ref}
        key={pin.index}
        map={
          null /* Marker clusterer controls which markers are attached to the map based on filters */
        }
        position={{
          lat: pin.location?.latitude ?? 0,
          lng: pin.location?.longitude ?? 0,
        }}
        onClick={clickHandler}
        zIndex={isActive ? 2 : 1}
      >
        <div className={styles['map__pin']}>
          <Image
            priority
            src={iconUrl}
            alt="pin"
            height={45}
            width={45}
            className={clsx(
              styles['map__pin__circle'],
              styles['small'],
              isActive && styles['active']
            )}
            style={{
              padding: pin.icon_image_fit === 'COVER' ? '0' : '8px',
            }}
          />
          <div
            className={clsx(
              styles['map__pin__arrow'],
              styles['small'],
              isActive && styles['active']
            )}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="17"
              viewBox="0 0 22 17"
              fill="none"
            >
              <path d="M4.38862 2.49414L9.78302 11.8998C10.1511 12.5415 11.0643 12.5753 11.4788 11.9626L17.8839 2.49414" />
            </svg>
          </div>
        </div>
      </AdvancedMarker>
    );
  }
);
