import clsx from 'clsx';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import styles from './Profile.module.css';
import { CustomerEditor } from './CustomerEditor';

interface Props {
  onLogout?: () => void;
  email?: string;
}

export const AccountInfoForm = ({ onLogout }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles['form']}>
        <div className={styles['main-info']}>
          <CustomerEditor />
          {onLogout && (
            <button
              className={clsx(styles['btn'], styles['logout'])}
              onClick={onLogout}
              type="button"
            >
              {t('Log Out')}
            </button>
          )}
        </div>
      </div>
    </>
  );
};
