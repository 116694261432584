import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CustomerContext, CustomerContextType } from 'contexts/CustomerContext';
import { ReduxState } from 'ducks';
import { fetchCustomer } from 'ducks/client/customers';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { getCommonRedirectUri } from 'lib/util/customer';
import { useCustomerToken } from 'hooks/useCustomerToken';
import { Amplify } from 'aws-amplify';
import { signOut } from 'aws-amplify/auth';

export const CustomerProvider = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = React.useState<boolean>(true);

  const { apiKey } = React.useContext(ApiKeyContext);

  const { accessToken, idProvider, loading, logout } = useCustomerToken();

  const customer = useSelector((state: ReduxState) => state.customer.customer);

  const fetchingCustomer = useSelector((state: ReduxState) => state.customer.loading);

  const fetchCustomerError = useSelector((state: ReduxState) => state.customer.error);

  const apiSettings = useSelector((state: ReduxState) => state.server.settings.all);

  React.useEffect(() => {
    if (accessToken && idProvider) {
      const fn = async () => {
        try {
          await dispatch(
            fetchCustomer(
              apiKey,
              idProvider ?? 'COGNITO',
              accessToken,
              getCommonRedirectUri(),
              i18n.language
            )
          );
        } catch (e) {
          console.error(e);
        }
      };
      fn();
    }
  }, [accessToken, idProvider]);

  React.useEffect(() => {
    (async () => {
      if (!fetchingCustomer && (customer || fetchCustomerError)) {
        console.log('* fetchingCustomer', fetchingCustomer);
        console.log('* customer', customer);
        console.log('* fetchCustomerError', fetchCustomerError);
        setInitialLoading(false);
        if (fetchCustomerError === 'INVALID_TOKEN') {
          if (idProvider === 'COGNITO') {
            const cognitoInfo = apiSettings.customer_ledger_settings?.cognito;

            if (!cognitoInfo?.user_pool_id || !cognitoInfo?.client_id) {
              return;
            }

            Amplify.configure({
              Auth: {
                Cognito: {
                  userPoolId: cognitoInfo.user_pool_id,
                  userPoolClientId: cognitoInfo.client_id,
                },
              },
            });

            await signOut();
          } else if (idProvider === 'GOOGLE') {
            try {
              await fetch('https://accounts.google.com/o/oauth2/revoke', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `token=${accessToken}`,
              });
            } catch (error) {
              console.error(error);
            }
          }
          logout();
        }
      }
    })();
  }, [fetchingCustomer, customer, fetchCustomerError]);

  React.useEffect(() => {
    if (!loading && !accessToken && !idProvider) {
      setInitialLoading(false);
    }
  }, [loading, accessToken, idProvider]);

  const value: CustomerContextType = {
    customer,
    fetchingCustomer: fetchingCustomer || initialLoading,
    fetchCustomerError,
  };

  return <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>;
};
