import * as React from 'react';

import {
  GeneralCreditCardContext,
  GeneralCreditCardContextType,
} from 'contexts/GeneralCreditCardContext';

export const GeneralCreditCardProvider = ({ children }: { children: React.ReactNode }) => {
  const [cardNumber, setCardNumber] = React.useState('');
  const [cardExpiry, setCardExpiry] = React.useState('');
  const [cardCvc, setCardCvc] = React.useState('');

  const value: GeneralCreditCardContextType = {
    cardNumber,
    setCardNumber,
    cardExpiry,
    setCardExpiry,
    cardCvc,
    setCardCvc,
  };

  return (
    <GeneralCreditCardContext.Provider value={value}>{children}</GeneralCreditCardContext.Provider>
  );
};
