import styles from './CounterButton.module.css';

interface Props {
  count: number;
  onIncrement: () => void;
  onDecrement: () => void;
}

export const CounterButton = ({ count, onIncrement, onDecrement }: Props) => {
  // Prevent events from bubbling up to parent elements
  // to avoid triggering other events

  return (
    <>
      {!count ? (
        <div className={styles['inc-btn']}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onIncrement();
            }}
            type="button"
          >
            +
          </button>
        </div>
      ) : (
        <div className={styles['inc-dec-btn']}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDecrement();
            }}
            type="button"
          >
            -
          </button>
          <span className={styles['count']}>{count}</span>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onIncrement();
            }}
            type="button"
          >
            +
          </button>
        </div>
      )}
    </>
  );
};
