import * as React from 'react';
import { ProductSummary } from 'models/product';
import { useTranslation } from 'react-i18next';
import { ProductCard } from './ProductCard';
import styles from './Products.module.css';

interface Props {
  products: ProductSummary[];
}
export const Products = React.forwardRef<HTMLDivElement, Props>(({ products }, ref) => {
  const { t } = useTranslation();

  return (
    <div id="bookable-products" className={styles['container']} ref={ref}>
      <header className={styles['header']}>
        <h2 className={styles['title']}>{t('Book Your Ride')}</h2>
      </header>
      <main className={styles['cards']}>
        <div className={styles['carousel']}>
          {products.map((product) => (
            <ProductCard product={product} key={product.id} />
          ))}
        </div>
      </main>
    </div>
  );
});
