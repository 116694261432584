import { Button, Box } from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { GuidanceStampRally } from 'models/guidanceStampRally';
import { redeemStampRallyByVisitorIdentifier } from 'ducks/client/stampRallies';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { Modal } from 'components/Modal/Modal';
import { formatDateTime } from 'lib/util/formatDateTime';

import styles from 'components/StampRallyModal/StampRallyModal.module.css';
import { DigitalMapPin } from 'models/digitalMap';
import { outOfRange } from './util';
import { useExistingReservation } from './useExistingReservation';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';
import config from 'config';
import { getMandatoryStampRallyRedemptions } from 'lib/util/getMandatoryStampRallyRedemptions';
import { ReduxState } from 'ducks';
import { logCustomerEvent } from 'ducks/client/customerEvents';
import { fetchMarketingAutomationCampaignsByVisitorIdentifier } from 'ducks/client/marketingAutomationCampaigns';
import { useVisitorIdentifier, VisitorIdentifier } from 'hooks/useVisitorIdentifier';

interface Props {
  pin: DigitalMapPin | null;
  onClose: () => void;
  openStampRallyModal: () => void;
  pageId: string;
  guidanceStampRally: GuidanceStampRally;
  itemKey: string;
  stampRallyLabel: string;
  stampImageUrl: string;
}

export const StampModal = ({
  pin,
  onClose,
  openStampRallyModal,
  pageId,
  guidanceStampRally,
  itemKey,
  stampRallyLabel,
  stampImageUrl,
}: Props) => {
  const dispatch = useDispatch();
  const { apiKey } = React.useContext(ApiKeyContext);
  const existingReservation = useExistingReservation();

  const [submittedTime, setSubmittedTime] = React.useState('');

  const [submitting, setSubmitting] = React.useState(false);

  const { currentPosition } = React.useContext(CurrentPositionContext);
  const visitorIdentifier = useVisitorIdentifier();
  const stringifiedVisitorIdentifier = JSON.stringify(visitorIdentifier);

  const outOfStampRange = pin?.activation_range
    ? outOfRange(currentPosition, pin.location, pin.activation_range)
    : false;

  const { t, i18n } = useTranslation();
  const visitorToken = useSelector((state: ReduxState) => state.tracking.visitorToken);
  const reservation = useSelector((state: ReduxState) => state.server.reservation.reservation);

  React.useEffect(() => {
    const execute = async () => {
      if (!apiKey || !visitorToken) {
        return;
      }

      // If redeemed stamp number is equal to total number of stamps, stamp rally is considered complete
      // And we send customer event & fetch appropriate MA campaign
      if (config.enableNpsSurvey) {
        const filteredGuidanceStampRallyRedemptions = getMandatoryStampRallyRedemptions(
          reservation,
          guidanceStampRally?.stamp_rally_redemptions ?? []
        );
        const parsedVisitorIdentifier = JSON.parse(
          stringifiedVisitorIdentifier
        ) as VisitorIdentifier;

        if (filteredGuidanceStampRallyRedemptions.length === guidanceStampRally.stamp_count) {
          // Send event only if no gift has been redeemed yet
          if (guidanceStampRally?.stamp_rally_gift_redemptions.length === 0) {
            await dispatch(
              logCustomerEvent({
                apiKey,
                eventType: 'online-guide-after-stamp-rally-completion',
                visitorToken,
                // For non-reservation, reservation_id will not be included
                reservationId: existingReservation?.id,
                metadata: JSON.stringify({
                  // This should match value expected in banda getTriggersForEvent
                  stampRallyId: guidanceStampRally.id,
                }),
              })
            );

            await dispatch(
              fetchMarketingAutomationCampaignsByVisitorIdentifier(
                apiKey,
                parsedVisitorIdentifier,
                'online-guide-after-stamp-rally-completion',
                i18n.language,
                JSON.stringify({
                  // This should match value expected in banda getTriggersForEvent
                  stampRallyId: guidanceStampRally.id,
                })
              )
            );
          }
        }
      }
    };
    execute();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [apiKey, visitorToken, existingReservation, guidanceStampRally, stringifiedVisitorIdentifier]);

  const handleRedeem = async () => {
    try {
      setSubmitting(true);
      await dispatch(
        redeemStampRallyByVisitorIdentifier(apiKey, {
          reservation_id: visitorIdentifier.reservationId,
          page_id: pageId,
          stamp_rally_id: guidanceStampRally.id,
          item_key: itemKey,
          tracking_id: visitorIdentifier.trackingId,
        })
      );
    } catch (e) {
      console.error(e);
    } finally {
      setSubmitting(false);
    }

    const now = formatDateTime(moment(), i18n.language);
    setSubmittedTime(now);
  };

  // Reset stamp time stamp
  React.useEffect(() => {
    if (pin) {
      setSubmittedTime('');
    }
  }, [pin]);

  return (
    <Modal onClose={onClose} open={!!pin}>
      <div className={clsx(styles['modal__content'])}>
        {submittedTime ? (
          <div className={clsx(styles['stampGet'])}>
            <div className={clsx(styles['stampGet__ttl'])}>
              <img src="/static/images/ic_stampGet.svg" />
            </div>
            <div className={clsx(styles['stampGet__stamp'])}>
              <img src={(stampImageUrl || guidanceStampRally?.stamp_image_url) ?? ''} />
              <img src={(stampImageUrl || guidanceStampRally?.stamp_image_url) ?? ''} />
            </div>
            <p
              className={clsx(styles['stampGet__desc'])}
              dangerouslySetInnerHTML={{
                __html: t('Got stamp for <br/><b>{{label}}</b>', {
                  label: stampRallyLabel,
                }),
              }}
            />
            <p className={clsx(styles['stampGet__date'])}>
              {t('Stamp date & time')}: {submittedTime}
            </p>
            <div className={clsx(styles['modal__content__btns'])}>
              <a
                className={clsx(styles['btn'], styles['btn--cancel'])}
                onClick={() => {
                  setSubmittedTime('');
                  openStampRallyModal();
                  onClose();
                }}
              >
                {t('Close')}
              </a>
            </div>
          </div>
        ) : (
          <>
            <Box className={styles['title']} display="flex" justifyContent="center">
              <h1>{stampRallyLabel}</h1>
            </Box>
            {outOfStampRange && (
              <Box mt={2} fontSize="12px">
                {t(
                  '* To stamp, you must be within {{distance}} meters of the designated location.',
                  {
                    distance: pin?.activation_range,
                  }
                )}
              </Box>
            )}
            {!(config.enableNpsSurvey || existingReservation) && (
              <Box mt={2} fontSize="12px">
                {t('* To stamp, you must book at least one ticket.', {
                  distance: pin?.activation_range,
                })}
              </Box>
            )}
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                disabled={
                  submitting || outOfStampRange || !(config.enableNpsSurvey || existingReservation)
                }
                onClick={handleRedeem}
                variant="contained"
                color="primary"
              >
                {t('Push stamp')}
              </Button>
            </Box>
          </>
        )}
      </div>
    </Modal>
  );
};
