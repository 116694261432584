import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import styles from './PageLoader.module.css';

export const PageLoader = () => {
  return (
    <div className={styles['container']}>
      <div className={styles['loader']}>
        <DotLottieReact src="/static/animations/map-page-loader.lottie" loop autoplay />
      </div>
    </div>
  );
};
