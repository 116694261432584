import moment from 'moment-timezone';

import { Reservation } from 'models/reservation';
import { GuidanceStampRallyRedemption } from 'models/guidanceStampRally';

export const getMandatoryStampRallyRedemptions = (
  reservation: Reservation | null,
  guidanceStampRallyRedemptions: GuidanceStampRallyRedemption[]
): GuidanceStampRallyRedemption[] => {
  const isAnnualPasMode = reservation?.is_annual_pass_mode ?? false;

  const startOfToday = moment()
    .tz(reservation?.start_timezone ?? 'UTC')
    .startOf('day');

  return (guidanceStampRallyRedemptions ?? []).filter((redemption: any) => {
    if (isAnnualPasMode) {
      // if annual pass mode, only show redemptions from today onwards
      return moment(redemption.date_time_utc).isSameOrAfter(startOfToday);
    }
    return true;
  });
};
