import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CustomerFormFieldSet } from 'models/settings';
import { toContentLanguage } from 'lib/util/toContentLanguage';
import { Field, useFormState } from 'react-final-form';
import { required, getOptionalValidator } from 'lib/forms/validate';
import { composeValidators } from 'lib/util/composeValidators';

import { ReduxState } from 'ducks';

import { CustomerFormFieldInput } from './CustomerFormFieldInput';

import styles from './MyPage.module.css';

export const CustomerEditor = () => {
  const apiSettings = useSelector((state: ReduxState) => state.server.settings.all);

  const customerLedgerSettings = apiSettings.customer_ledger_settings;

  const { t, i18n } = useTranslation();

  const { values } = useFormState();

  const getCustomerFormFieldSet = (language: string): CustomerFormFieldSet | undefined => {
    return customerLedgerSettings?.form_field_sets?.find(
      (formFieldSet) => formFieldSet.content_language === toContentLanguage(language)
    );
  };

  const customerFormFieldSet = getCustomerFormFieldSet(i18n.language);

  const validators: any[] = [required];
  if (customerFormFieldSet?.customer_name_format === 'ALPHABET') {
    validators.push(getOptionalValidator('alpha-name'));
  } else if (customerFormFieldSet?.customer_name_format === 'KANA') {
    validators.push(getOptionalValidator('kana-name'));
  } else if (customerFormFieldSet?.customer_name_format === 'KANA_OR_ALPHABET') {
    validators.push(getOptionalValidator('kana-or-alpha-name'));
  }

  return (
    <div className={clsx(styles['mayapeInfo'])}>
      {values?.email && (
        <div className={clsx(styles['form__item'])}>
          <div className={clsx(styles['form__item__ttl'])}>{t('Email')}</div>
          <div className={clsx(styles['form__item__body'])}>
            <input
              type="text"
              className={clsx(styles['inputtext'])}
              value={values?.email}
              disabled={true}
            />
          </div>
        </div>
      )}
      {customerFormFieldSet?.customer_name_format === 'ALPHABET' && (
        <div className={clsx(styles['form__item'])}>
          <div className={clsx(styles['form__flex'])}>
            <div>
              <div className={clsx(styles['form__item__ttl'], styles['required'])}>
                {t('First name')}
              </div>
              <Field type="text" name="firstName" validate={composeValidators(...validators)}>
                {({ input, meta }) => (
                  <div className={clsx(styles['form__item__body'])}>
                    <input type="text" className={clsx(styles['inputtext'])} {...input} />
                    {meta.touched && meta.error && (
                      <p className={clsx(styles['message'], styles['message--err'])}>
                        {t(`${meta.error}`)}
                      </p>
                    )}
                  </div>
                )}
              </Field>
            </div>
            <div>
              <div className={clsx(styles['form__item__ttl'], styles['required'])}>
                {t('Last name')}
              </div>
              <Field type="text" name="lastName" validate={composeValidators(...validators)}>
                {({ input, meta }) => (
                  <div className={clsx(styles['form__item__body'])}>
                    <input type="text" className={clsx(styles['inputtext'])} {...input} />
                    {meta.touched && meta.error && (
                      <p className={clsx(styles['message'], styles['message--err'])}>
                        {t(`${meta.error}`)}
                      </p>
                    )}
                  </div>
                )}
              </Field>
            </div>
          </div>
        </div>
      )}
      {(customerFormFieldSet?.customer_name_format === 'KANA' ||
        customerFormFieldSet?.customer_name_format === 'KANA_OR_ALPHABET') && (
        <div className={clsx(styles['form__item'])}>
          <div className={clsx(styles['form__flex'])}>
            <div>
              <div className={clsx(styles['form__item__ttl'], styles['required'])}>
                {t('Last name(Kana)')}
              </div>
              <Field type="text" name="kanaLastName" validate={composeValidators(...validators)}>
                {({ input, meta }) => (
                  <div className={clsx(styles['form__item__body'])}>
                    <input type="text" className={clsx(styles['inputtext'])} {...input} />
                    {meta.touched && meta.error && (
                      <p className={clsx(styles['message'], styles['message--err'])}>
                        {t(`${meta.error}`)}
                      </p>
                    )}
                  </div>
                )}
              </Field>
            </div>
            <div>
              <div className={clsx(styles['form__item__ttl'], styles['required'])}>
                {t('First name(Kana)')}
              </div>
              <Field type="text" name="kanaFirstName" validate={composeValidators(...validators)}>
                {({ input, meta }) => (
                  <div className={clsx(styles['form__item__body'])}>
                    <input type="text" className={clsx(styles['inputtext'])} {...input} />
                    {meta.touched && meta.error && (
                      <p className={clsx(styles['message'], styles['message--err'])}>
                        {t(`${meta.error}`)}
                      </p>
                    )}
                  </div>
                )}
              </Field>
            </div>
          </div>
        </div>
      )}
      {customerFormFieldSet?.form_fields?.map((formField) => (
        <CustomerFormFieldInput key={formField.key} formField={formField} />
      ))}

      <Field name={'shouldReceiveSpecialEmailOffers'}>
        {({ input }) => (
          <div className={styles['register__item']}>
            <label className={styles['form__confirm']}>
              <input
                type="checkbox"
                checked={input.value}
                onChange={() => {
                  input.onChange(!input.value);
                }}
              />
              <i></i>
              <p>{t('I agree to receive special email offers')}</p>
            </label>
          </div>
        )}
      </Field>
    </div>
  );
};
