import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import styles from './ButtonLoader.module.css';

export const ButtonLoader = () => {
  return (
    <div className={styles['container']}>
      <div className={styles['loader']}>
        <DotLottieReact src="/static/animations/btn-loader.lottie" loop autoplay />
      </div>
    </div>
  );
};
