import styles from './NotificationsIcon.module.css';

interface Props {
  onClick: () => void;
}

export const NotificationsIcon = ({ onClick }: Props) => {
  const notifications = [];

  return (
    <div className={styles['icon-container']}>
      <img
        onClick={onClick}
        src="/static/images/map/notification_bell.svg"
        alt="Notification bell"
        className={styles['icon']}
      />
      {notifications?.length > 0 && <div className={styles['active-indicator']} />}
    </div>
  );
};
