import * as React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import clsx from 'clsx';

import { GuidanceStampRally } from 'models/guidanceStampRally';
import { GuidancePage } from 'models/guidancePage';
import { Reservation } from 'models/reservation';
import { getMandatoryStampRallyRedemptions } from 'lib/util/getMandatoryStampRallyRedemptions';

import { StampRallyGiftModal } from './StampRallyGiftModal';

import styles from './StampRallyModal.module.css';

const getStampRallyGiftForStampCount = (
  guidanceStampRally: GuidanceStampRally,
  stampCount: number
): string => {
  const stampRallyGift = (guidanceStampRally.gifts || []).find(
    (gift) => gift.stamp_count_to_redeem === stampCount
  );

  if (stampRallyGift) {
    return stampRallyGift.title;
  }

  return '';
};

export const StampRallyModal = ({
  guidanceStampRally,
  guidancePage,
  reservation,
  open,
  onClose,
}: {
  guidanceStampRally: GuidanceStampRally;
  guidancePage?: GuidancePage;
  reservation: Reservation | null;
  open: boolean;
  onClose: () => void;
}) => {
  const [openGiftModal, setOpenGiftModal] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const filteredGuidanceStampRallyRedemptions = getMandatoryStampRallyRedemptions(
    reservation,
    guidanceStampRally?.stamp_rally_redemptions ?? []
  );

  return (
    <div className={clsx(styles['modal'], styles['--stamp'], open ? styles['is-active'] : null)}>
      <div className={clsx(styles['modal__inner'], styles['--stamp'])}>
        <div className={clsx(styles['modal__content'], styles['--stamp'])}>
          <div className={clsx(styles['stampRally'])}>
            <div className={clsx(styles['stampRally__header'])}>
              <div className={clsx(styles['stampRally__header__main'])}>
                <p className={clsx(styles['stampRally__header__main__ttl'])}>
                  {guidanceStampRally.title}
                </p>
              </div>
              <p
                className={clsx(styles['stampRally__header__close'])}
                onClick={() => onClose()}
              ></p>
            </div>
            <div className={clsx(styles['stampRally__body'])}>
              <div
                className={clsx(styles['stampRally__body__bg'])}
                style={{
                  backgroundImage: `url(${guidanceStampRally.background_image_url})`,
                }}
              ></div>
              <div className={clsx(styles['stampRally__body__info'])}>
                <div className={clsx(styles['stampRally__body__info__progress'])}>
                  <span>
                    {filteredGuidanceStampRallyRedemptions.length ?? '0'}/
                    {guidanceStampRally?.stamp_count ?? '0'}
                  </span>
                  {t('completed')}
                </div>
                <div
                  className={clsx(styles['stampRally__body__info__gift'])}
                  onClick={() => setOpenGiftModal(true)}
                >
                  <img src="/static/images/ic_gift.svg" />
                  <p>{t('see prizes')}</p>
                </div>
              </div>
              <div className={clsx(styles['stampRally__body__list'])}>
                {[...Array(guidanceStampRally?.stamp_count ?? 0)].map((_, idx) => {
                  const redemption =
                    filteredGuidanceStampRallyRedemptions.length > idx
                      ? filteredGuidanceStampRallyRedemptions[idx]
                      : null;

                  const giftTitle = getStampRallyGiftForStampCount(guidanceStampRally, idx + 1);

                  return (
                    <div className={clsx(styles['stampRally__body__list__item'])} key={idx}>
                      <div className={clsx(styles['stampRally__body__list__item__main'])}>
                        {redemption ? (
                          <div
                            className={clsx(styles['stampRally__body__list__item__main__stamp'])}
                          >
                            <img
                              className={clsx(
                                styles['stampRally__body__list__item__main__stamp__pic']
                              )}
                              src={
                                redemption
                                  ? redemption.stamp_image_url || guidanceStampRally.stamp_image_url
                                  : ''
                              }
                            />
                            <p
                              className={clsx(
                                styles['stampRally__body__list__item__main__stamp__date']
                              )}
                            >
                              {moment(redemption?.date_time_utc).format('YYYY/MM/DD HH:mm')}
                            </p>
                          </div>
                        ) : (
                          <div className={styles['stampRally__body__list__item__main__gift']}>
                            <p className={styles['stampRally__body__list__item__main__gift__num']}>
                              {idx + 1}
                            </p>
                            {giftTitle && (
                              <p
                                className={styles['stampRally__body__list__item__main__gift__txt']}
                              >
                                {t('Get {{prizeName}} for {{n}} stamps', {
                                  prizeName: giftTitle,
                                  n: idx + 1,
                                })}
                              </p>
                            )}
                            <img
                              className={styles['stampRally__body__list__item__main__gift__bg']}
                              src="/static/images/ic_gift_bg.svg"
                            />
                          </div>
                        )}
                      </div>
                      {redemption && (
                        <div className={clsx(styles['stampRally__body__list__item__ttl'])}>
                          {redemption.stamp_rally_label}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx(styles['modal__overlay'], styles['--stamp'])}></div>
      {guidanceStampRally && (
        <StampRallyGiftModal
          open={openGiftModal}
          onClose={() => setOpenGiftModal(false)}
          guidanceStampRally={guidanceStampRally}
          guidancePage={guidancePage}
          reservation={reservation}
        />
      )}
    </div>
  );
};
