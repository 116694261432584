import React from 'react';
import styles from './OrdersPage.module.css';
import { Restaurant, RestaurantOrder } from 'models/digitalMap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { TFunction } from 'i18next';
import { useMapDisplayContext } from 'components/Map/MapDisplayContext';

const getStatusText = (
  status: RestaurantOrder['status'],
  restaurant: Restaurant | undefined,
  t: TFunction
) => {
  switch (status) {
    case 'RECEIVED':
      return t('We got your order from {{restaurantName}}!', {
        restaurantName: restaurant?.name ?? '',
      });
    case 'IN_PROGRESS':
      return t('Your order is currently being prepared by {{restaurantName}}.', {
        restaurantName: restaurant?.name ?? '',
      });
    case 'READY':
      return t('Your order is ready for pickup at {{restaurantName}}.', {
        restaurantName: restaurant?.name ?? '',
      });
    case 'COMPLETE':
      return t('Your order from {{restaurantName}} has been picked up.', {
        restaurantName: restaurant?.name ?? '',
      });
  }
};

interface OrderCardProps {
  order: RestaurantOrder;
}

const OrderCard: React.FC<OrderCardProps> = ({ order }) => {
  const { t } = useTranslation();

  const {
    setOrderStatusRestaurantId,
    setOrderStatusRestaurantOrderId,
    setActivePage,
  } = useMapDisplayContext();

  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);
  const restaurant = digitalMap?.restaurants.find((r) =>
    order.restaurant_versioned_id?.includes(r.id)
  );

  return (
    <section
      className={`${styles.orderCard} ${
        order.status === 'COMPLETE' ? styles.completedOrderCard : ''
      }`}
    >
      <h3 className={styles.orderNumber}>{order.id?.substring(0, 8)}</h3>
      <div className={styles.orderInfo}>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/48e66648b18c5737880edd8fcb6d54813ad582a883239794e58bb68b794fe348?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818"
          alt=""
          className={styles.orderIcon}
        />
        <div className={styles.orderDetails}>
          <h4 className={styles.orderTitle}>{restaurant?.name}</h4>
          <p className={styles.orderStatus}>{getStatusText(order.status, restaurant, t)}</p>
        </div>
      </div>
      <button
        className={styles.viewOrderButton}
        onClick={() => {
          setOrderStatusRestaurantId(restaurant?.id ?? null);
          setOrderStatusRestaurantOrderId(order.id);
          setActivePage('MAP');
        }}
      >
        {t('View Order')}
      </button>
    </section>
  );
};

export default OrderCard;
