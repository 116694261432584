import { useTranslation } from 'react-i18next';
import styles from './AllergenIcon.module.css';
import { getAllergenInfoMap } from './util';

interface Props {
  allergen: string;
  showText?: boolean;
}

export const AllergenIcon: React.FC<Props> = ({ allergen, showText }) => {
  const { t } = useTranslation();
  const allergenInfo = getAllergenInfoMap(t)[allergen];

  if (!allergenInfo) {
    return null;
  }

  return (
    <div className={styles.allergenIconContainer}>
      <div
        className={styles.allergenIcon}
        style={{ backgroundColor: allergenInfo.backgroundColor }}
      >
        <img src={allergenInfo.icon} alt={allergenInfo.text} />
      </div>
      {showText && <span className={styles.allergenText}>{allergenInfo.text}</span>}
    </div>
  );
};
