import * as React from 'react';
import { useSelector } from 'react-redux';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import config from 'config';
import { useFormValues } from 'hooks/useFormValues';
import { ReduxState } from 'ducks';
import { GeneralCreditCardProvider } from 'components/GeneralCreditCardProvider/GeneralCreditCardProvider';

import { CustomerCardValues } from './formValues';
import { CardRegisterForm } from './CardRegisterForm';
import styles from './CustomerCard.module.css';

type Props = {
  onSubmit: () => void;
  handleBack?: () => void;
};

export const CustomerCard = ({ onSubmit, handleBack }: Props) => {
  const stripeAccounts = useSelector(
    (state: ReduxState) => state.server.settings.all.stripe_accounts
  );

  const { paymentCurrencyType } = useFormValues<CustomerCardValues>();

  const [stripeJPY, setStripeJPY] = React.useState<Promise<Stripe | null> | null>(null);
  const [stripeUSD, setStripeUSD] = React.useState<Promise<Stripe | null> | null>(null);

  React.useEffect(() => {
    const stripeAccountJPY = stripeAccounts.find((acct) => acct.currency === 'JPY');
    const optionsJPY =
      stripeAccountJPY &&
      stripeAccountJPY.account_type !== 'JAPAN_EXPRESS' &&
      stripeAccountJPY.account_type !== 'HIS_3DS'
        ? {
            stripeAccount: stripeAccountJPY.stripe_account_id,
          }
        : undefined;
    setStripeJPY(
      loadStripe(
        stripeAccountJPY?.account_type === 'JAPAN_EXPRESS' ||
          stripeAccountJPY?.account_type === 'JAPAN_NTMGJP_NO_CONNECT'
          ? config.stripeJPYExpressKey
          : stripeAccountJPY?.account_type === 'HIS_3DS'
          ? config.stripeHIS3DSKey
          : config.stripeJPYKey,
        optionsJPY
      )
    );

    const stripeAccountUSD = stripeAccounts.find((acct) => acct.currency === 'USD');
    const optionsUSD = stripeAccountUSD
      ? {
          stripeAccount: stripeAccountUSD.stripe_account_id,
        }
      : undefined;
    setStripeUSD(loadStripe(config.stripeUSDKey, optionsUSD));
  }, [stripeAccounts]);

  return (
    <div className={styles['container']}>
      <Elements
        key={paymentCurrencyType === 'JPY' ? config.stripeJPYKey : config.stripeUSDKey}
        stripe={paymentCurrencyType === 'JPY' ? stripeJPY : stripeUSD}
      >
        <GeneralCreditCardProvider>
          <CardRegisterForm onSubmit={onSubmit} handleBack={handleBack} />
        </GeneralCreditCardProvider>
      </Elements>
    </div>
  );
};
