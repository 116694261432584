import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Amplify } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { confirmSignUp, fetchAuthSession, signIn, signUp } from 'aws-amplify/auth';
import { useRouter } from 'next/router';

import styles from './SignUp.module.css';
import { continueWithSnsHandler, getCommonRedirectUri } from 'lib/util/customer';
import { ReduxState } from 'ducks';
import { CustomerTokenContext, CustomerTokenType } from 'contexts/CustomerTokenContext';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { useRelativeUrl } from 'hooks/useRelativeUrl';
import { ButtonLoader } from '../ButtonLoader';
import { createCustomer } from 'ducks/client/customers';
import { TextField } from '../TextField';
import { required } from 'lib/forms/validate';
import { composeValidators } from 'lib/util/composeValidators';
import { toContentLanguage } from 'lib/util/toContentLanguage';
import { useMapDisplayContext } from 'components/Map/MapDisplayContext';
import { CustomerEditor } from '../MyAccount/CustomerEditor';
import { PrivacyPolicy } from './PrivacyPolicy';
import { TermsAndConditions } from './TermsAndConditions';
import { convertToUpdateCustomerInformationRequest } from 'components/Customer/formValues';
import { CustomerValues } from '../MyAccount/formValues';
import { useCustomerToken } from 'hooks/useCustomerToken';
import { AnimatedButton } from 'components/Map/AnimatedButton';

type FormValues = CustomerValues & {
  // Email password
  email: string;
  password: string;
  confirmPassword: string;

  // Confirm
  confirmationCode: string;
};

interface Props {
  signUpStep: 'EMAIL_PASSWORD' | 'CONFIRM' | 'REGISTER';
  setSignUpStep: (signUpStep: 'EMAIL_PASSWORD' | 'CONFIRM' | 'REGISTER') => void;
}

export const SignUp = ({ signUpStep, setSignUpStep }: Props) => {
  const { t, i18n } = useTranslation();
  const router = useRouter();

  const { accessToken, idProvider } = useCustomerToken();
  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const { apiKey } = React.useContext(ApiKeyContext);
  const { restoreSavedDisplayState } = useMapDisplayContext();
  const apiSettings = useSelector((state: ReduxState) => state.server.settings.all);

  const customerLedgerSettings = apiSettings.customer_ledger_settings;

  const customerFormFieldSet = customerLedgerSettings?.form_field_sets?.find(
    (formFieldSet) => formFieldSet.content_language === toContentLanguage(i18n.language)
  );

  React.useEffect(() => {
    (async () => {
      if (accessToken && idProvider) {
        setSignUpStep('REGISTER');
      }
    })();
  }, [setSignUpStep, settings.customer_ledger_settings?.cognito]);

  const customerTokenContext = React.useContext(CustomerTokenContext);
  const dispatch = useDispatch();

  const authPath = useRelativeUrl('/auth');

  const validatePasswordsMatch = React.useCallback(
    (password: string, confirmPassword: string) => {
      return password === confirmPassword ? undefined : t('Passwords do not match');
    },
    [t]
  );

  return (
    <Form<FormValues>
      keepDirtyOnReinitialize
      initialValues={{
        shouldReceiveSpecialEmailOffers: true,
      }}
      onSubmit={async (values: FormValues) => {
        const cognitoInfo = settings.customer_ledger_settings?.cognito;

        if (!cognitoInfo?.user_pool_id || !cognitoInfo?.client_id) {
          return;
        }

        Amplify.configure({
          Auth: {
            Cognito: {
              userPoolId: cognitoInfo.user_pool_id,
              userPoolClientId: cognitoInfo.client_id,
            },
          },
        });

        if (signUpStep === 'EMAIL_PASSWORD') {
          try {
            await signUp({
              username: values.email,
              password: values.password,
              options: {
                userAttributes: {
                  email: values.email,
                  'custom:language_iso': i18n.language,
                  'custom:supplier_id': settings.supplier_id,
                },
              },
            });
            setSignUpStep('CONFIRM');
          } catch (err) {
            console.log(err);
            if (err?.toString().includes('UsernameExistsException')) {
              return {
                [FORM_ERROR]: t(
                  'There was an error during sign-up process. Please try again in 5 minutes.'
                ),
              };
            }

            return {
              [FORM_ERROR]: 'Something went wrong',
            };
          }
        } else if (signUpStep === 'CONFIRM') {
          const cognitoInfo = settings.customer_ledger_settings?.cognito;

          if (!cognitoInfo?.user_pool_id || !cognitoInfo?.client_id) {
            return;
          }

          try {
            await confirmSignUp({
              username: values.email,
              confirmationCode: values.confirmationCode,
            });
          } catch (err) {
            return {
              [FORM_ERROR]: t(
                'Invalid confirmation code. Please check your code. It will expire in 5 minutes. Please register again from the sign up page if your code has expired.'
              ),
            };
          }

          try {
            await signIn({
              username: values.email,
              password: values.password,
            });

            const session = await fetchAuthSession();

            const newContext: CustomerTokenType = {
              accessToken: session.tokens?.accessToken?.toString(),
              idToken: session.tokens?.idToken?.toString(),
              refreshToken: '',
              expiresAt: session.tokens?.accessToken?.payload.exp,
              idProvider: 'COGNITO',
              apiKey,
            };
            customerTokenContext.setToken(newContext);

            setSignUpStep('REGISTER');
          } catch (err) {
            console.log('err', err);
            return {
              [FORM_ERROR]: 'Something went wrong',
            };
          }
        } else if (signUpStep === 'REGISTER') {
          const session = await fetchAuthSession();

          const patch = convertToUpdateCustomerInformationRequest(
            values,
            customerFormFieldSet?.form_fields ?? []
          );

          await dispatch(
            createCustomer(apiKey, {
              ...patch,
              access_token: session.tokens?.accessToken?.toString(),
              id_provider: 'COGNITO',
              redirect_uri: getCommonRedirectUri(),
              language: i18n.language.toUpperCase().replace('-', '_'),
            })
          );

          // If we have stored app state, redirect to that page and context
          restoreSavedDisplayState();
        }
      }}
      debug={console.log}
    >
      {({ form, handleSubmit, submitError, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          {signUpStep === 'EMAIL_PASSWORD' ? (
            <>
              <div>
                <Field name="email" validate={required}>
                  {({ input, meta: { touched, error } }) => (
                    <TextField
                      label={t('Email address')}
                      placeholder={t('Your email')}
                      value={input.value}
                      onChange={(newValue) => {
                        input.onChange(newValue);
                      }}
                      error={touched ? t(error) : undefined}
                    />
                  )}
                </Field>
                <Field name="password" validate={required}>
                  {({ input, meta: { touched, error } }) => (
                    <TextField
                      label={t('Create password')}
                      placeholder={t('Password')}
                      value={input.value}
                      onChange={(newValue) => {
                        input.onChange(newValue);
                      }}
                      type="password"
                      error={touched ? t(error) : undefined}
                    />
                  )}
                </Field>
                <Field
                  name="confirmPassword"
                  validate={composeValidators(required, (confirmPassword: string) => {
                    return validatePasswordsMatch(values.password, confirmPassword);
                  })}
                >
                  {({ input, meta: { touched, error } }) => (
                    <TextField
                      label={t('Confirm password')}
                      placeholder={t('Password')}
                      value={input.value}
                      onChange={(newValue) => {
                        input.onChange(newValue);
                      }}
                      type="password"
                      error={touched ? t(error) : undefined}
                    />
                  )}
                </Field>
                {submitError && <div className={styles['error']}>{submitError}</div>}
                <AnimatedButton
                  className={clsx(styles['btn'], styles['signup'])}
                  clickedClassName={styles['clicked']}
                  disabled={submitting}
                  type="button"
                  onClick={() => form.submit()}
                >
                  {submitting ? <ButtonLoader /> : t('Sign Up')}
                </AnimatedButton>
              </div>
              <div className={styles['or-container']}>
                <div className={styles['divider']} />
                <div className={styles['or-text']}>{t('Or')}</div>
                <div className={styles['divider']} />
              </div>
              <AnimatedButton
                className={clsx(styles['btn'], styles['third-party'])}
                clickedClassName={styles['clicked']}
                onClick={() => {
                  continueWithSnsHandler('GOOGLE', authPath, router.asPath);
                }}
                type="button"
              >
                <img src="/static/images/ic_login_google.svg" className={styles['icon']} />
                <div>{t('Continue with Google')}</div>
              </AnimatedButton>
              <AnimatedButton
                className={clsx(styles['btn'], styles['third-party'])}
                clickedClassName={styles['clicked']}
                onClick={() => {
                  continueWithSnsHandler('FACEBOOK', authPath, router.asPath);
                }}
                type="button"
              >
                <img src="/static/images/ic_login_facebook_solid.svg" className={styles['icon']} />
                <div>{t('Continue with Facebook')}</div>
              </AnimatedButton>
            </>
          ) : signUpStep === 'CONFIRM' ? (
            <div>
              <p className={clsx(styles['ttl--large'])} style={{ fontWeight: 'bold' }}>
                {t('Confirmation code has been sent to your email address')}
              </p>
              <p>{t('* Confirmation code will expire in 5 minutes.')}</p>
              <p>{t('* Please do not click back button of the browser or reload page')}</p>
              <Field name="confirmationCode">
                {({ input }) => (
                  <TextField
                    label={t('Confirmation Code')}
                    placeholder={t('XXXX')}
                    value={input.value}
                    onChange={(newValue) => {
                      input.onChange(newValue);
                    }}
                  />
                )}
              </Field>
              {submitError && <div className={styles['error']}>{submitError}</div>}
              <button className={clsx(styles['btn'], styles['signup'])} type="submit">
                {submitting ? <ButtonLoader /> : t('Confirm')}
              </button>
            </div>
          ) : (
            <div>
              <CustomerEditor />
              <TermsAndConditions />
              <PrivacyPolicy />

              <Field name="agreeTermsAndConditions">
                {({ input }) => (
                  <div className={styles['register__item']}>
                    <label className={styles['form__confirm']}>
                      <input
                        type="checkbox"
                        checked={input.value}
                        onClick={() => {
                          input.onChange(!input.value);
                        }}
                      />
                      <i></i>
                      <p>{t('I agree to the Terms and Conditions and Privacy Policy')}</p>
                    </label>
                  </div>
                )}
              </Field>
              {submitError && <div className={styles['error']}>{submitError}</div>}
              <button
                className={clsx(styles['btn'], styles['signup'])}
                type="submit"
                disabled={!values.agreeTermsAndConditions}
              >
                {submitting ? <ButtonLoader /> : t('Register')}
              </button>
            </div>
          )}
        </form>
      )}
    </Form>
  );
};
