import base64js from 'base64-js';

import { DigitalMap } from 'models/digitalMap';
import { getFloorOverlay } from './util';

// Local server test: http://localhost:3000/image
// Lambda test: https://k5mwnq3xh9.execute-api.us-west-1.amazonaws.com/image

const cloudfrontHostname = 'https://d1pjfltiy06or1.cloudfront.net';
const s3Hostname = 'ntmg-media.s3.us-west-1.amazonaws.com';
const s3BucketName = 'ntmg-media';

interface ImageRequest {
  bucket: string;
  key: string;
  tilerParams: {
    topLeftLat: number;
    topLeftLong: number;
    overlayWidthInMeters: number;
    rotationDegrees: number;
    x: number;
    y: number;
    zoom: number;
    aspectRatioWidth: number;
    aspectRatioHeight: number;
  };
}

class TiledMapType {
  private digitalMap: DigitalMap;
  private selectedFloor: number;
  tileSize: google.maps.Size;
  name = 'tiled';
  maxZoom = 22;
  minZoom = 0;

  constructor(digitalMap: DigitalMap, selectedFloor: number) {
    this.tileSize = new google.maps.Size(256, 256);
    this.digitalMap = digitalMap;
    this.selectedFloor = selectedFloor;
    this.minZoom = (digitalMap?.default_map_zoom ?? 0) - 2;
  }

  getTile(coord: google.maps.Point, zoom: number, ownerDocument: Document): HTMLElement {
    const tileUrl = getTileUrl(this.digitalMap, this.selectedFloor, coord, zoom);

    const xExtra = 1;
    const yExtra = 1;

    const div = ownerDocument.createElement('div');
    div.style.width = this.tileSize.width + xExtra + 'px';
    div.style.height = this.tileSize.height + yExtra + 'px';

    const img = ownerDocument.createElement('img');
    img.src = tileUrl;
    img.style.width = this.tileSize.width + xExtra + 'px';
    img.style.height = this.tileSize.height + yExtra + 'px';

    div.append(img);

    return div;
  }

  releaseTile(): void {
    // No-op
  }
}

export const getTiledMapType = (digitalMap: DigitalMap, selectedFloor: number) => {
  return new TiledMapType(digitalMap, selectedFloor);
};

const getTileUrl = (
  digitalMap: DigitalMap,
  selectedFloor: number,
  a: google.maps.Point,
  b: number
): string => {
  const overlay = getFloorOverlay(digitalMap, selectedFloor);

  const originalImageUrl = overlay?.image_url;

  if (!originalImageUrl) {
    return '';
  }

  if (tileIsOutOfRange(digitalMap, selectedFloor, a, b)) {
    return 'https://assets.ntmg.com/digitalmap/bg_green.jpg';
  }

  if (!originalImageUrl.includes(s3Hostname)) {
    return originalImageUrl;
  }

  const parts = originalImageUrl.split(s3Hostname + '/');
  if (parts.length !== 2) {
    return originalImageUrl;
  }

  const key = parts[1]
    .split('/')
    .map((pathPart) => decodeURIComponent(pathPart))
    .join('/');

  const imageRequest: ImageRequest = {
    bucket: s3BucketName,
    key,
    tilerParams: {
      topLeftLat: overlay.top_left.latitude,
      topLeftLong: overlay.top_left.longitude,
      overlayWidthInMeters: overlay.image_projection_width_in_meters || 0,
      rotationDegrees: digitalMap.map_rotation || 0,
      x: a.x,
      y: a.y,
      zoom: b,
      aspectRatioWidth: overlay.image_aspect_ratio.width,
      aspectRatioHeight: overlay.image_aspect_ratio.height,
    },
  };

  return `${cloudfrontHostname}/${base64js.fromByteArray(
    Uint8Array.from(new TextEncoder().encode(JSON.stringify(imageRequest)))
  )}`;
};

function project(latLng: google.maps.LatLng): google.maps.Point {
  const siny = Math.sin((latLng.lat() * Math.PI) / 180);
  const sinySafe = Math.min(Math.max(siny, -0.9999), 0.9999);

  return new google.maps.Point(
    256 * (0.5 + latLng.lng() / 360),
    256 * (0.5 - Math.log((1 + sinySafe) / (1 - sinySafe)) / (4 * Math.PI))
  );
}

function getTileCoordinate(worldCoordinate: google.maps.Point, scale: number): google.maps.Point {
  return new google.maps.Point(
    (worldCoordinate.x * scale) / 256,
    (worldCoordinate.y * scale) / 256
  );
}

const tileIsOutOfRange = (
  digitalMap: DigitalMap,
  selectedFloor: number,
  tileCoord: google.maps.Point,
  zoomLevel: number
): boolean => {
  const overlay = getFloorOverlay(digitalMap, selectedFloor);

  const originalImageUrl = overlay?.image_url;

  if (!originalImageUrl) {
    return false;
  }

  const overlayWidthInMeters = overlay.image_projection_width_in_meters;
  const aspectRatioWidth = overlay.image_aspect_ratio.width;
  const aspectRatioHeight = overlay.image_aspect_ratio.height;
  const rotationDegrees = digitalMap.map_rotation;

  // Calculate corners of the bounding box of the rotated overlay rectangle in tile coordinates
  const topLeft = new google.maps.LatLng(overlay.top_left.latitude, overlay.top_left.longitude);
  const topRight = google.maps.geometry.spherical.computeOffset(
    new google.maps.LatLng(topLeft.lat(), topLeft.lng()),
    overlayWidthInMeters,
    90 + rotationDegrees
  );
  const bottomRight = google.maps.geometry.spherical.computeOffset(
    topRight,
    overlayWidthInMeters * (aspectRatioHeight / aspectRatioWidth),
    180 + rotationDegrees
  );
  const bottomLeft = google.maps.geometry.spherical.computeOffset(
    bottomRight,
    overlayWidthInMeters,
    270 + rotationDegrees
  );
  const topLeftWorld = project(topLeft);
  const topRightWorld = project(topRight);
  const bottomRightWorld = project(bottomRight);
  const bottomLeftWorld = project(bottomLeft);

  const minX = Math.min(topLeftWorld.x, topRightWorld.x, bottomLeftWorld.x, bottomRightWorld.x);
  const minY = Math.min(topLeftWorld.y, topRightWorld.y, bottomLeftWorld.y, bottomRightWorld.y);
  const maxX = Math.max(topLeftWorld.x, topRightWorld.x, bottomLeftWorld.x, bottomRightWorld.x);
  const maxY = Math.max(topLeftWorld.y, topRightWorld.y, bottomLeftWorld.y, bottomRightWorld.y);

  const boundingBoxTopLeft = new google.maps.Point(minX, minY);
  const boundingBoxBottomRight = new google.maps.Point(maxX, maxY);

  const scale = Math.pow(2, zoomLevel);
  const boundingBoxTopLeftTile = getTileCoordinate(boundingBoxTopLeft, scale);
  const boundingBoxBottomRightTile = getTileCoordinate(boundingBoxBottomRight, scale);

  // Check if the tile overlaps with the bounding box
  if (
    tileCoord.x + 1 < boundingBoxTopLeftTile.x ||
    tileCoord.x > boundingBoxBottomRightTile.x ||
    tileCoord.y + 1 < boundingBoxTopLeftTile.y ||
    tileCoord.y > boundingBoxBottomRightTile.y
  ) {
    return true;
  }

  return false;
};
