import React from 'react';
import { Form } from 'react-final-form';
import styles from './RestaurantDetails.module.css';
import ImageGallery from './ImageGallery';
import { PageContainer } from 'components/Map/Components/PageContainer';
import { useMapDisplayContext } from '../MapDisplayContext';
import { CartButton } from './CartButton';
import MenuItemPage from './MenuItemPage/MenuItemPage';
import { OrderSummary } from './OrderSummary/OrderSummary';
import OrderStatus from './OrderStatus/OrderStatus';
import { Menu } from './Menu';
import { MenuItem } from 'models/digitalMap';
import {
  CheckoutFormValues,
  OrderItem,
  OrderItemOptionGroup,
  convertCheckoutFormValuesToSwaggerOrderRequest,
} from './checkoutFormValues';
import { useTranslation } from 'react-i18next';
import { useActiveRestaurant } from './useActiveRestaurant';
import { CustomerContext } from 'contexts/CustomerContext';
import { CustomerCardRegisterForm } from '../MyAccount/PaymentSettings/CustomerCardRegisterForm';
import { FORM_ERROR } from 'final-form';
import { generateRandomString } from 'components/Checkout/getCreateReservationRequest';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { createRestaurantOrder } from 'ducks/client/restaurantOrders';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { ReduxState } from 'ducks';
import { CustomerTokenContext } from 'contexts/CustomerTokenContext';
import { AnimatedButton } from '../AnimatedButton';
import { optionGroupListsEqual } from './util';
import { useCustomerEventLoggingEnabled } from 'hooks/useCustomerEventLoggingEnabled';
import { logCustomerEventWithNoSideEffects } from 'ducks/client/customerEvents';
import { useExistingReservation } from '../useExistingReservation';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';
import { useRouter } from 'next/router';

type RestaurantPage = 'MAIN' | 'ITEM' | 'CHECKOUT' | 'STATUS';

export interface ItemSelection {
  menuItemKey: string;
  optionGroups: OrderItem['optionGroups'];
  quantity: number;
}

const initialValues: CheckoutFormValues = {
  items: [],
};

interface Props {
  initialPage?: RestaurantPage;
}

export const RestaurantDetails: React.FC<Props> = ({ initialPage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pageRef = React.useRef<HTMLDivElement>(null);
  const [activeItemSelection, setActiveItemSelection] = React.useState<ItemSelection | null>(null);
  const [activeRestaurantPageStack, setActiveRestaurantPageStack] = React.useState<
    RestaurantPage[]
  >(initialPage ? [initialPage] : ['MAIN']);
  const [showEditPaymentMethod, setShowEditPaymentMethod] = React.useState(false);
  const {
    setActiveRestaurantId,
    saveDisplayState,
    resetDisplay,
    setActivePage,
    setOrderStatusRestaurantId,
    setOrderStatusRestaurantOrderId,
  } = useMapDisplayContext();
  const restaurant = useActiveRestaurant();
  const reservation = useExistingReservation();
  const visitorToken = useSelector((state: ReduxState) => state.tracking.visitorToken);
  const customerId = useSelector((state: ReduxState) => state.customer.customer?.id);
  const { currentPositionRef } = React.useContext(CurrentPositionContext);
  const router = useRouter();
  const pathname = router.asPath;

  const { apiKey } = React.useContext(ApiKeyContext);
  const customerEventLoggingEnabled = useCustomerEventLoggingEnabled();

  React.useEffect(() => {
    if (customerEventLoggingEnabled) {
      logCustomerEventWithNoSideEffects({
        apiKey,
        eventType: 'restaurant-view',
        visitorToken,
        customerId: customerId ?? '',
        reservationId: reservation?.id || '',
        metadata: JSON.stringify({
          page: pathname,
          restaurant_id: restaurant?.id,
          lat: currentPositionRef.current?.lat(),
          lng: currentPositionRef.current?.lng(),
          site: 'onsite',
        }),
      });
    }
  }, [
    pathname,
    customerEventLoggingEnabled,
    apiKey,
    visitorToken,
    customerId,
    currentPositionRef,
    reservation?.id,
    restaurant?.id,
  ]);

  const { accessToken, idProvider } = React.useContext(CustomerTokenContext);
  const { customer } = React.useContext(CustomerContext);

  const store = useStore<ReduxState>();
  const activeMenuItem = restaurant?.menu?.menu_items.find(
    (item) => item.key === activeItemSelection?.menuItemKey
  );

  const pushPage = (page: RestaurantPage) => {
    setActiveRestaurantPageStack((prevStack) => [...prevStack, page]);
  };

  const popPage = () => {
    setActiveRestaurantPageStack((prevStack) => {
      if (prevStack.length > 1) {
        return prevStack.slice(0, -1);
      }
      return prevStack; // Don't pop if it's the last item
    });
  };

  const activeRestaurantPage = activeRestaurantPageStack[activeRestaurantPageStack.length - 1];

  React.useEffect(() => {
    // Scroll to top of the page when the active restaurant page changes
    if (pageRef.current) {
      pageRef.current.scrollTo(0, 0);
    }
  }, [activeRestaurantPage]);

  if (!customer) {
    return (
      <PageContainer
        ref={pageRef}
        onBackClick={() => {
          setActiveRestaurantId(null);
          setOrderStatusRestaurantId(null);
          setOrderStatusRestaurantOrderId(null);
        }}
      >
        <main className={styles['main-content']}>
          <p className={styles['message']}>{t('Please sign in to order food and drinks.')}</p>
          <AnimatedButton
            className={styles['sign-in-button']}
            clickedClassName={styles['clicked']}
            onClick={() => {
              saveDisplayState();

              resetDisplay();

              // Initiate sign in/ sign up flow.
              // Once complete, we'll restore the map display context.
              setActivePage('ACCOUNT');
            }}
          >
            {t('Sign In')}
          </AnimatedButton>
          <AnimatedButton
            className={styles['close-button']}
            clickedClassName={styles['clicked']}
            onClick={() => {
              resetDisplay();
            }}
          >
            {t('Close')}
          </AnimatedButton>
        </main>
      </PageContainer>
    );
  }

  return (
    <>
      <Form<CheckoutFormValues>
        initialValues={initialValues}
        onSubmit={async (values) => {
          if (activeRestaurantPage === 'CHECKOUT') {
            if (!customer?.payment_profile_card_info?.last_four_digits) {
              return { [FORM_ERROR]: t('Please add a payment method before continuing.') };
            }

            if (restaurant) {
              await dispatch(
                createRestaurantOrder(
                  apiKey,
                  convertCheckoutFormValuesToSwaggerOrderRequest(
                    values,
                    restaurant,
                    customer,
                    accessToken ?? '',
                    idProvider ?? ''
                  )
                )
              );

              if (store.getState().restaurantOrders.error) {
                return { [FORM_ERROR]: t('An error occurred. Please try again.') };
              }

              logCustomerEventWithNoSideEffects({
                apiKey: apiKey,
                eventType: 'mobile-order-purchase',
                visitorToken: visitorToken,
                customerId: customerId ?? '',
                reservationId: reservation?.id,
                metadata: JSON.stringify({
                  order_id: store.getState().restaurantOrders.lastCreatedOrder?.id,
                  order_gross: store.getState().restaurantOrders.lastCreatedOrder?.billing_info
                    ?.total,
                  restaurant_id: restaurant?.id,
                  page: pathname,
                  lat: currentPositionRef.current?.lat(),
                  lng: currentPositionRef.current?.lng(),
                  site: 'onsite',
                }),
              });

              pushPage('STATUS');
              setOrderStatusRestaurantOrderId(
                store.getState().restaurantOrders.lastCreatedOrder?.id ?? null
              );
            } else {
              return { [FORM_ERROR]: t('An error occurred. Please try again.') };
            }
          }
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            {showEditPaymentMethod ? null : activeRestaurantPage === 'MAIN' ? (
              <PageContainer ref={pageRef} onBackClick={() => setActiveRestaurantId(null)}>
                <main className={styles.mainContent}>
                  <ImageGallery />

                  <Menu
                    onClickItem={(item: MenuItem) => {
                      setActiveItemSelection({
                        menuItemKey: item.key,
                        optionGroups: [],
                        quantity: 1,
                      });
                      pushPage('ITEM');
                    }}
                  />
                </main>
                {values.items.length > 0 && <CartButton onClick={() => pushPage('CHECKOUT')} />}
              </PageContainer>
            ) : activeRestaurantPage === 'ITEM' && activeMenuItem && activeItemSelection ? (
              <PageContainer ref={pageRef} onBackClick={() => popPage()}>
                <MenuItemPage
                  menuItem={activeMenuItem}
                  itemSelection={activeItemSelection}
                  onChange={(itemSelection) => setActiveItemSelection(itemSelection)}
                  onAddItemClick={() => popPage()}
                />
              </PageContainer>
            ) : activeRestaurantPage === 'CHECKOUT' ? (
              <PageContainer
                ref={pageRef}
                onBackClick={() => popPage()}
                headerContent={
                  <div className={styles.headerText}>
                    <span className={styles.headerSubtitle}>{t('Your Cart at')}</span>
                    <h1 className={styles.headerTitle}>{restaurant?.name}</h1>
                  </div>
                }
              >
                <OrderSummary
                  onBackClick={() => popPage()}
                  onItemClick={(
                    itemKey: string,
                    optionGroups: OrderItemOptionGroup[],
                    quantity: number
                  ) => {
                    // Clear current items for this selection from order
                    form.change(
                      'items',
                      form
                        .getState()
                        .values.items.filter(
                          (item) =>
                            item.menuItemKey !== itemKey ||
                            !optionGroupListsEqual(item.optionGroups, optionGroups)
                        )
                    );

                    const item = restaurant?.menu?.menu_items.find((item) => item.key === itemKey);
                    if (item) {
                      setActiveItemSelection({
                        menuItemKey: item.key,
                        optionGroups,
                        quantity,
                      });
                      pushPage('ITEM');
                    }
                  }}
                  onClickEditPaymentMethod={() => {
                    setShowEditPaymentMethod(true);

                    // Set a field in the form to reset the submit error
                    form.change('submitErrorReset', generateRandomString(8));
                  }}
                />
              </PageContainer>
            ) : activeRestaurantPage === 'STATUS' ? (
              <PageContainer
                ref={pageRef}
                onBackClick={() => {
                  setActiveRestaurantId(null);
                  setOrderStatusRestaurantId(null);
                  setOrderStatusRestaurantOrderId(null);
                }}
              >
                <OrderStatus
                  onOkClick={() => {
                    setActiveRestaurantId(null);
                    setOrderStatusRestaurantId(null);
                    setOrderStatusRestaurantOrderId(null);
                  }}
                />
              </PageContainer>
            ) : null}
          </form>
        )}
      </Form>
      {showEditPaymentMethod && (
        <PageContainer onBackClick={() => setShowEditPaymentMethod(false)}>
          <CustomerCardRegisterForm
            handleBack={() => setShowEditPaymentMethod(false)}
            onMessageChange={() => {}}
          />
        </PageContainer>
      )}
    </>
  );
};
