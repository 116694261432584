import { selectDigitalMap } from 'ducks/universal/digitalMap';
import { useSelector } from 'react-redux';
import styles from './LanguageSelect.module.css';
import { languageOptionMap } from 'lib/util/i18nHelpers';
import { useRouter } from 'next/router';
import { useUrl } from 'hooks/useUrl';

export const LanguageSelect = () => {
  const digitalMap = useSelector(selectDigitalMap);
  const router = useRouter();

  const languageOptions = digitalMap?.display_settings?.language_menu_items ?? [];

  const baseUrl = useUrl('/maps/{{MAP_ID}}');

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = event.target.value;
    const newUrl = baseUrl
      .replace(
        '{{MAP_ID}}',
        languageOptions.find((option) => option.language === newLanguage)?.digital_map_id ??
          digitalMap?.id ??
          ''
      )
      .replace('{{LANGUAGE}}', newLanguage);

    router.push(newUrl);
  };

  return (
    <label className={styles['lang']}>
      <img src="/static/images/ic_lang.svg" />
      <select name="lang" onChange={handleLanguageChange} value={digitalMap?.seo_params?.language}>
        {languageOptions.map((languageOption) => (
          <option key={languageOption.language} value={languageOption.language}>
            {languageOptionMap[languageOption.language].name}
          </option>
        ))}
      </select>
    </label>
  );
};
