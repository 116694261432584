import * as React from 'react';
import { MapDisplayContext } from '../MapDisplayContext';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { RestaurantOrder } from 'models/digitalMap';

export const useActiveRestaurantOrder = (): RestaurantOrder | null => {
  const { orderStatusRestaurantOrderId } = React.useContext(MapDisplayContext);

  const restaurantOrders = useSelector((state: ReduxState) => state.restaurantOrders.orders);

  return restaurantOrders.find((order) => order.id === orderStatusRestaurantOrderId) || null;
};
