import * as React from 'react';
import clsx from 'clsx';

import styles from './Description.module.css';

interface Props {
  description: string;
}

export const Description = ({ description }: Props) => {
  return (
    <div className={styles['container']}>
      <div className={clsx(styles['inner'])}>{description}</div>
    </div>
  );
};
