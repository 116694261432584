import React from 'react';
import styles from './PopularTimes.module.css';

const barHeights = [55, 66, 80, 55, 37];
const timeLabels = ['9am', '12pm', '3pm', '6pm', '9pm'];

const Chart: React.FC = () => {
  return (
    <div className={styles.chartContainer}>
      <div className={styles.chart}>
        <div className={styles.chartBackground}>
          <div className={styles.backgroundLines}>
            <div className={styles.backgroundLine} />
          </div>
        </div>
        <div className={styles.barContainer}>
          {barHeights.map((height, index) => (
            <div
              key={index}
              className={index === 1 ? styles.activeBar : styles.bar}
              style={{ height: `${height}px` }}
            />
          ))}
        </div>
      </div>
      <div className={styles.timeLabels}>
        {timeLabels.map((label) => (
          <div key={label} className={styles.timeLabel}>
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Chart;
