import * as React from 'react';
import { useMapDisplayContext } from './MapDisplayContext';

export const SavedDisplayStateLoader = () => {
  const { restoreSavedDisplayState } = useMapDisplayContext();

  React.useEffect(() => {
    // Restore the saved display state when the component mounts
    // We might save display state prior to login, and we want to restore it after login redirects
    // back to the map.
    restoreSavedDisplayState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
