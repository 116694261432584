import { WithTranslation } from 'react-i18next';
import { Product, ProductSummary } from 'models/product';

export const validatePerBookingParticipants = (
  totalCount: number,
  product?: Product | ProductSummary | null
): boolean => {
  if (!product) {
    return true;
  }

  const isPerBookingParticipantRuleActive =
    product?.booking_widget_settings?.is_per_booking_participant_rule_active ?? false;
  if (!isPerBookingParticipantRuleActive) {
    return false;
  }

  const perBookingMinimumParticipants =
    product?.booking_widget_settings?.per_booking_participant_rule?.minimum_participants ?? 0;
  const perBookingMaximumParticipants =
    product?.booking_widget_settings?.per_booking_participant_rule?.maximum_participants ?? 0;
  if (perBookingMinimumParticipants === 0 && perBookingMaximumParticipants === 0) {
    return false;
  }

  if (perBookingMinimumParticipants !== 0 && perBookingMaximumParticipants === 0) {
    if (totalCount < perBookingMinimumParticipants) {
      return true;
    }
    return false;
  }

  if (perBookingMinimumParticipants === 0 && perBookingMaximumParticipants !== 0) {
    if (totalCount > perBookingMaximumParticipants) {
      return true;
    }
    return false;
  }

  if (totalCount < perBookingMinimumParticipants || totalCount > perBookingMaximumParticipants) {
    return true;
  }

  return false;
};

export const getEnablePerBookingMaximumParticipantsValue = (
  isPerBookingParticipantRuleActive: boolean,
  perBookingMaximumParticipants: number,
  unit: string,
  maximumParticipants: number,
  remainingCapacity: number,
  guestCount?: Record<string, string> | Record<string, number>
): number => {
  if (isPerBookingParticipantRuleActive && perBookingMaximumParticipants != 0) {
    if (maximumParticipants < perBookingMaximumParticipants) {
      if (!guestCount?.[unit]) {
        return maximumParticipants < remainingCapacity ? maximumParticipants : remainingCapacity;
      }
      if (remainingCapacity == 0) {
        return Number(guestCount[unit]);
      } else {
        return maximumParticipants < remainingCapacity + Number(guestCount[unit])
          ? maximumParticipants
          : remainingCapacity + Number(guestCount[unit]);
      }
    } else {
      if (!guestCount?.[unit]) {
        return remainingCapacity;
      }
      if (remainingCapacity == 0) {
        return Number(guestCount[unit]);
      }
      return perBookingMaximumParticipants < remainingCapacity + Number(guestCount[unit])
        ? perBookingMaximumParticipants
        : remainingCapacity + Number(guestCount[unit]);
    }
  }
  return maximumParticipants;
};

export const getPerBookingMaximumParticipantsText = (
  perBookingMinimumParticipants: number,
  perBookingMaximumParticipants: number,
  t: WithTranslation['t']
): string => {
  let perBookingParticipantsText = '';
  if (perBookingMinimumParticipants !== 0 && perBookingMaximumParticipants === 0) {
    perBookingParticipantsText = t(
      'This plan can be booked with minimum of {{min}} participants.',
      {
        min: perBookingMinimumParticipants,
      }
    );
  } else if (perBookingMinimumParticipants === 1 && perBookingMaximumParticipants !== 0) {
    perBookingParticipantsText = t(
      'This plan can be booked with maximum of {{max}} participants.',
      {
        max: perBookingMaximumParticipants,
      }
    );
  } else {
    perBookingParticipantsText = t(
      'This plan can be booked with maximum of {{max}} and minimum of {{min}} participants.',
      {
        min: perBookingMinimumParticipants,
        max: perBookingMaximumParticipants,
      }
    );
  }
  return perBookingParticipantsText;
};
