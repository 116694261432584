import clsx from 'clsx';
import styles from './Tabs.module.css';

interface Props<T extends string> {
  options: { value: T; text: string }[];
  selectedOption: T;
  onChange: (newOption: T) => void;
}

export function Tabs<T extends string>({ options, selectedOption, onChange }: Props<T>) {
  return (
    <div className={styles['tabs']}>
      {options.map((option) => (
        <div
          key={option.value}
          className={clsx(styles['tab'], selectedOption === option.value && styles['selected'])}
          onClick={() => onChange(option.value)}
        >
          <div className={styles['tab-text']}>{option.text}</div>
          {selectedOption === option.value && <div className={styles['underline']} />}
        </div>
      ))}
      {/*
          <div className={styles['div-8']}>
            <div className={styles['div-9']}>Schedule</div>
            <div className={styles['div-10']} />
          </div>
          */}
    </div>
  );
}
