import clsx from 'clsx';

import styles from './Button.module.css';

interface Props {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
}

export const Button = ({ children, className, onClick }: Props) => (
  <button onClick={onClick} className={clsx(styles['button'], className)}>
    {children}
  </button>
);
