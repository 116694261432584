export const useGmo = () => {
  const getToken = (creditCard: Parameters<typeof window.Multipayment.getToken>[0]) => {
    return new Promise<ReturnType<typeof window.Multipayment.getToken>>((resolve) => {
      window.Multipayment.getToken(creditCard, resolve);
    });
  };

  return {
    getToken,
  };
};

type useGmoType = typeof useGmo;
export type useGmoResult = ReturnType<useGmoType>;
export type useGmoGetTokenResult = Awaited<ReturnType<useGmoResult['getToken']>>;
