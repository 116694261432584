import * as React from 'react';
import axios from 'axios';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { Reservation } from 'models/reservation';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { GuidancePage } from 'models/guidancePage';
import config from 'config';
import { FootprintedModal } from 'components/FootprintedModal/FootprintedModal';
import { Product } from 'models/product';
import { ETicketCard as Card } from 'components/ETicketPage/ETicketCard';

interface Props {
  reservation: Reservation | undefined;
  initialOpen: boolean;
  product: Product | undefined;
  host: string;
}

const fetcher = (url: string, apiKey: string, contentLanguage: string) =>
  axios
    .get(url, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => response.data);

export const ETicketCard = ({ reservation, initialOpen, product, host }: Props) => {
  const { i18n } = useTranslation();
  const { apiKey } = React.useContext(ApiKeyContext);
  const locale = i18n.language;

  const [showFootprintedModal, setShowFootprintedModal] = React.useState<boolean>(false);

  const { data: guidancePages } = useSWR<GuidancePage[]>(
    [`${config.apiUrl}/reservations/${reservation?.id}/guidancepages`, apiKey, locale],
    fetcher
  );

  if (!product || !reservation) {
    return null;
  }

  return (
    <>
      <Card
        reservation={reservation}
        product={product}
        onFootprintClick={() => {
          setShowFootprintedModal(true);
        }}
        guidancePages={guidancePages}
        initialOpen={initialOpen}
        host={host}
      />
      <FootprintedModal
        reservation={reservation}
        product={product}
        onClose={() => {
          setShowFootprintedModal(false);
        }}
        open={showFootprintedModal}
      />
    </>
  );
};
