import clsx from 'clsx';

import styles from './CircularButton.module.css';

interface Props {
  icon: string;
  color: 'orange' | 'green' | 'gray';
  onClick?: () => void;
}

export const CircularButton: React.FC<Props> = ({ icon, color, onClick }) => (
  <button
    onClick={(e) => {
      if (onClick) {
        e.stopPropagation();
        onClick();
      }
    }}
    className={clsx(styles['circular-button'], styles[`circular-button-${color}`])}
  >
    <img src={icon} alt="" className={styles['circular-button-icon']} />
  </button>
);
