import _ from 'lodash';
import { ReduxState } from 'ducks';
import { Reservation } from 'models/reservation';
import { useSelector } from 'react-redux';

export const useExistingReservation = (): Reservation | null => {
  const existingReservation = useSelector(
    (state: ReduxState) => state.server.reservation.reservation
  );
  const customerReservations = useSelector(
    (state: ReduxState) => state.reservation.foundReservations
  );

  if (existingReservation) {
    return existingReservation;
  }
  if (customerReservations.length > 0) {
    return _.orderBy(customerReservations, ['booked_date_time_utc'], 'asc')[0];
  }

  return null;
};
