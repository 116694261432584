import * as React from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from 'lodash';

import { EventType } from 'ducks/client/customerEvents';
import { fetchMarketingAutomationCampaignsByVisitorIdentifier } from 'ducks/client/marketingAutomationCampaigns';
import { ReduxState } from 'ducks';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { useTranslation } from 'react-i18next';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';
import { useVisitorIdentifier, VisitorIdentifier } from 'hooks/useVisitorIdentifier';

export const MarketingAutomationCampaignFetcher = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const existingReservation = useSelector(
    (state: ReduxState) => state.server.reservation.reservation
  );
  const { apiKey } = React.useContext(ApiKeyContext);
  const router = useRouter();
  const visitorIdentifier = useVisitorIdentifier();
  const stringifiedVisitorIdentifier = JSON.stringify(visitorIdentifier);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchMarketingAutomationCampaigns = React.useCallback(
    throttle(
      (
        apiKey: string,
        visitorIdentifier: VisitorIdentifier,
        eventType: EventType,
        language: string,
        metadata?: string
      ) =>
        dispatch(
          fetchMarketingAutomationCampaignsByVisitorIdentifier(
            apiKey,
            visitorIdentifier,
            eventType,
            language,
            metadata
          )
        ),
      5000,
      {
        leading: true,
      }
    ),
    [dispatch]
  );

  const { currentPosition } = React.useContext(CurrentPositionContext);

  React.useEffect(() => {
    const parsedVisitorIdentifier = JSON.parse(stringifiedVisitorIdentifier) as VisitorIdentifier;

    if (currentPosition) {
      if (parsedVisitorIdentifier) {
        debouncedFetchMarketingAutomationCampaigns(
          apiKey,
          parsedVisitorIdentifier,
          'location',
          i18n.language,
          JSON.stringify({
            lat: currentPosition.lat(),
            lng: currentPosition.lng(),
            page: router.asPath,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    existingReservation,
    apiKey,
    debouncedFetchMarketingAutomationCampaigns,
    i18n.language,
    currentPosition,
    stringifiedVisitorIdentifier,
  ]);

  React.useEffect(() => {
    const parsedVisitorIdentifier = JSON.parse(stringifiedVisitorIdentifier) as VisitorIdentifier;

    if (parsedVisitorIdentifier) {
      debouncedFetchMarketingAutomationCampaigns(
        apiKey,
        parsedVisitorIdentifier,
        'page-view',
        i18n.language,
        JSON.stringify({
          page: router.asPath,
        })
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [apiKey, existingReservation, stringifiedVisitorIdentifier]);

  return null;
};
