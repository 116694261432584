import clsx from 'clsx';
import * as React from 'react';
import _ from 'lodash';

import { currency } from 'lib/currency';
import { HorizontalScrollSection } from './HorizontalScrollSection';
import { CategorySection } from './CategorySection';
import styles from './Menu.module.css';
import { MenuItem } from 'models/digitalMap';
import { useTranslation } from 'react-i18next';
import { useActiveRestaurant } from './useActiveRestaurant';
import { MenuSearch } from './MenuSearch';
import { FilterRow } from './FilterRow/FilterRow';
import { FilterResultsSection } from './FilterResultsSection';
import { MenuFilterState, getDefaultMenuFilters, menuItemMatchesSearchQuery } from './util';

function getNearestScrollableAncestor(element: HTMLElement | null): HTMLElement | null {
  while (element && element !== document.body) {
    // Check if the element is scrollable
    const { scrollHeight, clientHeight } = element;
    const overflowY = window.getComputedStyle(element).overflowY;

    if (scrollHeight > clientHeight && (overflowY === 'auto' || overflowY === 'scroll')) {
      return element;
    }

    element = element.parentElement;
  }

  // If no scrollable ancestor is found, return the document.body or null
  return document.body.scrollHeight > document.body.clientHeight ? document.body : null;
}

interface Props {
  onClickItem: (item: MenuItem) => void;
}

export const Menu: React.FC<Props> = ({ onClickItem }) => {
  const [activeTabIdx, setActiveTabIdx] = React.useState(0);

  const restaurant = useActiveRestaurant();

  const [filters, setFilters] = React.useState<MenuFilterState>(
    getDefaultMenuFilters(restaurant?.menu || null)
  );
  const tabAutoScrollEnabledRef = React.useRef(true);
  const sectionsRefs = React.useRef<any[]>([]);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const tabsRef = React.useRef<HTMLUListElement>(null);
  const tabItemsRefs = React.useRef<(HTMLLIElement | null)[]>([]);
  const scrollDistanceRef = React.useRef(0);

  const { t } = useTranslation();
  const itemCategories = _.uniq(restaurant?.menu?.menu_items?.map((item) => item.category) || []);

  const recommendedItems = restaurant?.menu?.menu_items.filter((item) => item.is_recommended) ?? [];
  const popularItems = _.orderBy(
    restaurant?.menu?.menu_items?.filter((item) => item.order_count),
    ['order_count'],
    ['desc']
  ).slice(0, 5);

  const setActiveTab = (index: number) => {
    setActiveTabIdx(index);

    const tabItem = tabItemsRefs.current[index];

    if (tabItem && headerRef.current) {
      const tabsContainer = headerRef.current;
      const tabItemLeft = tabItem.offsetLeft;
      const tabItemWidth = tabItem.offsetWidth;
      const containerWidth = tabsContainer.offsetWidth;

      const targetScrollLeft = tabItemLeft - (containerWidth - tabItemWidth) / 2;

      tabsContainer.scrollTo({
        left: targetScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const handleTabClick = (index: number) => {
    const section = sectionsRefs.current[index];

    if (section) {
      const offsetAdjustment = 10;
      const scrollableAncestor = getNearestScrollableAncestor(section);

      if (scrollableAncestor) {
        const sectionTop = section.offsetTop - scrollableAncestor.offsetTop + offsetAdjustment;

        // Disable tab horizontal auto-scroll when tab is clicked and we scroll to the section.
        tabAutoScrollEnabledRef.current = false;
        scrollableAncestor.scrollTo({
          top: sectionTop,
          behavior: 'smooth',
        });
      }
    }

    setActiveTab(index);

    setTimeout(() => {
      // Re-enable tab horizontal auto-scroll after a short delay.
      tabAutoScrollEnabledRef.current = true;
    }, 500);
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const scrollableAncestor = getNearestScrollableAncestor(headerRef.current);

      if (scrollableAncestor) {
        const nearnessThreshold = 60; // px
        scrollableAncestor.onscroll = () => {
          if (!tabAutoScrollEnabledRef.current) {
            return;
          }

          const scrollDelta = scrollableAncestor.scrollTop - scrollDistanceRef.current;
          scrollDistanceRef.current = scrollableAncestor.scrollTop;

          const scrollableAncestorHeight = scrollableAncestor.clientHeight;

          sectionsRefs.current.forEach((section, idx) => {
            if (!section) {
              return;
            }

            if (scrollDelta > 0) {
              // If scrolling down, switch to tab corresponding to the section whose top edge is close
              // to the top of the visible area.
              const sectionTop = section.getBoundingClientRect().top;

              const sectionTopDistanceToElementCenter = Math.abs(
                sectionTop - scrollableAncestorHeight / 2
              );
              if (sectionTopDistanceToElementCenter < nearnessThreshold) {
                setActiveTab(idx);
              }
            } else {
              // If scrolling up, switch to the tab corresponding to the section whose bottom edge is
              // close to the bottom of the visible area.

              const sectionBottom = section.getBoundingClientRect().bottom;

              const sectionBottomDistanceToElementCenter = Math.abs(
                sectionBottom - scrollableAncestorHeight / 2
              );
              if (sectionBottomDistanceToElementCenter < nearnessThreshold) {
                setActiveTab(idx);
              }
            }
          });
        };
      }
    }
  }, []);

  const filtersOrSearchQueryActive = !_.isEqual(
    filters,
    getDefaultMenuFilters(restaurant?.menu || null)
  );

  const filteredItems =
    restaurant?.menu?.menu_items.filter((item) => {
      if (filters.searchQuery && !menuItemMatchesSearchQuery(item, filters.searchQuery)) {
        return false;
      }

      if (filters.tags.length > 0 && !filters.tags.some((tag) => item.tags.includes(tag))) {
        return false;
      }

      if (
        filters.priceRangeLow &&
        currency(item.price).value < currency(filters.priceRangeLow).value
      ) {
        return false;
      }

      if (
        filters.priceRangeHigh &&
        currency(item.price).value > currency(filters.priceRangeHigh).value
      ) {
        return false;
      }

      return true;
    }) || [];

  return (
    <section className={styles.menuContent}>
      <MenuSearch
        searchQuery={filters.searchQuery}
        onSearchQueryChange={(query) => setFilters({ ...filters, searchQuery: query })}
      />
      <FilterRow filters={filters} onFiltersChange={(filters) => setFilters(filters)} />
      {filtersOrSearchQueryActive ? (
        <FilterResultsSection items={filteredItems} onClickItem={onClickItem} />
      ) : (
        <>
          <header ref={headerRef} className={styles.menuHeader}>
            <ul ref={tabsRef} className={styles.navList}>
              <li
                ref={(el) => (tabItemsRefs.current[0] = el)}
                onClick={() => handleTabClick(0)}
                className={clsx(styles.navItem, activeTabIdx === 0 && styles['active'])}
              >
                {t('Recommendations')}
                {activeTabIdx === 0 && <div className={styles['active-line']} />}
              </li>
              <li
                ref={(el) => (tabItemsRefs.current[1] = el)}
                onClick={() => handleTabClick(1)}
                className={clsx(styles.navItem, activeTabIdx === 1 && styles['active'])}
              >
                {t('Popular')}
                {activeTabIdx === 1 && <div className={styles['active-line']} />}
              </li>
              {itemCategories.map((category, idx) => (
                <li
                  ref={(el) => (tabItemsRefs.current[idx + 2] = el)}
                  onClick={() => handleTabClick(idx + 2)}
                  key={category}
                  className={clsx(styles.navItem, activeTabIdx === idx + 2 && styles['active'])}
                >
                  {category}
                  {activeTabIdx === idx + 2 && <div className={styles['active-line']} />}
                </li>
              ))}
            </ul>
          </header>
          <HorizontalScrollSection
            ref={(el) => (sectionsRefs.current[0] = el)}
            onClickItem={onClickItem}
            items={recommendedItems}
            id="recommended"
            title={t('Recommendations')}
          />
          <HorizontalScrollSection
            ref={(el) => (sectionsRefs.current[1] = el)}
            onClickItem={onClickItem}
            items={popularItems}
            id="popular"
            title={t('Popular')}
          />
          {itemCategories.map((category, idx) => (
            <CategorySection
              ref={(el) => (sectionsRefs.current[2 + idx] = el)}
              key={category}
              category={category}
              onClickItem={onClickItem}
            />
          ))}
        </>
      )}
    </section>
  );
};
