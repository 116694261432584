import * as React from 'react';
import { useMapDisplayContext } from '../MapDisplayContext';
import { useTranslation } from 'react-i18next';

import styles from './SearchInput.module.css';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { FixedQuery } from './FixedQuery';
import { SearchContext } from '../SearchContext';
import { Result } from './Result';
import { getSearchFilteredPins } from '../util';
import { usePinDisplayState } from '../usePinDisplayState';

export const SearchInput = () => {
  const {
    searchText,
    setSearchText,
    setDisplayMode,
    resetDisplay,
    setFilters,
    filters,
  } = useMapDisplayContext();
  const { setPinInfoDisplayState } = usePinDisplayState();

  const [intermediateSearchText, setIntermediateSearchText] = React.useState<string>(searchText);
  const { recentQueries, setRecentQueries } = React.useContext(SearchContext);

  const { t } = useTranslation();

  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);

  const filteredPins = getSearchFilteredPins(digitalMap?.pins ?? [], intermediateSearchText);

  const filteredRecentQueries = recentQueries.filter(
    (query) =>
      !intermediateSearchText ||
      (query.toLowerCase().includes(intermediateSearchText.toLowerCase()) &&
        query !== intermediateSearchText)
  );

  const clearCategories = () =>
    setFilters({
      ...filters,
      categories: [],
    });

  return (
    <div className={styles['search-container']}>
      <div className={styles['search']}>
        <svg
          onClick={() => {
            setSearchText('');
            setDisplayMode('BASE');
          }}
          className={styles['back-arrow']}
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="13"
          viewBox="0 0 17 13"
          fill="none"
        >
          <path
            d="M16 6.5L1 6.5"
            stroke="#393D3F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.8 1.70078L1 6.50078L5.8 11.3008"
            stroke="#393D3F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <input
          autoFocus
          type="text"
          placeholder={t('Search')}
          className={styles['placeholder']}
          onChange={(e) => setIntermediateSearchText(e.target.value)}
          value={intermediateSearchText}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.keyCode === 13) {
              event.preventDefault(); // Prevent the default action if needed
              clearCategories();
              setSearchText(intermediateSearchText);
              setRecentQueries([...recentQueries, intermediateSearchText]);
              setDisplayMode('SEARCH_RESULTS');
            }
          }}
        />

        {searchText && (
          <svg
            onClick={() => {
              setIntermediateSearchText('');
              setDisplayMode('SEARCH_INPUT');
            }}
            className={styles['close']}
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
          >
            <path
              d="M14 1L1 14"
              stroke="#393D3F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 1L14 14"
              stroke="#393D3F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
      <div className={styles['results-container']}>
        {intermediateSearchText && (
          <div className={styles['item']}>
            <FixedQuery
              query={intermediateSearchText}
              onClick={() => {
                clearCategories();
                setSearchText(intermediateSearchText);
                setRecentQueries([...recentQueries, intermediateSearchText]);
                setDisplayMode('SEARCH_RESULTS');
              }}
            />
          </div>
        )}
        {filteredRecentQueries.map((recentQuery) => (
          <div key={recentQuery} className={styles['item']}>
            <FixedQuery
              query={recentQuery}
              onClick={() => {
                clearCategories();
                setSearchText(recentQuery);
                setDisplayMode('SEARCH_RESULTS');
              }}
            />
          </div>
        ))}
        {intermediateSearchText &&
          filteredPins.map((pin) => (
            <div key={pin.index} className={styles['item']}>
              <Result
                pin={pin}
                onClick={() => {
                  resetDisplay();
                  setPinInfoDisplayState({ pinKey: pin.key, displayState: 'DETAILS' });
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};
