import clsx from 'clsx';
import styles from './SingleOptionSelect.module.css';
import { Checkmark } from './Checkmark';

interface Props<T> {
  options: {
    value: T;
    label: string;
  }[];
  selectedOption: T;
  onChange: (newOption: T) => void;
}

export function SingleOptionSelect<T extends string>({
  options,
  selectedOption,
  onChange,
}: Props<T>) {
  return (
    <div className={styles['container']}>
      {options.map((option) => (
        <div
          key={option.value}
          className={clsx(styles['option'], selectedOption === option.value && styles['selected'])}
          onClick={() => onChange(option.value)}
        >
          {selectedOption === option.value && <Checkmark />}
          {option.label}
        </div>
      ))}
    </div>
  );
}
