import * as React from 'react';
import { useRouter } from 'next/router';
import { appendQueryString } from 'lib/util/appendQueryString';
import { useMapUrl } from 'hooks/useMapUrl';

export interface PinDisplayState {
  activePinKey: string;
  pinInfoDisplayStatus: 'SUMMARY' | 'DETAILS' | 'HIDDEN';
  setPinInfoDisplayState: (state: {
    pinKey: string | null;
    displayState: 'SUMMARY' | 'DETAILS' | 'HIDDEN';
  }) => void;
}

export const usePinDisplayState = (): PinDisplayState => {
  const router = useRouter();
  const mapUrl = useMapUrl();

  const setPinInfoDisplayState = React.useCallback(
    (state: { pinKey: string | null; displayState: 'SUMMARY' | 'DETAILS' | 'HIDDEN' }) => {
      const queryParams = new URLSearchParams();
      if (state.pinKey) {
        queryParams.set('pin', state.pinKey);
        queryParams.set('pind', state.displayState);
      }

      router.push(appendQueryString(mapUrl, queryParams.toString()), undefined, {
        shallow: true,
        scroll: false,
      });
    },
    [router, mapUrl]
  );

  if (router.query.pin) {
    return {
      activePinKey: router.query.pin as string,
      pinInfoDisplayStatus: (router.query.pind as 'SUMMARY' | 'DETAILS' | 'HIDDEN') || 'DETAILS',
      setPinInfoDisplayState,
    };
  }
  return {
    activePinKey: '',
    pinInfoDisplayStatus: 'HIDDEN',
    setPinInfoDisplayState,
  };
};
