import * as React from 'react';
import styles from './ThankYou.module.css';
import { useMapDisplayContext } from '../MapDisplayContext';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../Components/PageContainer';
import { AnimatedButton } from '../AnimatedButton';
import { logCustomerEventWithNoSideEffects } from 'ducks/client/customerEvents';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { ReduxState } from 'ducks';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';
import { putAccessLog } from 'lib/util/putAccessLog';
import { getUserId } from 'lib/util/getUserId';

export const ThankYou = () => {
  const { t, i18n } = useTranslation();
  const { productInCart, setActivePage, resetDisplay, clearCart } = useMapDisplayContext();
  const { apiKey } = React.useContext(ApiKeyContext);
  const visitorToken = useSelector((state: ReduxState) => state.tracking.visitorToken);
  const customerId = useSelector((state: ReduxState) => state.customer.customer?.id);
  const reservation = useSelector((state: ReduxState) => state.reservation.lastCreatedReservation);
  const { currentPositionRef } = React.useContext(CurrentPositionContext);
  const router = useRouter();
  const pathname = router.asPath;
  const hasSentEvent = React.useRef(false);
  const settings = useSelector((state: ReduxState) => state.server.settings.all);

  React.useEffect(() => {
    // For stripe payment, the reservation is created in this page
    // When gmo it triggers in TdsFinalizeCheckout.tsx in other frame
    if (!apiKey || !visitorToken || !customerId || !reservation) {
      return;
    }

    if (hasSentEvent.current) {
      return;
    }

    hasSentEvent.current = true;

    const landingMarketingAutomationContentType = sessionStorage.getItem('landingContentType');

    logCustomerEventWithNoSideEffects({
      apiKey: apiKey,
      eventType: 'reservation-thank-you',
      visitorToken: visitorToken,
      customerId: customerId ?? '',
      reservationId: reservation?.id,
      metadata: JSON.stringify({
        page: pathname,
        lat: currentPositionRef.current?.lat(),
        lng: currentPositionRef.current?.lng(),
        site: 'onsite',
        ...(landingMarketingAutomationContentType && {
          landing_marketing_automation_content_type: landingMarketingAutomationContentType,
        }),
      }),
    });

    const userId = getUserId();
    putAccessLog(apiKey, {
      user_id: userId,
      supplier_id: settings?.supplier_id,
      path: pathname,
      action: 'CREATE_RESERVATION_SUCCESS',
      reservation_id: reservation.id,
      utime: Date.now(),
    });
  }, [apiKey, visitorToken, reservation?.id, currentPositionRef, customerId]);

  return (
    <PageContainer onBackClick={() => clearCart()}>
      <section className={styles['main-section']}>
        <div className={styles['ticket-content']}>
          <h2 className={styles['ticket-message']}>
            {i18n.language === 'en-US' ? (
              <>
                Your <em>{productInCart?.product_name}</em> Ticket has been Booked!
              </>
            ) : (
              t('Your {{product}} Ticket has been Booked!', {
                product: productInCart?.product_name,
              })
            )}
          </h2>

          <AnimatedButton
            className={styles['view-tickets']}
            clickedClassName={styles['clicked']}
            onClick={() => {
              resetDisplay();
              setActivePage('TICKETS');
            }}
          >
            {t('View Tickets')}
          </AnimatedButton>

          <AnimatedButton
            className={styles['close']}
            clickedClassName={styles['clicked']}
            onClick={() => clearCart()}
          >
            {t('Close')}
          </AnimatedButton>
        </div>
      </section>
    </PageContainer>
  );
};
