import * as React from 'react';
import styles from './SearchResults.module.css';
import { useMapDisplayContext } from '../MapDisplayContext';
import { ResultsList } from './ResultsList';

export const SearchResults = () => {
  const { searchText, resetDisplay, setDisplayMode } = useMapDisplayContext();

  return (
    <>
      <div className={styles['search-container']}>
        <div
          className={styles['search']}
          onClick={() => {
            setDisplayMode('SEARCH_INPUT');
          }}
        >
          <svg
            onClick={(e) => {
              e.stopPropagation();
              resetDisplay();
            }}
            className={styles['back-arrow']}
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="13"
            viewBox="0 0 17 13"
            fill="none"
          >
            <path
              d="M16 6.5L1 6.5"
              stroke="#393D3F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.8 1.70078L1 6.50078L5.8 11.3008"
              stroke="#393D3F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className={styles['search-text']}>{searchText}</div>
          <svg
            onClick={() => {
              resetDisplay();
              setDisplayMode('SEARCH_INPUT');
            }}
            className={styles['close']}
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
          >
            <path
              d="M14 1L1 14"
              stroke="#393D3F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 1L14 14"
              stroke="#393D3F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      {searchText && <ResultsList />}
    </>
  );
};
