import clsx from 'clsx';
import React from 'react';
import styles from './FilterRow.module.css';
import { useActiveRestaurant } from '../useActiveRestaurant';
import { MenuFilterState, getDefaultMenuFilters } from '../util';
import { FilterPage } from './FilterPage';
import { AnimatedButton } from 'components/Map/AnimatedButton';
import { isEqual } from 'lodash';

export const FilterRow: React.FC<{
  filters: MenuFilterState;
  onFiltersChange: (filters: MenuFilterState) => void;
}> = ({ filters, onFiltersChange }) => {
  const [showFilterPage, setShowFilterPage] = React.useState(false);
  const restaurant = useActiveRestaurant();

  const tagOptions = restaurant?.menu?.menu_items?.map((item) => item.tags)?.flat() ?? [];

  const filterActive = !isEqual(
    {
      ...filters,
      searchQuery: '',
    },
    getDefaultMenuFilters(restaurant?.menu || null)
  );

  return (
    <>
      <nav className={styles.filterTabs}>
        <AnimatedButton
          className={clsx(styles['filter'])}
          clickedClassName={styles['clicked']}
          onClick={() => {
            setShowFilterPage(true);
          }}
        >
          <img width="12px" height="12px" src="/static/images/map/filter.svg" />
          {filterActive && <div className={styles['filter-active-indicator']} />}
        </AnimatedButton>
        {tagOptions.map((tag) => (
          <button
            key={tag}
            className={clsx(styles.filterTab, filters.tags.includes(tag) && styles.active)}
            onClick={() => {
              onFiltersChange({
                ...filters,
                tags: filters.tags.includes(tag)
                  ? filters.tags.filter((t) => t !== tag)
                  : [...filters.tags, tag],
              });
            }}
          >
            {filters.tags.includes(tag) && (
              <img src="/static/images/map/checkmark.svg" className={styles.checkmark} />
            )}
            {tag}
          </button>
        ))}
      </nav>
      {showFilterPage && (
        <FilterPage
          onBackClick={() => setShowFilterPage(false)}
          filters={filters}
          onFiltersChange={onFiltersChange}
        />
      )}
    </>
  );
};
