import styles from './GuestCounter.module.css';

interface Props {
  title: string;
  description: string;
  count: number;
  onChange: (newCount: number) => void;
  totalGuestCount: number;
  minimum?: number;
  maximum?: number;
}

export const GuestCounter = ({ title, description, count, onChange, minimum, maximum }: Props) => {
  return (
    <section className={styles['person-counter']}>
      <div className={styles['person-counter__info']}>
        <h2 className={styles['person-counter__title']}>{title}</h2>
        <p className={styles['person-counter__description']}>{description}</p>
      </div>
      <div className={styles['person-counter__controls']}>
        <button
          className={styles['person-counter__button']}
          aria-label={`Decrease ${title}`}
          onClick={() => onChange(count - 1)}
          disabled={count <= (minimum || 0)}
          type="button"
        >
          -
        </button>
        <p className={styles['person-counter__count']}>{count}</p>
        <button
          className={styles['person-counter__button']}
          aria-label={`Increase ${title}`}
          onClick={() => onChange(count + 1)}
          disabled={count >= (maximum || 0)}
          type="button"
        >
          +
        </button>
      </div>
    </section>
  );
};
