import clsx from 'clsx';
import Image from 'next/image';
import moment from 'moment-timezone';

import { DigitalMapPin } from 'models/digitalMap';
import styles from './ScheduleItem.module.css';
import { formattedTimeOfDay } from '../util';
import { AnimatedClickableDiv } from '../AnimatedClickableDiv';

interface Props {
  pin: DigitalMapPin;
  date: string;
  showtime: string;
  onClick: () => void;
}

export const ScheduleItem = ({ pin, date, showtime, onClick }: Props) => {
  const timeOfDay = moment(showtime, 'HH:mm');
  const timeOfDayOnDate = moment(date).set({
    hour: timeOfDay.hours(),
    minute: timeOfDay.minutes(),
  });
  const isPast = timeOfDayOnDate.isBefore(moment());
  const imageUrl = pin.media_items?.find((item) => item.type === 'IMAGE')?.url;

  return (
    <>
      <AnimatedClickableDiv
        className={styles['schedule-item']}
        clickedClassName={styles['clicked']}
        onClick={onClick}
      >
        <div className={styles['schedule-details']}>
          <Image
            priority
            width={50}
            height={50}
            src={imageUrl ?? ''}
            alt={pin.title}
            className={clsx(styles['schedule-icon'], isPast && styles['past'])}
          />
          <div className={styles['schedule-text']}>
            <div className={styles['schedule-time']}>{formattedTimeOfDay(showtime)}</div>
            <div className={styles['schedule-event']}>{pin.title}</div>
          </div>

          <img src="/static/images/ic_arrow_right.svg" className={styles['arrow-icon']} />
        </div>
      </AnimatedClickableDiv>
    </>
  );
};
