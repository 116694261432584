import * as React from 'react';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';
import { AnimatedButton } from './AnimatedButton';
import styles from './CurrentLocationButton.module.css';
import { smoothMoveCamera } from './smoothMoveCamera';

interface Props {
  map: google.maps.Map;
  style?: React.CSSProperties;
}
export const CurrentLocationButton = ({ map, style }: Props) => {
  const { currentPositionRef } = React.useContext(CurrentPositionContext);

  return (
    <AnimatedButton
      className={styles['current-location-button']}
      clickedClassName={styles['clicked']}
      style={style}
      onClick={() => {
        if (currentPositionRef.current) {
          smoothMoveCamera(map, {
            center: {
              lat: currentPositionRef.current.lat(),
              lng: currentPositionRef.current.lng(),
            },
          });
        }
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 15 15"
        fill="none"
      >
        <path
          d="M14.8661 6.86384H12.8203C12.5307 4.41295 10.5871 2.46931 8.13616 2.17969V0.133929C8.13616 0.0602679 8.07589 0 8.00223 0H6.99777C6.92411 0 6.86384 0.0602679 6.86384 0.133929V2.17969C4.41295 2.46931 2.46931 4.41295 2.17969 6.86384H0.133929C0.0602679 6.86384 0 6.92411 0 6.99777V8.00223C0 8.07589 0.0602679 8.13616 0.133929 8.13616H2.17969C2.46931 10.5871 4.41295 12.5307 6.86384 12.8203V14.8661C6.86384 14.9397 6.92411 15 6.99777 15H8.00223C8.07589 15 8.13616 14.9397 8.13616 14.8661V12.8203C10.5871 12.5307 12.5307 10.5871 12.8203 8.13616H14.8661C14.9397 8.13616 15 8.07589 15 8.00223V6.99777C15 6.92411 14.9397 6.86384 14.8661 6.86384ZM7.5 11.5848C5.2433 11.5848 3.41518 9.7567 3.41518 7.5C3.41518 5.2433 5.2433 3.41518 7.5 3.41518C9.7567 3.41518 11.5848 5.2433 11.5848 7.5C11.5848 9.7567 9.7567 11.5848 7.5 11.5848Z"
          fill="#393D3F"
        />
        <path
          d="M7.50026 5.49312C6.96287 5.49312 6.46063 5.70071 6.08061 6.08241C5.70059 6.46243 5.49133 6.96466 5.49133 7.50205C5.49133 8.03944 5.70059 8.54167 6.08061 8.92169C6.46063 9.30004 6.96454 9.51098 7.50026 9.51098C8.03597 9.51098 8.53988 9.30172 8.9199 8.92169C9.29825 8.54167 9.50918 8.03777 9.50918 7.50205C9.50918 6.96634 9.29992 6.46243 8.9199 6.08241C8.73433 5.89474 8.51319 5.74596 8.26943 5.64477C8.02567 5.54359 7.76418 5.49204 7.50026 5.49312Z"
          fill="#393D3F"
        />
      </svg>
    </AnimatedButton>
  );
};
