import React from 'react';
import clsx from 'clsx';

import styles from './RestaurantDetails.module.css';
import { useActiveRestaurant } from './useActiveRestaurant';

const ImageGallery: React.FC = () => {
  const restaurant = useActiveRestaurant();

  return (
    <div className={clsx(styles['media-carousel'], styles['details'])}>
      {restaurant?.media_items?.map((mediaItem) => (
        <img
          key={mediaItem.url}
          src={mediaItem.url}
          className={clsx(styles['img'], styles['details'])}
          alt="Gallery item"
        />
      ))}
    </div>
  );
};

export default ImageGallery;
