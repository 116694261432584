import _ from 'lodash';
import { useForm } from 'react-final-form';

export function useFieldState<T>(name: string): [T, (newValue: T) => void] {
  const form = useForm();

  const value: T = _.get(form.getState().values, name);

  const setValue = (newValue: T) => form.change(name, newValue);

  return [value, setValue];
}
