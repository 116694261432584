/**
 * This code was generated by Builder.io.
 */
import React from 'react';
import styles from './OrderStatus.module.css';
import ProgressBar from './ProgressBar';
import { useActiveRestaurant } from '../useActiveRestaurant';
import { useTranslation } from 'react-i18next';
import { RestaurantOrder } from 'models/digitalMap';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { fetchRestaurantOrder } from 'ducks/client/restaurantOrders';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { removeNotifications } from 'ducks/client/notification';
import { AnimatedButton } from 'components/Map/AnimatedButton';
import OrderDetails from './builder/OrderDetails/OrderDetails';
import { useActiveRestaurantOrder } from '../useActiveRestaurantOrder';

interface Step {
  headerText: string;
  bodyText: string;
  statusText: string;
  status: RestaurantOrder['status'];
  icon: string;
}

interface Props {
  onOkClick: () => void;
}

const OrderStatus: React.FC<Props> = ({ onOkClick }) => {
  const { t } = useTranslation();
  const restaurant = useActiveRestaurant();
  const restaurantOrder = useActiveRestaurantOrder();
  const dispatch = useDispatch();
  const { apiKey } = React.useContext(ApiKeyContext);

  const steps: Step[] = [
    {
      headerText: t('We got your order from {{restaurantName}}!', {
        restaurantName: restaurant?.name ?? '',
      }),
      bodyText: t(
        "{{restaurantName}} will start preparing your order soon. We'll keep you posted!",
        {
          restaurantName: restaurant?.name ?? '',
        }
      ),
      statusText: t('Received'),
      status: 'RECEIVED',
      icon: '/static/images/map/complete_checkmark.svg',
    },
    {
      headerText: t('Your order is being made'),
      bodyText: t('Your order is currently being prepared by {{restaurantName}}.', {
        restaurantName: restaurant?.name ?? '',
      }),
      statusText: t('In progress'),
      status: 'IN_PROGRESS',
      icon: '/static/images/map/order_in_progress.svg',
    },
    {
      headerText: t('Your order is ready for pickup!'),
      bodyText: t('Your order is ready for pickup at {{restaurantName}}.', {
        restaurantName: restaurant?.name ?? '',
      }),
      statusText: t('Ready'),
      status: 'READY',
      icon: '/static/images/map/fork_spoon_white.svg',
    },
    {
      headerText: t('Your order has been picked up. Enjoy!'),
      bodyText: t('Your order from {{restaurantName}} has been picked up.', {
        restaurantName: restaurant?.name ?? '',
      }),
      statusText: t('Complete'),
      status: 'COMPLETE',
      icon: '/static/images/map/fork_spoon_white.svg',
    },
  ];

  const currentStep = steps.find((step) => step.status === restaurantOrder?.status);

  const restaurantOrderNotifications = useSelector(
    (state: ReduxState) => state.notification.restaurantOrderNotifications
  );

  React.useEffect(() => {
    if (restaurantOrderNotifications.length > 0) {
      for (const notification of restaurantOrderNotifications) {
        dispatch(removeNotifications([notification.id]));
      }
      for (const notification of restaurantOrderNotifications) {
        dispatch(fetchRestaurantOrder(apiKey, notification.payload.restaurant_order_id));
      }
    }
  }, [apiKey, restaurantOrderNotifications, dispatch]);

  return (
    <main className={styles.container}>
      <section className={styles.contentWrapper}>
        <h1 className={styles.title}>{currentStep?.headerText}</h1>
        <p className={styles.description}>{currentStep?.bodyText}</p>
        <div className={styles.statusIconWrapper}>
          <img src={currentStep?.icon} className={styles.statusIcon} alt="" />
        </div>
        <ProgressBar
          steps={steps.slice(0, 3).map((step) => step.statusText)}
          currentStepIndex={steps.findIndex((s) => s.status === restaurantOrder?.status) ?? 2}
        />
        <OrderDetails />
        <AnimatedButton
          onClick={onOkClick}
          className={styles.confirmButton}
          clickedClassName={styles.clicked}
        >
          {t('OK')}
        </AnimatedButton>
      </section>
    </main>
  );
};

export default OrderStatus;
