import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { useTranslation } from 'react-i18next';
import styles from './TermsAndConditions.module.css';

export const TermsAndConditions = () => {
  const { t } = useTranslation();
  const settings = useSelector((state: ReduxState) => state.server.settings.all);

  return settings.customer_ledger_settings?.terms_and_conditions ? (
    <div className={styles['register__item']}>
      <p className={clsx(styles['ttl'], styles['ttl--large'])}>{t('Terms and Conditions')}</p>
      <div className={clsx(styles['form__item'])}>
        <div className={clsx(styles['register__item__box__pp'])}>
          {settings.customer_ledger_settings?.terms_and_conditions}
        </div>
      </div>
    </div>
  ) : null;
};
