import * as React from 'react';
import clsx from 'clsx';

import styles from './Toggle.module.css';

type Props = {
  label: string | React.ReactElement;
  onChange: (arg0: React.SyntheticEvent<Record<string, any>>) => void;
  checked: boolean;
};

export const Toggle = ({ label, onChange, checked }: Props) => {
  return (
    <div>
      <label className={clsx(styles['c-toggle'])}>
        <input onChange={onChange} type="checkbox" checked={checked} />
        <i></i>
        <p>{label}</p>
      </label>
    </div>
  );
};
