import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { redeemStampRallyGiftByVisitorIdentifier } from 'ducks/client/stampRallyGifts';
import { Reservation } from 'models/reservation';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { GuidanceStampRally } from 'models/guidanceStampRally';
import { GuidancePage } from 'models/guidancePage';
import { getMandatoryStampRallyGiftRedemptions } from 'lib/util/getMandatoryStampRallyGiftRedemptions';
import { getMandatoryStampRallyRedemptions } from 'lib/util/getMandatoryStampRallyRedemptions';

import styles from './StampRallyModal.module.css';
import { ConfirmStampRallyGiftRedemptionModal } from './ConfirmStampRallyGiftRedemptionModal';
import config from 'config';
import { Download } from './Prize/Download';
import { useVisitorIdentifier } from 'hooks/useVisitorIdentifier';

interface OwnProps {
  onClose: () => void;
  open: boolean;
  guidanceStampRally: GuidanceStampRally;
  guidancePage?: GuidancePage;
  reservation: Reservation | null;
}

type Props = OwnProps;

export const StampRallyGiftModal = ({
  onClose,
  open,
  guidanceStampRally,
  guidancePage,
  reservation,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { apiKey } = React.useContext(ApiKeyContext);
  const [isRedeeming, setIsRedeeming] = React.useState<{ [index: string]: boolean }>({});
  const [redemptionGift, setRedemptionGift] = React.useState<{ key: string; name: string } | null>(
    null
  );
  const visitorIdentifier = useVisitorIdentifier();

  const filteredGuidanceStampRallyGiftRedemptions = getMandatoryStampRallyGiftRedemptions(
    reservation,
    guidanceStampRally?.stamp_rally_gift_redemptions ?? []
  );

  const filteredGuidanceStampRallyRedemptions = getMandatoryStampRallyRedemptions(
    reservation,
    guidanceStampRally?.stamp_rally_redemptions ?? []
  );

  const redeemedGifts: { [index: string]: boolean } = (
    filteredGuidanceStampRallyGiftRedemptions || []
  ).reduce((prev, current) => {
    return { ...prev, [current.stamp_rally_gift_key]: true };
  }, {});

  const remainingStampCount = filteredGuidanceStampRallyRedemptions?.length ?? 0;

  const handleRedeem = async (giftKey: string) => {
    try {
      setIsRedeeming({ ...isRedeeming, [giftKey]: true });
      await dispatch(
        redeemStampRallyGiftByVisitorIdentifier(apiKey, {
          reservation_id: reservation?.id ?? '',
          page_id: guidancePage?.id ?? '',
          stamp_rally_id: guidanceStampRally.id,
          stamp_rally_gift_key: giftKey,
          tracking_id: visitorIdentifier.trackingId,
        })
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsRedeeming({ ...isRedeeming, [giftKey]: false });
    }
  };

  return (
    <div className={clsx(styles['modal'], open ? styles['is-active'] : null)}>
      <div className={clsx(styles['modal__inner'])}>
        <div className={clsx(styles['modal__content'])}>
          <div className={clsx(styles['gift'])}>
            {(guidanceStampRally?.gifts ?? []).map((gift, idx) => (
              <div className={clsx(styles['gift__item'])} key={idx}>
                <div className={clsx(styles['gift__item__pic'])}>
                  <img src={gift.thumbnail_url} />
                  {Boolean(redeemedGifts[gift.key]) && (
                    <div className={clsx(styles['gift__item__pic__stamp'])}>
                      <i></i>
                      {t('Redeemed')}
                    </div>
                  )}
                  {config.enableNpsSurvey &&
                    gift.type === 'STAMP_RALLY_GIFT_TYPE_DOWNLOAD' &&
                    Boolean(redeemedGifts[gift.key]) && (
                      <Download
                        downloadLinks={
                          guidanceStampRally.stamp_rally_gift_redemptions.find(
                            (redemption) => redemption.stamp_rally_gift_key === gift.key
                          )?.download_links ?? []
                        }
                      />
                    )}
                </div>

                <div className={clsx(styles['gift__item__body'])}>
                  <p className={clsx(styles['gift__item__body__conditions'])}>
                    {t('Acquired for {{n}} stamps', { n: gift.stamp_count_to_redeem })}
                  </p>
                  <p className={clsx(styles['gift__item__body__ttl'])}>{gift.title}</p>
                  <p className={clsx(styles['gift__item__body__desc'])}>{gift.description}</p>
                </div>
                <div className={clsx(styles['gift__item__btns'])}>
                  <div className={clsx(styles['modal__content__btns'])}>
                    <a
                      className={clsx(
                        styles['btn'],
                        Boolean(redeemedGifts[gift.key]) ||
                          remainingStampCount < gift.stamp_count_to_redeem ||
                          isRedeeming[gift.key]
                          ? styles['btn--disabled']
                          : styles['btn--submit']
                      )}
                      onClick={() => {
                        setRedemptionGift({ key: gift.key, name: gift.title });
                      }}
                    >
                      {t('Get a prize')}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className={clsx(styles['modal__content__btns'])}>
            <a className={clsx(styles['btn'], styles['btn--cancel'])} onClick={() => onClose()}>
              {t('Close')}
            </a>
          </div>
        </div>
      </div>
      <div className={clsx(styles['modal__overlay'])}></div>
      {redemptionGift && (
        <ConfirmStampRallyGiftRedemptionModal
          submitting={isRedeeming[redemptionGift.key]}
          giftName={redemptionGift.name}
          open={Boolean(redemptionGift)}
          onClose={() => setRedemptionGift(null)}
          onClick={async () => {
            try {
              handleRedeem(redemptionGift.key);
              setRedemptionGift(null);
            } catch (e) {
              console.log(e);
            }
          }}
        />
      )}
    </div>
  );
};
