import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSpring, animated, config } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';

import { MapDisplayContext } from '../MapDisplayContext';
import { ReduxState } from 'ducks';
import { getFilteredPins } from '../util';

import styles from './ResultsList.module.css';
import { Result } from './Result';

export const ResultsList = () => {
  const { t } = useTranslation();
  const {
    filters,
    searchText,
    searchResultsListPosition,
    setSearchResultsListPosition,
  } = React.useContext(MapDisplayContext);

  React.useEffect(() => {
    setSearchResultsListPosition('MIDWAY');
  }, [setSearchResultsListPosition]);

  const [springs] = useSpring(() => ({
    from: { y: 400 },
    to: { y: 0 },
    config: {
      ...config.stiff,
      clamp: true,
    },
  }));

  const bind = useDrag(
    ({ swipe: [, swipeY] }) => {
      switch (searchResultsListPosition) {
        case 'MAXIMIZED':
          if (swipeY > 0) {
            setSearchResultsListPosition('MINIMIZED');
          }
          break;
        case 'MIDWAY':
          if (swipeY < 0) {
            setSearchResultsListPosition('MAXIMIZED');
          } else if (swipeY > 0) {
            setSearchResultsListPosition('MINIMIZED');
          }

          break;
        case 'MINIMIZED':
          if (swipeY < 0) {
            setSearchResultsListPosition('MAXIMIZED');
          }

          break;
      }
    },
    {
      swipe: {
        distance: [0, 10],
        velocity: [0, 0.1],
        duration: 250,
      },
    }
  );

  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);

  const filteredPins = getFilteredPins(digitalMap?.pins ?? [], filters, searchText);

  let top = filteredPins.length ? 'calc(100dvh - 275px)' : 'calc(100dvh - 88px)';
  if (searchResultsListPosition === 'MINIMIZED') {
    top = 'calc(100dvh - 20px)';
  } else if (searchResultsListPosition === 'MAXIMIZED') {
    top = '70px';
  }
  const style: React.CSSProperties = {
    top,
    overflowY: searchResultsListPosition === 'MAXIMIZED' ? 'auto' : 'visible',
  };

  return (
    <animated.div
      {...bind()}
      className={styles['results-container']}
      style={{
        ...style,
        ...springs,
      }}
    >
      <div className={styles['handle-container']}>
        <div className={styles['handle']} />
      </div>
      <div>
        {!filteredPins.length && (
          <div className={styles['no-results-container']}>{t('Sorry, no results found')}</div>
        )}
        {filteredPins.map((pin) => (
          <div key={pin.index} className={styles['item']}>
            <Result pin={pin} />
          </div>
        ))}
      </div>
    </animated.div>
  );
};
