import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './Download.module.css';
import { ImageDownloadModal } from './ImageDownloadModal';

interface Props {
  downloadLinks?: {
    url?: string;
    text?: string;
    media_type?: 'IMAGE' | 'VIDEO';
  }[];
}

export const Download = ({ downloadLinks }: Props): any => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  return (
    <div className={styles['modal__content__btns']}>
      <button
        type="button"
        className={clsx(styles['btn'], styles['btn--submit'])}
        onClick={() => setOpen(true)}
      >
        {t('Download')}
      </button>
      {open && (
        <ImageDownloadModal
          open={open}
          onClose={() => setOpen(false)}
          downloadLinks={downloadLinks}
        />
      )}
    </div>
  );
};
