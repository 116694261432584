import React from 'react';
import { CategoryItemCard } from './CategoryItemCard';
import styles from './CategorySection.module.css';
import { MenuItem } from 'models/digitalMap';
import { useActiveRestaurant } from './useActiveRestaurant';

interface Props {
  category: string;
  onClickItem: (item: MenuItem) => void;
}

export const CategorySection = React.forwardRef<HTMLElement, Props>(
  ({ category, onClickItem }, ref) => {
    const restaurant = useActiveRestaurant();

    const categoryItems =
      restaurant?.menu?.menu_items.filter((item) => item.category === category) ?? [];

    return (
      <section ref={ref} id={category} className={styles.entreeSection}>
        <h2 className={styles.sectionTitle}>{category}</h2>
        <div className={styles.itemsContainer}>
          {categoryItems.map((item) => (
            <a key={item.name} onClick={() => onClickItem(item)}>
              <CategoryItemCard item={item} />
            </a>
          ))}
        </div>
      </section>
    );
  }
);
