import {
  DigitalMap,
  DigitalMapFloorOverlay,
  DigitalMapLocation,
  DigitalMapPin,
  Schedule,
} from 'models/digitalMap';
import moment, { Moment } from 'moment-timezone';
import { Filters } from './MapDisplayContext';
import { UseTranslationResponse } from 'react-i18next';
import { Weekday } from 'models/product';

export const formattedTimeOfDay = (time?: string) => {
  if (!time) {
    return '';
  }

  return moment(time, 'HH:mm').format('H:mm');
};

export const getSearchFilteredPins = (
  pins: DigitalMapPin[],
  searchText: string
): DigitalMapPin[] => {
  if (!searchText) {
    return pins;
  }

  return (
    pins?.filter((pin) => {
      return (
        pin.title?.toLowerCase().includes(searchText.toLowerCase()) ||
        pin.description?.toLowerCase().includes(searchText.toLowerCase()) ||
        pin.tags?.some((tag) => tag.toLowerCase().includes(searchText.toLowerCase())) ||
        pin.category === searchText
      );
    }) ?? []
  );
};

// findActivePinSchedule returns the first rule that is active for the given moment.
export const findActivePinSchedule = <T extends { schedule: Schedule }>(
  scheduleRules: T[],
  m: Moment
): T | null => {
  const date = m.format('YYYY-MM-DD');
  const weekday = m.locale('en').format('ddd').toUpperCase() as Weekday;

  for (const scheduleRule of scheduleRules) {
    const schedule = scheduleRule.schedule;

    for (const dateRange of schedule.date_ranges) {
      if (dateRange.start_date_local > date || dateRange.end_date_local < date) {
        continue;
      }

      if (!schedule.days_of_week.includes(weekday)) {
        continue;
      }

      if (schedule.closed_dates.includes(date)) {
        continue;
      }

      if (schedule.annually_repeating_closed_dates.some((d) => d.endsWith(date.slice(5)))) {
        continue;
      }

      return scheduleRule;
    }
  }

  return null;
};

export const getFilteredPins = (
  pins: DigitalMapPin[],
  filters: Filters,
  searchText: string,
  selectedFloor?: number
): DigitalMapPin[] => {
  return (
    getSearchFilteredPins(pins, searchText)
      ?.filter((pin) => {
        if (filters.categories.length === 0) {
          return true;
        }

        if (filters.categories.includes('WAIT_TIME')) {
          return pin.show_wait_time;
        }

        return filters.categories?.includes(pin.category);
      })
      ?.filter((pin) => {
        if (filters.attributes.length === 0) {
          return true;
        }

        return (
          (!filters.attributes.includes('hasTickets') || pin.associated_product_ids?.length > 0) &&
          (!filters.attributes.includes('hasMobileOrders') || !!pin.online_order_restaurant_id)
        );
      })
      ?.filter((pin) => {
        if (filters.tags.length === 0) {
          return true;
        }

        return pin.tags?.some((tag) => filters.tags.includes(tag)) ?? false;
      })
      ?.filter((pin) => {
        if (filters.structuredInfoItems.length === 0) {
          return true;
        }

        return filters.structuredInfoItems.every((filter) => {
          if (filter.values.length === 0) {
            return true;
          }

          const structuredInfoItem = pin.structured_info_items?.find(
            (item) => item.info_category_key === filter.info_category_key
          );

          if (!structuredInfoItem) {
            return false;
          }

          return filter.values.some((value) => structuredInfoItem.values?.includes(value));
        });
      })
      ?.filter((pin) => {
        if (filters.openNow) {
          const openHoursForToday = findActivePinSchedule(pin.open_hour_schedules, moment());
          return (
            openHoursForToday &&
            moment(openHoursForToday?.start_time_local, 'HH:mm').isSameOrBefore(moment()) &&
            moment(openHoursForToday?.end_time_local, 'HH:mm').isSameOrAfter(moment())
          );
        }

        if (!filters.openAt) {
          return true;
        }

        const openHours = findActivePinSchedule(pin.open_hour_schedules, moment());
        if (!openHours?.start_time_local || !openHours?.end_time_local) {
          return false;
        }

        const openAt = moment(filters.openAt, 'HH:mm');

        return (
          moment(openHours?.start_time_local, 'HH:mm').isSameOrBefore(openAt) &&
          moment(openHours?.end_time_local, 'HH:mm').isSameOrAfter(openAt)
        );
      })
      ?.filter((pin) => {
        if (!filters.showTimeFrom && !filters.showTimeTo) {
          return true;
        }

        const showtimes = findActivePinSchedule(pin.showtime_schedules, moment())?.showtimes ?? [];

        if (filters.showTimeFrom && filters.showTimeTo) {
          return showtimes?.some(
            (showtime) =>
              moment(showtime, 'HH:mm').isSameOrAfter(moment(filters.showTimeFrom, 'HH:mm')) &&
              moment(showtime, 'HH:mm').isSameOrBefore(moment(filters.showTimeTo, 'HH:mm'))
          );
        } else if (filters.showTimeTo) {
          return showtimes?.some((showtime) =>
            moment(showtime, 'HH:mm').isSameOrBefore(moment(filters.showTimeTo, 'HH:mm'))
          );
        }

        return showtimes?.some((showtime) =>
          moment(showtime, 'HH:mm').isSameOrAfter(moment(filters.showTimeFrom, 'HH:mm'))
        );
      })
      ?.filter((pin) => {
        if (!selectedFloor) {
          return true;
        }

        return pin.floor_number === selectedFloor;
      }) ?? []
  );
};

export const getDistanceText = (
  distanceInMeters: number,
  language: string,
  t: UseTranslationResponse['t']
): string => {
  // If language is English, return the distance in miles
  if (language === 'en-US') {
    return t('{{distanceInMiles}}mi', { distanceInMiles: (distanceInMeters / 1609).toFixed(1) });
  }

  return t('{{distanceInKm}}km', { distanceInKm: (distanceInMeters / 1000).toFixed(1) });
};

export const outOfRange = (
  position: google.maps.LatLng | null,
  location: DigitalMapLocation | null,
  activationRange: number
): boolean => {
  if (!position) {
    return true;
  }

  const distanceBetweenPoints = window.google.maps.geometry.spherical.computeDistanceBetween(
    position,
    new google.maps.LatLng({
      lat: location?.latitude ?? 0,
      lng: location?.longitude ?? 0,
    })
  );

  return distanceBetweenPoints > activationRange;
};

export const preloadImages = (urls: string[]) => {
  urls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

export const getFloorOverlay = (
  digitalMap: DigitalMap | null,
  selectedFloor: number
): DigitalMapFloorOverlay | null => {
  if (!digitalMap) {
    return null;
  }

  if (digitalMap?.background?.do_not_use_overlay_image) {
    return null;
  }

  if ((digitalMap?.map_floor_count ?? 0) > 1 && selectedFloor > 1) {
    return (
      digitalMap?.floor_overlays?.find((overlay) => overlay.floor_number === selectedFloor) ?? null
    );
  }

  return {
    floor_number: 1,
    image_url: digitalMap?.background?.image_url ?? '',
    image_aspect_ratio: digitalMap?.background?.image_aspect_ratio,
    image_projection_width_in_meters: digitalMap?.background?.image_projection_width_in_meters ?? 0,
    top_left: digitalMap?.background?.top_left,
  };
};
