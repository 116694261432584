import React from 'react';
import styles from './OrdersPage.module.css';
import OrderCard from './OrderCard';
import { PageContainer } from 'components/Map/Components/PageContainer';
import { useMapDisplayContext } from 'components/Map/MapDisplayContext';
import { useTranslation } from 'react-i18next';
import { ReduxState } from 'ducks';
import { useSelector } from 'react-redux';

const OrdersPage: React.FC = () => {
  const { t } = useTranslation();

  const { setActivePage } = useMapDisplayContext();

  const restaurantOrders = useSelector((state: ReduxState) => state.restaurantOrders.orders);
  const activeOrders = restaurantOrders.filter((order) => order.status !== 'COMPLETE');
  const completedOrders = restaurantOrders.filter((order) => order.status === 'COMPLETE');

  return (
    <PageContainer
      onBackClick={() => setActivePage('MAP')}
      headerContent={
        <div className={styles['header']}>
          <h1 className={styles['header-title']}>{t('Your Orders')}</h1>
        </div>
      }
    >
      <div className={styles.pageContent}>
        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>
            {t('Active Orders ({{orderCount}})', { orderCount: activeOrders.length })}
          </h2>
          {activeOrders.map((order, index) => (
            <OrderCard key={index} order={order} />
          ))}
        </section>

        <section className={styles.section}>
          <h2 className={styles.sectionTitle}>{t('Past Orders')}</h2>
          {completedOrders.map((order, index) => (
            <OrderCard key={index} order={order} />
          ))}
        </section>
      </div>
    </PageContainer>
  );
};

export default OrdersPage;
