import * as React from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import config from 'config';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { ReduxState } from 'ducks';
import {
  addWebPushNotification,
  enableWebPushNotification,
} from 'ducks/client/webPushNotification';
import { selectMarketingAutomationCampaignReservation } from 'ducks/client/marketingAutomationCampaigns';

export const WebPushNotificationReceiver = () => {
  const dispatch = useDispatch();
  const [token, setToken] = React.useState<string>('');

  //const reservation = useSelector((state: ReduxState) => state.server.reservation.reservation);
  //const lastCreatedReservation = useSelector(
  //  (state: ReduxState) => state.reservation.lastCreatedReservation
  //);
  const reservation = useSelector(selectMarketingAutomationCampaignReservation);
  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const { apiKey } = React.useContext(ApiKeyContext);

  const webPushNotificationEnabled = useSelector(
    (state: ReduxState) => state.webPushNotification.webPushNotificationEnabled
  );

  console.log('token', token);

  React.useEffect(() => {
    try {
      if (Notification.permission === 'granted') {
        dispatch(enableWebPushNotification(true));
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register('/firebase-messaging-sw.js').catch((err) => {
            console.log('ServiceWorker registration failed: ', err);
          });
        }
      } else if (settings.web_push_notifications_enabled) {
        dispatch(enableWebPushNotification(true));
      }
    } catch (err) {
      console.log('err', err);
    }
  }, [settings]);

  React.useEffect(() => {
    if (!webPushNotificationEnabled) {
      return;
    }

    if (!reservation) {
      return;
    }

    if ('serviceWorker' in navigator) {
      const firebaseConfig = {
        apiKey: 'AIzaSyCxeQn2jNA33WA2S4bABAKT65vU_EuUrZA',
        authDomain: 'falconet-notifications.firebaseapp.com',
        projectId: 'falconet-notifications',
        storageBucket: 'falconet-notifications.appspot.com',
        messagingSenderId: '274594774684',
        appId: '1:274594774684:web:97a0dea33ff8863e643759',
        measurementId: 'G-DXNBCGEECR',
      };

      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);

      getToken(messaging, {
        vapidKey:
          'BOdcVIHPCEMRmuSSiRb8uvg1axN6FnHqiBa7NWcXeBa-gY--TBuA0JUlcUjoWuGX1yWS2lalv3ELTnlcmXOXTz4',
      })
        .then((currentToken) => {
          if (currentToken) {
            setToken(currentToken);
          }

          // Save token to server so we can notify specific reservations.

          axios.put(
            `${config.apiUrl}/cloudmessagingtokens`,
            {
              token_id: currentToken,
              reservation_id: reservation?.id,
            },
            {
              headers: { 'x-api-key': apiKey },
            }
          );
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);

          // Retry getting token if it failed:
          // https://github.com/firebase/firebase-js-sdk/issues/7693
          getToken(messaging, {
            vapidKey:
              'BOdcVIHPCEMRmuSSiRb8uvg1axN6FnHqiBa7NWcXeBa-gY--TBuA0JUlcUjoWuGX1yWS2lalv3ELTnlcmXOXTz4',
          })
            .then((currentToken) => {
              if (currentToken) {
                setToken(currentToken);
              }

              // Save token to server so we can notify specific reservations.

              axios.put(
                `${config.apiUrl}/cloudmessagingtokens`,
                {
                  token_id: currentToken,
                  reservation_id: reservation?.id,
                },
                {
                  headers: { 'x-api-key': apiKey },
                }
              );
            })
            .catch((err) => {
              console.log('An error occurred while retrieving token (retry). ', err);
            });
          // ...
        });

      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        dispatch(addWebPushNotification(payload));
      });
    }
  }, [reservation, apiKey, webPushNotificationEnabled]);
  return null;
};
