import * as React from 'react';
import styles from './GuestPicker.module.css';
import { GuestCounter } from './GuestCounter';
import { UseTranslationResponse, useTranslation } from 'react-i18next';
import { GuestType } from 'models/product';
import { useMapDisplayContext } from '../MapDisplayContext';
import { getEnablePerBookingMaximumParticipantsValue } from 'lib/util/getEnablePerBookingMaximumParticipants';
import { isRequiredGuestType } from 'lib/util/util';

const getAgeRangeDescription = (
  minAge: number | undefined,
  maxAge: number | undefined,
  t: UseTranslationResponse['t']
) => {
  if (minAge && maxAge) {
    return t('Ages {{minAge}}-{{maxAge}}', { minAge, maxAge });
  }
  if (minAge) {
    return t('Ages {{minAge}}+', { minAge });
  }
  if (maxAge) {
    return t('Under {{maxAge}}', { maxAge });
  }

  return '';
};

interface GuestCounts {
  [key: string]: number;
}

interface Props {
  onClose: () => void;
  position: { top?: string; left?: string; right?: string; bottom?: string };
  onGuestsChange: (guestCounts: GuestCounts) => void;
  guestCounts: GuestCounts;
  guestTypes: GuestType[];
}

export const GuestPicker = ({
  onClose,
  position,
  guestTypes,
  guestCounts,
  onGuestsChange,
}: Props) => {
  const { t } = useTranslation();

  const { productInCart } = useMapDisplayContext();

  const totalCount = guestTypes.reduce(
    (acc, guestType) => acc + (guestCounts[guestType?.key ?? ''] ?? 0),
    0
  );

  const maximumParticipantRules =
    productInCart?.booking_widget_settings?.maximum_participant_rules ?? [];

  const minimumParticipantRules =
    productInCart?.booking_widget_settings?.minimum_participant_rules ?? [];

  const selectableParticipantRules =
    productInCart?.booking_widget_settings?.selectable_participant_rules ?? [];

  const isSelectableParticipantRuleActive =
    productInCart?.booking_widget_settings?.is_selectable_participant_rule_active ?? false;

  const isPerBookingParticipantRuleActive =
    productInCart?.booking_widget_settings?.is_per_booking_participant_rule_active ?? false;

  const perBookingMaximumParticipants =
    productInCart?.booking_widget_settings?.per_booking_participant_rule?.maximum_participants ?? 0;

  const getMaximumParticipants = (unit: string, maximumParticipants: number): number => {
    const remainingCapacity = perBookingMaximumParticipants - totalCount;
    const enablePerBookingMaximumParticipantsValue = getEnablePerBookingMaximumParticipantsValue(
      isPerBookingParticipantRuleActive,
      perBookingMaximumParticipants,
      unit,
      maximumParticipants,
      remainingCapacity,
      guestCounts
    );
    if (!isSelectableParticipantRuleActive) {
      return enablePerBookingMaximumParticipantsValue;
    }

    let newMaximumParticipants = enablePerBookingMaximumParticipantsValue;

    for (let i = 0; i < selectableParticipantRules.length ?? 0; i++) {
      const rule = selectableParticipantRules[i];
      if (rule.restricted_units.includes(unit)) {
        newMaximumParticipants = 0;
        break;
      }
    }

    for (let i = 0; i < selectableParticipantRules.length ?? 0; i++) {
      const rule = selectableParticipantRules[i];

      if (rule.required_units.includes(unit) || !rule.restricted_units.includes(unit)) {
        continue;
      }

      const numOfRequiredUnit = rule.required_units.reduce(
        (count: number, requiredUnit: string) => {
          return count + (guestCounts[requiredUnit] || 0);
        },
        0
      );

      const numOfRestrictedUnit = rule.restricted_units.reduce(
        (count: number, restrictedUnit: string) => {
          return count + (guestCounts[restrictedUnit] || 0);
        },
        0
      );

      const availableCapacity = numOfRequiredUnit * rule.maximum_participants - numOfRestrictedUnit;

      const tempMaximumParticipants = (guestCounts[unit] || 0) + availableCapacity;

      if (newMaximumParticipants < tempMaximumParticipants) {
        newMaximumParticipants = tempMaximumParticipants;
        if (isPerBookingParticipantRuleActive) {
          if (enablePerBookingMaximumParticipantsValue < tempMaximumParticipants) {
            newMaximumParticipants = enablePerBookingMaximumParticipantsValue;
          }
        }
      }
    }
    return newMaximumParticipants;
  };

  return (
    <div className={styles['picker-overlay']} onClick={onClose}>
      <div
        className={styles['picker']}
        style={{ ...position }}
        onClick={(e) => e.stopPropagation()}
      >
        {guestTypes.map((guestType) => {
          const minPaxRule = minimumParticipantRules?.find((rule) => rule.unit === guestType.key);
          const maxPaxRule = maximumParticipantRules?.find((rule) => rule.unit === guestType.key);
          const maximumParticipants = getMaximumParticipants(
            guestType.key,
            maxPaxRule ? maxPaxRule.maximum_participants ?? 11 : 11
          );

          const minimumParticipants = minPaxRule
            ? minPaxRule.minimum_participants ?? 0
            : isRequiredGuestType(guestType)
            ? 1
            : 0;

          if (Number(guestCounts?.[guestType.key] ?? 0) > maximumParticipants) {
            onGuestsChange({
              ...guestCounts,
              [guestType?.key ?? '']: 0,
            });
          }

          return (
            <GuestCounter
              title={guestType?.title}
              description={getAgeRangeDescription(
                guestType?.minimum_age,
                guestType?.maximum_age,
                t
              )}
              key={guestType?.key}
              count={guestCounts[guestType?.key ?? ''] ?? 0}
              onChange={(newCount) => {
                onGuestsChange({
                  ...guestCounts,
                  [guestType?.key ?? '']: newCount,
                });
              }}
              totalGuestCount={totalCount}
              minimum={minimumParticipants}
              maximum={maximumParticipants}
            />
          );
        })}
      </div>
    </div>
  );
};
