import { useSelector } from 'react-redux';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { required } from 'lib/forms/validate';

import axios from 'axios';
import config from 'config';

import { ReduxState } from 'ducks';
import { useFormValues } from 'hooks/useFormValues';
import { CreditCardInput } from './CreditCardInput/CreditCardInput';
import { CustomerCardValues } from 'components/Customer/formValues';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { GeneralCreditCardInput } from './GeneralCreditCardInput/GeneralCreditCardInput';
import { GeneralCreditCardContext } from 'contexts/GeneralCreditCardContext';
import { useGmo } from 'hooks/useGmo';
import { Select } from './Select';
import { selectAcceptedGuestPaymentCurrencies } from 'ducks/server/settings';
import { TextField } from './TextField';
import styles from './CardRegisterForm.module.css';
import { ButtonLoader } from '../ButtonLoader';

interface Props {
  onSubmit: () => void;
  handleBack?: () => void;
}

export const CardRegisterForm = ({ onSubmit }: Props) => {
  // This is used for Stripe. If we are using GMO, we don't need this.
  const stripe = useStripe();
  const elements = useElements();

  const { apiKey } = React.useContext(ApiKeyContext);

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  // This is used for GMO payment gateway. If we are using Stripe, we don't need this.
  const gmo = useGmo();
  const { cardNumber, cardExpiry, cardCvc } = React.useContext(GeneralCreditCardContext);

  const customer = useSelector((state: ReduxState) => state.customer.customer);
  const settings = useSelector((state: ReduxState) => state.server.settings.all);

  const { cardHolderName, paymentCurrencyType } = useFormValues<CustomerCardValues>();

  const shouldUseGmo =
    settings?.reservation_payment_gateway_settings?.payment_gateway === 'GMO' &&
    paymentCurrencyType === 'JPY';

  const { t } = useTranslation();

  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (submitting) {
      return;
    }

    const cardNumberElement = elements?.getElement(CardNumberElement);
    if (stripe && cardNumberElement) {
      setSubmitting(true);

      const resp = await axios.post(
        `${config.apiUrl}/create-stripe-setup-intent`,
        {
          settlement_currency: paymentCurrencyType,
        },
        {
          headers: { 'x-api-key': apiKey },
        }
      );

      const confirmResp = await stripe.confirmCardSetup(resp.data.client_secret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: cardHolderName,
            email: customer?.email ?? '',
          },
        },
        expand: ['payment_method'],
      } as any);

      if (confirmResp.error) {
        setSubmitting(false);
        return;
      } else {
        if (confirmResp.setupIntent?.payment_method) {
          form.change('paymentMethod', confirmResp.setupIntent?.payment_method);
          form.change('paymentGateway', 'STRIPE');
        }
      }

      await onSubmit();
      setSubmitting(false);

      return;
    }

    setSubmitting(true);
    await onSubmit();
    setSubmitting(false);
  };

  const handleGmoSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (submitting) {
      return;
    }

    try {
      setSubmitting(true);
      const resp = await gmo.getToken({
        cardno: cardNumber,
        holdername: cardHolderName,
        expire: cardExpiry.replace('/', ''),
        securitycode: cardCvc,
        tokennumber: '5',
      });

      if (resp.resultCode !== '000') {
        setSubmitting(false);
        return;
      } else {
        form.change('gmoMpTokens', resp?.tokenObject?.token);
        form.change('paymentGateway', 'GMO');
      }

      await onSubmit();
    } catch (error) {
      console.error('Error submitting card payment form', error);
    } finally {
      setSubmitting(false);
    }
  };

  const acceptedGuestPaymentCurrencies = useSelector(selectAcceptedGuestPaymentCurrencies);

  // 'JPY' is translated to 日本円 for Japanese suppliers
  const isJapaneseSupplier =
    settings.default_timezone === 'Asia/Tokyo' && settings.default_currency === 'JPY';

  const form = useForm();

  const reservationPaymentCurrencyOptions = [
    {
      text: 'USD ($)',
      value: 'USD',
    },
    {
      text: isJapaneseSupplier ? t('JPY (¥)') : 'JPY (¥)',
      value: 'JPY',
    },
  ].filter((currencyOption) => acceptedGuestPaymentCurrencies.includes(currencyOption.value));

  React.useEffect(() => {
    if (reservationPaymentCurrencyOptions.length === 1) {
      form.change('paymentCurrencyType', reservationPaymentCurrencyOptions[0].value);
    }
  }, []);

  return (
    <form noValidate={true} onSubmit={shouldUseGmo ? handleGmoSubmit : handleSubmit}>
      <div className={styles['container']}>
        <div className={styles['inner']}>
          <Field
            required={true}
            validate={required}
            id="paymentCurrencyType"
            name="paymentCurrencyType"
          >
            {({ input }) => (
              <Select
                label={t('Payment Currency')}
                value={input.value}
                onChange={input.onChange}
                options={reservationPaymentCurrencyOptions}
              />
            )}
          </Field>

          <Field<CustomerCardValues['cardHolderName']> id="cardHolderName" name="cardHolderName">
            {({ input }) => (
              <TextField
                label={t('Card Holder Name')}
                value={input.value}
                onChange={input.onChange}
              />
            )}
          </Field>

          {shouldUseGmo ? (
            <GeneralCreditCardInput
              onChange={({ complete }) => {
                console.log('complete', complete);
              }}
            />
          ) : (
            <CreditCardInput
              onChange={({ complete }) => {
                console.log('complete', complete);
              }}
            />
          )}
        </div>

        <button className={styles['btn-save']} type="submit">
          {submitting ? <ButtonLoader /> : t('Save')}
        </button>
      </div>
    </form>
  );
};
