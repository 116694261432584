import * as React from 'react';

import { DigitalMapPin, DigitalMapPinStructuredInfoItem } from 'models/digitalMap';
import { ProductSummary } from 'models/product';
import { GuidancePageCoupon } from 'models/guidancePage';
import { CheckoutFormValues } from './Checkout/formValues';
import { useRouter } from 'next/router';
import { useMapUrl } from 'hooks/useMapUrl';

export type AttributeFilterType = 'hasTickets' | 'hasMobileOrders';

export interface Filters {
  tags: string[];
  categories: string[];
  attributes: AttributeFilterType[];

  structuredInfoItems: DigitalMapPinStructuredInfoItem[];

  showTimeFrom: string;
  showTimeTo: string;

  openAt: string;

  openNow: boolean;
  noWait: boolean;
}

export const getDefaultFilters = (): Filters => ({
  tags: [],
  categories: [],
  attributes: [],
  structuredInfoItems: [],
  showTimeFrom: '',
  showTimeTo: '',
  openAt: '',
  openNow: false,
  noWait: false,
});

export type Page = 'MAP' | 'SCHEDULE' | 'TICKETS' | 'ACCOUNT' | 'NOTIFICATIONS' | 'ORDERS';
export type DisplayMode =
  | 'BASE'
  | 'SEARCH_RESULTS'
  | 'SEARCH_INPUT'
  | 'DESKTOP_SEARCH_RESULTS'
  | 'SHOW_DIRECTIONS_ROUTE';
export type SortType = 'RELEVANCE' | 'DISTANCE';
export type PinInfoDisplayStatus = 'HIDDEN' | 'SUMMARY' | 'DETAILS';

interface MapDisplayContextType {
  activePage: Page;
  setActivePage: (page: Page) => void;
  displayMode: DisplayMode;
  setDisplayMode: (mode: DisplayMode) => void;
  searchText: string;
  setSearchText: (text: string) => void;
  sortBy: SortType;
  setSortBy: (sortBy: SortType) => void;
  filters: Filters;
  setFilters: (filters: Filters) => void;
  showFilterPane: boolean;
  setShowFilterPane: (show: boolean) => void;
  searchResultsListPosition: 'MINIMIZED' | 'MIDWAY' | 'MAXIMIZED';
  setSearchResultsListPosition: (position: 'MINIMIZED' | 'MIDWAY' | 'MAXIMIZED') => void;
  productInCart: ProductSummary | null;
  setProductInCart: (product: ProductSummary | null) => void;
  savedCartParams: CheckoutFormValues | null;
  setSavedCartParams: (params: CheckoutFormValues | null) => void;
  activeStampPinIdx: number | null;
  setActiveStampPinIdx: (idx: number | null) => void;
  showStampRallyModal: boolean;
  setShowStampRallyModal: (show: boolean) => void;
  activeCouponViewContext: {
    coupon: GuidancePageCoupon;
    pin: DigitalMapPin;
  } | null;
  setActiveCouponViewContext: (
    coupon: {
      coupon: GuidancePageCoupon;
      pin: DigitalMapPin;
    } | null
  ) => void;
  menuExpanded: boolean;
  setMenuExpanded: (expanded: boolean) => void;
  googleMap: google.maps.Map | null;
  setActiveRestaurantId: (restaurantId: string | null) => void;
  activeRestaurantId: string | null;
  setActiveOpenTableRestaurantId: (restaurantId: string | null) => void;
  activeOpenTableRestaurantId: string | null;
  orderStatusRestaurantId: string | null;
  setOrderStatusRestaurantId: (restaurantId: string | null) => void;
  orderStatusRestaurantOrderId: string | null;
  setOrderStatusRestaurantOrderId: (restaurantOrderId: string | null) => void;
  setShouldRefreshCustomerReservations: (shouldRefresh: boolean) => void;
  shouldRefreshCustomerReservations: boolean;
  onGetDirections: (origin: google.maps.LatLng, destinationPin: DigitalMapPin) => void;
  selectedFloor: number;
  setSelectedFloor: (floor: number) => void;
}

interface MapDisplayContextTypeWithHelpers extends MapDisplayContextType {
  resetDisplay(): void;
  resetFilters(): void;
  clearCart(): void;
  restoreSavedDisplayState(): void;
  saveDisplayState(): void;
  clearSavedDisplayState(): void;
}

const defaultContext: MapDisplayContextType = {
  activePage: 'MAP',
  setActivePage: () => {},
  displayMode: 'BASE',
  setDisplayMode: () => {},
  searchText: '',
  setSearchText: () => {},
  sortBy: 'RELEVANCE',
  setSortBy: () => {},
  filters: getDefaultFilters(),
  setFilters: () => {},
  showFilterPane: false,
  setShowFilterPane: () => {},
  searchResultsListPosition: 'MINIMIZED',
  setSearchResultsListPosition: () => {},
  productInCart: null,
  setProductInCart: () => {},
  savedCartParams: null,
  setSavedCartParams: () => {},
  activeStampPinIdx: null,
  setActiveStampPinIdx: () => {},
  showStampRallyModal: false,
  setShowStampRallyModal: () => {},
  activeCouponViewContext: null,
  setActiveCouponViewContext: () => {},
  menuExpanded: false,
  setMenuExpanded: () => {},
  googleMap: null,
  setActiveRestaurantId: () => {},
  activeRestaurantId: null,
  setActiveOpenTableRestaurantId: () => {},
  activeOpenTableRestaurantId: null,
  orderStatusRestaurantId: null,
  setOrderStatusRestaurantId: () => {},
  orderStatusRestaurantOrderId: null,
  setOrderStatusRestaurantOrderId: () => {},
  setShouldRefreshCustomerReservations: () => {},
  shouldRefreshCustomerReservations: false,
  onGetDirections: () => {},
  selectedFloor: 1,
  setSelectedFloor: () => {},
};
export const MapDisplayContext = React.createContext<MapDisplayContextType>(defaultContext);

export const useMapDisplayContext = (): MapDisplayContextTypeWithHelpers => {
  const context = React.useContext(MapDisplayContext);
  const router = useRouter();
  const pathName = router.asPath;
  const mapUrl = useMapUrl();

  return {
    ...context,
    resetDisplay: () => {
      context.setActivePage('MAP');
      context.setDisplayMode('BASE');
      context.setSearchText('');
      context.setShowFilterPane(false);
      context.setProductInCart(null);
      context.setSavedCartParams(null);
      context.setActiveRestaurantId(null);
      context.setActiveOpenTableRestaurantId(null);
      context.setOrderStatusRestaurantId(null);
      context.setOrderStatusRestaurantOrderId(null);
      context.setSelectedFloor(1);
      router.push(mapUrl, undefined, {
        shallow: true,
        scroll: false,
      });
    },
    resetFilters: () => {
      context.setFilters({
        ...getDefaultFilters(),
        categories: context.filters.categories,
        attributes: context.filters.attributes,
      });
    },
    clearCart: () => {
      context.setProductInCart(null);
      context.setSavedCartParams(null);
    },
    saveDisplayState: () => {
      sessionStorage.setItem(
        'map-ctx',
        JSON.stringify({
          activePage: context.activePage,
          displayMode: context.displayMode,
          searchText: context.searchText,
          sortBy: context.sortBy,
          filters: context.filters,
          showFilterPane: context.showFilterPane,
          searchResultsListPosition: context.searchResultsListPosition,
          productInCart: context.productInCart,
          savedCartParams: context.savedCartParams,
          activeStampPinIdx: context.activeStampPinIdx,
          showStampRallyModal: context.showStampRallyModal,
          activeCouponViewContext: context.activeCouponViewContext,
          menuExpanded: context.menuExpanded,
          activeRestaurantId: context.activeRestaurantId,
          orderStatusRestaurantId: context.orderStatusRestaurantId,
          orderStatusRestaurantOrderId: context.orderStatusRestaurantOrderId,
          pathName,
          selectedFloor: context.selectedFloor,
        })
      );
    },
    restoreSavedDisplayState: () => {
      const ctx = sessionStorage.getItem('map-ctx');
      if (!ctx) {
        return null;
      }

      const savedCtx: MapDisplayContextType & { pathName: string } = JSON.parse(ctx);

      context.setProductInCart(savedCtx.productInCart);
      context.setSavedCartParams(savedCtx.savedCartParams);
      context.setActivePage(savedCtx.activePage);
      context.setDisplayMode(savedCtx.displayMode);
      context.setSearchText(savedCtx.searchText);
      context.setSortBy(savedCtx.sortBy);
      context.setFilters(savedCtx.filters);
      context.setShowFilterPane(savedCtx.showFilterPane);
      context.setSearchResultsListPosition(savedCtx.searchResultsListPosition);
      context.setActiveStampPinIdx(savedCtx.activeStampPinIdx);
      context.setShowStampRallyModal(savedCtx.showStampRallyModal);
      context.setActiveCouponViewContext(savedCtx.activeCouponViewContext);
      context.setMenuExpanded(savedCtx.menuExpanded);
      context.setActiveRestaurantId(savedCtx.activeRestaurantId);
      context.setActiveOpenTableRestaurantId(savedCtx.activeOpenTableRestaurantId);
      context.setOrderStatusRestaurantId(savedCtx.orderStatusRestaurantId);
      context.setOrderStatusRestaurantOrderId(savedCtx.orderStatusRestaurantOrderId);
      context.setSelectedFloor(savedCtx.selectedFloor);
      sessionStorage.removeItem('map-ctx');

      if (savedCtx.pathName) {
        router.push(savedCtx.pathName, undefined, {
          shallow: true,
          scroll: false,
        });
      }
    },
    clearSavedDisplayState: () => {
      sessionStorage.removeItem('map-ctx');
    },
  };
};
