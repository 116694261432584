import * as React from 'react';
import Image from 'next/image';

import { ProductSummary } from 'models/product';
import { useTranslation } from 'react-i18next';
import styles from './ProductCard.module.css';
import { MapDisplayContext } from '../MapDisplayContext';
import { useCurrencyFormatter } from '../useCurrencyFormatter';
import { getImageUrl } from 'lib/util/imageUrl';
import { AnimatedButton } from '../AnimatedButton';

interface Props {
  product: ProductSummary;
}

export const ProductCard = ({ product }: Props) => {
  const { t } = useTranslation();
  const { setProductInCart } = React.useContext(MapDisplayContext);

  const currencyFormatter = useCurrencyFormatter();

  const imageUrl = product.media.find((item) => item.type === 'IMAGE')?.url || '';

  return (
    <>
      <section className={styles['product-card']}>
        <Image
          priority
          alt=""
          width={200}
          height={148}
          className={styles['product-image']}
          key={imageUrl}
          src={imageUrl}
          loader={({ width, src }) => getImageUrl(src, width * 1.5)}
        />
        <div className={styles['product-content']}>
          <h3 className={styles['product-title']}>{product.product_name}</h3>
          {product.lowest_price_gross && (
            <>
              <p className={styles['price']}>
                {currencyFormatter.format(product.lowest_price_gross)}
              </p>
              {product.lowest_price_unit_title && (
                <p className={styles['price-per']}>
                  {t('per {{unit}}', {
                    unit: product.lowest_price_unit_title,
                  })}
                </p>
              )}
            </>
          )}
          <p className={styles['product-description']}>{product.description}</p>
          <AnimatedButton
            className={styles['product-button']}
            clickedClassName={styles['clicked']}
            onClick={() => setProductInCart(product)}
          >
            {t('BOOK')}
          </AnimatedButton>
        </div>
      </section>
    </>
  );
};
