import clsx from 'clsx';
import Image from 'next/image';
import _ from 'lodash';
import * as React from 'react';
import moment from 'moment-timezone';

import styles from './Tickets.module.css';
import { useTranslation } from 'react-i18next';
import { TicketScanner } from './TicketScanner';
import { useSelector } from 'react-redux';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { ReduxState } from 'ducks';
import { Reservation } from 'models/reservation';
import { Product } from 'models/product';
import { useProductDetails } from 'hooks/useProductDetails';
import { TicketView } from './TicketView';
import { CustomerContext } from 'contexts/CustomerContext';
import { useMapDisplayContext } from '../MapDisplayContext';
import { PageLoader } from '../PageLoader/PageLoader';
import { useRelativeUrl } from 'hooks/useRelativeUrl';
import { getCheckinStatus } from 'lib/util/getCheckinStatus';
import { PageContainer } from '../Components/PageContainer';
import { AnimatedButton } from '../AnimatedButton';
import { AnimatedClickableDiv } from '../AnimatedClickableDiv';

interface TicketType {
  imageSrc: string;
  title: string;
  startTimeUtc: string;
  status: 'REDEEMED' | 'UNREDEEMED' | 'IN_USE';
  reservation: Reservation;
  product: Product;
}

interface TicketProps {
  ticket: TicketType;
}

const Ticket: React.FC<TicketProps> = ({ ticket }) => {
  const { t, i18n } = useTranslation();

  const relativeUrl = useRelativeUrl('/eticket');
  const ticketUrl = `${relativeUrl}?r=${ticket.reservation.id}`;

  return (
    <AnimatedClickableDiv
      className={styles['ticket']}
      clickedClassName={styles['clicked']}
      onClick={() => window.open(ticketUrl, '_blank')}
    >
      <Image
        priority
        width={156}
        height={101}
        src={ticket.imageSrc}
        alt={`${ticket.title}`}
        className={styles['ticket-image']}
      />
      <div className={styles['ticket-content']}>
        <p className={styles['ticket-title']}>{ticket.title}</p>
        <p className={styles['ticket-timestamp']}>
          {moment(ticket.startTimeUtc).locale(i18n.language).format('lll')}
        </p>
        {ticket.status === 'REDEEMED' && (
          <a className={clsx(styles['ticket-status'], styles['status-redeemed'])} target="_blank">
            {t('Redeemed')}
          </a>
        )}
        {ticket.status === 'IN_USE' && (
          <a className={clsx(styles['ticket-status'], styles['status-in-use'])} target="_blank">
            {t('In Use')}
          </a>
        )}
        {ticket.status === 'UNREDEEMED' && (
          <a className={clsx(styles['ticket-status'], styles['status-unredeemed'])} target="_blank">
            {t('Activate')}
          </a>
        )}
      </div>
    </AnimatedClickableDiv>
  );
};

export function Tickets() {
  const { t, i18n } = useTranslation();
  const [activeView, setActiveView] = React.useState<'TICKETS' | 'TICKET_READER'>('TICKETS');
  const [activeTicketReservationId, setActiveTicketReservationId] = React.useState<string | null>(
    null
  );

  const { customer } = React.useContext(CustomerContext);
  const { apiKey } = React.useContext(ApiKeyContext);
  const customerReservations = useSelector(
    (state: ReduxState) => state.reservation.foundReservations
  );
  const productSummaries = useSelector((state: ReduxState) => state.server.productSummaries.all);
  const loading = useSelector((state: ReduxState) => state.reservation.loading);
  const { resetDisplay, setActivePage, saveDisplayState } = useMapDisplayContext();

  const sortedReservations = _.orderBy(customerReservations, ['start_date_time_utc']);
  const { productDetails } = useProductDetails(
    Array.from(new Set(customerReservations.map((r) => r.product_id))),
    apiKey,
    i18n.language
  );

  const redeemedTickets: TicketType[] = sortedReservations
    .filter((reservation) => getCheckinStatus(reservation) === 'expired')
    .map((reservation) => {
      const product = productSummaries.find((product) => product.id === reservation.product_id);

      const imageSrc = product?.media?.find((item) => item.type === 'IMAGE')?.url ?? '';

      return {
        imageSrc,
        title: product?.product_name ?? '',
        startTimeUtc: reservation.start_date_time_utc,
        status: 'REDEEMED',
        reservation,
        product: productDetails?.find((pd) => pd.id === reservation.product_id),
      };
    });
  const inUseTickets: TicketType[] = sortedReservations
    .filter((reservation) => getCheckinStatus(reservation) === 'started')
    .map((reservation) => {
      const product = productSummaries.find((product) => product.id === reservation.product_id);

      const imageSrc = product?.media?.find((item) => item.type === 'IMAGE')?.url ?? '';

      return {
        imageSrc,
        title: product?.product_name ?? '',
        startTimeUtc: reservation.start_date_time_utc,
        status: 'IN_USE',
        reservation,
        product: productDetails?.find((pd) => pd.id === reservation.product_id),
      };
    });
  const unRedeemedTickets: TicketType[] = sortedReservations
    .filter((reservation) =>
      ['NOT_CHECKED_IN', 'IN_PROGRESS'].includes(reservation.checkin_info?.checkin_status)
    )
    .map((reservation) => {
      const product = productSummaries.find((product) => product.id === reservation.product_id);

      const imageSrc = product?.media?.find((item) => item.type === 'IMAGE')?.url ?? '';

      return {
        imageSrc,
        title: product?.product_name ?? '',
        startTimeUtc: reservation.start_date_time_utc,
        status: 'UNREDEEMED',
        reservation,
        product: productDetails?.find((pd) => pd.id === reservation.product_id),
      };
    });

  if (!customer) {
    return (
      <PageContainer onBackClick={() => setActivePage('MAP')}>
        <main className={styles['main-content']}>
          <p className={styles['message']}>{t('Please sign in to view tickets.')}</p>
          <AnimatedButton
            className={styles['sign-in-button']}
            clickedClassName={styles['clicked']}
            onClick={() => {
              saveDisplayState();

              // Initiate sign in/ sign up flow.
              // Once complete, we'll restore the map display context.
              setActivePage('ACCOUNT');
            }}
          >
            {t('Sign In')}
          </AnimatedButton>
          <AnimatedButton
            className={styles['close-button']}
            clickedClassName={styles['clicked']}
            onClick={() => {
              resetDisplay();
            }}
          >
            {t('Close')}
          </AnimatedButton>
        </main>
      </PageContainer>
    );
  }

  if (activeTicketReservationId) {
    const activeTicketReservation = customerReservations?.find(
      (r) => r.id === activeTicketReservationId
    );
    const activeTicketProduct = productDetails?.find(
      (p) => p.id === activeTicketReservation?.product_id
    );

    return (
      <PageContainer onBackClick={() => setActivePage('MAP')}>
        <div className={styles['ticket-header']}>
          <svg
            onClick={() => {
              setActiveTicketReservationId(null);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
          >
            <path
              d="M14 1L1 14"
              stroke="#393D3F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 1L14 14"
              stroke="#393D3F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <TicketView reservation={activeTicketReservation} product={activeTicketProduct} />
      </PageContainer>
    );
  }

  switch (activeView) {
    case 'TICKET_READER':
      return (
        <PageContainer onBackClick={() => setActivePage('MAP')}>
          <TicketScanner onClose={() => setActiveView('TICKETS')} />
        </PageContainer>
      );
    default:
      return (
        <PageContainer
          headerContent={
            <header className={styles['header']}>
              <h1 className={styles['header-title']}>{t('Your Tickets')}</h1>
            </header>
          }
          onBackClick={() => setActivePage('MAP')}
        >
          <div className={styles.pageContent}>
            {unRedeemedTickets.length === 0 &&
              redeemedTickets.length === 0 &&
              inUseTickets.length === 0 &&
              (loading ? (
                <PageLoader />
              ) : (
                <div className={styles['validated-tickets']}>{t('No tickets found.')}</div>
              ))}
            {redeemedTickets.length > 0 && (
              <section className={styles['validated-tickets']}>
                <h2 className={styles['section-title']}>
                  {t('Redeemed ({{ticketCount}})', {
                    ticketCount: redeemedTickets.length,
                  })}
                </h2>
                {redeemedTickets.map((ticket, idx) => (
                  <Ticket key={idx} ticket={ticket} />
                ))}
              </section>
            )}
            {inUseTickets.length > 0 && (
              <section className={styles['in-use-tickets']}>
                <h2 className={styles['section-title']}>
                  {t('In Use ({{ticketCount}})', {
                    ticketCount: inUseTickets.length,
                  })}
                </h2>
                {inUseTickets.map((ticket, idx) => (
                  <Ticket key={idx} ticket={ticket} />
                ))}
              </section>
            )}
            {unRedeemedTickets.length > 0 && (
              <section className={styles['unvalidated-tickets']}>
                <h2 className={styles['section-title']}>
                  {t('Unredeemed ({{ticketCount}})', {
                    ticketCount: unRedeemedTickets.length,
                  })}
                </h2>
                {unRedeemedTickets.map((ticket, idx) => (
                  <Ticket key={idx} ticket={ticket} />
                ))}
              </section>
            )}
          </div>
        </PageContainer>
      );
  }
}
