import * as React from 'react';
import { FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';

import { FieldWrapper } from '../FieldWrapper';
import styles from './CreditCardInput.module.css';

const isCardInfoComplete = (
  cardNumberComplete: boolean,
  cardExpiryComplete: boolean,
  cardCvcComplete: boolean
): boolean => {
  return cardNumberComplete && cardExpiryComplete && cardCvcComplete;
};

interface Props {
  onChange: ({ error, complete }: { error: string; complete: boolean }) => void;
}

export const CreditCardInput = ({ onChange }: Props) => {
  const [cardNumberComplete, setCardNumberComplete] = React.useState(false);
  const [cardExpiryError, setCardExpiryError] = React.useState('');
  const [cardExpiryComplete, setCardExpiryComplete] = React.useState(false);
  const [cardCvcError, setCardCvcError] = React.useState('');
  const [cardCvcComplete, setCardCvcComplete] = React.useState(false);
  const { t } = useTranslation();

  const cardInputStyle = {
    base: {
      color: 'black',
      fontFamily: '"Roboto, Helvetica, Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#a3a0a0',
      },
    },
    invalid: {
      color: '#f44336',
      iconColor: '#f44336',
    },
  };

  return (
    <>
      <FieldWrapper label={t('Card Number')}>
        <CardNumberElement
          options={{ style: cardInputStyle }}
          onChange={({ complete }: any) => {
            setCardNumberComplete(complete as boolean);

            onChange({
              error: '',
              complete: isCardInfoComplete(complete, cardExpiryComplete, cardCvcComplete),
            });
          }}
        />
      </FieldWrapper>
      <div className={styles['col-2']}>
        <FieldWrapper label={t('Expiry Date')}>
          <CardExpiryElement
            options={{ style: cardInputStyle }}
            onChange={({ error, complete }: any) => {
              setCardExpiryError(error ? error.message : '');
              setCardExpiryComplete(complete as boolean);

              onChange({
                error: '',
                complete: isCardInfoComplete(cardNumberComplete, complete, cardCvcComplete),
              });
            }}
          />
        </FieldWrapper>
        <FieldWrapper label={t('Security Code')}>
          <CardCvcElement
            options={{ style: cardInputStyle, placeholder: t('123') }}
            onChange={({ error, complete }: any) => {
              setCardCvcError(error ? error.message : '');
              setCardCvcComplete(complete as boolean);

              onChange({
                error: '',
                complete: isCardInfoComplete(cardNumberComplete, cardExpiryComplete, complete),
              });
            }}
          />
        </FieldWrapper>
      </div>
      {cardExpiryError && <FormHelperText error>{cardExpiryError}</FormHelperText>}
      {cardCvcError && <FormHelperText error>{cardCvcError}</FormHelperText>}
    </>
  );
};
