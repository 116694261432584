import config from 'config';

import { Customer, UpdateCustomerRequest, CustomerFormFieldResponse } from 'models/customer';
import { CustomerFormField } from 'models/settings';

export interface CustomerCardValues {
  cardHolderName: string;
  paymentCurrencyType: 'JPY' | 'USD';
  paymentMethod: stripe.PaymentMethodResponse['paymentMethod'] | null;
  gmoMpTokens: string[];
  paymentGateway: 'STRIPE' | 'GMO';
}

export interface CustomerInformationValues {
  firstName: string;
  lastName: string;
  kanaFirstName: string;
  kanaLastName: string;
  email: string;
  formFieldResponses: { [key: string]: FormFieldResponseValues };
  agreeTermsAndConditions: boolean;
  shouldReceiveSpecialEmailOffers?: boolean;
}

export interface FormFieldResponseValues {
  value?: string;
  year?: string;
  month?: string;
  day?: string;
  postalCode?: string;
  country?: string;
  province?: string;
  city?: string;
  addressLine?: string;
}

export type CustomerValues = CustomerCardValues & CustomerInformationValues;

export const getInitialCardValues = (customer: Customer | null): CustomerCardValues => ({
  cardHolderName: customer?.payment_profile_card_info?.card_holder_name || '',
  paymentCurrencyType: 'JPY',
  paymentMethod: null,
  gmoMpTokens: [],
  paymentGateway: customer?.payment_gateway || 'STRIPE',
});

export const getInitialValues = (
  customer: Customer | null,
  formFields: CustomerFormField[] | null
): CustomerValues => ({
  cardHolderName: customer?.payment_profile_card_info?.card_holder_name || '',
  paymentCurrencyType: 'JPY',
  paymentMethod: null,
  firstName: customer?.given_name || '',
  lastName: customer?.family_name || '',
  kanaFirstName: customer?.kana_given_name || '',
  kanaLastName: customer?.kana_family_name || '',
  email: customer?.email || '',
  formFieldResponses: getInitialFormFieldResponse(customer, formFields),
  agreeTermsAndConditions: false,
  shouldReceiveSpecialEmailOffers: customer?.should_receive_special_email_offers?.value || false,
  gmoMpTokens: [],
  paymentGateway: customer?.payment_gateway || 'STRIPE',
});

export const getInitialFormFieldResponse = (
  customer: Customer | null,
  formFields: CustomerFormField[] | null
): { [key: string]: FormFieldResponseValues } => {
  const formFieldResponses: { [key: string]: FormFieldResponseValues } = {};
  for (const formFieldResponse of customer?.form_field_responses || []) {
    const formFieldResponseValue: FormFieldResponseValues = {};
    const formField = (formFields || []).find(
      (formField) => formField.key === formFieldResponse.key
    );

    if (formField?.type === 'ADDRESS') {
      formFieldResponseValue.postalCode = formFieldResponse.address?.postal_code;
      formFieldResponseValue.country = formFieldResponse.address?.country;
      formFieldResponseValue.province = formFieldResponse.address?.province;
      formFieldResponseValue.city = formFieldResponse.address?.city;
      formFieldResponseValue.addressLine = formFieldResponse.address?.address_line;
    } else if (formField?.format == 'YYYY_MM_DD') {
      const part = formFieldResponse.value?.split('-');
      formFieldResponseValue.year = part?.[0] || '';
      formFieldResponseValue.month = part?.[1] || '';
      formFieldResponseValue.day = part?.[2] || '';
    } else {
      formFieldResponseValue.value = formFieldResponse.value;
    }

    formFieldResponses[formFieldResponse.key] = formFieldResponseValue;
  }

  return formFieldResponses;
};

export const convertToUpdateCustomerCardRequest = (
  values: CustomerCardValues
): UpdateCustomerRequest => {
  if (config.enableGmoPaymentGateway) {
    if (values.paymentGateway === 'GMO') {
      if (values.gmoMpTokens.length > 0) {
        return {
          card_settlement_currency: values.paymentCurrencyType,
          payment_profile_gateway_reference: values.gmoMpTokens[0],
          payment_gateway: values.paymentGateway,
        };
      }
    } else {
      if (values.paymentMethod) {
        return {
          card_settlement_currency: values.paymentCurrencyType,
          payment_profile_gateway_reference: values.paymentMethod.id,
          payment_gateway: values.paymentGateway,
        };
      }
    }
    return {};
  }
  if (values.paymentMethod) {
    return {
      payment_profile_card_info: {
        payment_profile_gateway_reference: values.paymentMethod.id,
        card_holder_name: values.cardHolderName,
        card_brand: values.paymentMethod.card?.brand as string,
        last_four_digits: values.paymentMethod.card?.last4 as string,
        exp_month: values.paymentMethod.card?.exp_month || 0,
        exp_year: values.paymentMethod.card?.exp_year || 0,
      },
      card_settlement_currency: values.paymentCurrencyType,
    };
  }
  return {};
};

export const convertToUpdateCustomerInformationRequest = (
  values: CustomerValues,
  formFields: CustomerFormField[] | null
): UpdateCustomerRequest => {
  return {
    given_name: values.firstName,
    family_name: values.lastName,
    kana_given_name: values.kanaFirstName,
    kana_family_name: values.kanaLastName,
    form_field_responses: convertToUpdateCustomerFormFieldResponse(values, formFields),
    should_receive_special_email_offers: {
      value: values.shouldReceiveSpecialEmailOffers,
    },
  };
};

const convertToUpdateCustomerFormFieldResponse = (
  values: CustomerValues,
  formFields: CustomerFormField[] | null
): CustomerFormFieldResponse[] => {
  const formFieldResponses: CustomerFormFieldResponse[] = [];
  for (const key in values.formFieldResponses) {
    const formField = (formFields || []).find((formField) => formField.key === key);

    const formFieldResponseValue = values.formFieldResponses[key];
    const formFieldResponse: CustomerFormFieldResponse =
      formField?.type === 'ADDRESS'
        ? {
            key: key,
            address: {
              postal_code: formFieldResponseValue.postalCode ?? '',
              country: formFieldResponseValue.country ?? '',
              province: formFieldResponseValue.province ?? '',
              city: formFieldResponseValue.city ?? '',
              address_line: formFieldResponseValue.addressLine ?? '',
            },
          }
        : formField?.format === 'YYYY_MM_DD'
        ? {
            key: key,
            value: `${formFieldResponseValue.year}-${formFieldResponseValue.month}-${formFieldResponseValue.day}`,
          }
        : {
            key: key,
            value: formFieldResponseValue.value ?? '',
          };
    formFieldResponses.push(formFieldResponse);
  }
  return formFieldResponses;
};

export interface ChangePasswordValues {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}
