import { getCommonRedirectUri } from 'lib/util/customer';
import { Customer } from 'models/customer';
import { CreateRestaurantOrderRequest, Restaurant } from 'models/digitalMap';
import moment from 'moment-timezone';

export interface CheckoutFormValues {
  items: OrderItem[];
  submitErrorReset?: string;
}

export interface OrderItem {
  menuItemKey: string;
  optionGroups: OrderItemOptionGroup[];
}

export interface OrderItemOptionGroup {
  key: string;
  optionKeys: string[];
}

export const convertCheckoutFormValuesToSwaggerOrderRequest = (
  values: CheckoutFormValues,
  restaurant: Restaurant,
  customer: Customer,
  accessToken: string,
  idProvider: string
): CreateRestaurantOrderRequest => {
  return {
    settlement_currency: customer?.card_settlement_currency ?? '',
    cart_finalization_date_time_utc: moment().utc().format(),
    restaurant_versioned_id: restaurant.versioned_id,
    items: values.items.map((item) => ({
      item_key: item.menuItemKey,
      option_groups: item.optionGroups.map((optionGroup) => ({
        key: optionGroup.key,
        option_keys: optionGroup.optionKeys,
      })),
    })),
    given_name: customer.given_name || '',
    family_name: customer.family_name || '',
    email: customer.email || '',
    phone_number: customer.form_field_responses?.find((resp) => resp.key === 'phone')?.value || '',
    should_use_customer_payment_info: true,
    access_token: accessToken,
    id_provider: idProvider ?? '',
    redirect_uri: getCommonRedirectUri(),
    special_requests: '',
  };
};
