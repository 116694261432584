import { Reservation } from 'models/reservation';
import { GetSettingsResponse } from 'models/settings';

export const getShouldUseGmo = (
  reservation: Reservation | null,
  settings: GetSettingsResponse | null,
  paymentCurrencyType: string
): boolean => {
  if (paymentCurrencyType === 'USD') {
    return false;
  }

  if (reservation && reservation.payment_gateway !== 'GMO') {
    return false;
  }

  if (!settings?.reservation_payment_gateway_settings?.payment_gateway) {
    return false;
  }

  if (settings.reservation_payment_gateway_settings.payment_gateway !== 'GMO') {
    return false;
  }

  return true;
};
