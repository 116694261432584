import * as React from 'react';
import Image from 'next/image';

import { DigitalMapPin } from 'models/digitalMap';

import styles from './Result.module.css';
import { PinHeaderActions } from '../PinHeaderActions/PinHeaderActions';
import { usePinDisplayState } from '../usePinDisplayState';

interface Props {
  pin: DigitalMapPin;
}

export const Result = ({ pin }: Props) => {
  const { setPinInfoDisplayState } = usePinDisplayState();

  return (
    <div
      className={styles['container']}
      onClick={() => {
        setPinInfoDisplayState({ pinKey: pin.key, displayState: 'DETAILS' });
      }}
    >
      <div className={styles['carousel']}>
        {pin.media_items?.map((mediaItem) => (
          <Image
            priority
            width={120}
            height={120}
            alt={pin.title}
            key={mediaItem.url}
            src={mediaItem.url}
            className={styles['img']}
          />
        ))}
      </div>

      <div className={styles['title']}>{pin.title}</div>
      <PinHeaderActions pin={pin} />
    </div>
  );
};
