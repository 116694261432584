import * as React from 'react';
import { MapDisplayContext } from '../MapDisplayContext';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { Restaurant } from 'models/digitalMap';

export const useActiveRestaurant = (): Restaurant | null => {
  const { activeRestaurantId, orderStatusRestaurantId } = React.useContext(MapDisplayContext);

  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);

  return (
    digitalMap?.restaurants.find((restaurant) => restaurant.id === activeRestaurantId) ||
    digitalMap?.restaurants.find((restaurant) => restaurant.id === orderStatusRestaurantId) ||
    null
  );
};
