import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import styles from './Menu.module.css';
import { useMapDisplayContext } from '../MapDisplayContext';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { useExistingReservation } from '../useExistingReservation';
import { AnimatedClickableDiv } from '../AnimatedClickableDiv';
import config from 'config';
import { LanguageSelect } from './LanguageSelect';

interface MenuItemProps {
  iconUrl: string;
  label: string;
  onClick: () => void;
}

const MenuItem = ({ iconUrl, label, onClick }: MenuItemProps) => {
  return (
    <AnimatedClickableDiv
      className={clsx(styles['menu-item'])}
      clickedClassName={styles['clicked']}
      onClick={onClick}
    >
      <div className={styles['menu-item-content']}>
        <Image
          width={16}
          height={24}
          priority
          src={iconUrl}
          alt={label}
          className={styles['menu-icon']}
        />
        <div className={styles['menu-label']}>{label}</div>
      </div>
      <Image width={7} height={24} priority src="/static/images/ic_arrow_right.svg" alt="" />
    </AnimatedClickableDiv>
  );
};

interface Props {
  onClose: () => void;
}

export const Menu = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const { setActivePage, setShowStampRallyModal, resetDisplay } = useMapDisplayContext();

  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);

  const stampRally = useSelector(
    (state: ReduxState) => state.universal.digitalGuidanceStampRally.stampRally
  );

  const shouldShowMenuItem = (
    item: 'MAP' | 'MY_ACCOUNT' | 'TICKETS' | 'SCHEDULE' | 'STAMP_RALLY'
  ) => {
    const displaySettings = digitalMap?.display_settings;
    return (
      (displaySettings?.menu_items?.length ?? 0) === 0 || displaySettings?.menu_items.includes(item)
    );
  };

  const existingReservation = useExistingReservation();

  return (
    <aside className={styles['menu']}>
      {shouldShowMenuItem('MAP') && (
        <>
          <MenuItem
            label={t('Map')}
            iconUrl="/static/images/map/map_pin.svg"
            onClick={() => {
              resetDisplay();
              setActivePage('MAP');
              onClose();
            }}
          />
          <hr className={styles['separator']} />
        </>
      )}
      {shouldShowMenuItem('MY_ACCOUNT') && (
        <>
          <MenuItem
            label={t('My Account')}
            iconUrl="/static/images/map/user_circle.svg"
            onClick={() => {
              resetDisplay();
              setActivePage('ACCOUNT');
              onClose();
            }}
          />
          <hr className={styles['separator']} />
        </>
      )}
      {shouldShowMenuItem('TICKETS') && (
        <>
          <MenuItem
            label={t('Tickets')}
            iconUrl="/static/images/map/ticket.svg"
            onClick={() => {
              resetDisplay();
              setActivePage('TICKETS');
              onClose();
            }}
          />
          <hr className={styles['separator']} />
        </>
      )}
      {shouldShowMenuItem('SCHEDULE') && (
        <>
          <MenuItem
            label={t('Schedule')}
            iconUrl="/static/images/map/schedule.svg"
            onClick={() => {
              resetDisplay();
              setActivePage('SCHEDULE');
              onClose();
            }}
          />
          <hr className={styles['separator']} />
        </>
      )}
      {shouldShowMenuItem('STAMP_RALLY') &&
        stampRally &&
        (config.enableNpsSurvey || existingReservation) && (
          <>
            <MenuItem
              label={t('Stamp Rally')}
              iconUrl="/static/images/map/stamp_rally.svg"
              onClick={() => {
                resetDisplay();
                setShowStampRallyModal(true);
                onClose();
              }}
            />
            <hr className={styles['separator']} />
          </>
        )}

      <div className={styles['language-select-container']}>
        {(digitalMap?.display_settings?.language_menu_items?.length ?? 0) > 0 && <LanguageSelect />}
      </div>
    </aside>
  );
};
