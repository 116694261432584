import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Amplify } from 'aws-amplify';
import { TextField } from './TextField';

import styles from './SignIn.module.css';
import { Field, Form } from 'react-final-form';
import { continueWithSnsHandler } from 'lib/util/customer';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { fetchAuthSession, signIn } from 'aws-amplify/auth';
import { FORM_ERROR } from 'final-form';
import { useRelativeUrl } from 'hooks/useRelativeUrl';
import { useMapDisplayContext } from '../MapDisplayContext';
import { CustomerTokenContext, CustomerTokenType } from 'contexts/CustomerTokenContext';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { ButtonLoader } from './ButtonLoader';
import { AnimatedButton } from '../AnimatedButton';

interface FormValues {
  email: string;
  password: string;
}

export const SignIn = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { restoreSavedDisplayState } = useMapDisplayContext();
  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const { apiKey } = React.useContext(ApiKeyContext);

  const customerTokenContext = React.useContext(CustomerTokenContext);

  const authPath = useRelativeUrl('/auth');

  return (
    <Form
      initialValues={{ email: '', password: '' }}
      onSubmit={async (values: FormValues) => {
        const cognitoInfo = settings.customer_ledger_settings?.cognito;

        if (!cognitoInfo?.user_pool_id || !cognitoInfo?.client_id) {
          return;
        }

        Amplify.configure({
          Auth: {
            Cognito: {
              userPoolId: cognitoInfo.user_pool_id,
              userPoolClientId: cognitoInfo.client_id,
            },
          },
        });

        try {
          await signIn({
            username: values.email,
            password: values.password,
          });

          const session = await fetchAuthSession();

          const newContext: CustomerTokenType = {
            accessToken: session.tokens?.accessToken?.toString(),
            idToken: session.tokens?.idToken?.toString(),
            refreshToken: '',
            expiresAt: session.tokens?.accessToken?.payload.exp,
            idProvider: 'COGNITO',
            apiKey,
          };
          customerTokenContext.setToken(newContext);

          restoreSavedDisplayState();
        } catch (error) {
          console.log('error', error);
          return {
            [FORM_ERROR]: t(
              'There was an error with your email address or password. If you signed-up with your Google or Facebook account, please login using your Facebook or Google account.'
            ),
          };
        }
      }}
      validate={(values: FormValues) => {
        const errors: Partial<FormValues> = {};

        if (!values.email) {
          errors.email = t('Required');
        }

        if (!values.password) {
          errors.password = t('Required');
        }

        return errors;
      }}
    >
      {({ form, handleSubmit, submitError, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field name="email">
            {({ input, meta: { touched, error } }) => (
              <TextField
                label={t('Email address')}
                placeholder={t('Your email')}
                value={input.value}
                onChange={input.onChange}
                error={touched && error}
              />
            )}
          </Field>
          <Field name="password">
            {({ input, meta: { touched, error } }) => (
              <TextField
                label={t('Password')}
                placeholder={t('Password')}
                value={input.value}
                onChange={input.onChange}
                type="password"
                error={touched && error}
              />
            )}
          </Field>
          {submitError && <div className={styles['error']}>{submitError}</div>}

          <AnimatedButton
            className={clsx(styles['btn'], styles['signup'])}
            clickedClassName={styles['clicked']}
            disabled={submitting}
            type="button"
            onClick={() => form.submit()}
          >
            {submitting ? <ButtonLoader /> : t('Log In')}
          </AnimatedButton>

          <div className={styles['or-container']}>
            <div className={styles['divider']} />
            <div className={styles['or-text']}>{t('Or')}</div>
            <div className={styles['divider']} />
          </div>
          <AnimatedButton
            className={clsx(styles['btn'], styles['third-party'])}
            clickedClassName={styles['clicked']}
            onClick={() => {
              continueWithSnsHandler('GOOGLE', authPath, router.asPath);
            }}
            type="button"
          >
            <img src="/static/images/ic_login_google.svg" className={styles['icon']} />
            <div>{t('Continue with Google')}</div>
          </AnimatedButton>
          <AnimatedButton
            className={clsx(styles['btn'], styles['third-party'])}
            clickedClassName={styles['clicked']}
            onClick={() => {
              continueWithSnsHandler('FACEBOOK', authPath, router.asPath);
            }}
            type="button"
          >
            <img src="/static/images/ic_login_facebook_solid.svg" className={styles['icon']} />
            <div>{t('Continue with Facebook')}</div>
          </AnimatedButton>
        </form>
      )}
    </Form>
  );
};
