import { Cluster, ClusterStats, Marker, Renderer } from '@googlemaps/markerclusterer';

export class ClusterRenderer implements Renderer {
  public render({ count, position }: Cluster, stats: ClusterStats, map: google.maps.Map): Marker {
    const backgroundColor = '#fff';
    const borderColor = '#F3F6F8';

    const title = `Cluster of ${count} markers`,
      // adjust zIndex to be above other markers
      zIndex: number = Number(google.maps.Marker.MAX_ZINDEX) + count;

    const clusterEl = document.createElement('div');
    clusterEl.style.backgroundColor = backgroundColor;
    clusterEl.style.border = `6px solid ${borderColor}`;
    clusterEl.style.borderRadius = '50%';
    clusterEl.style.boxShadow = '0 0 5px rgba(0, 0, 0, 0.1)';
    clusterEl.style.height = '57px';
    clusterEl.style.width = '57px';
    clusterEl.style.display = 'flex';
    clusterEl.style.justifyContent = 'center';
    clusterEl.style.alignItems = 'center';
    clusterEl.style.fontSize = '26px';
    clusterEl.style.fontWeight = '700';
    clusterEl.textContent = count.toString();

    const clusterOptions: google.maps.marker.AdvancedMarkerElementOptions = {
      map,
      position,
      zIndex,
      title,
      content: clusterEl,
    };
    return new google.maps.marker.AdvancedMarkerElement(clusterOptions);
  }
}
