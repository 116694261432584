import moment from 'moment-timezone';
import { isValidPhoneNumber } from 'libphonenumber-js';

// Field-level validator for dynamic fields whose names are not known beforehand
export function required<T>(value: T): string | undefined {
  // t('Required')
  return value ? undefined : 'Required';
}

export const locationRequired = (location: any) =>
  location && location.locationName ? undefined : 'Required';

const defaultValidator = () => undefined;

export const getOptionalValidator = (format: string): Validator => {
  const validator = (s: string) => {
    if (!s || !(format in formats)) {
      return undefined;
    }

    return formats[format](s);
  };

  return validator || defaultValidator;
};

export type Validator = (s: string) => string | undefined;

export const formats: { [key: string]: Validator } = {
  email: (s: string) => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i.test(s)) {
      // t('Invalid email format')
      return 'Invalid email format';
    }

    return undefined;
  },
  // Alphabet name.
  'alpha-name': (s: string) => {
    if (!/^[-a-zA-Z/. ]+$/.test(s)) {
      // t('Alphabet characters or spaces')
      return 'Alphabet characters or spaces';
    }

    return undefined;
  },
  'non-negative-integer': (s: string) => {
    if (!/^(0|[1-9][0-9]*)$/.test(s)) {
      // t('Positive numeric')
      return 'Positive numeric';
    }
  },
  float: (s: string) => {
    if (!/^[+-]?([0-9]*[.])?[0-9]+$/.test(s)) {
      // t(’Numeric')
      return 'Numeric';
    }
  },
  'yyyy-mm-dd': (s: string) => {
    if (!moment(s, 'YYYY-MM-DD', true).isValid()) {
      return 'YYYY-MM-DD';
    }
  },
  'kana-name': (s: string) => {
    if (!/^[ァ-ヶー\u3000]+$/.test(s)) {
      return 'Kana characters';
    }

    return undefined;
  },
  phone: (s: string) => {
    if (!/^[0-9]{8,20}$/.test(s.replace(/[-\s]/gi, ''))) {
      // t(’Numeric, hyphen(-) or space')
      return 'Numeric, hyphen(-) or space';
    }

    return undefined;
  },
  international_phone: (s: string) => {
    if (!/^\+[0-9]{8,20}$/.test(s.replace(/[-\s]/gi, ''))) {
      // t(’Start with "+" and numeric, hyphen(-) or space')
      return 'Start with "+" and numeric, hyphen(-) or space';
    }

    return undefined;
  },
  friendly_phone: (s: string) => {
    if (!isValidPhoneNumber(s)) {
      // t(’Invalid phone number')
      return 'Invalid phone number';
    }

    return undefined;
  },
  'kana-or-alpha-name': (s: string) => {
    // t('Kana or alphabet characters')
    if (!/^[ァ-ヶー\u3000a-zA-Z]+$/.test(s)) {
      return 'Kana or alphabet characters';
    }

    return undefined;
  },
};
