import * as TWEEN from '@tweenjs/tween.js';

interface CameraOptions {
  center?: {
    lat: number;
    lng: number;
  };
  zoom?: number;
}

export const smoothMoveCamera = (map: google.maps.Map, options: CameraOptions) => {
  const currentCenter = map.getCenter();
  const currentZoom = map.getZoom();

  const cameraOptions = {
    center: {
      lat: currentCenter?.lat() ?? 0,
      lng: currentCenter?.lng() ?? 0,
    },
    zoom: currentZoom ?? 0,
  };

  const tween = new TWEEN.Tween(cameraOptions)
    .to(
      {
        center: options.center
          ? {
              lat: options.center.lat,
              lng: options.center.lng,
            }
          : {
              lat: currentCenter?.lat() ?? 0,
              lng: currentCenter?.lng() ?? 0,
            },
        zoom: options.zoom ? options.zoom : currentZoom,
      },
      400
    )
    .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
    .onUpdate(() => {
      map?.moveCamera({
        center: new google.maps.LatLng(cameraOptions.center.lat, cameraOptions.center.lng),
        zoom: cameraOptions.zoom,
      });
    })
    .start();

  const animate = (time: number) => {
    requestAnimationFrame(animate);
    tween.update(time);
  };

  requestAnimationFrame(animate);
};
