import { Button, Box } from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/Modal/Modal';

import styles from './StampRallyModal.module.css';

interface OwnProps {
  onClose: () => void;
  open: boolean;
  giftName: string;
  onClick: () => void;
  submitting: boolean;
}

type Props = OwnProps;

export const ConfirmStampRallyGiftRedemptionModal = ({
  onClose,
  open,
  giftName,
  submitting,
  onClick,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} open={open}>
      <div className={clsx(styles['modal__content'])}>
        <>
          <Box className={styles['title']} display="flex" justifyContent="center">
            <h1>{giftName}</h1>
          </Box>
          <Box display="flex" justifyContent="center" mt={2}>
            <Button onClick={onClick} variant="contained" color="primary" disabled={submitting}>
              {t('Get a prize')}
            </Button>
          </Box>
        </>
      </div>
    </Modal>
  );
};
