import * as React from 'react';
import { useEffect, useRef } from 'react';
import { PageContainer } from '../Components/PageContainer';
import { MapDisplayContext } from '../MapDisplayContext';
import styles from './OpenTableReservation.module.css';

export const OpenTableReservation = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { activeOpenTableRestaurantId, setActiveOpenTableRestaurantId } = React.useContext(
    MapDisplayContext
  );

  useEffect(() => {
    if (!iframeRef.current) return;

    const iframe = iframeRef.current;
    const doc = iframe.contentDocument || iframe.contentWindow?.document;
    if (!doc) return;

    // Write the HTML content including the script
    doc.open();
    doc.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1">
        </head>
        <body style="margin:0;padding:0;">
          <div id="opentable-widget-container"></div>
          <script 
            type="text/javascript" 
            src="https://www.opentable.com/widget/reservation/loader?rid=${activeOpenTableRestaurantId}&type=standard"
          ></script>
        </body>
      </html>
    `);
    doc.close();
  }, [activeOpenTableRestaurantId]);

  return (
    <PageContainer onBackClick={() => setActiveOpenTableRestaurantId(null)}>
      <iframe
        ref={iframeRef}
        className={styles['opentable-widget']}
        title="OpenTable Reservation Widget"
        sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
      />
    </PageContainer>
  );
};
