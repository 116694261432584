import * as React from 'react';
import styles from './FloorButton.module.css';
import { FloorButton } from './FloorButton';
import { ReduxState } from 'ducks';
import { useSelector } from 'react-redux';
import { useMapDisplayContext } from '../MapDisplayContext';

export const FloorSelector: React.FC = () => {
  const { selectedFloor, setSelectedFloor } = useMapDisplayContext();

  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);

  if (!digitalMap?.map_floor_count || digitalMap.map_floor_count === 1) return null;

  return (
    <div className={styles.container}>
      {Array.from({ length: digitalMap.map_floor_count }, (_, index) => (
        <FloorButton
          key={index}
          selected={index + 1 === selectedFloor}
          onClick={() => setSelectedFloor(index + 1)}
        >
          {index + 1}
        </FloorButton>
      ))}
    </div>
  );
};
