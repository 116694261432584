import * as React from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';

import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { DigitalMapPin } from 'models/digitalMap';
import { MapDisplayContext } from '../MapDisplayContext';

import styles from './SchedulePane.module.css';
import { ScheduleItem } from './ScheduleItem';
import { findActivePinSchedule } from '../util';
import { usePinDisplayState } from '../usePinDisplayState';

type PinWithShowtime = {
  pin: DigitalMapPin;
  showtime: string;
};

export const Today = () => {
  const { setActivePage } = React.useContext(MapDisplayContext);
  const { setPinInfoDisplayState } = usePinDisplayState();

  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);

  const onResultClick = (result: { key: string }) => {
    setPinInfoDisplayState({ pinKey: result.key, displayState: 'DETAILS' });
    setActivePage('MAP');
  };

  const today = moment();

  const pinsWithTimes: PinWithShowtime[] = _.orderBy(
    digitalMap?.pins
      ?.map((pin) => {
        const showtimes = findActivePinSchedule(pin.showtime_schedules, today)?.showtimes ?? [];
        return showtimes.map((showtime) => ({
          pin,
          showtime,
        }));
      })
      .flat() ?? [],
    (pinWithShowtime) => moment(pinWithShowtime.showtime, 'HH:mm')
  );

  return (
    <div className={styles['items']}>
      {pinsWithTimes.map(({ pin, showtime }) => {
        return (
          <div key={pin.index} className={styles['item']}>
            <ScheduleItem
              pin={pin}
              date={today.format('YYYY-MM-DD')}
              showtime={showtime}
              onClick={() => onResultClick(pin)}
            />
          </div>
        );
      })}
    </div>
  );
};
