import clsx from 'clsx';
import * as React from 'react';
import { BUTTON_CLICK_ANIMATION_DURATION } from './constants';
import styles from './AnimatedButton.module.css';
type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  clickedClassName: string;
};

export const AnimatedButton = ({ children, clickedClassName, ...props }: Props) => {
  const [isClicked, setIsClicked] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsClicked(true);

    setTimeout(() => {
      setIsClicked(false);

      props.onClick?.(e);
    }, BUTTON_CLICK_ANIMATION_DURATION);
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.onMouseEnter?.(e);
    setIsHovered(true);
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.onMouseLeave?.(e);
    setIsHovered(false);
  };

  return (
    <button
      {...props}
      className={clsx(
        styles.btn,
        props.className,
        isClicked && clickedClassName,
        isHovered && clickedClassName
      )}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </button>
  );
};
