import * as React from 'react';
import clsx from 'clsx';
import { Modal } from 'components/Modal/Modal';

import styles from './ImageDownloadModal.module.css';
import { ImageDownloadCard } from './ImageDownloadCard';

interface Props {
  open: boolean;
  onClose: () => void;
  downloadLinks?: {
    url?: string;
    text?: string;
    media_type?: 'IMAGE' | 'VIDEO';
  }[];
}

export const ImageDownloadModal = ({ open, onClose, downloadLinks }: Props) => {
  const showScrollButtons = (downloadLinks || []).length > 1;
  const sliderRef = React.useRef<HTMLDivElement>(null);

  const scroll = (direction: string) => {
    if (!sliderRef.current) {
      return;
    }

    const slideWidth = sliderRef.current.offsetWidth;
    const currentScroll = sliderRef.current.scrollLeft;

    if (direction === 'prev') {
      sliderRef.current.scrollTo({
        left: currentScroll - slideWidth,
        behavior: 'smooth',
      });
    } else {
      sliderRef.current.scrollTo({
        left: currentScroll + slideWidth,
        behavior: 'smooth',
      });
    }
  };
  return (
    <Modal onClose={onClose} open={open}>
      <div className={clsx(styles['modal__content'])}>
        <div className={styles['sideSlider']}>
          {showScrollButtons && (
            <button className={styles['prev']} onClick={() => scroll('prev')}>
              &lt;
            </button>
          )}
          <div className={clsx(styles['sideSlides'])} ref={sliderRef}>
            {(downloadLinks || []).map((item, index) => (
              <div className={styles['sideSlide']} key={index}>
                <div className={clsx(styles['slideUp__content__list'])}>
                  <ImageDownloadCard
                    imageUrl={item.url ?? ''}
                    mediaType={item.media_type ?? 'IMAGE'}
                  />
                </div>
              </div>
            ))}
          </div>
          {showScrollButtons && (
            <button className={styles['next']} onClick={() => scroll('next')}>
              &gt;
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};
