import clsx from 'clsx';
import React from 'react';
import styles from './AttributeFilterButton.module.css';
import { AttributeFilterType, useMapDisplayContext } from '../MapDisplayContext';
import { useTranslation } from 'react-i18next';
import { getFilteredPins } from '../util';
import { ReduxState } from 'ducks';
import { smoothMoveCamera } from '../smoothMoveCamera';
import { useSelector } from 'react-redux';

interface AttributeFilterButtonProps {
  action: AttributeFilterType;
}

const AttributeFilterButton: React.FC<AttributeFilterButtonProps> = ({ action }) => {
  const { t } = useTranslation();
  const { filters, setFilters } = useMapDisplayContext();
  const selected = filters.attributes?.includes(action);
  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);
  const { googleMap } = useMapDisplayContext();

  const onClick = () => {
    const newFilters = {
      ...filters,
      attributes: selected
        ? filters.attributes?.filter((a) => a !== action)
        : [...filters.attributes, action],
    };

    // Recenter the map based on pins that match the new filters.
    const filteredPins = getFilteredPins(digitalMap?.pins ?? [], newFilters, '');

    const bounds = new google.maps.LatLngBounds();
    filteredPins.forEach((pin) => {
      if (pin.location) {
        bounds.extend(new google.maps.LatLng(pin.location.latitude, pin.location.longitude));
      }
    });
    const newCenter = bounds.getCenter();

    smoothMoveCamera(googleMap as any, {
      center: {
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      },
      zoom: digitalMap?.default_map_zoom ?? 15,
    });

    setFilters(newFilters);
  };

  return (
    <button className={clsx(styles.button, selected && styles.selected)} onClick={onClick}>
      {action === 'hasTickets' ? (
        <svg
          width="19"
          height="17"
          viewBox="0 0 19 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 14.0042C1 14.8303 1.60781 15.5 2.35757 15.5H16.1879C16.9375 15.5 17.5455 14.8303 17.5455 14.0042V11.2523C16.4454 10.9238 15.6363 9.81597 15.6363 8.49999C15.6363 7.18401 16.4454 6.07623 17.5455 5.74768V2.99582C17.5455 2.16971 16.9375 1.5 16.1879 1.5H2.35757C1.60781 1.5 1 2.16971 1 2.99582V5.74144C2.11078 6.06202 2.93029 7.17556 2.93029 8.49999C2.93029 9.82442 2.11078 10.938 1 11.2585V14.0042Z"
            stroke="#393D3F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.1351 1.51172V3.96581"
            stroke="#393D3F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.1351 7.27344V9.72752"
            stroke="#393D3F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.1351 13.043V15.497"
            stroke="#393D3F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="11"
          height="15"
          viewBox="0 0 11 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="phone-mobile-phone--android-phone-mobile-device-smartphone-iphone">
            <path
              id="Vector"
              d="M8.96143 1H1.96143C1.40915 1 0.961426 1.44771 0.961426 2V13C0.961426 13.5523 1.40915 14 1.96143 14H8.96143C9.51373 14 9.96143 13.5523 9.96143 13V2C9.96143 1.44771 9.51373 1 8.96143 1Z"
              stroke="#393D3F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M4.96143 11.5H5.96143"
              stroke="#393D3F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      )}
      <span className={styles.buttonText}>
        {action === 'hasTickets' ? t('eTickets') : t('Mobile Order')}
      </span>
    </button>
  );
};

export default AttributeFilterButton;
