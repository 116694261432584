import * as React from 'react';
import { createPortal } from 'react-dom';
import { useGoogleMapsMarkerLibrary } from './useGoogleMapsLibrary';

type LatLngLiteral = google.maps.LatLngLiteral;
type Map = google.maps.Map;

interface MarkerProps {
  map: Map | null;
  position: LatLngLiteral;
  children: React.ReactNode;
  onClick: (e: Event) => void;
  zIndex: number;
}

export const AdvancedMarker = React.forwardRef<
  google.maps.marker.AdvancedMarkerElement | null,
  MarkerProps
>(({ map, position, children, onClick, zIndex }, ref) => {
  const [isInitialized, setIsInitialized] = React.useState(false);
  const marker = React.useRef<google.maps.marker.AdvancedMarkerElement | null>(null);
  const contentContainer = React.useRef<HTMLDivElement | null>(null);

  const markerLib = useGoogleMapsMarkerLibrary();

  React.useImperativeHandle(ref, () => marker.current, [isInitialized]);

  React.useEffect(() => {
    if (!markerLib || marker.current) return;

    const newMarker = new markerLib.AdvancedMarkerElement();

    marker.current = newMarker;

    // create the container for marker content if there are children
    let contentElement: HTMLDivElement | null = null;
    contentElement = document.createElement('div');

    newMarker.content = contentElement;
    contentContainer.current = contentElement;

    setIsInitialized(true);
  }, [children, map, markerLib]);

  React.useEffect(() => {
    if (marker.current) {
      marker.current.zIndex = zIndex;
    }
  }, [zIndex, isInitialized]);
  React.useEffect(() => {
    if (marker.current) {
      marker.current.position = position;
    }
  }, [position, isInitialized]);
  React.useEffect(() => {
    if (marker.current) {
      marker.current.addListener('click', ({ domEvent }: { domEvent: Event }) => onClick(domEvent));
    }
  }, [onClick, isInitialized]);
  React.useEffect(() => {
    if (marker.current) {
      marker.current.map = map;
    }
  }, [map, isInitialized]);

  if (!contentContainer.current) {
    return null;
  }

  return createPortal(children, contentContainer.current);
});
