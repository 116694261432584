import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../Components/PageContainer';
import styles from './FilterPage.module.css';
import clsx from 'clsx';
import { useActiveRestaurant } from '../useActiveRestaurant';
import { MenuFilterState, getDefaultMenuFilters } from '../util';
import { AnimatedButton } from 'components/Map/AnimatedButton';
import { PriceRangeSlider } from './PriceRangeSlider';

interface FilterPageProps {
  onBackClick: () => void;
  filters: MenuFilterState;
  onFiltersChange: (filters: MenuFilterState) => void;
}

export const FilterPage = ({ onBackClick, filters, onFiltersChange }: FilterPageProps) => {
  const { t } = useTranslation();
  const restaurant = useActiveRestaurant();

  const tagOptions = restaurant?.menu?.menu_items?.map((item) => item.tags)?.flat() ?? [];

  return (
    <PageContainer
      onBackClick={onBackClick}
      headerContent={<h1>{t('Filters')}</h1>}
      style={{ zIndex: 11 }}
    >
      <div className={styles['inner-container']}>
        <div className={styles['header']}>{t('Tags')}</div>
        <div className={styles['tag-container']}>
          {tagOptions.map((tag) => (
            <div
              key={tag}
              className={clsx(styles['tag'], filters.tags.includes(tag) && styles['active'])}
              onClick={() => {
                onFiltersChange({
                  ...filters,
                  tags: filters.tags.includes(tag)
                    ? filters.tags.filter((t) => t !== tag)
                    : [...filters.tags, tag],
                });
              }}
            >
              {tag}
            </div>
          ))}
        </div>
        <div className={styles['header']}>{t('Price')}</div>
        <PriceRangeSlider filters={filters} onFiltersChange={onFiltersChange} />
      </div>

      <div className={styles['actions']}>
        <AnimatedButton
          className={styles['reset-btn']}
          clickedClassName={styles['clicked']}
          onClick={() => {
            onFiltersChange({
              ...getDefaultMenuFilters(restaurant?.menu ?? null),
              searchQuery: filters.searchQuery,
            });
          }}
        >
          {t('Reset All')}
        </AnimatedButton>
        <AnimatedButton
          className={styles['apply-btn']}
          clickedClassName={styles['clicked']}
          onClick={onBackClick}
        >
          {t('Apply')}
        </AnimatedButton>
      </div>
    </PageContainer>
  );
};
