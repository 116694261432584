import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LoggedInMenu.module.css';
import { Profile } from './MyAccount/Profile';
import { Notifications } from './Notifications/Notifications';
import { PaymentSettings } from './PaymentSettings/PaymentSettings';
import { Support } from './Support';
import { Page } from './Page';
import { AccountPage } from './MyAccount';

interface Props {
  activePage: AccountPage;
  setActivePage: (page: AccountPage) => void;
}

export const LoggedInMenu = ({ activePage, setActivePage }: Props) => {
  const { t } = useTranslation();
  switch (activePage) {
    case 'PROFILE':
      return <Profile onBackClick={() => setActivePage('')} />;
    case 'NOTIFICATIONS':
      return <Notifications onBackClick={() => setActivePage('')} />;
    case 'PAYMENT_SETTINGS':
      return <PaymentSettings onBackClick={() => setActivePage('')} />;
    case 'SUPPORT':
      return <Support onBackClick={() => setActivePage('')} />;
    default:
      return (
        <Page title={t('My Account')}>
          <div className={styles['menu']}>
            <div className={styles['menu-item']} onClick={() => setActivePage('PROFILE')}>
              <div className={styles['content']}>
                <img src="/static/images/ic_user_circle.svg" className={styles['menu-icon']} />
                <div>{t('Profile')}</div>
              </div>
              <img src="/static/images/ic_arrow_right.svg" className={styles['arrow-icon']} />
            </div>
            <div className={styles['divider']} />
            <div className={styles['menu-item']} onClick={() => setActivePage('PAYMENT_SETTINGS')}>
              <div className={styles['content']}>
                <img src="/static/images/ic_credit_card.svg" className={styles['menu-icon']} />
                <div>{t('Payment Settings')}</div>
              </div>
              <img src="/static/images/ic_arrow_right.svg" className={styles['arrow-icon']} />
            </div>
            <div className={styles['divider']} />
          </div>
        </Page>
      );
  }
};
