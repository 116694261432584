import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from 'lodash';

import { ReduxState } from 'ducks';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { useTranslation } from 'react-i18next';
import { useVisitorIdentifier, VisitorIdentifier } from 'hooks/useVisitorIdentifier';
import { fetchDigitalGuidanceStampRallyByVisitorIdentifier } from 'ducks/universal/digitalGuidanceStampRally';
import { useRouter } from 'next/router';

export const StampRallyFetcher = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { apiKey } = React.useContext(ApiKeyContext);
  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);
  const supplierId = useSelector((state: ReduxState) => state.server.settings.all.supplier_id);
  const visitorIdentifier = useVisitorIdentifier();
  const stringifiedVisitorIdentifier = JSON.stringify(visitorIdentifier);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchStampRallies = React.useCallback(
    throttle(
      (
        apiKey: string,
        stampRallyId: string,
        visitorIdentifier: VisitorIdentifier,
        language: string,
        supplierId: string
      ) =>
        dispatch(
          fetchDigitalGuidanceStampRallyByVisitorIdentifier(
            apiKey,
            stampRallyId,
            visitorIdentifier.reservationId,
            language,
            visitorIdentifier.trackingId,
            supplierId
          )
        ),
      5000,
      {
        leading: true,
      }
    ),
    [dispatch]
  );

  // Use this to force fetch stamp rallies on browser open
  // otherwise they are not fetched when browser is closed then reopened
  const router = useRouter();
  React.useEffect(() => {
    const parsedVisitorIdentifier = JSON.parse(stringifiedVisitorIdentifier) as VisitorIdentifier;
    debouncedFetchStampRallies(
      apiKey,
      digitalMap?.stamp_rally_id ?? '',
      parsedVisitorIdentifier,
      i18n.language,
      supplierId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  React.useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Refresh stamp rally data when page becomes visible; for example, when the
        // device is unlocked after being locked or browser is opened
        const parsedVisitorIdentifier = JSON.parse(
          stringifiedVisitorIdentifier
        ) as VisitorIdentifier;

        if (digitalMap?.stamp_rally_id && parsedVisitorIdentifier) {
          debouncedFetchStampRallies(
            apiKey,
            digitalMap.stamp_rally_id,
            parsedVisitorIdentifier,
            i18n.language,
            supplierId
          );
        }
      }
    };

    // Add event listener
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [
    apiKey,
    debouncedFetchStampRallies,
    i18n.language,
    digitalMap?.stamp_rally_id,
    stringifiedVisitorIdentifier,
    supplierId,
  ]);

  React.useEffect(() => {
    const parsedVisitorIdentifier = JSON.parse(stringifiedVisitorIdentifier) as VisitorIdentifier;

    if (digitalMap?.stamp_rally_id && parsedVisitorIdentifier) {
      debouncedFetchStampRallies(
        apiKey,
        digitalMap.stamp_rally_id,
        parsedVisitorIdentifier,
        i18n.language,
        supplierId
      );
    }
  }, [
    apiKey,
    debouncedFetchStampRallies,
    i18n.language,
    digitalMap?.stamp_rally_id,
    stringifiedVisitorIdentifier,
    supplierId,
  ]);

  return null;
};
