import clsx from 'clsx';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import 'react-phone-input-2/lib/style.css';
import { Page } from '../Page';
import styles from './Profile.module.css';
import { useCustomerToken } from 'hooks/useCustomerToken';
import { useDispatch, useSelector } from 'react-redux';
import { clearCustomer, updateCustomer } from 'ducks/client/customers';
import { Amplify } from 'aws-amplify';
import { ReduxState } from 'ducks';
import { signOut } from 'aws-amplify/auth';
import { CustomerContext } from 'contexts/CustomerContext';
import { getCommonRedirectUri } from 'lib/util/customer';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { ButtonLoader } from '../ButtonLoader';
import { AccountInfoForm } from './AccountInfoForm';
import {
  CustomerValues,
  getInitialValues,
  convertToUpdateCustomerInformationRequest,
} from './formValues';
import { toContentLanguage } from 'lib/util/toContentLanguage';

interface Props {
  onBackClick?: () => void;
}

export const Profile = ({ onBackClick }: Props) => {
  const { t, i18n } = useTranslation();
  const { apiKey } = React.useContext(ApiKeyContext);

  const { idProvider, accessToken, logout } = useCustomerToken();
  const { customer } = React.useContext(CustomerContext);

  const dispatch = useDispatch();
  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const customerLedgerSettings = settings.customer_ledger_settings;

  const customerFormFieldSet = customerLedgerSettings?.form_field_sets?.find(
    (formFieldSet) => formFieldSet.content_language === toContentLanguage(i18n.language)
  );

  const initialValues = React.useMemo(() => {
    if (!customer) {
      return undefined;
    }
    return getInitialValues(customer, customerFormFieldSet?.form_fields ?? []);
  }, [customer, customerFormFieldSet]);

  const handleLogout = async () => {
    if (idProvider === 'COGNITO') {
      const cognitoInfo = settings.customer_ledger_settings?.cognito;

      if (!cognitoInfo?.user_pool_id || !cognitoInfo?.client_id) {
        return;
      }

      Amplify.configure({
        Auth: {
          Cognito: {
            userPoolId: cognitoInfo.user_pool_id,
            userPoolClientId: cognitoInfo.client_id,
          },
        },
      });

      await signOut();
    } else if (idProvider === 'GOOGLE') {
      try {
        await fetch('https://accounts.google.com/o/oauth2/revoke', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `token=${accessToken}`,
        });
      } catch (error) {
        console.error(error);
      }
    }

    logout();
    dispatch(clearCustomer());
  };

  return (
    <Page title={t('Profile')} onBackClick={onBackClick}>
      <Form<CustomerValues>
        initialValues={initialValues}
        keepDirtyOnReinitialize
        onSubmit={async (values: CustomerValues) => {
          try {
            const patch = convertToUpdateCustomerInformationRequest(
              values,
              customerFormFieldSet?.form_fields ?? []
            );
            await dispatch(
              updateCustomer(
                apiKey,
                {
                  ...patch,
                  access_token: accessToken,
                  id_provider: idProvider ?? 'FACEBOOK',
                  redirect_uri: getCommonRedirectUri(),
                },
                i18n.language
              )
            );
          } catch (e) {
            console.error(e);
          }
        }}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles['form-container']}>
              <AccountInfoForm onLogout={handleLogout} />
              <button className={clsx(styles['btn'], styles['save'])} type="submit">
                {submitting ? <ButtonLoader /> : t('Save')}
              </button>
            </div>
          </form>
        )}
      </Form>
    </Page>
  );
};
