import * as React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './DesktopSearch.module.css';
import { useMapDisplayContext } from '../MapDisplayContext';

export const DesktopSearch = () => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const {
    displayMode,
    setFilters,
    filters,
    setSearchText,
    setDisplayMode,
    searchText,
    setActivePage,
    activePage,
  } = useMapDisplayContext();

  const [intermediateSearchText, setIntermediateSearchText] = React.useState<string>('');

  React.useEffect(() => {
    if (activePage !== 'MAP') {
      setIntermediateSearchText('');
    }
  }, [activePage]);

  const clearCategories = () =>
    setFilters({
      ...filters,
      categories: [],
    });

  return (
    <div className={styles['search-container']}>
      <div className={styles['search']}>
        {displayMode === 'DESKTOP_SEARCH_RESULTS' && (
          <svg
            onClick={() => {
              setSearchText('');
              setIntermediateSearchText('');
              setDisplayMode('BASE');
            }}
            className={styles['back-arrow']}
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="13"
            viewBox="0 0 17 13"
            fill="none"
          >
            <path
              d="M16 6.5L1 6.5"
              stroke="#393D3F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.8 1.70078L1 6.50078L5.8 11.3008"
              stroke="#393D3F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
        {displayMode === 'BASE' && <img src="/static/images/map/search.svg" alt="gift" />}

        <input
          ref={inputRef}
          type="text"
          placeholder={t('Search')}
          className={styles['placeholder']}
          onChange={(e) => setIntermediateSearchText(e.target.value)}
          value={intermediateSearchText}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.keyCode === 13) {
              event.preventDefault(); // Prevent the default action if needed
              clearCategories();
              setSearchText(intermediateSearchText);
              setDisplayMode('DESKTOP_SEARCH_RESULTS');
              setActivePage('MAP');
            }
          }}
        />

        {(searchText || intermediateSearchText) && (
          <svg
            onClick={() => {
              setIntermediateSearchText('');
              setSearchText('');
              setDisplayMode('BASE');
              inputRef.current?.focus();
            }}
            className={styles['close']}
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
          >
            <path
              d="M14 1L1 14"
              stroke="#393D3F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 1L14 14"
              stroke="#393D3F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
    </div>
  );
};
