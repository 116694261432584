export const Checkmark = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
      <path
        d="M1 6.57272L2.89 9.00268C2.95378 9.08555 3.0355 9.15291 3.12902 9.19971C3.22254 9.24644 3.32545 9.27144 3.43 9.27268C3.53287 9.27393 3.63472 9.25212 3.72813 9.20906C3.82153 9.16593 3.90416 9.10251 3.97 9.02345L10 1.72656"
        stroke="#393D3F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
