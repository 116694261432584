import clsx from 'clsx';
import Image from 'next/image';
import { Box } from '@mui/material';
import { isEqual } from 'lodash';
import * as React from 'react';
// import clsx from 'clsx';
import { useSelector } from 'react-redux';
import config from 'config';
import styles from './SingleCategorySelect.module.css';
import { ReduxState } from 'ducks';
import { MapDisplayContext, getDefaultFilters } from '../MapDisplayContext';
import { useTranslation } from 'react-i18next';
import AttributeFilterButton from './AttributeFilterButton';
import { getFilteredPins } from '../util';
import { smoothMoveCamera } from '../smoothMoveCamera';
import { AnimatedButton } from '../AnimatedButton';
import { usePinDisplayState } from '../usePinDisplayState';

export const SingleCategorySelect = () => {
  const { t } = useTranslation();

  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);
  const { googleMap, filters, setFilters, setShowFilterPane } = React.useContext(MapDisplayContext);
  const { setPinInfoDisplayState } = usePinDisplayState();

  const filterActive = !isEqual(
    {
      ...filters,
      categories: [],
      attributes: [],
    },
    getDefaultFilters()
  );

  const selectedCategoryLabel = !filters.categories?.length
    ? t('All')
    : filters.categories[0] === 'WAIT_TIME'
    ? t('Wait Time')
    : filters.categories[0] || '';
  const selectedCategoryIconUrl = !filters.categories?.length
    ? '/static/images/map/all_icon.svg'
    : filters.categories[0] === 'WAIT_TIME'
    ? '/static/images/map/all_icon.svg'
    : digitalMap?.pin_categories?.find((category) => category.label === selectedCategoryLabel)
        ?.icon_url || '';
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const categoryHasTickets = getFilteredPins(digitalMap?.pins ?? [], filters, '').some(
    (pin) => pin.associated_product_ids?.length > 0
  );
  const categoryHasMobileOrders = getFilteredPins(digitalMap?.pins ?? [], filters, '').some(
    (pin) => !!pin.online_order_restaurant_id
  );

  const setCategories = React.useCallback(
    (categories: string[]) => {
      const newFilters = { ...filters, categories, attributes: [] };
      setFilters(newFilters);

      const filteredPins = getFilteredPins(digitalMap?.pins ?? [], newFilters, '');

      const bounds = new google.maps.LatLngBounds();
      filteredPins.forEach((pin) => {
        if (pin.location) {
          bounds.extend(new google.maps.LatLng(pin.location.latitude, pin.location.longitude));
        }
      });
      const newCenter = bounds.getCenter();

      setPinInfoDisplayState({ pinKey: null, displayState: 'HIDDEN' });

      smoothMoveCamera(googleMap as any, {
        center: {
          lat: newCenter.lat(),
          lng: newCenter.lng(),
        },
        zoom: digitalMap?.default_map_zoom ?? 15,
      });
    },
    [googleMap, digitalMap, filters, setFilters, setPinInfoDisplayState]
  );

  // Effect to handle outside click for dropdown
  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (target instanceof Node && !dropdownRef?.current?.contains(target)) {
        setDropdownOpen(false);
      }
    };

    window.document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    window.document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      window.document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      window.document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, [dropdownOpen]);

  const categories = digitalMap?.pin_categories ?? [];

  const hasWaitTimes = digitalMap?.pins?.some((pin) => pin.show_wait_time);

  return (
    <div className={styles['category-bar-container']}>
      <div className={styles['category-bar']}>
        <AnimatedButton
          className={clsx(styles['filter'])}
          clickedClassName={styles['clicked']}
          onClick={() => {
            setShowFilterPane(true);
          }}
        >
          <img width="12px" height="12px" src="/static/images/map/filter.svg" />
          {filterActive && <div className={styles['filter-active-indicator']} />}
        </AnimatedButton>

        {categoryHasTickets && <AttributeFilterButton action="hasTickets" />}

        {!categoryHasTickets && categoryHasMobileOrders && (
          <AttributeFilterButton action="hasMobileOrders" />
        )}

        <nav className={styles.navigationMenu} ref={dropdownRef}>
          {!dropdownOpen && (
            <div className={styles.menuItem} onClick={() => setDropdownOpen(true)}>
              <Image
                priority
                width={15}
                height={15}
                src={selectedCategoryIconUrl}
                alt={selectedCategoryLabel}
              />
              <h2 className={styles.label}>{selectedCategoryLabel}</h2>

              <Image
                priority
                width={15}
                height={15}
                src="/static/images/map/down_chevron.svg"
                alt="down arrow"
              />
            </div>
          )}
          {dropdownOpen && (
            <>
              <div
                className={styles.menuItem}
                onClick={() => {
                  setDropdownOpen(false);

                  if (selectedCategoryLabel !== t('All')) {
                    setCategories([]);
                  }
                }}
              >
                <Image
                  priority
                  width={15}
                  height={15}
                  src="/static/images/map/all_icon.svg"
                  alt={t('All')}
                />
                <h2 className={styles.label}>{t('All')}</h2>

                <Box ml="auto">
                  <Image
                    priority
                    width={15}
                    height={15}
                    src="/static/images/map/down_chevron.svg"
                    alt="down arrow"
                  />
                </Box>
              </div>
              {config.enableWaitTimeCategory && hasWaitTimes && (
                <div
                  className={styles.menuItem}
                  onClick={() => {
                    setDropdownOpen(false);
                    if (selectedCategoryLabel !== t('Wait Time')) {
                      setCategories(['WAIT_TIME']);
                    }
                  }}
                >
                  <Image
                    width={15}
                    height={15}
                    src="/static/images/map/ic_time.svg"
                    alt={t('Wait Time')}
                  />
                  <h2 className={styles.label}>{t('Wait Time')}</h2>
                </div>
              )}
              {categories.map((category, index) => (
                <div
                  key={index}
                  className={styles.menuItem}
                  onClick={() => {
                    setDropdownOpen(false);
                    if (selectedCategoryLabel !== category.label) {
                      setCategories([category.label]);
                    }
                  }}
                >
                  <Image
                    priority
                    width={15}
                    height={15}
                    src={category.icon_url}
                    alt={category.label}
                  />
                  <h2 className={styles.label}>{category.label}</h2>
                </div>
              ))}
            </>
          )}
        </nav>
      </div>
    </div>
  );
};
