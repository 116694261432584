import _ from 'lodash';

import { currency } from 'lib/currency';
import { OrderItemOptionGroup } from './checkoutFormValues';
import { MenuItem, RestaurantMenu } from 'models/digitalMap';
import { WithTranslation } from 'react-i18next';

export interface MenuFilterState {
  tags: string[];
  searchQuery: string;
  priceRangeLow: string;
  priceRangeHigh: string;
}

export const optionGroupListsEqual = (
  groupList1: OrderItemOptionGroup[],
  groupList2: OrderItemOptionGroup[]
) => {
  if (groupList1.length !== groupList2.length) {
    return false;
  }

  const sortedGroupList1 = _.orderBy(groupList1, 'key');
  const sortedGroupList2 = _.orderBy(groupList2, 'key');

  return sortedGroupList1.every((group, index) => optionGroupEqual(group, sortedGroupList2[index]));
};

export const optionGroupEqual = (group1: OrderItemOptionGroup, group2: OrderItemOptionGroup) => {
  return (
    group1.key === group2.key &&
    _.isEqual([...group1.optionKeys].sort(), [...group2.optionKeys].sort())
  );
};

export const getDefaultPriceRange = (
  menu: RestaurantMenu | null
): { low: string; high: string } => {
  if (!menu) {
    return {
      low: '',
      high: '',
    };
  }

  const currencyCode = menu.menu_items[0].price.substring(0, 3);
  const step = getCurrencyStep(currencyCode);

  const priceRangeLow = menu.menu_items
    .map((item) => item.price)
    .reduce((min, price) => Math.min(min, currency(price).value), Infinity);
  const priceRangeHigh = menu.menu_items
    .map((item) => item.price)
    .reduce((max, price) => Math.max(max, currency(price).value), -Infinity);

  return {
    low: (Math.floor(priceRangeLow / step) * step).toString(),
    high: (Math.ceil(priceRangeHigh / step) * step).toString(),
  };
};

export const getCurrencyStep = (currencyCode: string) => {
  switch (currencyCode) {
    case 'JPY':
      return 100;
    default:
      return 1;
  }
};

export const getDefaultMenuFilters = (menu: RestaurantMenu | null): MenuFilterState => {
  if (!menu) {
    return {
      tags: [],
      searchQuery: '',
      priceRangeLow: '',
      priceRangeHigh: '',
    };
  }

  const priceRange = getDefaultPriceRange(menu);

  return {
    tags: [],
    searchQuery: '',
    priceRangeLow: priceRange.low,
    priceRangeHigh: priceRange.high,
  };
};

export const menuItemMatchesSearchQuery = (item: MenuItem, searchQuery: string) => {
  if (item.name.toLowerCase().includes(searchQuery.toLowerCase())) {
    return true;
  }

  if (item.description.toLowerCase().includes(searchQuery.toLowerCase())) {
    return true;
  }

  return false;
};

interface AllergenInfo {
  icon: string;
  backgroundColor: string;
  text: string;
}

export const getAllergenInfoMap = (t: WithTranslation['t']): Record<string, AllergenInfo> => {
  return {
    fish: {
      icon: '/static/images/map/fish.svg',
      backgroundColor: '#FFCAB180',
      text: t('May contain fish'),
    },
    nuts: {
      icon: '/static/images/map/nuts.svg',
      backgroundColor: '#8978784D',
      text: t('May contain nuts'),
    },
    alcohol: {
      icon: '/static/images/map/alcohol.svg',
      backgroundColor: '#BBF8FF',
      text: t('Contains alcohol'),
    },
    vegan: {
      icon: '/static/images/map/vegan.svg',
      backgroundColor: '#8AF2B480',
      text: t('Vegan'),
    },
    vegetarian: {
      icon: '/static/images/map/vegetarian.svg',
      backgroundColor: '#D9E77533',
      text: t('Vegetarian'),
    },
    ['gluten-free']: {
      icon: '/static/images/map/gluten_free.svg',
      backgroundColor: '#FBC84033',
      text: t('Gluten-Free'),
    },
  };
};
