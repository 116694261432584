import { AnimatedClickableDiv } from '../AnimatedClickableDiv';
import styles from './BackButton.module.css';

interface Props {
  onClick: () => void;
  headerContent?: React.ReactNode;
}

export const BackButton = ({ onClick, headerContent }: Props) => {
  return (
    <div className={styles['back-container']}>
      <AnimatedClickableDiv
        className={styles['back-arrow']}
        clickedClassName={styles['clicked']}
        onClick={onClick}
      >
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23 15.5H8"
            stroke="#393D3F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.4 9.10039L8 15.5004L14.4 21.9004"
            stroke="#393D3F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </AnimatedClickableDiv>
      {headerContent}
    </div>
  );
};
