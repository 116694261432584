import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { MapDisplayContext } from '../MapDisplayContext';
import { ReduxState } from 'ducks';
import { getFilteredPins } from '../util';

import styles from './DesktopSearchResults.module.css';
import { Result } from './Result';

export const DesktopSearchResults = () => {
  const { t } = useTranslation();
  const { filters, searchText } = React.useContext(MapDisplayContext);

  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);

  const filteredPins = getFilteredPins(digitalMap?.pins ?? [], filters, searchText);

  return (
    <div className={styles['results-container']}>
      <div>
        {filteredPins.length > 0 && (
          <div className={styles['results-summary']}>
            {t('Search results ({{count}})', { count: filteredPins.length })}
          </div>
        )}
        {!filteredPins.length && (
          <div className={styles['no-results-container']}>{t('Sorry, no results found')}</div>
        )}
        {filteredPins.map((pin) => (
          <div key={pin.index} className={styles['item']}>
            <Result pin={pin} />
          </div>
        ))}
      </div>
    </div>
  );
};
