import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import styles from './MyPage.module.css';

export const SelectComponent = ({ input, options, label, meta }: any) => {
  const { t } = useTranslation();
  return (
    <div>
      <label className={clsx(styles['select'])}>
        <select {...input}>
          <option value="">{label}</option>
          {options.map((option: any) => (
            <option key={option.key} value={option.key}>
              {option.text}
            </option>
          ))}
        </select>
      </label>
      {meta.touched && meta.error && (
        <p className={clsx(styles['message'], styles['message--err'])}>{t(`${meta.error}`)}</p>
      )}
    </div>
  );
};
