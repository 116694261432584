import React from 'react';
import styles from './NotificationPopup.module.css';

interface Props {
  categoryTitle: string;
  icon: string;
  mainTitle: string;
  description: string;
  onClick?: () => void;
}

export const NotificationPopup: React.FC<Props> = ({
  categoryTitle,
  icon,
  mainTitle,
  description,
  onClick,
}) => {
  return (
    <div className={styles.notificationSection} onClick={onClick}>
      <article className={styles.notificationCard}>
        <h2 className={styles.notificationLabel}>{categoryTitle}</h2>
        <div className={styles.notificationContent}>
          <img src={icon} alt="" className={styles.notificationIcon} />
          <div className={styles.notificationTextWrapper}>
            <h3 className={styles.notificationTitle}>{mainTitle}</h3>
            <p className={styles.notificationDescription}>{description}</p>
          </div>
        </div>
      </article>
    </div>
  );
};
