import * as React from 'react';
import clsx from 'clsx';

import config from 'config';
import { useSelector } from 'react-redux';
import styles from './Header.module.css';
import { ReduxState } from 'ducks';
import { useMapDisplayContext } from '../MapDisplayContext';
import { Menu } from './Menu';
import { HamburgerIcon } from './HamburgerIcon';
import { useTranslation } from 'react-i18next';
import { DesktopSearch } from './DesktopSearch';
import { NotificationsIcon } from './NotificationsIcon';
import { OrdersIcon } from './OrdersIcon';
import { usePinDisplayState } from '../usePinDisplayState';
import { LanguageSelect } from './LanguageSelect';

export const Header = () => {
  const {
    setDisplayMode,
    resetDisplay,
    menuExpanded,
    setMenuExpanded,
    activePage,
    setActivePage,
  } = useMapDisplayContext();
  const { t } = useTranslation();

  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);

  const settings = useSelector((state: ReduxState) => state.server.settings.all);

  const restaurantOrders = useSelector((state: ReduxState) => state.restaurantOrders.orders);

  const { activePinKey } = usePinDisplayState();

  const isMapTopPage = !activePinKey;

  const shouldShowMenuItem = (
    item: 'MAP' | 'MY_ACCOUNT' | 'TICKETS' | 'SCHEDULE' | 'STAMP_RALLY'
  ) => {
    const displaySettings = digitalMap?.display_settings;
    return (
      (displaySettings?.menu_items?.length ?? 0) === 0 || displaySettings?.menu_items.includes(item)
    );
  };

  return (
    <>
      <section className={clsx(styles['container'], menuExpanded && styles['expanded'])}>
        <div className={styles['main-content']}>
          {!menuExpanded &&
            (digitalMap?.seo_params?.title ? (
              isMapTopPage ? (
                <h1 className={styles['title']}>{digitalMap.seo_params.title}</h1>
              ) : (
                <div className={styles['title']}>{digitalMap.seo_params.title}</div>
              )
            ) : (
              <img
                src={settings.logo_url}
                alt="Logo"
                className={styles['logo']}
                onClick={() => {
                  resetDisplay();
                }}
              />
            ))}
          <aside className={styles['desktop-items']}>
            {shouldShowMenuItem('MAP') && (
              <div
                onClick={() => {
                  resetDisplay();
                  setActivePage('MAP');
                }}
                className={clsx(styles['desktop-item'], activePage === 'MAP' && styles['active'])}
              >
                {t('Map')}
              </div>
            )}
            {shouldShowMenuItem('MY_ACCOUNT') && (
              <div
                onClick={() => {
                  resetDisplay();
                  setActivePage('ACCOUNT');
                }}
                className={clsx(
                  styles['desktop-item'],
                  activePage === 'ACCOUNT' && styles['active']
                )}
              >
                {t('My Account')}
              </div>
            )}
            {shouldShowMenuItem('TICKETS') && (
              <div
                onClick={() => {
                  resetDisplay();
                  setActivePage('TICKETS');
                }}
                className={clsx(
                  styles['desktop-item'],
                  activePage === 'TICKETS' && styles['active']
                )}
              >
                {t('Tickets')}
              </div>
            )}
            {shouldShowMenuItem('SCHEDULE') && (
              <div
                onClick={() => {
                  resetDisplay();
                  setActivePage('SCHEDULE');
                }}
                className={clsx(
                  styles['desktop-item'],
                  activePage === 'SCHEDULE' && styles['active']
                )}
              >
                {t('Schedule')}
              </div>
            )}
          </aside>

          {(digitalMap?.display_settings?.language_menu_items?.length ?? 0) > 0 && (
            <div className={styles['desktop-only']}>
              <LanguageSelect />
            </div>
          )}
          {config.enableIAAPADemo && (
            <div className={styles['desktop-only']}>
              <NotificationsIcon
                onClick={() => {
                  resetDisplay();
                  setActivePage('NOTIFICATIONS');
                }}
              />
            </div>
          )}
          {config.enableIAAPADemo && restaurantOrders.length > 0 && (
            <div className={styles['desktop-only']}>
              <OrdersIcon
                onClick={() => {
                  resetDisplay();
                  setActivePage('ORDERS');
                }}
              />
            </div>
          )}
          <DesktopSearch />
          <div className={styles['mobile-icons']}>
            {!menuExpanded && (
              <>
                <img
                  onClick={() => {
                    resetDisplay();
                    setDisplayMode('SEARCH_INPUT');
                  }}
                  src="/static/images/map/search.svg"
                  alt="Sub icon 1"
                  className={styles['icon']}
                />
              </>
            )}
            {config.enableIAAPADemo && (
              <NotificationsIcon
                onClick={() => {
                  resetDisplay();
                  setActivePage('NOTIFICATIONS');
                }}
              />
            )}
            {config.enableIAAPADemo && restaurantOrders.length > 0 && (
              <OrdersIcon
                onClick={() => {
                  resetDisplay();
                  setActivePage('ORDERS');
                }}
              />
            )}
            <HamburgerIcon onClick={() => setMenuExpanded(!menuExpanded)} isCross={menuExpanded} />
          </div>
        </div>
        {menuExpanded && <Menu onClose={() => setMenuExpanded(false)} />}
      </section>
    </>
  );
};
