import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '../Components/PageContainer';
import { useMapDisplayContext } from '../MapDisplayContext';
import styles from './Notifications.module.css';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { MarketingAutomationNotificationCard } from './MarketingAutomationNotificationCard';

export function Notifications() {
  const { t } = useTranslation();
  const { setActivePage } = useMapDisplayContext();

  const notifications = useSelector(
    (state: ReduxState) => state.marketingAutomationCampaigns.displayed
  );
  const dedupedNotifications = notifications.filter(
    (notification, index, self) => index === self.findIndex((t) => t.id === notification.id)
  );

  return (
    <PageContainer onBackClick={() => setActivePage('MAP')}>
      <div className={styles['page-content']}>
        {!dedupedNotifications?.length && (
          <div className={styles['no-notifications']}>
            {t("You don't have any notifications yet. Please check back later.")}
          </div>
        )}
        {dedupedNotifications.map((notification) => (
          <MarketingAutomationNotificationCard key={notification.id} campaign={notification} />
        ))}
      </div>
    </PageContainer>
  );
}
