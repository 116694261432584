import * as React from 'react';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';
import { useCurrentPosition } from 'hooks/useCurrentPosition';

export const CurrentPositionProvider = ({ children }: { children: React.ReactNode }) => {
  const { currentPosition, currentPositionRef, updatedAt } = useCurrentPosition();
  return (
    <CurrentPositionContext.Provider value={{ currentPosition, currentPositionRef, updatedAt }}>
      {children}
    </CurrentPositionContext.Provider>
  );
};
