import * as React from 'react';
import clsx from 'clsx';

import styles from './FloorButton.module.css';

interface FloorButtonProps {
  selected: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

export const FloorButton: React.FC<FloorButtonProps> = ({ selected, children, onClick }) => {
  return (
    <button className={clsx(styles.button, selected && styles.selected)} onClick={onClick}>
      {children}
    </button>
  );
};
