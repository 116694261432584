import clsx from 'clsx';
import * as React from 'react';
import { BUTTON_CLICK_ANIMATION_DURATION } from './constants';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  clickedClassName: string;
};

export const AnimatedClickableDiv = ({ children, ...props }: Props) => {
  const [isClicked, setIsClicked] = React.useState(false);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsClicked(true);

    setTimeout(() => {
      setIsClicked(false);

      props.onClick?.(e);
    }, BUTTON_CLICK_ANIMATION_DURATION);
  };

  return (
    <div
      {...props}
      className={clsx(props.className, isClicked && props.clickedClassName)}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};
