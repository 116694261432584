import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './SchedulePane.module.css';
import { Today } from './Today';
import { Schedule } from './Schedule';

export const SchedulePane = () => {
  const [activeTab, setActiveTab] = React.useState<'TODAY' | 'SCHEDULE'>('TODAY');
  const { t } = useTranslation();

  return (
    <div className={clsx(styles['schedule'], styles['is-active'])}>
      <div className={styles['header-section']}>
        <h2 className={styles['title']}>{t('Schedule')}</h2>
        <div className={styles['tabs']}>
          <div
            className={clsx(styles['tab'], activeTab === 'TODAY' && styles['active'])}
            onClick={() => setActiveTab('TODAY')}
          >
            <h3 className={styles['tab-text']}>{t('Today')}</h3>
            <div className={styles['underline']} />
          </div>
          <div
            className={clsx(styles['tab'], activeTab === 'SCHEDULE' && styles['active'])}
            onClick={() => setActiveTab('SCHEDULE')}
          >
            <h3 className={styles['tab-text']}>{t('Schedule')}</h3>
            <div className={styles['underline']} />
          </div>
        </div>
      </div>
      {activeTab === 'TODAY' && <Today />}
      {activeTab === 'SCHEDULE' && <Schedule />}
    </div>
  );
};
