import { useTranslation } from 'react-i18next';
import { Page } from './Page';
import styles from './Support.module.css';

interface Props {
  onBackClick: () => void;
}

export const Support = ({ onBackClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Page title={t('Support')} onBackClick={onBackClick}>
      <div className={styles['content']}>{t('TODO: Support content')}</div>
    </Page>
  );
};
