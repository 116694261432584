import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import dynamic from 'next/dynamic';
import { useSpring, animated, config as reactSpringConfig } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';

import config from 'config';
import { Props as EditorProps } from 'components/Editor/Editor';
import styles from './PinDetails.module.css';
import { PinHeaderActions } from '../PinHeaderActions/PinHeaderActions';
import { DigitalMapPin } from 'models/digitalMap';
import { useTranslation } from 'react-i18next';
import { findActivePinSchedule, formattedTimeOfDay, getDistanceText } from '../util';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { ProductSummary } from 'models/product';
import { MapDisplayContext } from '../MapDisplayContext';
import Image from 'next/image';
import { Description } from './Description';
import { Products } from './Products';
import { Reviews } from './Reviews';
import Stars from '../Stars/Stars';
import { SwipeHandle } from '../SwipeHandle';
import { Button } from '../Components/Button';
import { useInView } from 'react-intersection-observer';
import { AnimatedClickableDiv } from '../AnimatedClickableDiv';
import PopularTimes from './PopularTimes/PopularTimes';
import { CurrentPositionContext } from 'contexts/CurrentPositionContext';
import { logCustomerEventWithNoSideEffects } from 'ducks/client/customerEvents';
import { useExistingReservation } from '../useExistingReservation';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { useCustomerEventLoggingEnabled } from 'hooks/useCustomerEventLoggingEnabled';
import { useRouter } from 'next/router';
import { usePinDisplayState } from '../usePinDisplayState';
import { AnimatedButton } from '../AnimatedButton';

const Editor = dynamic<EditorProps>(
  () => import('components/Editor/Editor').then((mod) => mod.Editor) as any,
  {
    ssr: false,
  }
);

export interface Props {
  pin: DigitalMapPin;
  onClose: () => void;
}

export const PinDetails = ({ onClose, pin }: Props) => {
  const { t, i18n } = useTranslation();
  const scrollableDivRef = React.useRef<HTMLDivElement>(null);
  const [productCardsRef, productCardsInView] = useInView();
  const reservation = useExistingReservation();
  const customerEventLoggingEnabled = useCustomerEventLoggingEnabled();
  const { currentPosition, currentPositionRef } = React.useContext(CurrentPositionContext);
  const visitorToken = useSelector((state: ReduxState) => state.tracking.visitorToken);
  const customerId = useSelector((state: ReduxState) => state.customer.customer?.id);
  const { apiKey } = React.useContext(ApiKeyContext);
  const router = useRouter();
  const pathname = router.asPath;

  const { pinInfoDisplayStatus, setPinInfoDisplayState } = usePinDisplayState();
  const { setActiveRestaurantId, setActiveOpenTableRestaurantId } = React.useContext(
    MapDisplayContext
  );
  const hasSentEvent = React.useRef(false);

  const pinDetailsExpanded = pinInfoDisplayStatus === 'DETAILS';

  const [springs, api] = useSpring(
    () => ({
      from: { y: 400 },
      to: { y: 0 },
      config: {
        ...reactSpringConfig.gentle,
        clamp: true,
      },
      immediate: pinDetailsExpanded,
    }),
    []
  );

  React.useEffect(() => {
    if (customerEventLoggingEnabled) {
      if (!apiKey || !visitorToken) {
        return;
      }

      if (hasSentEvent.current) {
        return;
      }

      hasSentEvent.current = true;

      if (pinDetailsExpanded) {
        logCustomerEventWithNoSideEffects({
          apiKey,
          eventType: 'pin-details',
          visitorToken,
          customerId: customerId ?? '',
          reservationId: reservation?.id || '',
          metadata: JSON.stringify({
            page: pathname,
            lat: currentPositionRef.current?.lat(),
            lng: currentPositionRef.current?.lng(),
            site: 'onsite',
            pin_key: pin.key,
          }),
        });
      } else {
        logCustomerEventWithNoSideEffects({
          apiKey,
          eventType: 'pin-view',
          visitorToken,
          customerId: customerId ?? '',
          reservationId: reservation?.id || '',
          metadata: JSON.stringify({
            page: pathname,
            lat: currentPositionRef.current?.lat(),
            lng: currentPositionRef.current?.lng(),
            site: 'onsite',
            pin_key: pin.key,
          }),
        });
      }
    }
  }, [
    pathname,
    customerEventLoggingEnabled,
    apiKey,
    visitorToken,
    customerId,
    currentPositionRef,
    reservation?.id,
    pin.key,
    pinDetailsExpanded,
  ]);

  const handleClose = React.useCallback(() => {
    api.set({ y: window.innerHeight });
    hasSentEvent.current = false;
    onClose();
  }, [onClose, api]);

  const bindMainContainer = useDrag(
    ({ swipe: [, swipeY] }) => {
      if (!pinDetailsExpanded) {
        const shouldExpand = swipeY < 0;

        if (shouldExpand) {
          setPinInfoDisplayState({ pinKey: pin.key, displayState: 'DETAILS' });
          return;
        }
      }

      const shouldHide =
        swipeY > 0 && (!pinDetailsExpanded || scrollableDivRef.current?.scrollTop === 0);

      if (shouldHide) {
        handleClose();
        return;
      }
    },
    { swipe: { distance: [0, 10], velocity: [0, 0.1], duration: 250 } }
  );

  const now = moment();
  const openHoursForToday = findActivePinSchedule(pin.open_hour_schedules, now);
  const openNow =
    openHoursForToday &&
    moment(openHoursForToday?.start_time_local, 'HH:mm').isSameOrBefore(now) &&
    moment(openHoursForToday?.end_time_local, 'HH:mm').isSameOrAfter(now);
  const showtimesForToday = findActivePinSchedule(pin.showtime_schedules, now)?.showtimes ?? [];

  const productSummaries = useSelector((state: ReduxState) => state.server.productSummaries.all);
  const bookableProducts: ProductSummary[] = [];

  pin.associated_product_ids?.forEach((id) => {
    const productSummary = productSummaries.find((product) => product.id === id);

    if (productSummary) {
      bookableProducts.push(productSummary);
    }
  });

  let reviewRating = null;
  let reviewCount = 0;
  if (pin.associated_product_ids?.length) {
    const product = productSummaries.find((p) => p.id === pin.associated_product_ids[0]);
    if (product?.review_count) {
      reviewRating = product?.review_rating ?? 0;
      reviewCount = product?.review_count ?? 0;
    }
  }
  let distanceInMeters = null;
  if (currentPosition) {
    distanceInMeters = window.google.maps.geometry.spherical.computeDistanceBetween(
      currentPosition,
      new google.maps.LatLng({
        lat: pin?.location?.latitude ?? 0,
        lng: pin?.location?.longitude ?? 0,
      })
    );
  }
  const hasReviews = pin.associated_product_ids?.some((id) => {
    const product = productSummaries.find((p) => p.id === id);
    return (product?.review_count ?? 0) > 0;
  });
  const editorJsDescription = pin.free_form_content_items?.find(
    (item) => item.content_tag === 'DESCRIPTION'
  )?.data;
  const parsedDescriptionContent = React.useMemo(
    () => (editorJsDescription ? JSON.parse(editorJsDescription) : null),
    [editorJsDescription]
  );

  const structuredInfo: {
    title: string;
    values: string[];
  }[] = [];

  if (pin.time_data_type === 'TIME_RANGE' && openHoursForToday) {
    structuredInfo.push({
      title: t('Open Hours'),
      values: [
        ` ${formattedTimeOfDay(openHoursForToday?.start_time_local)} - ${formattedTimeOfDay(
          openHoursForToday?.end_time_local
        )}`,
      ],
    });
  }
  if (pin.time_data_type === 'SHOWTIMES' && showtimesForToday?.length) {
    structuredInfo.push({
      title: t('Times'),
      values: showtimesForToday?.map((time) => formattedTimeOfDay(time)) || [],
    });
  }
  pin.structured_info_items?.forEach((item) => {
    structuredInfo.push({
      title: item.info_category_key,
      values: item.values,
    });
  });

  return (
    <animated.div
      {...bindMainContainer()}
      className={clsx(
        styles['container'],
        pinDetailsExpanded ? styles['details'] : styles['summary'],
        pin.media_items?.length > 0 ? undefined : styles['no-media']
      )}
      style={{ ...springs }}
    >
      {!pinDetailsExpanded && (
        <>
          <SwipeHandle
            onClick={() => setPinInfoDisplayState({ pinKey: pin.key, displayState: 'DETAILS' })}
          />
          <div
            className={styles['header-section']}
            onClick={() => setPinInfoDisplayState({ pinKey: pin.key, displayState: 'DETAILS' })}
          >
            {pin.media_items?.length > 0 && (
              <div className={clsx(styles['media-carousel'], styles['summary'])}>
                {pin.media_items?.map((mediaItem) =>
                  mediaItem.type === 'IMAGE' ? (
                    <Image
                      priority
                      alt=""
                      width={328}
                      height={250}
                      className={clsx(styles['img'], styles['summary'])}
                      key={mediaItem.url}
                      src={mediaItem.url}
                    />
                  ) : (
                    <div className={clsx(styles['img'], styles['summary'])}>
                      <iframe
                        width="100%"
                        height="100%"
                        src={`${mediaItem.url}?enablejsapi=1`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                      />
                    </div>
                  )
                )}
              </div>
            )}
            <h1 className={clsx(styles['title'], styles['summary'])}>{pin.title}</h1>
            <div className={styles['action-container']}>
              <PinHeaderActions pin={pin} />
            </div>
          </div>
          {pin.description && <Description description={pin.description} />}
        </>
      )}
      {pinDetailsExpanded && (
        <div
          ref={scrollableDivRef}
          className={clsx(styles['inner-container'], styles['details'])}
          style={
            /* If floating button is active, add padding to the bottom of the container to make room for the button */
            (bookableProducts.length > 0 && !productCardsInView) ||
            (bookableProducts.length === 0 && pin.online_order_restaurant_id)
              ? {
                  paddingBottom: '84px',
                }
              : undefined
          }
        >
          <div className={styles['header-section']}>
            <div className={styles['back-container']}>
              <AnimatedClickableDiv
                className={styles['back-arrow']}
                clickedClassName={styles['clicked']}
                onClick={() => {
                  handleClose();
                }}
              >
                <svg
                  width="31"
                  height="31"
                  viewBox="0 0 31 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23 15.5H8"
                    stroke="#393D3F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.4 9.10039L8 15.5004L14.4 21.9004"
                    stroke="#393D3F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </AnimatedClickableDiv>
            </div>
            <div className={clsx(styles['media-carousel'], styles['details'])}>
              {pin.media_items?.map((mediaItem) =>
                mediaItem.type === 'IMAGE' ? (
                  <Image
                    priority
                    alt=""
                    width={328}
                    height={250}
                    className={clsx(styles['img'], styles['details'])}
                    key={mediaItem.url}
                    src={mediaItem.url}
                  />
                ) : (
                  <div className={clsx(styles['img'], styles['details'])}>
                    <iframe
                      width="100%"
                      height="100%"
                      src={`${mediaItem.url}?enablejsapi=1`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen={true}
                    />
                  </div>
                )
              )}
            </div>
            <h1 className={clsx(styles['title'], styles['details'])}>{pin.title}</h1>
            <div className={styles['rating-block']}>
              {reviewRating != null && (
                <>
                  <div>{reviewRating.toFixed(1)}</div>
                  <Stars rating={reviewRating} />
                  <div className={styles['rating-count']}>{`(${reviewCount})`}</div>
                </>
              )}
              {openHoursForToday?.start_time_local && (
                <>
                  {reviewRating != null && '·'}
                  {openNow ? (
                    <div className={clsx(styles['open-status'], styles['open'])}>{t('Open')}</div>
                  ) : (
                    <div className={clsx(styles['open-status'], styles['closed'])}>
                      {t('Closed')}
                    </div>
                  )}
                </>
              )}
              {distanceInMeters && (
                <>
                  {(reviewRating != null || openHoursForToday?.start_time_local) && '·'}
                  <span>{getDistanceText(distanceInMeters, i18n.language, t)}</span>
                </>
              )}
            </div>
            <div className={styles['action-container']}>
              <PinHeaderActions pin={pin} />
            </div>
          </div>
          {pin.description && <Description description={pin.description} />}
          {config.enableIAAPADemo && pin.online_order_restaurant_id && (
            <div className={styles['order-button-container']}>
              <Button onClick={() => setActiveRestaurantId(pin.online_order_restaurant_id)}>
                {t('ORDER')}
              </Button>
            </div>
          )}
          {pin.open_table_restaurant_id && (
            <div className={styles['order-button-container']}>
              <Button onClick={() => setActiveOpenTableRestaurantId(pin.open_table_restaurant_id)}>
                {t('BOOK')}
              </Button>
            </div>
          )}
          <div className={styles['structured-section']}>
            <div className={styles['column']}>
              {structuredInfo?.map((info) => (
                <div key={info.title} className={styles['structured-item']}>
                  <h2 className={styles['structured-title']}>{info.title}</h2>
                  {info.values.map((val) => (
                    <h3 key={val} className={styles['structured-value']}>
                      {val}
                    </h3>
                  ))}
                </div>
              ))}
            </div>
          </div>
          {pin.show_wait_time && config.enableWaitTimeForecast && <PopularTimes pin={pin} />}
          {bookableProducts.length > 0 && (
            <Products ref={productCardsRef} products={bookableProducts} />
          )}
          {editorJsDescription && (
            <div className={styles['editor-js']}>
              <Editor data={parsedDescriptionContent} minHeight={0} optimizeImages={true} />
            </div>
          )}
          {hasReviews && <Reviews pin={pin} />}
          {bookableProducts.length > 0 && (
            <div
              className={clsx(
                styles['floating-action-button'],
                !productCardsInView && styles['is-active']
              )}
            >
              <AnimatedButton
                clickedClassName={styles['clicked']}
                onClick={() => {
                  document.querySelector('#bookable-products')?.scrollIntoView({
                    behavior: 'smooth',
                  });
                }}
              >
                {t('BOOK')}
              </AnimatedButton>
            </div>
          )}
          {bookableProducts.length === 0 && pin.online_order_restaurant_id && (
            <div className={clsx(styles['floating-action-button'], styles['is-active'])}>
              <AnimatedButton
                clickedClassName={styles['clicked']}
                onClick={() => setActiveRestaurantId(pin.online_order_restaurant_id)}
              >
                {t('Order')}
              </AnimatedButton>
            </div>
          )}
        </div>
      )}
    </animated.div>
  );
};
