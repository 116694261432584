import { useTranslation } from 'react-i18next';
import { Page } from '../Page';
import { CustomerCardRegisterForm } from './CustomerCardRegisterForm';

interface Props {
  onBackClick: () => void;
}

export const EditPaymentMethod = ({ onBackClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Page title={t('Edit Payment Method')} onBackClick={onBackClick}>
      <CustomerCardRegisterForm handleBack={onBackClick} onMessageChange={() => {}} />
    </Page>
  );
};
