import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { NotificationPopup } from './NotificationPopup/NotificationPopup';
import {
  selectwaitTimeNotificationPinKeys,
  toggleWaitTimeNotificationPinKey,
} from 'ducks/client/mapUserSettings';
import { selectDigitalMap } from 'ducks/universal/digitalMap';
import { usePinDisplayState } from './usePinDisplayState';

export const WaitTimeUpdateNotifier: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setPinInfoDisplayState } = usePinDisplayState();

  const [activeNotificationPinKey, setActiveNotificationPinKey] = React.useState<string | null>(
    null
  );
  const waitTimeNotificationPinKeys = useSelector(selectwaitTimeNotificationPinKeys);
  const digitalMap = useSelector(selectDigitalMap);

  React.useEffect(() => {
    if (waitTimeNotificationPinKeys.length > 0) {
      for (const pin of digitalMap?.pins ?? []) {
        if (
          waitTimeNotificationPinKeys.includes(pin.key) &&
          pin.show_wait_time &&
          !pin.is_closed &&
          pin.wait_time < 30
        ) {
          setActiveNotificationPinKey(pin.key);
          dispatch(toggleWaitTimeNotificationPinKey(pin.key));
          break;
        }
      }
    }
  }, [waitTimeNotificationPinKeys, digitalMap]);

  if (!activeNotificationPinKey) {
    return null;
  }

  const notificationPin = digitalMap?.pins?.find((pin) => pin.key === activeNotificationPinKey);

  return (
    <NotificationPopup
      categoryTitle="Wait Time Update"
      icon="https://cdn.builder.io/api/v1/image/assets/TEMP/2ddc76fe036ddfa64ac58ac13d4c520f594619c179191b9351648b50904728a1?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818"
      mainTitle={t('{{attractionName}} wait time is only {{waitTime}} minutes', {
        attractionName: notificationPin?.title,
        waitTime: notificationPin?.wait_time,
      })}
      description={t('Come enjoy {{attractionName}} while the wait is shorter!', {
        attractionName: notificationPin?.title,
      })}
      onClick={() => {
        setPinInfoDisplayState({ pinKey: activeNotificationPinKey, displayState: 'DETAILS' });

        setActiveNotificationPinKey(null);
      }}
    />
  );
};
