import { ReduxState } from 'ducks';
import Head from 'next/head';
import { useSelector } from 'react-redux';
import { usePinDisplayState } from './usePinDisplayState';

export const MapHead = () => {
  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);

  const { activePinKey, pinInfoDisplayStatus } = usePinDisplayState();

  const activePin = digitalMap?.pins.find(
    (pin) => pin.key === activePinKey && pinInfoDisplayStatus !== 'HIDDEN'
  );

  const title = activePin ? activePin.title : digitalMap?.seo_params?.title;
  const metaDescription = activePin
    ? activePin.description
    : digitalMap?.seo_params?.meta_description;
  const imageUrl = activePin
    ? activePin.media_items.find((mediaItem) => mediaItem.type === 'IMAGE')?.url
    : digitalMap?.seo_params?.thumbnail_url;

  return (
    <Head>
      {title && <title>{title}</title>}
      {metaDescription && <meta name="description" content={metaDescription} />}
      {title && <meta property="og:title" content={title} />}
      <meta property="og:type" content="website" />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
    </Head>
  );
};
