import styles from './TextField.module.css';

interface Props {
  value: string;
  onChange: (newValue: string) => void;
  options: { value: string; text: string }[];
  label?: string;
}

export const Select = ({ value, onChange, options, label }: Props) => {
  return (
    <div>
      <div className={styles['label']}>{label}</div>
      <div className={styles['input-container']}>
        <select value={value} onChange={(e) => onChange(e.target.value)}>
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
