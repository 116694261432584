import * as React from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field, useForm, useFormState } from 'react-final-form';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import jaLocale from 'i18n-iso-countries/langs/ja.json';
import _ from 'lodash';

import config from 'config';
import styles from './MyPage.module.css';
import { composeValidators } from 'lib/util/composeValidators';
import { required as requiredValidator } from 'lib/forms/validate';
import { ApiKeyContext } from 'contexts/ApiKeyContext';

import { CustomerFormField } from 'models/settings';

import { CountrySelectComponent } from './CountrySelectComponent';

export const CustomerAddressInputForm = ({ formField }: { formField: CustomerFormField }) => {
  const required = formField.required === 'WHEN_SIGN_UP';

  const { values } = useFormState();

  const country = _.get(values, `formFieldResponses.${formField.key}.country`);

  return (
    <div className={clsx(styles['form__item'])}>
      <div className={clsx(styles['form__item__ttl'], required ? styles['required'] : null)}>
        {formField.field_name}
      </div>
      <div className={clsx(styles['form__item__body'])}>
        <CountryInputForm formField={formField} />

        {country === 'JP' && <JpAddressInputForm formField={formField} />}
        {country === 'US' && <UsAddressInputForm formField={formField} />}
        {country !== 'US' && country !== 'JP' && (
          <OtherCountryAddressInputForm formField={formField} />
        )}
      </div>
    </div>
  );
};

const CountryInputForm = ({ formField }: { formField: CustomerFormField }) => {
  const { t, i18n } = useTranslation();
  const required = formField.required === 'WHEN_SIGN_UP';
  const form = useForm();
  const validators = [];
  if (required) {
    validators.push(requiredValidator);
  }
  countries.registerLocale(enLocale);
  countries.registerLocale(jaLocale);

  const lang = i18n.language.split('-')[0];

  const countryObj = countries.getNames(lang, { select: 'official' });

  const countryOptions = Object.entries(countryObj)
    .map(([key, value]) => {
      return {
        key: key,
        text: value,
      };
    })
    .sort((a, b) => a.text.localeCompare(b.text));

  let reOrderedCountryOptions: { key: string; text: string }[] = [];
  if (lang === 'ja') {
    reOrderedCountryOptions = [
      { key: 'JP', text: countryObj.JP },
      ...countryOptions.filter((country) => country.key !== 'JP'),
    ];
  } else if (lang === 'en') {
    reOrderedCountryOptions = [
      { key: 'US', text: countryObj.US },
      ...countryOptions.filter((country) => country.key !== 'US'),
    ];
  } else {
    reOrderedCountryOptions = countryOptions;
  }

  React.useEffect(() => {
    form.change(`formFieldResponses.${formField.key}.country`, 'JP');
  }, []);

  return (
    <>
      <div className={clsx(styles['form__item__ttl'])}>{t('Country')}</div>
      <Field
        type="text"
        name={`formFieldResponses.${formField.key}.country`}
        validate={composeValidators(...validators)}
      >
        {({ input, meta }) => (
          <>
            <CountrySelectComponent
              input={input}
              meta={meta}
              options={reOrderedCountryOptions ?? []}
              label={t('Please Select')}
            />
          </>
        )}
      </Field>
    </>
  );
};

const JpAddressInputForm = ({ formField }: { formField: CustomerFormField }) => {
  const { t } = useTranslation();
  const required = formField.required === 'WHEN_SIGN_UP';
  const validators = [];
  if (required) {
    validators.push(requiredValidator);
  }

  const form = useForm();

  const normalizeJapanesePostalCode = (postalCode: string) => {
    return postalCode
      .replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      })
      .replace(/[^0-9]/g, '');
  };

  return (
    <>
      <Box mt={2}>
        <div className={clsx(styles['form__item__ttl'])}>{t('Postal Code')}</div>
        <Field
          type="text"
          name={`formFieldResponses.${formField.key}.postalCode`}
          validate={composeValidators(...validators)}
        >
          {({ input, meta }) => (
            <>
              <input
                type="text"
                className={clsx(styles['inputtext'])}
                value={input.value}
                onChange={async (val) => {
                  input.onChange(val);

                  const newValue = normalizeJapanesePostalCode(val.target.value);

                  if (newValue.length === 7) {
                    try {
                      const resp = await axios.get(
                        `https://postcode.teraren.com/postcodes/${newValue}.json`
                      );
                      form.change(
                        `formFieldResponses.${formField.key}.province`,
                        resp.data.prefecture
                      );
                      form.change(`formFieldResponses.${formField.key}.city`, resp.data.city);
                      form.change(
                        `formFieldResponses.${formField.key}.addressLine`,
                        resp.data.suburb
                      );

                      console.log(resp);
                    } catch (e) {
                      console.log(e);
                    }
                  }
                }}
              />
              {meta.touched && meta.error && (
                <p className={clsx(styles['message'], styles['message--err'])}>
                  {t(`${meta.error}`)}
                </p>
              )}
            </>
          )}
        </Field>
      </Box>

      <Box mt={2}>
        <div className={clsx(styles['form__item__ttl'])}>{t('Prefecture')}</div>
        <Field
          type="text"
          name={`formFieldResponses.${formField.key}.province`}
          validate={composeValidators(...validators)}
        >
          {({ input, meta }) => (
            <>
              <input type="text" className={clsx(styles['inputtext'])} {...input} />
              {meta.touched && meta.error && (
                <p className={clsx(styles['message'], styles['message--err'])}>
                  {t(`${meta.error}`)}
                </p>
              )}
            </>
          )}
        </Field>
      </Box>

      <Box mt={2}>
        <div className={clsx(styles['form__item__ttl'])}>{t('City, Ward, Town')}</div>
        <Field
          type="text"
          name={`formFieldResponses.${formField.key}.city`}
          validate={composeValidators(...validators)}
        >
          {({ input, meta }) => (
            <>
              <input type="text" className={clsx(styles['inputtext'])} {...input} />
              {meta.touched && meta.error && (
                <p className={clsx(styles['message'], styles['message--err'])}>
                  {t(`${meta.error}`)}
                </p>
              )}
            </>
          )}
        </Field>
      </Box>

      <Box mt={2}>
        <div className={clsx(styles['form__item__ttl'])}>{t('Street/House Number')}</div>
        <Field
          type="text"
          name={`formFieldResponses.${formField.key}.addressLine`}
          validate={composeValidators(...validators)}
        >
          {({ input, meta }) => (
            <>
              <input type="text" className={clsx(styles['inputtext'])} {...input} />
              {meta.touched && meta.error && (
                <p className={clsx(styles['message'], styles['message--err'])}>
                  {t(`${meta.error}`)}
                </p>
              )}
            </>
          )}
        </Field>
      </Box>
    </>
  );
};

const UsAddressInputForm = ({ formField }: { formField: CustomerFormField }) => {
  const { t } = useTranslation();
  const required = formField.required === 'WHEN_SIGN_UP';
  const validators = [];
  if (required) {
    validators.push(requiredValidator);
  }

  const { apiKey } = React.useContext(ApiKeyContext);
  const form = useForm();

  const normalizeUsZipCode = (postalCode: string) => {
    return postalCode
      .replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      })
      .replace(/[^0-9]/g, '');
  };

  return (
    <>
      <Box mt={2}>
        <div className={clsx(styles['form__item__ttl'])}>{t('Zip / Postal code')}</div>
        <Field
          type="text"
          name={`formFieldResponses.${formField.key}.postalCode`}
          validate={composeValidators(...validators)}
        >
          {({ input, meta }) => (
            <>
              <input
                type="text"
                className={clsx(styles['inputtext'])}
                value={input.value}
                onChange={async (val) => {
                  input.onChange(val);

                  const newValue = normalizeUsZipCode(val.target.value);

                  if (newValue.length === 5) {
                    try {
                      const resp = await axios.get(`${config.apiUrl}/citystate`, {
                        params: { zip_code: newValue },
                        headers: { 'x-api-key': apiKey },
                      });

                      form.change(`formFieldResponses.${formField.key}.province`, resp.data.state);
                      form.change(`formFieldResponses.${formField.key}.city`, resp.data.city);

                      console.log(resp);
                    } catch (e) {
                      console.log(e);
                    }
                  }
                }}
              />
              {meta.touched && meta.error && (
                <p className={clsx(styles['message'], styles['message--err'])}>
                  {t(`${meta.error}`)}
                </p>
              )}
            </>
          )}
        </Field>
      </Box>

      <Box mt={2}>
        <div className={clsx(styles['form__item__ttl'])}>{t('State/Province/Region')}</div>
        <Field
          type="text"
          name={`formFieldResponses.${formField.key}.province`}
          validate={composeValidators(...validators)}
        >
          {({ input, meta }) => (
            <>
              <input type="text" className={clsx(styles['inputtext'])} {...input} />
              {meta.touched && meta.error && (
                <p className={clsx(styles['message'], styles['message--err'])}>
                  {t(`${meta.error}`)}
                </p>
              )}
            </>
          )}
        </Field>
      </Box>

      <Box mt={2}>
        <div className={clsx(styles['form__item__ttl'])}>{t('City')}</div>
        <Field
          type="text"
          name={`formFieldResponses.${formField.key}.city`}
          validate={composeValidators(...validators)}
        >
          {({ input, meta }) => (
            <>
              <input type="text" className={clsx(styles['inputtext'])} {...input} />
              {meta.touched && meta.error && (
                <p className={clsx(styles['message'], styles['message--err'])}>
                  {t(`${meta.error}`)}
                </p>
              )}
            </>
          )}
        </Field>
      </Box>

      <Box mt={2}>
        <div className={clsx(styles['form__item__ttl'])}>{t('Addless Line')}</div>
        <Field
          type="text"
          name={`formFieldResponses.${formField.key}.addressLine`}
          validate={composeValidators(...validators)}
        >
          {({ input, meta }) => (
            <>
              <input type="text" className={clsx(styles['inputtext'])} {...input} />
              {meta.touched && meta.error && (
                <p className={clsx(styles['message'], styles['message--err'])}>
                  {t(`${meta.error}`)}
                </p>
              )}
            </>
          )}
        </Field>
      </Box>
    </>
  );
};

const OtherCountryAddressInputForm = ({ formField }: { formField: CustomerFormField }) => {
  const { t } = useTranslation();
  const required = formField.required === 'WHEN_SIGN_UP';
  const validators = [];
  if (required) {
    validators.push(requiredValidator);
  }

  return (
    <>
      <Box mt={2}>
        <div className={clsx(styles['form__item__ttl'])}>{t('Zip / Postal code')}</div>
        <Field
          type="text"
          name={`formFieldResponses.${formField.key}.postalCode`}
          validate={composeValidators(...validators)}
        >
          {({ input, meta }) => (
            <>
              <input
                type="text"
                className={clsx(styles['inputtext'])}
                value={input.value}
                onChange={async (val) => {
                  input.onChange(val);
                }}
              />
              {meta.touched && meta.error && (
                <p className={clsx(styles['message'], styles['message--err'])}>
                  {t(`${meta.error}`)}
                </p>
              )}
            </>
          )}
        </Field>
      </Box>

      <Box mt={2}>
        <div className={clsx(styles['form__item__ttl'])}>{t('State/Province/Region')}</div>
        <Field
          type="text"
          name={`formFieldResponses.${formField.key}.province`}
          validate={composeValidators(...validators)}
        >
          {({ input, meta }) => (
            <>
              <input type="text" className={clsx(styles['inputtext'])} {...input} />
              {meta.touched && meta.error && (
                <p className={clsx(styles['message'], styles['message--err'])}>
                  {t(`${meta.error}`)}
                </p>
              )}
            </>
          )}
        </Field>
      </Box>

      <Box mt={2}>
        <div className={clsx(styles['form__item__ttl'])}>{t('City')}</div>
        <Field
          type="text"
          name={`formFieldResponses.${formField.key}.city`}
          validate={composeValidators(...validators)}
        >
          {({ input, meta }) => (
            <>
              <input type="text" className={clsx(styles['inputtext'])} {...input} />
              {meta.touched && meta.error && (
                <p className={clsx(styles['message'], styles['message--err'])}>
                  {t(`${meta.error}`)}
                </p>
              )}
            </>
          )}
        </Field>
      </Box>

      <Box mt={2}>
        <div className={clsx(styles['form__item__ttl'])}>{t('Addless Line')}</div>
        <Field
          type="text"
          name={`formFieldResponses.${formField.key}.addressLine`}
          validate={composeValidators(...validators)}
        >
          {({ input, meta }) => (
            <>
              <input type="text" className={clsx(styles['inputtext'])} {...input} />
              {meta.touched && meta.error && (
                <p className={clsx(styles['message'], styles['message--err'])}>
                  {t(`${meta.error}`)}
                </p>
              )}
            </>
          )}
        </Field>
      </Box>
    </>
  );
};
