import styles from './TextField.module.css';

interface Props {
  label?: string;
  children?: React.ReactNode;
}

export const FieldWrapper = ({ label, children }: Props) => {
  return (
    <div>
      <div className={styles['label']}>{label}</div>
      <div className={styles['input-container']}>{children}</div>
    </div>
  );
};
