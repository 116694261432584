import React from 'react';
import styles from './CategoryItemCard.module.css';
import { MenuItem } from 'models/digitalMap';
import { CounterButton } from './CounterButton';
import { useForm } from 'react-final-form';
import { CheckoutFormValues } from './checkoutFormValues';
import { useCurrencyFormatter } from '../useCurrencyFormatter';
import { AllergenIcon } from './AllergenIcon';

interface Props {
  item: MenuItem;
}

export const CategoryItemCard: React.FC<Props> = ({ item }) => {
  const thumbnail = item.media_items.find((mediaItem) => mediaItem.type === 'IMAGE')?.url;

  const currencyFormatter = useCurrencyFormatter();

  const form = useForm<CheckoutFormValues>();
  const values = form.getState().values;

  const itemCount = values.items.filter((cartItem) => cartItem.menuItemKey === item.key).length;

  return (
    <article className={styles.entreeItem}>
      <div className={styles.itemInfo}>
        <h3 className={styles.itemName}>{item.name}</h3>
        <p className={styles.itemDescription}>{item.description}</p>
        {item.allergens?.length > 0 && (
          <div className={styles.allergenInfo}>
            {item.allergens?.map((allergen) => (
              <AllergenIcon allergen={allergen} key={allergen} />
            ))}
          </div>
        )}
        <span className={styles.price}>{currencyFormatter.format(item.price)}</span>
      </div>
      <div className={styles.imageWrapper}>
        <img src={thumbnail} alt={item.name} className={styles.itemImage} />
        <div className={styles.buttonContainer}>
          <CounterButton
            count={itemCount}
            onIncrement={() => {
              form.change('items', [...values.items, { menuItemKey: item.key, optionGroups: [] }]);
            }}
            onDecrement={() => {
              // remove last item having the same key from the cart items
              const idx = values.items.findLastIndex(
                (cartItem) => cartItem.menuItemKey === item.key
              );

              if (idx !== -1) {
                form.change('items', [
                  ...values.items.slice(0, idx),
                  ...values.items.slice(idx + 1),
                ]);
              }
            }}
          />
        </div>
      </div>
    </article>
  );
};
