import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './MenuItemPage.module.css';
import { MenuItem, OptionGroup } from 'models/digitalMap';
import { useCurrencyFormatter } from 'components/Map/useCurrencyFormatter';
import { OrderItem } from '../checkoutFormValues';

interface Props {
  menuItem: MenuItem;
  optionGroup: OptionGroup;
  orderItem: OrderItem;
  onChangeOrderItem: (orderItem: OrderItem) => void;
}

export const OptionSelector: React.FC<Props> = ({
  menuItem,
  optionGroup,
  orderItem,
  onChangeOrderItem,
}) => {
  const { t } = useTranslation();
  const currencyFormatter = useCurrencyFormatter();

  const isSelectOne = optionGroup.selection_limit === 1 && optionGroup.is_required;

  return (
    <section className={styles.section}>
      <h2 className={styles.sectionTitle}>{optionGroup.name}</h2>
      <div className={styles.sectionHeader}>
        {optionGroup.is_required && (
          <div className={styles.requiredTag}>
            <img
              src="/static/images/map/warning_triangle.svg"
              alt=""
              className={styles.requiredIcon}
            />
            <div className={styles.requiredTextChecked}>{t('Required')}</div>
          </div>
        )}
        {!optionGroup.is_required && (
          <div className={styles.optionalTag}>
            <div className={styles.optionalTextChecked}>{t('Optional')}</div>
          </div>
        )}
        {isSelectOne ? (
          <>
            <div className={styles.circle} />
            <div className={styles.selectionInfo}>{t('Select 1')}</div>
          </>
        ) : optionGroup.selection_limit ? (
          <>
            <div className={styles.circle} />
            <div className={styles.selectionInfo}>
              {optionGroup.selection_limit === 1
                ? t('Select 1')
                : t('Choose up to {{count}}', { count: optionGroup.selection_limit })}
            </div>
          </>
        ) : null}
      </div>
      {isSelectOne &&
        optionGroup.options.map((option) => {
          const thumbnail = option.media_items?.find((item) => item)?.url;
          return (
            <React.Fragment key={option.key}>
              <div className={styles.optionContainer}>
                <label className={styles.optionLabel}>
                  <input
                    type="radio"
                    name="option"
                    value={option.key}
                    className={styles['visually-hidden']}
                    checked={orderItem.optionGroups.some(
                      (group) =>
                        group.key === optionGroup.key && group.optionKeys.includes(option.key)
                    )}
                    onChange={() =>
                      onChangeOrderItem({
                        ...orderItem,
                        optionGroups: menuItem.option_groups.map((group) => {
                          if (group.key === optionGroup.key) {
                            return {
                              key: group.key,
                              optionKeys: [option.key],
                            };
                          }
                          return (
                            orderItem.optionGroups.find((group) => group.key === group.key) || {
                              key: group.key,
                              optionKeys: [],
                            }
                          );
                        }),
                      })
                    }
                  />
                  <div className={styles.radioButton}>
                    <div className={styles.radioCircle} />
                    <div className={styles.radioCircleFilled} />
                  </div>

                  {thumbnail && <img src={thumbnail} alt="" className={styles.optionImage} />}
                  {option.name}
                </label>
                {option.price && (
                  <div className={styles.optionPrice}>
                    + {currencyFormatter.format(option.price)}
                  </div>
                )}
              </div>
              <div className={styles.divider} />
            </React.Fragment>
          );
        })}
      {!isSelectOne &&
        optionGroup.options.map((option, index) => {
          const thumbnail = option.media_items?.find((item) => item)?.url;
          const checked = orderItem.optionGroups.some(
            (group) => group.key === optionGroup.key && group.optionKeys.includes(option.key)
          );
          const existingOptionKeys =
            orderItem.optionGroups.find((group) => group.key === optionGroup.key)?.optionKeys || [];

          return (
            <React.Fragment key={option.key}>
              <div className={styles.checkboxContainer}>
                <div className={styles.checkboxOption}>
                  <input
                    type="checkbox"
                    id={`option-${index}`}
                    name={option.name}
                    className={styles['visually-hidden']}
                    checked={checked}
                    onChange={() =>
                      onChangeOrderItem({
                        ...orderItem,
                        optionGroups: menuItem.option_groups.map((group) => {
                          if (group.key === optionGroup.key) {
                            return {
                              key: group.key,
                              optionKeys: checked
                                ? existingOptionKeys?.filter((key) => key !== option.key)
                                : [...(existingOptionKeys || []), option.key],
                            };
                          }
                          return (
                            orderItem.optionGroups.find((group) => group.key === group.key) || {
                              key: group.key,
                              optionKeys: [],
                            }
                          );
                        }),
                      })
                    }
                  />
                  <label htmlFor={`option-${index}`} className={styles.checkbox}>
                    {checked && <span className={styles.checkmark}>✓</span>}
                  </label>
                  {thumbnail && <img src={thumbnail} alt="" className={styles.optionImage} />}
                  <div className={styles.optionLabel}>{option.name}</div>
                </div>
                {option.price && (
                  <div className={styles.optionPrice}>
                    + {currencyFormatter.format(option.price)}
                  </div>
                )}
              </div>
              <div className={styles.divider} />
            </React.Fragment>
          );
        })}
    </section>
  );
};
