import * as React from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import createDecorator from 'final-form-focus';

import { ApiKeyContext } from 'contexts/ApiKeyContext';
import {
  CustomerCardValues,
  getInitialCardValues,
  convertToUpdateCustomerCardRequest,
} from './formValues';
import { updateCustomer } from 'ducks/client/customers';
import { CustomerCard } from './CustomerCard';
import { getCommonRedirectUri } from 'lib/util/customer';
import { useCustomerToken } from 'hooks/useCustomerToken';
import { CustomerContext } from 'contexts/CustomerContext';

interface Props {
  handleBack: () => void;
  onMessageChange: (prop: { style: string; message: string }) => void;
}

export const CustomerCardRegisterForm = ({ handleBack, onMessageChange }: Props) => {
  const dispatch = useDispatch();
  const { apiKey } = React.useContext(ApiKeyContext);
  const { t, i18n } = useTranslation();
  const { accessToken, idProvider } = useCustomerToken();
  const { customer } = React.useContext(CustomerContext);

  const focusOnError = createDecorator<CustomerCardValues>();

  return (
    <Form<CustomerCardValues>
      initialValues={getInitialCardValues(customer)}
      keepDirtyOnReinitialize
      onSubmit={async (values: CustomerCardValues) => {
        try {
          const patch = convertToUpdateCustomerCardRequest(values);

          await dispatch(
            updateCustomer(
              apiKey,
              {
                ...patch,
                access_token: accessToken,
                id_provider: idProvider ?? 'FACEBOOK',
                redirect_uri: getCommonRedirectUri(),
              },
              i18n.language
            )
          );
          onMessageChange({
            style: 'ok',
            message: t('Your credit card information is successfully added.'),
          });
          handleBack();
        } catch (e) {
          console.error(e);
          onMessageChange({
            style: 'ng',
            message: t('Your credit card information is not added.'),
          });
        }
      }}
      decorators={[focusOnError]}
      debug={console.log}
    >
      {({ handleSubmit }) => (
        <CustomerCard onSubmit={handleSubmit} handleBack={() => handleBack()} />
      )}
    </Form>
  );
};
