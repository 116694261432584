import { Field } from 'react-final-form';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { required as requiredValidator, getOptionalValidator } from 'lib/forms/validate';
import { composeValidators } from 'lib/util/composeValidators';

import config from 'config';
import { CustomerFormField } from 'models/settings';

import styles from './MyPage.module.css';
import { CustomerAddressInputForm } from './CustomerAddressInputForm';
import { SelectComponent } from './SelectComponent';

export const CustomerFormFieldInput = ({ formField }: { formField: CustomerFormField }) => {
  const { t } = useTranslation();

  const required = formField.required === 'WHEN_SIGN_UP';

  const validators = [];
  if (required) {
    validators.push(requiredValidator);
  }

  if (formField.format === 'NUMERIC') {
    validators.push(getOptionalValidator('float'));
  }

  if (formField.format === 'YYYY_MM_DD') {
    const thisYear = new Date().getFullYear();

    return (
      <div className={clsx(styles['form__item'])}>
        <div className={clsx(styles['form__item__ttl'], required ? styles['required'] : null)}>
          {formField.field_name}
        </div>
        <div className={clsx(styles['form__item__body'])}>
          <div className={clsx(styles['form__flex'])}>
            <Field
              name={`formFieldResponses.${formField.key}.year`}
              validate={composeValidators(...validators)}
            >
              {({ input, meta }) => (
                <SelectComponent
                  input={input}
                  meta={meta}
                  options={Array.from(Array(100).keys()).map((i) => ({
                    key: `${thisYear - 100 + i}`,
                    text: `${thisYear - 100 + i}`,
                  }))}
                  label={t('Year')}
                />
              )}
            </Field>
            <Field
              name={`formFieldResponses.${formField.key}.month`}
              validate={composeValidators(...validators)}
            >
              {({ input, meta }) => (
                <SelectComponent
                  input={input}
                  meta={meta}
                  options={Array.from(Array(12).keys()).map((i) => ({
                    key: `${i + 1}`.padStart(2, '0'),
                    text: `${i + 1}`,
                  }))}
                  label={t('Month')}
                />
              )}
            </Field>
            <Field
              name={`formFieldResponses.${formField.key}.day`}
              validate={composeValidators(...validators)}
            >
              {({ input, meta }) => (
                <SelectComponent
                  input={input}
                  meta={meta}
                  options={Array.from(Array(31).keys()).map((i) => ({
                    key: `${i + 1}`.padStart(2, '0'),
                    text: `${i + 1}`,
                  }))}
                  label={t('Day')}
                />
              )}
            </Field>
          </div>
        </div>
      </div>
    );
  }

  if (formField.response_type === 'SELECT_ONE') {
    return (
      <div className={clsx(styles['form__item'])}>
        <div className={clsx(styles['form__item__ttl'], required ? styles['required'] : null)}>
          {formField.field_name}
        </div>
        <div className={clsx(styles['form__item__body'])}>
          <div className={clsx(styles['form__flex'])}>
            <Field
              name={`formFieldResponses.${formField.key}.value`}
              validate={composeValidators(...validators)}
            >
              {({ input, meta }) => (
                <SelectComponent
                  input={input}
                  meta={meta}
                  options={formField.options ?? []}
                  label={t('Please Select')}
                />
              )}
            </Field>
          </div>
        </div>
      </div>
    );
  }

  if (config.enableAddressForm && formField.response_type === 'ADDRESS_FORM') {
    return <CustomerAddressInputForm formField={formField} />;
  }

  return (
    <div className={clsx(styles['form__item'])}>
      <div className={clsx(styles['form__item__ttl'], required ? styles['required'] : null)}>
        {formField.field_name}
      </div>
      <div className={clsx(styles['form__item__body'])}>
        <Field
          type="text"
          name={`formFieldResponses.${formField.key}.value`}
          validate={composeValidators(...validators)}
        >
          {({ input, meta }) => (
            <>
              <input type="text" className={clsx(styles['inputtext'])} {...input} />
              {meta.touched && meta.error && (
                <p className={clsx(styles['message'], styles['message--err'])}>
                  {t(`${meta.error}`)}
                </p>
              )}
            </>
          )}
        </Field>
      </div>
    </div>
  );
};
