import * as React from 'react';
import { BackButton } from './BackButton';
import styles from './PageContainer.module.css';

interface Props {
  children: React.ReactNode;
  onBackClick?: () => void;
  headerContent?: React.ReactNode;
  style?: React.CSSProperties;
}

export const PageContainer = React.forwardRef<HTMLDivElement, Props>(
  ({ children, onBackClick, headerContent, style }, ref) => {
    return (
      <div ref={ref} className={styles['page-container']} style={style}>
        {onBackClick && <BackButton onClick={onBackClick} headerContent={headerContent} />}
        {children}
      </div>
    );
  }
);
