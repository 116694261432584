import { DigitalMap } from 'models/digitalMap';

export const getSolidBackgroundMapType = (digitalMap: DigitalMap) =>
  new google.maps.ImageMapType({
    getTileUrl: function (): string {
      return 'https://assets.ntmg.com/digitalmap/bg_green.jpg';
    },
    tileSize: new google.maps.Size(256, 256),
    maxZoom: 22,
    minZoom: (digitalMap?.default_map_zoom ?? 0) - 2,
    name: 'solid',
  });
