/**
 * This code was generated by Builder.io.
 */
import React from 'react';
import styles from './MenuItemCard.module.css';
import { MenuItem } from 'models/digitalMap';
import { CounterButton } from './CounterButton';
import { useForm } from 'react-final-form';
import { CheckoutFormValues } from './checkoutFormValues';
import { useCurrencyFormatter } from '../useCurrencyFormatter';

interface MenuItemProps {
  item: MenuItem;
  note?: string;
}

export const MenuItemCard: React.FC<MenuItemProps> = ({ item, note }) => {
  const thumbnail = item.media_items.find((mediaItem) => mediaItem.type === 'IMAGE')?.url;

  const form = useForm<CheckoutFormValues>();
  const values = form.getState().values;
  const currencyFormatter = useCurrencyFormatter();

  const itemCount = values.items.filter((cartItem) => cartItem.menuItemKey === item.key).length;

  return (
    <article className={styles.menuItem}>
      <div className={styles.imageWrapper}>
        <img src={thumbnail} alt={item.name} className={styles.itemImage} />
        <div className={styles.buttonContainer}>
          <CounterButton
            count={itemCount}
            onIncrement={() => {
              form.change('items', [...values.items, { menuItemKey: item.key, optionGroups: [] }]);
            }}
            onDecrement={() => {
              // remove last item having the same key from the cart items
              const idx = values.items.findLastIndex(
                (cartItem) => cartItem.menuItemKey === item.key
              );

              if (idx !== -1) {
                form.change('items', [
                  ...values.items.slice(0, idx),
                  ...values.items.slice(idx + 1),
                ]);
              }
            }}
          />
        </div>
      </div>
      <h3 className={styles.itemName}>{item.name}</h3>
      <div className={styles.priceContainer}>
        <span className={styles.price}>{currencyFormatter.format(item.price)}</span>
      </div>
      {note && <div className={styles.note}>{note}</div>}
    </article>
  );
};
