import clsx from 'clsx';
import moment from 'moment-timezone';
import React from 'react';
import styles from './MenuSearch.module.css';
import { useActiveRestaurant } from './useActiveRestaurant';
import { findActivePinSchedule } from '../util';
import { useTranslation } from 'react-i18next';

export const MenuSearch: React.FC<{
  searchQuery: string;
  onSearchQueryChange: (query: string) => void;
}> = ({ searchQuery, onSearchQueryChange }) => {
  const { t } = useTranslation();
  const formRef = React.useRef<HTMLFormElement>(null);
  const restaurant = useActiveRestaurant();

  const now = moment();
  const openHoursForToday = findActivePinSchedule(restaurant?.open_hour_schedules ?? [], now);
  const openNow =
    openHoursForToday &&
    moment(openHoursForToday?.start_time_local, 'HH:mm').isSameOrBefore(now) &&
    moment(openHoursForToday?.end_time_local, 'HH:mm').isSameOrAfter(now);

  return (
    <section className={styles.searchSection}>
      <div className={styles.title}>
        <h1 className={styles.restaurantName}>{restaurant?.name}</h1>
        {openNow ? (
          <span className={clsx(styles.openStatus, styles.open)}>{t('Open')}</span>
        ) : (
          <span className={clsx(styles.openStatus, styles.closed)}>{t('Closed')}</span>
        )}
      </div>
      <form ref={formRef} className={styles.searchForm}>
        <div className={styles.searchInputWrapper}>
          <img src="/static/images/map/search.svg" alt="" className={styles.searchIcon} />
          <input
            type="text"
            id="menuSearch"
            placeholder={t('Search Menu')}
            className={styles.searchInput}
            value={searchQuery}
            onChange={(e) => onSearchQueryChange(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                (e.target as HTMLInputElement).blur();

                // Scroll form into view at top of screen
                if (formRef.current) {
                  formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
              }
            }}
          />
          {searchQuery && (
            <img
              src="/static/images/map/close.svg"
              alt=""
              className={styles.clearIcon}
              onClick={() => onSearchQueryChange('')}
            />
          )}
        </div>
      </form>
    </section>
  );
};
