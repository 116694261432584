import * as React from 'react';
import { ETicketRedeemTargetContext, RedeemTarget } from 'contexts/ETicketRedeemTargetContext';
import { ETicketCheckinModal } from 'components/ETicketCheckinModal/ETicketCheckinModal';
import { ETicketCard } from 'components/ETickets/ETicketCard';
import { Reservation } from 'models/reservation';
import { Product } from 'models/product';
import { ShowRedeemedETicketCardModal } from 'components/ETicketPage/ShowRedeemedETicketCardModal';

interface Props {
  reservation: Reservation | undefined;
  product: Product | undefined;
}

export const TicketView = ({ reservation, product }: Props) => {
  const [redeemTarget, setRedeemTarget] = React.useState<RedeemTarget | null>(null);
  const [redeemedReservationId, setRedeemedReservationId] = React.useState<string | null>(null);

  return (
    <ETicketRedeemTargetContext.Provider value={{ redeemTarget, setRedeemTarget }}>
      {redeemTarget && (
        <ETicketCheckinModal
          reservation={redeemTarget.reservation}
          stubKey={redeemTarget.stubKey}
          open={Boolean(redeemTarget)}
          onSuccessfulCheckin={() => {
            if (product?.qr_checkin_settings?.expiration_date?.instant_expiration) {
              setRedeemedReservationId(redeemTarget.reservation.id ?? '');
            }
          }}
          onClose={() => {
            setRedeemTarget(null);
          }}
          guestTypeCounts={redeemTarget.guestTypeCounts}
          redemptionCount={redeemTarget.redemptionCount}
          product={product}
        />
      )}
      {redeemedReservationId && (
        <ShowRedeemedETicketCardModal onClose={() => setRedeemedReservationId(null)}>
          <ETicketCard
            reservation={reservation}
            product={product}
            initialOpen={true}
            host=""
          ></ETicketCard>
        </ShowRedeemedETicketCardModal>
      )}
      <ETicketCard reservation={reservation} product={product} host="" initialOpen={true} />
    </ETicketRedeemTargetContext.Provider>
  );
};
