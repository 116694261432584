import clsx from 'clsx';
import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Page } from '../Page';
import styles from './Notifications.module.css';
import { Toggle } from '../Toggle';
import { Customer } from 'models/customer';
import { mergeFormFieldResponses } from '../util';
import { useCustomerToken } from 'hooks/useCustomerToken';
import { CustomerContext } from 'contexts/CustomerContext';
import { useDispatch } from 'react-redux';
import { updateCustomer } from 'ducks/client/customers';
import { getCommonRedirectUri } from 'lib/util/customer';
import { ApiKeyContext } from 'contexts/ApiKeyContext';

interface FormValues {
  muteNotifications: boolean;
  popUpNotifications: boolean;
  emailNotifications: boolean;
  pushNotifications: boolean;
}

const convertFormValuesToCustomerPatch = (values: FormValues, existingCustomer: Customer) => {
  return {
    ...existingCustomer,
    form_field_responses: mergeFormFieldResponses(
      existingCustomer.form_field_responses?.map((f) => ({
        key: f.key || '',
        value: f.value || '',
      })),
      [
        {
          key: 'mute_notifications',
          value: `${values.muteNotifications}`,
        },
        {
          key: 'pop_up_notifications',
          value: `${values.popUpNotifications}`,
        },
        {
          key: 'email_notifications',
          value: `${values.emailNotifications}`,
        },
        {
          key: 'push_notifications',
          value: `${values.pushNotifications}`,
        },
      ]
    ),
  };
};

interface Props {
  onBackClick: () => void;
}

export const Notifications = ({ onBackClick }: Props) => {
  const { i18n, t } = useTranslation();
  const { apiKey } = React.useContext(ApiKeyContext);

  const { idProvider, accessToken } = useCustomerToken();
  const { customer } = React.useContext(CustomerContext);
  const dispatch = useDispatch();

  const initialValues = React.useMemo(
    () => ({
      muteNotifications:
        customer?.form_field_responses?.find((f) => f.key === 'mute_notifications')?.value ===
        'true',
      popUpNotifications:
        customer?.form_field_responses?.find((f) => f.key === 'pop_up_notifications')?.value ===
        'true',
      emailNotifications:
        customer?.form_field_responses?.find((f) => f.key === 'email_notifications')?.value ===
        'true',
      pushNotifications:
        customer?.form_field_responses?.find((f) => f.key === 'push_notifications')?.value ===
        'true',
    }),
    [customer]
  );

  return (
    <Page title={t('Notifications')} onBackClick={onBackClick}>
      <div className={styles['content']}>
        <div className={styles['subheader']}>{t('Preferences')}</div>
        <Form<FormValues>
          initialValues={initialValues}
          keepDirtyOnReinitialize
          onSubmit={async (values: FormValues) => {
            await dispatch(
              updateCustomer(
                apiKey,
                {
                  ...convertFormValuesToCustomerPatch(values, customer as Customer),
                  access_token: accessToken,
                  id_provider: idProvider ?? 'FACEBOOK',
                  redirect_uri: getCommonRedirectUri(),
                },
                i18n.language
              )
            );
          }}
          debug={console.log}
        >
          {({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles['toggle-container']}>
                <Field name="muteNotifications" type="checkbox">
                  {({ input }) => (
                    <Toggle
                      label={t('Mute Notifications')}
                      checked={input.checked || false}
                      onChange={() => {
                        const newValue = !input.checked;

                        if (newValue) {
                          form.change('popUpNotifications', false);
                          form.change('emailNotifications', false);
                          form.change('pushNotifications', false);
                        }
                        input.onChange(!input.checked);
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className={styles['toggle-container']}>
                <Field name="popUpNotifications" type="checkbox">
                  {({ input }) => (
                    <Toggle
                      label={t('Pop Up Notifications')}
                      checked={input.checked || false}
                      onChange={() => {
                        const newValue = !input.checked;

                        if (newValue) {
                          form.change('muteNotifications', false);
                        }

                        input.onChange(!input.checked);
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className={styles['toggle-container']}>
                <Field name="emailNotifications" type="checkbox">
                  {({ input }) => (
                    <Toggle
                      label={t('Email Notifications')}
                      checked={input.checked || false}
                      onChange={() => {
                        const newValue = !input.checked;

                        if (newValue) {
                          form.change('muteNotifications', false);
                        }

                        input.onChange(!input.checked);
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className={styles['toggle-container']}>
                <Field name="pushNotifications" type="checkbox">
                  {({ input }) => (
                    <Toggle
                      label={t('Push Notifications')}
                      checked={input.checked || false}
                      onChange={() => {
                        const newValue = !input.checked;

                        if (newValue) {
                          form.change('muteNotifications', false);
                        }

                        input.onChange(!input.checked);
                      }}
                    />
                  )}
                </Field>
              </div>
              <button className={clsx(styles['btn'], styles['save'])} type="submit">
                {t('Save')}
              </button>
            </form>
          )}
        </Form>
      </div>
    </Page>
  );
};
