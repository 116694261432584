import * as React from 'react';
import moment from 'moment-timezone';
import styles from './Payment.module.css';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import { useMapDisplayContext } from '../MapDisplayContext';
import { useFieldState } from './useFieldState';
import { CheckoutFormValues } from './formValues';
import { calculateReservationFares } from 'ducks/client/fares';
import { useDispatch, useSelector } from 'react-redux';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { ReduxState } from 'ducks';
import { CircularProgress } from '@mui/material';
import { currency } from 'lib/currency';
import { useCurrencyFormatter } from '../useCurrencyFormatter';
import { CustomerContext } from 'contexts/CustomerContext';
import { ButtonLoader } from '../MyAccount/ButtonLoader';
import Image from 'next/image';
import { getImageUrl } from 'lib/util/imageUrl';
import { AnimatedButton } from '../AnimatedButton';
import { PageContainer } from '../Components/PageContainer';
import { AnimatedClickableDiv } from '../AnimatedClickableDiv';

interface Props {
  onClickEditPaymentMethod: () => void;
}

export const Payment = ({ onClickEditPaymentMethod }: Props) => {
  const { t, i18n } = useTranslation();

  const { apiKey } = React.useContext(ApiKeyContext);
  const dispatch = useDispatch();
  const {
    setActivePage,
    clearCart,
    productInCart,
    resetDisplay,
    saveDisplayState,
  } = useMapDisplayContext();

  const form = useForm<CheckoutFormValues>();
  const values = form.getState().values;

  React.useEffect(() => {
    dispatch(
      calculateReservationFares(
        apiKey,
        {
          product_instance_id: values.productInstance?.id ?? '',
          guests: Object.entries(values.guestCounts)
            .map(([key, value]) =>
              Array.from({ length: value as number }, () => ({
                guest_type_key: key,
                field_responses: [],
              }))
            )
            .flat(),
          payment_type: 'PAID_IN_FULL',
        },
        i18n.language
      )
    );
  }, [apiKey, i18n.language, values, dispatch]);

  const submitting = form.getState().submitting;
  const submitError = form.getState().submitError;

  const [selectedProductInstance] = useFieldState<CheckoutFormValues['productInstance']>(
    'productInstance'
  );

  const billingInfo = useSelector((state: ReduxState) => state.fares.fares);

  const loading = useSelector((state: ReduxState) => state.fares.loading);

  const currencyFormatter = useCurrencyFormatter();

  const { customer } = React.useContext(CustomerContext);

  if (!customer) {
    return (
      <main className={styles['main-content']}>
        <p className={styles['message']}>{t('Please Sign In To Finish Booking Tickets.')}</p>
        <AnimatedButton
          type="button"
          className={styles['sign-in-button']}
          clickedClassName={styles['clicked']}
          onClick={() => {
            // Save page state to session storage then go to sign in / sign up page.
            // Once user is signed in, we will redirect back to this page.
            saveDisplayState();

            resetDisplay();
            setActivePage('ACCOUNT');
          }}
        >
          {t('Sign In')}
        </AnimatedButton>
        <AnimatedButton
          type="button"
          className={styles['close-button']}
          clickedClassName={styles['clicked']}
          onClick={() => {
            resetDisplay();
          }}
        >
          {t('Close')}
        </AnimatedButton>
      </main>
    );
  }

  return (
    <PageContainer
      onBackClick={() => clearCart()}
      headerContent={<h1 className={styles['title']}>{t('Payment')}</h1>}
    >
      <div className={styles['main-section']}>
        <article className={styles['event-details']}>
          <Image
            priority
            alt=""
            width={200}
            height={148}
            className={styles['event-image']}
            key={productInCart?.media.find((m) => m.type === 'IMAGE')?.url}
            src={productInCart?.media.find((m) => m.type === 'IMAGE')?.url ?? ''}
            loader={({ width, src }) => getImageUrl(src, width * 1.5)}
          />
          <div className={styles['event-info']}>
            <h2 className={styles['event-title']}>{productInCart?.product_name}</h2>
            <time className={styles['event-date']}>
              {moment(selectedProductInstance?.start_date_time_local)
                .locale(i18n.language)
                .format('LLL')}
            </time>
          </div>
        </article>

        <div className={styles['payment-details']}>
          {loading ? (
            <div className={styles['loading-container']}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {billingInfo?.line_items?.map((item) => (
                <div key={item.title} className={styles['ticket-item']}>
                  <div>
                    {item.count} x {item.title || productInCart?.product_name}
                  </div>
                  <div>
                    {currencyFormatter.format(
                      currency(item.amount_gross).multiply(item.count).format()
                    )}
                  </div>
                </div>
              ))}

              <hr className={styles['divider']} />

              <div className={styles['total-section']}>
                <h3 className={styles['total-title']}>{t('Total')}</h3>
                <div className={styles['total-amount']}>
                  {currencyFormatter.format(billingInfo?.amount_gross ?? '')}
                </div>
              </div>
            </>
          )}

          <hr className={styles['divider']} />
        </div>

        <section className={styles['payment-method']}>
          <AnimatedClickableDiv
            className={styles['payment-method-info']}
            clickedClassName={styles['clicked']}
            onClick={onClickEditPaymentMethod}
          >
            <div className={styles['card-info']}>
              {customer?.payment_profile_card_info?.last_four_digits ? (
                <div>
                  <div className={styles['card-type']}>
                    {customer?.payment_profile_card_info?.card_brand}
                  </div>
                  <div className={styles['card-number']}>
                    ****{customer?.payment_profile_card_info?.last_four_digits}
                  </div>
                </div>
              ) : (
                <div className={styles['add-card']}>{t('Add Payment Method')}</div>
              )}
            </div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path d="M15.5402 11.2895L9.88023 5.63955C9.78726 5.54582 9.67666 5.47143 9.5548 5.42066C9.43294 5.36989 9.30224 5.34375 9.17023 5.34375C9.03821 5.34375 8.90751 5.36989 8.78565 5.42066C8.66379 5.47143 8.55319 5.54582 8.46023 5.63955C8.27398 5.82691 8.16943 6.08036 8.16943 6.34455C8.16943 6.60873 8.27398 6.86219 8.46023 7.04955L13.4102 12.0495L8.46023 16.9995C8.27398 17.1869 8.16943 17.4404 8.16943 17.7045C8.16943 17.9687 8.27398 18.2222 8.46023 18.4095C8.55284 18.504 8.66329 18.5792 8.78516 18.6307C8.90704 18.6822 9.03792 18.709 9.17023 18.7095C9.30253 18.709 9.43342 18.6822 9.55529 18.6307C9.67717 18.5792 9.78761 18.504 9.88023 18.4095L15.5402 12.7595C15.6417 12.6659 15.7227 12.5523 15.7781 12.4258C15.8336 12.2993 15.8622 12.1627 15.8622 12.0245C15.8622 11.8864 15.8336 11.7498 15.7781 11.6233C15.7227 11.4968 15.6417 11.3832 15.5402 11.2895Z" />
            </svg>
          </AnimatedClickableDiv>
        </section>

        {submitError && <div className={styles['error']}>{submitError}</div>}

        <AnimatedButton
          className={styles['pay-button']}
          clickedClassName={styles['clicked']}
          type="button"
          onClick={() => form.submit()}
        >
          {submitting ? <ButtonLoader /> : t('Pay Now')}
        </AnimatedButton>
        <AnimatedButton
          className={styles['cancel-button']}
          clickedClassName={styles['clicked']}
          onClick={() => clearCart()}
          type="button"
        >
          {t('Cancel')}
        </AnimatedButton>
      </div>
    </PageContainer>
  );
};
