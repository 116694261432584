import React from 'react';
import styles from './PopularTimes.module.css';
import { DigitalMapPin } from 'models/digitalMap';
import { useTranslation } from 'react-i18next';

const LiveInfo: React.FC<{ pin: DigitalMapPin }> = ({ pin }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.liveInfo}>
      <img src="/static/images/map/live_icon.svg" className={styles.liveIcon} alt="" />
      <p>
        <span className={`${styles.bold} ${styles.highlight}`}>Live: </span>
        {pin.is_closed ? (
          <span className={styles.bold}>{t('Closed')}</span>
        ) : (
          <>
            Current wait time is <span className={styles.bold}>{pin.wait_time} minutes</span>.
          </>
        )}
      </p>
    </div>
  );
};

export default LiveInfo;
