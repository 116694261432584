import { currency } from 'lib/currency';
import { MenuItem } from 'models/digitalMap';
import { OrderItem } from './checkoutFormValues';

export const calculateTotalPrice = (item: MenuItem, orderItem: OrderItem, quantity: number) => {
  let itemPrice = currency(item.price);
  for (const orderOptionGroup of orderItem.optionGroups || []) {
    const menuOptionGroup = item.option_groups?.find(
      (optionGroup) => optionGroup.key === orderOptionGroup.key
    );
    if (menuOptionGroup) {
      for (const orderOptionKey of orderOptionGroup.optionKeys || []) {
        const menuOption = menuOptionGroup.options?.find((option) => option.key === orderOptionKey);
        if (menuOption && menuOption.price) {
          itemPrice = itemPrice.add(currency(menuOption.price));
        }
      }
    }
  }

  return itemPrice.multiply(quantity).format();
};
