import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MenuItemCard } from './MenuItemCard';
import styles from './HorizontalScrollSection.module.css';
import { MenuItem } from 'models/digitalMap';
import { useActiveRestaurant } from './useActiveRestaurant';

interface Props {
  onClickItem: (item: MenuItem) => void;
  items: MenuItem[];
  id: string;
  title: string;
}

export const HorizontalScrollSection = React.forwardRef<HTMLElement, Props>(
  ({ onClickItem, items, id, title }, ref) => {
    const { t } = useTranslation();
    const restaurant = useActiveRestaurant();
    const popularItems = _.orderBy(
      restaurant?.menu?.menu_items?.filter((item) => item.order_count),
      ['order_count'],
      ['desc']
    ).slice(0, 5);

    return (
      <section ref={ref} id={id} className={styles.section}>
        <h2 className={styles.sectionTitle}>{title}</h2>
        <div className={styles.itemsContainer}>
          {items.map((item) => {
            const popularIdx = popularItems.findIndex(
              (popularItem) => popularItem.key === item.key
            );
            let note = '';
            if (popularIdx !== -1) {
              note = t('#{{rank}} Most Ordered', { rank: popularIdx + 1 });
            }

            return (
              <a onClick={() => onClickItem(item)} key={item.name}>
                <MenuItemCard item={item} note={note} />
              </a>
            );
          })}
        </div>
      </section>
    );
  }
);
