import * as React from 'react';

export const useGoogleMapsMarkerLibrary = () => {
  const [markerLib, setMarkerLib] = React.useState<google.maps.MarkerLibrary | null>(null);

  // Call google.maps.importLibrary to import the marker library
  React.useEffect(() => {
    (async () => {
      const lib = await google.maps.importLibrary('marker');

      setMarkerLib(lib as google.maps.MarkerLibrary);
    })();
  }, []);

  // Return the marker library
  return markerLib;
};
