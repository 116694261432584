import { MenuFilterState } from '../util';
import styles from './PriceRangeSlider.module.css';
import { useActiveRestaurant } from '../useActiveRestaurant';
import { getCurrencyStep, getDefaultPriceRange } from '../util';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { useCurrencyFormatter } from 'components/Map/useCurrencyFormatter';
import { useTranslation } from 'react-i18next';

export const PriceRangeSlider = ({
  filters,
  onFiltersChange,
}: {
  filters: MenuFilterState;
  onFiltersChange: (filters: MenuFilterState) => void;
}) => {
  const { t } = useTranslation();
  const restaurant = useActiveRestaurant();
  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const currencyCode = settings?.default_currency || 'USD';
  const { low, high } = getDefaultPriceRange(restaurant?.menu || null);
  const currencyFormatter = useCurrencyFormatter();

  const lowValue = parseInt(low);
  const highValue = parseInt(high);
  const range = highValue - lowValue;

  const filterLow = filters.priceRangeLow
    ? `${currencyCode}${filters.priceRangeLow}`
    : `${currencyCode}${lowValue}`;
  const filterHigh = filters.priceRangeHigh
    ? `${currencyCode}${filters.priceRangeHigh}`
    : `${currencyCode}${highValue}`;

  const step = getCurrencyStep(currencyCode);

  return (
    <div className={styles['price-container']}>
      <div className={styles['price-range']}>
        {low === filters.priceRangeLow && high === filters.priceRangeHigh
          ? t('Any price')
          : currencyFormatter.format(filterLow) + ' - ' + currencyFormatter.format(filterHigh)}
      </div>
      <div className={styles['slider-container']}>
        <input
          type="range"
          step={step.toString()}
          min={low}
          max={high}
          value={filters.priceRangeLow ? parseInt(filters.priceRangeLow) : lowValue}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            onFiltersChange({
              ...filters,
              priceRangeLow: value.toString(),
              priceRangeHigh: Math.max(
                value,
                filters.priceRangeHigh ? parseInt(filters.priceRangeHigh) : highValue
              ).toString(),
            });
          }}
          className={styles['slider']}
        />
        <input
          type="range"
          step={step.toString()}
          min={low}
          max={high}
          value={filters.priceRangeHigh ? parseInt(filters.priceRangeHigh) : highValue}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            onFiltersChange({
              ...filters,
              priceRangeHigh: value.toString(),
              priceRangeLow: Math.min(
                value,
                filters.priceRangeLow ? parseInt(filters.priceRangeLow) : lowValue
              ).toString(),
            });
          }}
          className={styles['slider']}
        />
        <span
          className={styles['range-track']}
          style={{
            left: `${((parseInt(filters.priceRangeLow) - lowValue) / range) * 100}%`,
            right: `${((highValue - parseInt(filters.priceRangeHigh)) / range) * 100}%`,
          }}
        />
      </div>
    </div>
  );
};
