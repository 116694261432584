import clsx from 'clsx';
import * as React from 'react';
import styles from './MyAccount.module.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';
import { Tabs } from '../Tabs';
import { SignUp } from './SignUp/SignUp';
import { SignIn } from './SignIn';
import { LoggedInMenu } from './LoggedInMenu';
import { CustomerContext } from 'contexts/CustomerContext';

export type AccountPage = '' | 'PROFILE' | 'NOTIFICATIONS' | 'PAYMENT_SETTINGS' | 'SUPPORT';

export const MyAccount = () => {
  const { t } = useTranslation();

  const [activeAccountPage, setAccountActivePage] = React.useState<
    '' | 'PROFILE' | 'NOTIFICATIONS' | 'PAYMENT_SETTINGS' | 'SUPPORT'
  >('');
  const [activeTab, setActiveTab] = React.useState<'SIGN_UP' | 'SIGN_IN'>('SIGN_IN');
  const [signUpStep, setSignUpStep] = React.useState<'EMAIL_PASSWORD' | 'CONFIRM' | 'REGISTER'>(
    'EMAIL_PASSWORD'
  );
  const settings = useSelector((state: ReduxState) => state.server.settings.all);

  const { customer } = React.useContext(CustomerContext);

  return (
    <div className={clsx(styles['page-container'], styles['is-active'])}>
      {customer ? (
        <LoggedInMenu activePage={activeAccountPage} setActivePage={setAccountActivePage} />
      ) : (
        <div className={styles['inner']}>
          {(activeTab === 'SIGN_IN' ||
            (signUpStep === 'EMAIL_PASSWORD' && activeTab === 'SIGN_UP')) && (
            <>
              <div className={styles['logo-container']}>
                <img src={settings.logo_url} className={styles['logo']} />
              </div>
              <Tabs<'SIGN_UP' | 'SIGN_IN'>
                options={[
                  {
                    text: t('Sign Up'),
                    value: 'SIGN_UP',
                  },
                  {
                    text: t('Log In'),
                    value: 'SIGN_IN',
                  },
                ]}
                onChange={(newTab) => {
                  setActiveTab(newTab);
                }}
                selectedOption={activeTab}
              />
            </>
          )}
          {activeTab === 'SIGN_UP' ? (
            <SignUp signUpStep={signUpStep} setSignUpStep={setSignUpStep} />
          ) : (
            <SignIn />
          )}
        </div>
      )}
    </div>
  );
};
