import * as React from 'react';

import styles from './Stars.module.css';

interface Props {
  rating: number;
}

export const Stars = ({ rating }: Props) => {
  return (
    <div className={styles['stars']}>
      {
        // Render 5 stars
        Array.from({ length: Math.round(rating) }).map((_, index) => (
          <img
            key={index}
            src="/static/images/ic_rating_star.svg"
            alt="Star"
            className={styles['star']}
          />
        ))
      }
    </div>
  );
};

export default Stars;
