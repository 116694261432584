import { AdvancedMarker } from './AdvancedMarker';
import { useCurrentPosition } from 'hooks/useCurrentPosition';
import styles from './CurrentPositionMarker.module.css';
import { smoothMoveCamera } from './smoothMoveCamera';

interface Props {
  map: google.maps.Map;
}

export const CurrentPositionMarker = ({ map }: Props) => {
  const { currentPosition } = useCurrentPosition();

  if (!currentPosition) {
    return null;
  }
  return (
    <AdvancedMarker
      key="current-position"
      map={map as any}
      position={{
        lat: currentPosition.lat(),
        lng: currentPosition.lng(),
      }}
      onClick={() => {
        smoothMoveCamera(map, {
          center: {
            lat: currentPosition.lat(),
            lng: currentPosition.lng(),
          },
        });
      }}
      zIndex={3}
    >
      <div className={styles['current-position-marker']}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="61"
          height="61"
          viewBox="0 0 61 61"
          fill="none"
        >
          <g filter="url(#filter0_d_430_16274)">
            <circle cx="30.5" cy="30.5" r="30.5" fill="#3AA0FF" fillOpacity="0.3" />
            <circle cx="30.5" cy="30.5" r="17" fill="#3AA0FF" stroke="#3AA0FF" strokeWidth="3" />
          </g>
          <g transform="translate(-11.5, -6.5)">
            <path
              d="M41.7104 36.0589C43.6596 36.0589 45.2398 34.4787 45.2398 32.5295C45.2398 30.5802 43.6596 29 41.7104 29C39.7611 29 38.1809 30.5802 38.1809 32.5295C38.1809 34.4787 39.7611 36.0589 41.7104 36.0589Z"
              fill="white"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M48.4228 43.9994C47.9668 42.5775 47.0712 41.3374 45.865 40.4575C44.6589 39.5776 43.2044 39.1035 41.7114 39.1035C40.2184 39.1035 38.7639 39.5776 37.5577 40.4575C36.3515 41.3374 35.4559 42.5775 35 43.9994H48.4228Z"
              fill="white"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_430_16274"
              x="0"
              y="0"
              width="61"
              height="61"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="1.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_430_16274"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_430_16274"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </AdvancedMarker>
  );
};
