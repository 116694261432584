import moment from 'moment-timezone';

import { isRequiredGuestType } from 'lib/util/util';
import {
  productInstanceIsInstantConfirmation,
  productInstanceIsRequestOnly,
} from 'lib/util/productInstanceHelpers';
import { getAmountFromMoneyString } from 'lib/util/util';
import { ProductInstance } from 'models/productInstance';

export interface Week {
  // each day is a 'YYYY-MM-DD' string
  days: string[];
}

// Return the full set of Sun-Sat weeks containing this month. The first and last weeks include any padding days
// from the previous and next months.
export const getWeeksForMonth = (month: string): Week[] => {
  let firstDayOfFirstWeekOfMonth = moment(month).startOf('month');
  while (firstDayOfFirstWeekOfMonth.day() !== 0) {
    firstDayOfFirstWeekOfMonth = firstDayOfFirstWeekOfMonth.subtract(1, 'day');
  }

  let lastDayOfLastWeekOfMonth = moment(month).endOf('month');
  while (lastDayOfLastWeekOfMonth.day() !== 6) {
    lastDayOfLastWeekOfMonth = lastDayOfLastWeekOfMonth.add(1, 'day');
  }

  const weeks: Week[] = [];
  for (
    let currentWeek = moment(firstDayOfFirstWeekOfMonth);
    currentWeek.isSameOrBefore(lastDayOfLastWeekOfMonth);
    currentWeek = currentWeek.add(7, 'days')
  ) {
    const days: string[] = [];
    for (let day = 0; day < 7; day++) {
      days.push(moment(currentWeek).day(day).format('YYYY-MM-DD'));
    }

    weeks.push({ days });
  }

  return weeks;
};

export const areSameMonth = (yyyymmdd1: string, yyyymmdd2: string): boolean =>
  moment(yyyymmdd1).format('YYYY-MM') === moment(yyyymmdd2).format('YYYY-MM');

export const isCurrentMonth = (yyyymmdd: string): boolean =>
  moment().format('YYYY-MM') === moment(yyyymmdd).format('YYYY-MM');

export type DateStatus = 'request' | 'instant' | 'closed' | 'fully_booked';
export interface DateAttributes {
  status: DateStatus;
  hasPromo: boolean;
  isLimitedAvailability: boolean;
}

export const getAttrByDateMap = (
  productInstances: ProductInstance[],
  timezone: string,
  limitedAvailabilityThreshold: number
): { [date: string]: DateAttributes } => {
  const attrByDateMap: { [date: string]: DateAttributes } = {};

  const datesWithRequestOnlyInstances = productInstances
    .filter((p) => productInstanceIsRequestOnly(p, timezone))
    .map((p) => {
      const m = moment.tz(p.start_date_time_utc, timezone);
      return m.format('YYYY-MM-DD');
    });

  const datesWithInstantConfirmation = productInstances
    .filter((p) => productInstanceIsInstantConfirmation(p, timezone))
    .map((p) => {
      const m = moment.tz(p.start_date_time_utc, timezone);
      return m.format('YYYY-MM-DD');
    });

  const datesWithClosedInstances = productInstances
    .filter((p) => p.is_closed)
    .map((p) => {
      const m = moment.tz(p.start_date_time_utc, timezone);
      return m.format('YYYY-MM-DD');
    });

  const datesWithFullyBookedInstances = productInstances
    .filter((p) => p.is_fully_booked && !p.is_closed)
    .map((p) => {
      const m = moment.tz(p.start_date_time_utc, timezone);
      return m.format('YYYY-MM-DD');
    });

  const datesWithPromos = productInstances
    .filter((p) => p.promotions.length > 0)
    .map((p) => {
      const m = moment.tz(p.start_date_time_utc, timezone);
      return m.format('YYYY-MM-DD');
    });

  const datesWithLimitedAvailability = limitedAvailabilityThreshold
    ? productInstances
        .filter(
          (p) =>
            p.available_slots &&
            p.available_slots > 0 &&
            p.available_slots <= limitedAvailabilityThreshold
        )
        .map((p) => {
          const m = moment.tz(p.start_date_time_utc, timezone);
          return m.format('YYYY-MM-DD');
        })
    : [];

  for (const date of datesWithFullyBookedInstances) {
    attrByDateMap[date] = {
      status: 'fully_booked',
      hasPromo: false,
      isLimitedAvailability: false,
    };
  }

  // Overwrite existing 'fully_booked' status if there is request booking for at least one
  // instance in a date.
  for (const date of datesWithRequestOnlyInstances) {
    attrByDateMap[date] = {
      status: 'request',
      hasPromo: false,
      isLimitedAvailability: false,
    };
  }

  // Overwrite existing 'request' status if there is instant confirmation for at least one
  // instance in a date.
  for (const date of datesWithInstantConfirmation) {
    attrByDateMap[date] = {
      status: 'instant',
      hasPromo: false,
      isLimitedAvailability: false,
    };
  }

  // For any dates that haven't been marked as instant or request, if they have closed dates
  // mark them as 'closed'.
  for (const date of datesWithClosedInstances) {
    if (!(date in attrByDateMap)) {
      attrByDateMap[date] = {
        status: 'closed',
        hasPromo: false,
        isLimitedAvailability: false,
      };
    }
  }

  for (const date of datesWithPromos) {
    attrByDateMap[date] = {
      ...attrByDateMap[date],
      hasPromo: true,
    };
  }

  for (const date of datesWithLimitedAvailability) {
    attrByDateMap[date] = {
      ...attrByDateMap[date],
      isLimitedAvailability: true,
    };
  }

  return attrByDateMap;
};

const getLowPrice = (productInstance: ProductInstance): string => {
  const units = productInstance.units || [];
  for (const unit of units) {
    if (unit.guest_type && isRequiredGuestType(unit.guest_type)) {
      return unit.gross;
    }
  }

  if (units.length > 0) {
    return units[0].gross;
  }

  return '';
};

export const getPriceByDateMap = (
  productInstances: ProductInstance[],
  timezone: string
): { [date: string]: string } => {
  const priceByDateMap: { [date: string]: string } = {};

  for (const productInstance of productInstances) {
    const m = moment.tz(productInstance.start_date_time_utc, timezone);
    const date = m.format('YYYY-MM-DD');

    const lowPrice = getLowPrice(productInstance);
    if (
      !(date in priceByDateMap) ||
      getAmountFromMoneyString(priceByDateMap[date]) > getAmountFromMoneyString(lowPrice)
    ) {
      priceByDateMap[date] = lowPrice;
    }
  }

  return priceByDateMap;
};

export const attrMapHasLimitedAvailabilityDate = (attrMap: {
  [date: string]: DateAttributes;
}): boolean => {
  return Object.values(attrMap).some(
    (attr) => attr.isLimitedAvailability && (attr.status === 'instant' || attr.status === 'request')
  );
};

export const attrMapHasPromotionDate = (attrMap: { [date: string]: DateAttributes }): boolean => {
  return Object.values(attrMap).some(
    (attr) => attr.hasPromo && (attr.status === 'instant' || attr.status === 'request')
  );
};

export const attrMapHasFullyBookedDate = (attrMap: { [date: string]: DateAttributes }): boolean => {
  return Object.values(attrMap).some((attr) => attr.status === 'fully_booked');
};
