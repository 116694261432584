/**
 * This code was generated by Builder.io.
 */
import React from 'react';
import styles from './OrderStatus.module.css';

interface ProgressBarProps {
  steps: string[];
  currentStepIndex: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ steps, currentStepIndex }) => {
  return (
    <>
      <div className={styles.progressBar}>
        {steps.map((step, index) => (
          <React.Fragment key={step}>
            {index <= currentStepIndex ? (
              <div className={styles.progressStep}>
                <div className={styles.progressDot} />
                {index < steps.length - 1 && <div className={styles.progressLine} />}
              </div>
            ) : (
              <div className={styles.progressStepInactive}>
                <div className={styles.progressDotInactive} />
                {index < steps.length - 1 && <div className={styles.progressLineInactive} />}
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className={styles.statusLabels}>
        {steps.map((step) => (
          <div key={step} className={styles.statusLabel}>
            {step}
          </div>
        ))}
      </div>
    </>
  );
};

export default ProgressBar;
