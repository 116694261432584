import * as React from 'react';

import { useGoogleMapsApi } from './useGoogleMapsApi';

export const useCurrentPosition = () => {
  const { isLoaded } = useGoogleMapsApi();
  const currentPositionRef = React.useRef<google.maps.LatLng | null>(null);

  // Use geolocation watch position to get the current position
  const [currentPosition, setCurrentPosition] = React.useState<google.maps.LatLng | null>(null);
  const [updatedAt, setUpdatedAt] = React.useState<number | null>(null);
  React.useEffect(() => {
    if (isLoaded) {
      const watchId = navigator.geolocation.watchPosition(
        (position) => {
          currentPositionRef.current = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );
          setCurrentPosition(currentPositionRef.current);
          setUpdatedAt(Date.now());
        },
        (error) => {
          console.error('Error getting current position:', error);
        },
        { enableHighAccuracy: true }
      );
      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    }
  }, [isLoaded]);

  return { currentPosition, currentPositionRef, updatedAt };
};
