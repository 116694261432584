import * as React from 'react';
import {
  Cluster,
  MarkerClusterer,
  Marker,
  SuperClusterAlgorithm,
} from '@googlemaps/markerclusterer';
import { MapDisplayContext } from './MapDisplayContext';
import { PinMarker } from './PinMarker';
import { ReduxState } from 'ducks';
import { useSelector } from 'react-redux';
import { getFilteredPins } from './util';
import { ClusterRenderer } from './ClusterRenderer';
import { smoothMoveCamera } from './smoothMoveCamera';
import { usePinDisplayState } from './usePinDisplayState';

export interface Props {
  map: google.maps.Map;
  onClickPin: (pinKey: string) => void;
}

export const Pins = ({ map, onClickPin }: Props) => {
  const { activePinKey } = usePinDisplayState();

  const { filters, searchText, displayMode, selectedFloor } = React.useContext(MapDisplayContext);
  const markers = React.useRef<{ [key: string]: Marker }>({});
  const [initializedMarkersCount, setInitializedMarkersCount] = React.useState(0);
  const clusterer = React.useRef<MarkerClusterer | null>(null);
  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);
  const allPins = digitalMap?.pins ?? [];

  const activeGetDirectionsPinKey = displayMode === 'SHOW_DIRECTIONS_ROUTE' ? activePinKey : null;
  const filteredPins = React.useMemo(
    () =>
      activeGetDirectionsPinKey
        ? digitalMap?.pins?.filter((p) => p.key === activeGetDirectionsPinKey) ?? []
        : getFilteredPins(
            digitalMap?.pins ?? [],
            filters,
            searchText,
            (digitalMap?.map_floor_count ?? 0) > 1 ? selectedFloor : undefined
          ),
    [digitalMap, filters, searchText, activeGetDirectionsPinKey, selectedFloor]
  );

  React.useEffect(() => {
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({
        map,
        algorithm: new SuperClusterAlgorithm({
          maxZoom: 20 /* Note: this has no effect for SuperCluster when using WebGL vector maps like we do */,
          radius: 30,
        }),
        onClusterClick: (_: google.maps.MapMouseEvent, cluster: Cluster, m: google.maps.Map) => {
          const currentZoom = m.getZoom() ?? 0;

          smoothMoveCamera(m, {
            center: {
              lat: cluster.position.lat(),
              lng: cluster.position.lng(),
            },
            zoom: currentZoom + 1,
          });
        },
        renderer: new ClusterRenderer(),
      });
    }
  }, [map]);

  React.useEffect(() => {
    if (initializedMarkersCount !== digitalMap?.pins?.length) return;

    const visibleMarkers = Object.keys(markers.current)
      .filter((pinIndex) => filteredPins.some((p) => p.index.toString() === pinIndex))
      .map((key) => markers.current[key]);

    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(visibleMarkers));
  }, [digitalMap?.pins?.length, filteredPins, initializedMarkersCount]);

  const setMarkerRef = React.useCallback((marker: Marker | null, key: string) => {
    if (marker) {
      const existingMarker = markers.current[key];
      markers.current[key] = marker;

      if (!existingMarker) {
        setInitializedMarkersCount((prev) => prev + 1);
      }
    } else {
      const existingMarker = markers.current[key];

      if (existingMarker) {
        delete markers.current[key];
        setInitializedMarkersCount((prev) => prev - 1);
      }
    }
  }, []);

  return (
    <>
      {allPins.map((pin) => (
        <PinMarker
          key={pin.index}
          map={map}
          pin={pin}
          isActive={activePinKey === pin.key}
          onClick={onClickPin}
          ref={(marker) => setMarkerRef(marker, pin.index.toString())}
        />
      ))}
    </>
  );
};
