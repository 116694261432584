import { ReduxState } from 'ducks';
import styles from './NotificationsIcon.module.css';
import { useSelector } from 'react-redux';

interface Props {
  onClick: () => void;
}

export const OrdersIcon = ({ onClick }: Props) => {
  const restaurantOrders = useSelector((state: ReduxState) => state.restaurantOrders.orders);

  return (
    <div className={styles['icon-container']}>
      <img
        onClick={onClick}
        src="/static/images/map/burger.svg"
        alt="Orders"
        className={styles['icon']}
      />
      {restaurantOrders.filter((order) => order.status !== 'COMPLETE')?.length > 0 && (
        <div className={styles['active-indicator']} />
      )}
    </div>
  );
};
