import * as React from 'react';
import { useStore } from 'react-redux';
import { useSelector } from 'react-redux';

import config from 'config';
import styles from './Checkout.module.css';
import { Params } from './Params';
import { Payment } from './Payment';
import { ThankYou } from './ThankYou';
import { Form } from 'react-final-form';
import { CheckoutFormValues } from './formValues';
import { generateAgentReference } from 'components/Checkout/getCreateReservationRequest';
import { useDispatch } from 'react-redux';
import { createReservation } from 'ducks/client/reservation';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { CustomerContext } from 'contexts/CustomerContext';
import { useTranslation } from 'react-i18next';
import { useCustomerToken } from 'hooks/useCustomerToken';
import { getCommonRedirectUri } from 'lib/util/customer';
import { MapDisplayContext } from '../MapDisplayContext';
import { FORM_ERROR } from 'final-form';
import { PageLoader } from '../PageLoader/PageLoader';
import { EditPaymentMethod } from '../MyAccount/PaymentSettings/EditPaymentMethod';
import { isRequiredGuestType } from 'lib/util/util';
import { getGuestTypesUsedInProductSummary, getCreateReservationParam } from './util';
import { ReduxState } from 'ducks';
import { getShouldUseGmo } from 'lib/util/getShouldUseGmo';
import { TdsCheckoutModal } from './TdsCheckoutModal';

type Step = 'PARAMS' | 'PAYMENT' | 'THANK_YOU';

export const Checkout = () => {
  const [agentReference, setAgentReference] = React.useState<string>('');

  const [showEditPaymentMethod, setShowEditPaymentMethod] = React.useState(false);
  const {
    productInCart,
    savedCartParams,
    setSavedCartParams,
    setShouldRefreshCustomerReservations,
  } = React.useContext(MapDisplayContext);
  const [activeStep, setActiveStep] = React.useState<Step>(savedCartParams ? 'PAYMENT' : 'PARAMS');

  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();
  const store = useStore();

  const guestTypes = productInCart ? getGuestTypesUsedInProductSummary(productInCart, t) : [];

  const { apiKey } = React.useContext(ApiKeyContext);
  const { fetchingCustomer, customer } = React.useContext(CustomerContext);
  const { accessToken, idProvider } = useCustomerToken();

  const [openTdsCheckoutModal, setOpenTdsCheckoutModal] = React.useState(false);
  const [checkoutValues, setCheckoutValues] = React.useState<CheckoutFormValues | null>(null);
  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const shouldUseGmo = getShouldUseGmo(null, settings, customer?.card_settlement_currency ?? 'USD');
  const digitalMap = useSelector((state: ReduxState) => state.universal.digitalMap.map);

  React.useEffect(() => {
    setAgentReference(generateAgentReference());
  }, []);

  const initialGuestCounts = guestTypes.reduce((acc, guestType) => {
    const minPaxRule = productInCart?.booking_widget_settings?.minimum_participant_rules?.find(
      (rule) => rule.unit === guestType.key
    );
    const minimumParticipants = minPaxRule
      ? minPaxRule.minimum_participants ?? 0
      : isRequiredGuestType(guestType)
      ? 1
      : 0;

    acc[guestType.key] = minimumParticipants > 0 ? minimumParticipants : 0;
    return acc;
  }, {} as Record<string, number>);

  return fetchingCustomer ? (
    <PageLoader />
  ) : (
    <>
      <div className={styles['page-container']}>
        <div className={styles['container']}>
          <Form
            initialValues={
              savedCartParams
                ? savedCartParams
                : {
                    productInstance: null,
                    guestCounts: initialGuestCounts,
                  }
            }
            keepDirtyOnReinitialize
            onSubmit={async (values: CheckoutFormValues) => {
              if (activeStep === 'PARAMS') {
                setSavedCartParams(values);
                setActiveStep('PAYMENT');
                return;
              }
              if (activeStep === 'PAYMENT') {
                if (!customer?.payment_profile_card_info?.last_four_digits) {
                  return { [FORM_ERROR]: t('Please add a payment method before continuing.') };
                }

                if (shouldUseGmo && config.enableMapsGmoPaymentGateway) {
                  setCheckoutValues(values);
                  setOpenTdsCheckoutModal(true);
                  return;
                }

                try {
                  await dispatch(
                    createReservation(
                      apiKey,
                      config.enableMapsGmoPaymentGateway
                        ? getCreateReservationParam({
                            customer: customer ?? null,
                            values,
                            agentReference,
                            language: i18n.language,
                            redirectUri: getCommonRedirectUri(),
                            accessToken: accessToken ?? null,
                            idProvider: idProvider ?? null,
                          })
                        : {
                            settlement_currency: customer?.card_settlement_currency ?? '',
                            product_instance_id: values.productInstance?.id ?? '',
                            guests:
                              Object.entries(values.guestCounts)
                                .map(([key, value]) =>
                                  Array.from({ length: value as number }, () => ({
                                    guest_type_key: key,
                                    field_responses: [],
                                  }))
                                )
                                .flat() ?? [],
                            agent_reference: agentReference,
                            field_responses: [
                              {
                                key: 'email',
                                response: customer?.email ?? '',
                              },
                              {
                                key: 'preferred_language_iso2',
                                response: i18n.language,
                              },
                              ...(customer.kana_given_name && customer.kana_family_name
                                ? [
                                    {
                                      key: 'kana_given_name',
                                      response: customer.kana_given_name,
                                    },
                                    {
                                      key: 'kana_family_name',
                                      response: customer.kana_family_name,
                                    },
                                  ]
                                : []),
                              ...(customer?.given_name && customer?.family_name
                                ? [
                                    {
                                      key: 'given_name',
                                      response: customer?.given_name ?? '',
                                    },
                                    {
                                      key: 'family_name',
                                      response: customer?.family_name ?? '',
                                    },
                                  ]
                                : []),
                            ],
                            payment_type: 'PAID_IN_FULL',
                            booking_source: {
                              source_type: 'DIRECT_WEB',
                            },
                            access_token: accessToken,
                            id_provider: idProvider ?? '',
                            redirect_uri: getCommonRedirectUri(),
                            should_use_customer_payment_info: true,
                            landing_sid: 'maps',
                          },
                      i18n.language
                    )
                  );

                  if (store.getState().reservation.error) {
                    console.error('reservation error', store.getState().reservation.error);

                    return {
                      [FORM_ERROR]: t(
                        'Your payment failed. Please check your credit card number, expiry month/year, and CVC, and try again. If credit card information is correct but still fails, please contact your card issuer or try with different credit card.'
                      ),
                    };
                  } else {
                    setShouldRefreshCustomerReservations(true);
                    setActiveStep('THANK_YOU');
                  }
                } catch (e) {
                  return {
                    [FORM_ERROR]: t('There was an error with your reservation. Please try again.'),
                  };
                }
              }
            }}
            debug={console.log}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {showEditPaymentMethod ? null : activeStep === 'THANK_YOU' ? (
                  <ThankYou />
                ) : activeStep === 'PARAMS' ? (
                  <Params />
                ) : activeStep === 'PAYMENT' ? (
                  <Payment onClickEditPaymentMethod={() => setShowEditPaymentMethod(true)} />
                ) : null}
              </form>
            )}
          </Form>
          {showEditPaymentMethod && (
            <EditPaymentMethod onBackClick={() => setShowEditPaymentMethod(false)} />
          )}
          {openTdsCheckoutModal && (
            <TdsCheckoutModal
              values={checkoutValues}
              digitalMapId={digitalMap?.id ?? ''}
              open={openTdsCheckoutModal}
              onClose={() => {
                setCheckoutValues(null);
                setOpenTdsCheckoutModal(false);
              }}
              onSuccessfulPayment={() => {
                setShouldRefreshCustomerReservations(true);
                setActiveStep('THANK_YOU');
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
