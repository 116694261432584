/**
 * This code was generated by Builder.io
 */
import React, { useState } from 'react';
import styles from './OrderDetails.module.css';
import { useTranslation } from 'react-i18next';
import { useActiveRestaurantOrder } from 'components/Map/RestaurantDetails/useActiveRestaurantOrder';
import { useActiveRestaurant } from 'components/Map/RestaurantDetails/useActiveRestaurant';
import { useCurrencyFormatter } from 'components/Map/useCurrencyFormatter';

const OrderDetails = () => {
  const [expanded, setExpanded] = useState(false);

  const restaurantOrder = useActiveRestaurantOrder();
  const restaurant = useActiveRestaurant();

  const { t } = useTranslation();

  const currencyFormatter = useCurrencyFormatter();

  return (
    <section className={styles.orderCard}>
      <header className={styles.orderHeader} onClick={() => setExpanded(!expanded)}>
        <h2 className={styles.orderTitle}>{t('Order details')}</h2>
        <img
          src="/static/images/map/down_chevron.svg"
          alt=""
          className={styles.expandIcon}
          style={{
            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />
      </header>

      {expanded && (
        <>
          <div className={styles.restaurantInfo}>
            <img
              src={restaurant?.media_items?.find((media) => media.type === 'IMAGE')?.url}
              alt={restaurant?.name}
              className={styles.restaurantImage}
            />
            <div className={styles.restaurantDetails}>
              <h3 className={styles.restaurantName}>{restaurant?.name}</h3>
              <p className={styles.itemCount}>{restaurantOrder?.items?.length} items</p>
            </div>
          </div>

          <hr className={styles.divider} />

          <ul className={styles.orderItems}>
            {restaurantOrder?.billing_info?.line_items?.map((item, idx) => {
              return (
                <>
                  <li key={idx}>
                    {item.quantity}x {item.name}
                  </li>
                  {item.option_groups?.map((optionGroup) =>
                    optionGroup.option_names?.map((optionName) => (
                      <li key={optionName} className={styles.optionRow}>
                        + {optionName}
                      </li>
                    ))
                  )}
                </>
              );
            })}
          </ul>

          <hr className={styles.divider} />

          <div className={styles.orderSummary}>
            <div className={`${styles.summaryRow} ${styles.totalRow}`}>
              <span>{t('Total')}</span>
              <span>{currencyFormatter.format(restaurantOrder?.billing_info?.total || '')}</span>
            </div>
          </div>

          <hr className={styles.divider} />

          <div className={styles.paymentInfo}>
            <div className={styles.paymentDetails}>
              <h4 className={styles.paymentTitle}>{t('Payment')}</h4>
              <div className={styles.cardInfo}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/6a43f2582062c87d3a4f31dda7eff068639815a99e4057d09f07febc7e0384a6?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818"
                  alt=""
                  className={styles.cardIcon}
                />
                <div className={styles.cardDetails}>
                  <span className={styles.cardType}>Master Card</span>
                  <span className={styles.cardNumber}>****4242</span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default OrderDetails;
