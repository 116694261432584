import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReduxState } from 'ducks';
import { formattedCurrencyAmount } from 'lib/util/formattedCurrencyAmount';

export interface CurrencyFormatter {
  format: (s: string) => string;
}

export const useCurrencyFormatter = (): CurrencyFormatter => {
  const { i18n } = useTranslation();
  const settings = useSelector((state: ReduxState) => state.server.settings.all);

  const shouldUseJapaneseFormatting =
    i18n.language === 'ja-JP' &&
    settings.default_timezone === 'Asia/Tokyo' &&
    settings.default_currency === 'JPY';

  const format = React.useCallback(
    (s: string) => {
      const formattedAmount = formattedCurrencyAmount(s);

      return replaceUSDWithSymbol(
        shouldUseJapaneseFormatting ? replaceJPYWithSymbol(formattedAmount) : formattedAmount
      );
    },
    [shouldUseJapaneseFormatting]
  );

  return {
    format,
  };
};

const replaceJPYWithSymbol = (s: string): string => {
  if (s.includes('JPY')) {
    s = `${s.replace(/JPY\s*/, '')}円`;
    if (s === '0円') {
      return '無料';
    }
  }

  return s;
};

const replaceUSDWithSymbol = (s: string): string => {
  if (s.includes('USD')) {
    s = `$${s.replace(/USD\s*/, '')}`;
  }

  return s;
};
