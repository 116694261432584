import * as React from 'react';

import { DirectionsRoute } from '../Map';
import styles from './DirectionsFooterBar.module.css';
import { useTranslation } from 'react-i18next';
import { AnimatedClickableDiv } from '../AnimatedClickableDiv';

interface DirectionsFooterBarProps {
  onClickClose: () => void;
  directionsRoute: DirectionsRoute;
}

export const DirectionsFooterBar = ({
  onClickClose,
  directionsRoute,
}: DirectionsFooterBarProps) => {
  const { distanceInMeters, timeInSeconds, destinationPinName } = directionsRoute;
  const { t, i18n } = useTranslation();

  return (
    <section className={styles.directionsFooterBar}>
      <div className={styles.directionsContent}>
        <AnimatedClickableDiv
          className={styles.closeButton}
          clickedClassName={styles.clicked}
          onClick={onClickClose}
        >
          <img src="/static/images/map/close.svg" className={styles.closeIcon} alt="close" />
        </AnimatedClickableDiv>

        <div className={styles.timeInfo}>
          <div className={styles.timeValue}>
            {t('{{time}} min', { time: Math.round(timeInSeconds / 60) })}
          </div>
          <div className={styles.timeDescription}>
            {t('to {{pinName}}', { pinName: destinationPinName })}
          </div>
        </div>
        <div className={styles.distanceInfo}>
          <img src="/static/images/map/up_arrow.svg" className={styles.distanceIcon} alt="" />
          {i18n.language === 'en' ? (
            <div className={styles.distanceValue}>{`${(distanceInMeters / 1609.34).toFixed(
              1
            )} mi`}</div>
          ) : (
            <div className={styles.distanceValue}>{`${(distanceInMeters / 1000).toFixed(
              1
            )} km`}</div>
          )}
        </div>
      </div>
    </section>
  );
};
