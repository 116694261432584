import _ from 'lodash';

export interface OrderSummaryItem {
  name: string;
  unitPrice: string;
  image: string;
  quantity: number;
  key: string;
  optionGroups: {
    key: string;
    options: {
      key: string;
      name: string;
      price: string;
    }[];
  }[];
}

export const orderSummaryItemsEqual = (item1: OrderSummaryItem, item2: OrderSummaryItem) => {
  if (item1.key !== item2.key) {
    return false;
  }

  const sortedItem1OptionGroups = _.orderBy(item1.optionGroups, 'key');
  const sortedItem2OptionGroups = _.orderBy(item2.optionGroups, 'key');

  if (sortedItem1OptionGroups.length !== sortedItem2OptionGroups.length) {
    return false;
  }

  return sortedItem1OptionGroups.every((group, index) => {
    const sortedItem2OptionGroup = sortedItem2OptionGroups[index];
    return (
      group.key === sortedItem2OptionGroup.key &&
      _.isEqual(_.orderBy(group.options, 'key'), _.orderBy(sortedItem2OptionGroup.options, 'key'))
    );
  });
};
