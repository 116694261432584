/**
 * This code was generated by Builder.io.
 */
import * as React from 'react';
import styles from './MenuItemPage.module.css';
import { MenuItem } from 'models/digitalMap';
import { useTranslation } from 'react-i18next';
import { OptionSelector } from './OptionSelector';
import { useForm } from 'react-final-form';
import { calculateTotalPrice } from '../calculateTotalPrice';
import { useCurrencyFormatter } from 'components/Map/useCurrencyFormatter';
import { ItemSelection } from '../RestaurantDetails';
import { OrderItem } from '../checkoutFormValues';
import { AllergenIcon } from '../AllergenIcon';
interface Props {
  menuItem: MenuItem;
  onAddItemClick: () => void;
  itemSelection: ItemSelection;
  onChange: (itemSelection: ItemSelection) => void;
}

const MenuItemPage: React.FC<Props> = ({ menuItem, onAddItemClick, itemSelection, onChange }) => {
  const { t } = useTranslation();
  const currencyFormatter = useCurrencyFormatter();

  const setQuantity = (quantity: number) => {
    onChange({
      ...itemSelection,
      quantity,
    });
  };
  const orderItem: OrderItem = {
    menuItemKey: menuItem.key,
    optionGroups: itemSelection.optionGroups,
  };

  const form = useForm();

  const addItem = () => {
    setQuantity(itemSelection.quantity + 1);
  };

  const removeItem = () => {
    if (itemSelection.quantity > 0) {
      setQuantity(itemSelection.quantity - 1);
    }
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.carousel}>
            {menuItem.media_items?.map((mediaItem) => (
              <div key={mediaItem.url} className={styles.slide}>
                <img src={mediaItem.url} className={styles.mainImage} />
              </div>
            ))}
          </div>
        </div>
        <h1 className={styles.title}>{menuItem.name}</h1>
        <p className={styles.description}>{menuItem.description}</p>
        {menuItem.allergens?.length > 0 && (
          <div className={styles.allergens}>
            {menuItem.allergens?.map((allergen) => (
              <AllergenIcon allergen={allergen} key={allergen} showText />
            ))}
          </div>
        )}
      </header>

      {menuItem.option_groups?.map((optionGroup) => (
        <OptionSelector
          key={optionGroup.key}
          menuItem={menuItem}
          optionGroup={optionGroup}
          orderItem={orderItem}
          onChangeOrderItem={(orderItem) =>
            onChange({
              ...itemSelection,
              optionGroups: orderItem.optionGroups,
            })
          }
        />
      ))}

      <footer className={styles.footer}>
        <div className={styles.quantityControl}>
          <button
            className={styles.quantityButton}
            onClick={removeItem}
            disabled={itemSelection.quantity === 1}
          >
            -
          </button>
          <span>{itemSelection.quantity}</span>
          <button className={styles.quantityButton} onClick={addItem}>
            +
          </button>
        </div>
      </footer>
      <div className={styles['addToCartButton-container']}>
        <button
          onClick={() => {
            for (let i = 0; i < itemSelection.quantity; i++) {
              form.change('items', [
                ...form.getState().values.items,
                {
                  menuItemKey: menuItem.key,
                  optionGroups: itemSelection.optionGroups,
                },
              ]);
            }
            onAddItemClick();
          }}
          className={styles.addToCartButton}
          disabled={itemSelection.quantity === 0}
        >
          <span className={styles.addToCartText}>{t('Add Item')}</span>
          <span className={styles.totalPrice}>
            {currencyFormatter.format(
              calculateTotalPrice(menuItem, orderItem, itemSelection.quantity)
            )}
          </span>
        </button>
      </div>
    </div>
  );
};

export default MenuItemPage;
